import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isOpenTerms: false,
};

const footer = createSlice({
	name: "footer",
	initialState,
	reducers: {
		toggleShowTerms(state, action) {
			state.isOpenTerms = !action.payload.isOpenTerms;
		},
	},
});

export const { toggleShowTerms } = footer.actions;

export default footer.reducer;
