import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { getMatchingItemsBlank } from "features/functions";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	renderInput: false,
	list: null,
};

const vehicleModels = createSlice({
	name: "vehicleModels",
	initialState,
	reducers: {
		requestVehicleModelsList(state) {
			state.isLoading = true;
		},
		receiveVehicleModelsList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
			state.renderInput = action.payload.renderInput;
		},
		loadVehicleModelsSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateVehicleModelsSuggestions(state, action) {
			state.isLoading = false;
			if (state.value !== action.payload.value) {
			} else {
				state.suggestions = action.payload.suggestions;
			}
		},
		updateVehicleModelValue(state, action) {
			state.value = action.payload.value;
		},
		updateVehicleModelItem(state, action) {
			if (
				action.payload.selectedItem != null &&
				action.payload.selectedItem.Name != null
			) {
				ReactGA.event({
					category: "Vehiculo",
					action: "Seleccionar Modelo",
					label: action.payload.selectedItem.Name,
					value: action.payload.selectedItem.ID,
				});
			}
			state.selectedItem = action.payload.selectedItem;
		},
		clearVehicleModelsSuggestions(state) {
			state.suggestions = [];
		},
		clearVehicleModelValue(state, action) {
			state.value = "";
			state.selectedItem = {};
			state.renderInput = action.payload.renderInput;
		},
		clearVehicleModelData: () => initialState,
	},
});

export const {
	requestVehicleModelsList,
	receiveVehicleModelsList,
	loadVehicleModelsSuggestionsBegin,
	maybeUpdateVehicleModelsSuggestions,
	updateVehicleModelValue,
	updateVehicleModelItem,
	clearVehicleModelsSuggestions,
	clearVehicleModelValue,
	clearVehicleModelData,
} = vehicleModels.actions;

const shouldFetchVehicleModels = (state, brandId) => {
	const brand = state.vehicleBrands.selectedItem;
	if (state.vehicleModels.list == null) {
		if (brand != null) {
			return true;
		}
		return false;
	}
	if (brand?.ID !== brandId) {
		return true;
	}
	return false;
};
export const fetchVehicleModelsIfNeeded =
	(brandId) => async (dispatch, getState) => {
		if (shouldFetchVehicleModels(getState(), brandId)) {
			return dispatch(fetchVehicleModels(brandId));
		}
	};
export const loadVehicleModelsSuggestions =
	(value) => async (dispatch, getState) => {
		dispatch(loadVehicleModelsSuggestionsBegin());
		const theState = getState();
		dispatch(
			fetchVehicleModelsIfNeeded(theState.vehicleBrands.selectedItem.ID)
		);
		setTimeout(() => {
			dispatch(
				maybeUpdateVehicleModelsSuggestions({
					suggestions: getMatchingItemsBlank(theState.vehicleModels, value),
					value,
				})
			);
		});
	};
export const fetchVehicleModels = (brandId) => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	dispatch(requestVehicleModelsList());
	const url = BASE_API_URL + "/api/Vehiculos/Buscar/Modelos?marca=" + brandId;
	return fetch(url, options)
		.then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				return response.text().then((error) => Promise.reject(error));
			}
		})
		.then((json) => {
			dispatch(receiveVehicleModelsList({ list: json, renderInput: true }));
		})
		.catch(() => {
			// TODO: fetch error actions
		});
};

export default vehicleModels.reducer;
