import React from "react";

export default function HomeDealers() {
	return (
		<div id="dealers-view">
			<div className="dealers-stripe">
				<h3>Continental Tire de México</h3>
			</div>
			<div>
				<img src="/images/footer/contishop-footer-banner-2024-06.jpg" style={{width: "100%"}} alt="Recibe tus llantas en casa sin costo."/>
			</div>
		</div>
	);
}
