import React from "react";
import { useDispatch } from "react-redux";
import { SHIP_TYPES } from "features/constants";
import { currencyFormat } from "features/functions";
import { setCartShipType } from "features/cart/cartSlice";
import { fetchDealerShipAddressListIfNeeded } from "features/cart/dealerShipAddressSlice";

export default function CartShipToButton(props) {
	const dispatch = useDispatch();
	// Handlers
	function handleClick() {
		if (
			props.isDisabled == null ||
			(props.isDisabled != null && props.isDisabled === false)
		) {
			dispatch(setCartShipType(props.shipType));
			switch (props.shipType) {
				case SHIP_TYPES.DEALER:
				default:
					dispatch(fetchDealerShipAddressListIfNeeded());
					break;
			}
		}
	}
	// Render functions
	function renderPrice() {
		const formatted = currencyFormat(props.amount);
		const splitted = formatted.split(".");
		if (props.shipType === SHIP_TYPES.ADDRESS) {
			return (
				<p>
					{splitted[0]}
					<span>.{splitted[1]}</span>
				</p>
			);
		}
		return <p>$0</p>;
	}
	// Render
	return (
		<div className="shipto-button" onClick={handleClick}>
			{renderPrice()}
			<p>MXN</p>
		</div>
	);
}
