import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import Modal from "react-modal";
import { setAlert, toggleShowAlert } from "features/alerts/alertsSlice";

export default function AlertModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isOpen, alert } = useSelector((state) => state.alert);
	// Handlers
	function handleClick() {
		ReactGA.send({ hitType: "pageview", page: "/"});
		dispatch(setAlert({ alert: null }));
		dispatch(toggleShowAlert(isOpen));
	}
	// Render
	if (alert == null) {
		return null;
	}
	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal small"
				overlayClassName="modal-overlay"
				onRequestClose={handleClick}
				contentLabel="Alert"
			>
				<div className="modal-inner" id="alert-inner">
					<div className="modal-close" onClick={handleClick}></div>
					<h2>{alert.title}</h2>
					<div className="alert-main">
						<div>{alert.description}</div>
						<p>
							<em>{alert.details}</em>
						</p>
						<div className="modal-button alternate" onClick={handleClick}>
							Cerrar
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
}
