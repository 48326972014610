import { createSlice } from "@reduxjs/toolkit";
import { BASE_API_URL } from "../constants";
import axios from "axios";
import { isServer } from "features/functions";
import ReactGA from "react-ga4";

const initialState = {
	isLoading: false,
	isOpen: false,
	message: null,
};
const activation = createSlice({
	name: "activation",
	initialState,
	reducers: {
		requestActivation(state) {
			state.isLoading = true;
		},
		receiveActivation(state, action) {
			state.isLoading = false;
			state.message = action.payload.message;
		},
		toggleShowActivation(state, action) {
			state.isOpen = !action.payload.isOpen;
		},
		clearActivationData: () => initialState,
	},
});

export const {
	requestActivation,
	receiveActivation,
	toggleShowActivation,
	clearActivationData,
} = activation.actions;

export const fetchActivation = (userId) => async (dispatch) => {
	const headers = isServer
		? {
				"Content-Type": "application/json",
				Origin: [process.env.RAZZLE_SRV_HOST],
		  }
		: {
				"Content-Type": "application/json",
		  };
	const data = JSON.stringify({
		id: userId,
	});
	const options = {
		headers: headers,
	};
	dispatch(requestActivation());
	const url = BASE_API_URL + "/api/Cuenta/Activacion/";
	await axios
		.post(url, data, options)
		.then((response) => {
			ReactGA.event({
				category: "Cuenta",
				action: "Activar Cuenta",
			});
			dispatch(receiveActivation({ message: response.data }));
			dispatch(toggleShowActivation({ isOpen: false }));
		})
		.catch((error) => {
			if (error.response) {
				ReactGA.event({
					category: "Cuenta",
					action: "Error al Activar Cuenta",
				});
				if (error.response.status === "404") {
					dispatch(
						receiveActivation({
							message:
								"No se pudo encontrar la clave de activación, por favor verifica que la url sea correcta.",
						})
					);
				} else {
					if (typeof error.response.data === "object") {
						dispatch(
							receiveActivation({ message: error.response.data.Message })
						);
					} else {
						dispatch(receiveActivation({ message: error.response.data }));
					}
				}
			} else {
				dispatch(
					receiveActivation({
						message: "Ocurrió un error al intentar activar tu cuenta.",
					})
				);
			}
			dispatch(toggleShowActivation({ isOpen: false }));
		});
};

export default activation.reducer;
