import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import qs from "qs";
import Scroll from "react-scroll";
import { isServer } from "features/functions";
import ReactGA from "react-ga4";
import { BASE_API_URL, VEHICLE_TYPES as v } from "../constants";
import { hideHighlightsTiresList } from "./highlightsTiresSlice";
import { setVehicleType } from "features/tireFinder/vehicleTypeSlice";

const initialState = {
	isLoading: false,
	list: null,
	width: null,
	ratio: null,
	rim: null,
	dept: null,
	scroll: false,
};
const sizeTires = createSlice({
	name: "sizeTires",
	initialState,
	reducers: {
		requestSizeTiresList(state) {
			state.isLoading = true;
		},
		receiveSizeTiresList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
			state.width = action.payload.width;
			state.ratio = action.payload.ratio;
			state.rim = action.payload.rim;
			state.dept = action.payload.dept;
		},
		setSizeTiresScroll(state, action) {
			state.scroll = action.payload.scroll;
		},
		clearSizeTiresList(state) {
			state.list = null;
			state.width = null;
			state.ratio = null;
			state.rim = null;
		},
		clearSizeTiresData: () => initialState,
	},
});

export const {
	requestSizeTiresList,
	receiveSizeTiresList,
	setSizeTiresSize,
	setSizeTiresScroll,
	clearSizeTiresList,
	clearSizeTiresData,
} = sizeTires.actions;

const shouldFetchSizeTiresList = (state, newRim) => {
	if (state.sizeTires.list == null) {
		return true;
	}
	const oldRim = state.sizeRims.selectedItem;
	if (oldRim !== newRim) {
		return true;
	}
	return false;
};

export const fetchSizeTiresListIfNeeded =
	(selectedRim) => async (dispatch, getState) => {
		if (shouldFetchSizeTiresList(getState(), selectedRim)) {
			const theState = getState();
			return dispatch(
				fetchSizeTiresList(
					theState.vehicleType.value,
					theState.sizeWidthDiameters.selectedItem.OriginalValue,
					theState.sizeRatios.selectedItem.Value,
					selectedRim.Value
				)
			);
		}
	};

export const fetchSizeTiresList =
	(dept, width, ratio, rim, brand = 0) =>
	async (dispatch, getState) => {
		const headers = {
			"Content-Type": "application/json",
		};
		const options = {
			headers: headers,
		};
		const theState = getState();
		const coupon =
			theState.cart.coupon == null ? null : theState.cart.coupon.Code;
		dispatch(requestSizeTiresList());
		const url =
			coupon != null
				? BASE_API_URL +
				  "/api/Productos/Buscar?anchoseccion=" +
				  width +
				  "&serie=" +
				  ratio +
				  "&rin=" +
				  rim +
				  "&depto=" +
				  dept +
				  "&marca=" +
				  brand +
				  "&cupon=" +
				  coupon
				: BASE_API_URL +
				  "/api/Productos/Buscar?anchoseccion=" +
				  width +
				  "&serie=" +
				  ratio +
				  "&rin=" +
				  rim +
				  "&depto=" +
				  dept +
				  "&marca=" +
				  brand;
		return axios
			.get(url, options)
			.then((response) => {
				ReactGA.send({
					hitType: "pageview",
					page:
						"/llantas/medida/" +
						v.properties[dept].slug +
						"/" +
						width +
						"/" +
						ratio +
						"R" +
						rim,
				});
				dispatch(
					receiveSizeTiresList({ list: response.data, width, ratio, rim, dept })
				);
			})
			.catch((error) => {
				// TODO: fetch error actions
			});
	};
export const fetchSizeStringTireList =
	(vehicleType, size, brand = 0) =>
	async (dispatch, getState) => {
		const headers = isServer
			? {
					"Content-Type": "application/json",
					Origin: [process.env.RAZZLE_SRV_HOST],
			  }
			: {
					"Content-Type": "application/json",
			  };
		const options = {
			headers: headers,
		};
		const theState = getState();
		const coupon =
			theState.cart.coupon == null ? "0" : theState.cart.coupon.Code;
		const vType = Object.keys(v.properties).filter(
			(item) => v.properties[item]["slug"] === vehicleType
		);
		const queryString = {
			depto: vType[0],
			medida: size,
			marca: brand,
			cupon: coupon,
		};
		const url =
			BASE_API_URL +
			"/api/Productos/BuscarMedida?" +
			qs.stringify(queryString, { encodeValuesOnly: true });
		await axios
			.get(url, options)
			.then((response) => {
				const list = response.data;
				if (list != null && list.length > 0) {
					dispatch(
						receiveSizeTiresList({
							list,
							dept: vType[0],
							width: list[0].Width,
							ratio: list[0].Ratio,
							rim: list[0].Rim,
						})
					);
					dispatch(hideHighlightsTiresList({ hide: true }));
					dispatch(setVehicleType({ value: vType[0] }));
					dispatch(setSizeTiresScroll({ scroll: true }));
				}
			})
			.catch((error) => {
				console.log("Error", error);
			});
	};
export const scrollToSizeTires = () => async (dispatch, getState) => {
	const theState = getState();
	if (theState.sizeTires.list != null && theState.sizeTires.scroll) {
		let scroll = Scroll.animateScroll;
		let target = document.getElementById("resultCarousel");
		let targetPos =
			target.offsetWidth > 980 ? target.offsetTop - 100 : target.offsetTop - 50;
		scroll.scrollTo(targetPos, { smooth: true, delay: 100, duration: 500 });
		dispatch(setSizeTiresScroll({ scroll: false }));
	}
};

export default sizeTires.reducer;
