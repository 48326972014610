import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Autosuggest from "react-autosuggest";
import {
	updateNeighborhoodItem,
	updateNeighborhoodValue,
	loadNeighborhoodsSuggestions,
	clearNeighborhoodsSuggestions,
} from "features/account/neighborhoodsSlice";

export default function NighborhoodAutosuggest(props) {
	const dispatch = useDispatch();
	// Selectors
	const { value, suggestions } = useSelector((state) => state.neighborhoods);
	// Handlers
	function handleChange(event, { newValue }) {
		dispatch(updateNeighborhoodValue({ value: newValue }));
	}
	function onSuggestionSelected(event, { suggestion }) {
		dispatch(updateNeighborhoodItem({ selectedItem: suggestion }));
	}
	function onSuggestionsFetchRequested({ value }) {
		dispatch(loadNeighborhoodsSuggestions(value));
	}
	function onSuggestionsClearRequested() {
		dispatch(clearNeighborhoodsSuggestions());
	}
	function getSuggestionValue(suggestion) {
		return suggestion.Nombre;
	}
	function shouldRenderSuggestions() {
		return true;
	}
	function renderSuggestion(suggestion) {
		return <div>{suggestion.Nombre}</div>;
	}
	// Render
	const inputProps = {
		placeholder: "Colonia",
		value,
		onChange: handleChange,
		autoComplete: "erp-neighborhood-from-database",
		onSelect: props.onSelect,
	};
	return (
		<div className="field">
			<Autosuggest
				suggestions={suggestions}
				onSuggestionsFetchRequested={onSuggestionsFetchRequested}
				onSuggestionsClearRequested={onSuggestionsClearRequested}
				getSuggestionValue={getSuggestionValue}
				shouldRenderSuggestions={shouldRenderSuggestions}
				renderSuggestion={renderSuggestion}
				onSuggestionSelected={onSuggestionSelected}
				inputProps={inputProps}
				id="neighborhood"
			/>
		</div>
	);
}
