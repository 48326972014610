import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { getMatchingItemsTireSize } from "features/functions";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	list: null,
	renderInput: false,
	showInput: false,
};

const sizeSaeWidths = createSlice({
	name: "sizeSaeWidths",
	initialState,
	reducers: {
		requestWidthSaesList(state) {
			state.isLoading = true;
		},
		receiveWidthSaesList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
			state.renderInput = action.payload.renderInput;
		},
		loadWidthSaesSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateWidthSaesSuggestions(state, action) {
			state.isLoading = false;
			if (state.value === action.payload.value) {
				state.suggestions = action.payload.suggestions;
			}
		},
		updateWidthSaeValue(state, action) {
			state.value = action.payload.value;
		},
		updateWidthSaeItem(state, action) {
			const selectedItem = action.payload.selectedItem;
			if (selectedItem != null && selectedItem.Value != null) {
				ReactGA.event({
					category: "Medida SAE",
					action: "Seleccionar Ancho",
					label: selectedItem.Value + "",
					value: selectedItem.Value,
				});
			}
			state.selectedItem = selectedItem;
		},
		clearWidthSaesSuggestions(state) {
			state.suggestions = [];
		},
		clearWidthSaeValue(state, action) {
			state.value = "";
			state.selectedItem = {};
			state.list = null;
			state.renderInput = action.payload.renderInput;
			state.showInput = action.payload.showInput;
		},
		clearWidthSaeData: () => initialState,
	},
});

export const {
	requestWidthSaesList,
	receiveWidthSaesList,
	loadWidthSaesSuggestionsBegin,
	maybeUpdateWidthSaesSuggestions,
	updateWidthSaeValue,
	updateWidthSaeItem,
	clearWidthSaesSuggestions,
	clearWidthSaeValue,
	clearWidthSaeData,
} = sizeSaeWidths.actions;

const shouldFetchWidthSaes = (state, newDiameter) => {
	if (state.sizeSaeWidths.list == null) {
		return true;
	}
	const oldDiameter = state.sizeWidthDiameters.selectedItem;
	if (oldDiameter !== newDiameter) {
		return true;
	}
	return false;
};
export const fetchWidthSaesIfNeeded =
	(selectedDiameter) => async (dispatch, getState) => {
		if (shouldFetchWidthSaes(getState(), selectedDiameter)) {
			const theState = getState();
			return dispatch(
				fetchWidthsSae(
					theState.vehicleType.value,
					selectedDiameter.OriginalValue
				)
			);
		}
	};
export const loadWidthSaesSuggestions =
	(value) => async (dispatch, getState) => {
		dispatch(loadWidthSaesSuggestionsBegin());
		let theState = getState();
		if (theState.sizeSaeWidths.list == null) {
			dispatch(
				fetchWidthsSae(
					theState.vehicleType.value,
					theState.sizeWidthDiameters.selectedItem.OriginalValue
				)
			);
		}
		theState = getState();
		return setTimeout(() => {
			dispatch(
				maybeUpdateWidthSaesSuggestions({
					suggestions: getMatchingItemsTireSize(theState.sizeSaeWidths, value),
					value,
				})
			);
		});
	};
export const fetchWidthsSae = (dept, originalValue) => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	dispatch(requestWidthSaesList());
	const url =
		BASE_API_URL +
		"/api/Medidas/AnchoSAE/?depto=" +
		dept +
		"&marca=0&diametro=" +
		originalValue;
	return fetch(url, options)
		.then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				return response.text().then((error) => Promise.reject(error));
			}
		})
		.then((json) => {
			dispatch(receiveWidthSaesList({ list: json, renderInput: true }));
		})
		.catch(() => {
			// TODO: fetch error actions
		});
};

export default sizeSaeWidths.reducer;
