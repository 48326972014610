import React from "react";
import Scroll from "react-scroll";

var ScrollLink = Scroll.Link;
var Element = Scroll.Element;

export default function TermsDisclaimer() {
	const offset = -140;
	const offsetTop = -300;
	return (
		<div id="legal-inner" className="light">
			<h2>
				<Element name="terms-top" className="element">
					Términos y Condiciones de Uso
				</Element>
			</h2>
			<ul className="index">
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="licencia"
						smooth={true}
						duration={500}
					>
						De la Licencia
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="informacion"
						smooth={true}
						duration={500}
					>
						De la Información contenida en <strong>www.contishop.com.mx</strong>
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="politicasPrivacidad"
						smooth={true}
						duration={500}
					>
						De las Políticas de Privacidad Sobre el Uso de Información
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="horariosOperacion"
						smooth={true}
						duration={500}
					>
						De los horarios de Operación
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="gastosEnvio"
						smooth={true}
						duration={500}
					>
						Políticas de entrega y costos de envío
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="cancelaciones"
						smooth={true}
						duration={500}
					>
						De las Cancelaciones
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="garantias"
						smooth={true}
						duration={500}
					>
						De las Garantías y Devoluciones
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="formasPago"
						smooth={true}
						duration={500}
					>
						De las Formas de Pago
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="precios"
						smooth={true}
						duration={500}
					>
						De los Precios
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="disponibilidad"
						smooth={true}
						duration={500}
					>
						Disponibilidad de mercancía y existencias
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="derechosAutor"
						smooth={true}
						duration={500}
					>
						De los Derechos de Autor y Propiedad Industrial
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="publicidad"
						smooth={true}
						duration={500}
					>
						Del Material Publicitario
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="negacionGarantias"
						smooth={true}
						duration={500}
					>
						Negación de Garantías
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="limiteResponsabilidad"
						smooth={true}
						duration={500}
					>
						Límite de Responsabilidades
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="tarjetasRobadas"
						smooth={true}
						duration={500}
					>
						En el caso de uso de tarjetas robadas
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="usoProductos"
						smooth={true}
						duration={500}
					>
						Uso de productos
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="controlParental"
						smooth={true}
						duration={500}
					>
						Control Parental
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="recomendaciones"
						smooth={true}
						duration={500}
					>
						Recomendaciones y Precauciones
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="modificaciones"
						smooth={true}
						duration={500}
					>
						De las Modificaciones a <strong>www.contishop.com.mx</strong>
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="cesionDerechos"
						smooth={true}
						duration={500}
					>
						De la Cesión de Derechos
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="indemnizaciones"
						smooth={true}
						duration={500}
					>
						De las Indemnizaciones
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="terminacion"
						smooth={true}
						duration={500}
					>
						De la Terminación del presente contrato
					</ScrollLink>
				</li>
				<li>
					<ScrollLink
						activeClass="active"
						containerId="login-content"
						offset={offset}
						to="vigencia"
						smooth={true}
						duration={500}
					>
						De la Vigencia
					</ScrollLink>
				</li>
			</ul>
			<p>
				CONTINENTAL Tire México S.A. de C.V. Y SUS FILIALES (en adelante
				"CONTINENTAL"), le dan la más cordial bienvenida. Agradecemos su
				preferencia y nos permitimos informarle sobre algunos puntos importantes
				a considerar durante su visita a <strong>www.contishop.com.mx</strong>.
			</p>
			<p>
				Con el objetivo de ofrecerle un ambiente seguro y agradable para
				nuestros clientes y visitantes, hemos establecido reglas y términos bajo
				los cuales se delimitan las áreas de responsabilidad y derechos, tanto
				de CONTINENTAL como de nuestros visitantes.
			</p>
			<p>
				AL INGRESAR Y UTILIZAR ESTE SITIO DE INTERNET, CUYO NOMBRE DE DOMINIO
				ES: <strong>www.contishop.com.mx</strong>, PROPIEDAD DE CONTINENTAL TIRE
				DE MÉXICO, S.A. DE C.V. Y SUS FILIALES, EN LO SUCESIVO “CONTINENTAL”
				USTED (EL USUARIO) ESTÁ ACEPTANDO LOS TÉRMINOS Y LAS CONDICIONES
				CONTENIDOS EN ESTE CONVENIO Y DECLARA EXPRESAMENTE SU ACEPTACIÓN,
				MANIFESTANDO SU VOLUNTAD EN TÉRMINOS DE LO ESTABLECIDO POR LOS ARTÍCULOS
				1803 Y 1834 BIS DEL CÓDIGO CIVIL FEDERAL, ASI COMO LOS ARTÍCULOS 80, 81,
				89 DEL CÓDIGO DE COMERCIO Y DEMÁS RELATIVOS Y APLICABLES DE LA
				LEGISLACIÓN APLICABLE.
			</p>
			<p>
				SI EL USUARIO NO ACEPTA EN FORMA ABSOLUTA LOS TÉRMINOS Y CONDICIONES DE
				ESTE CONVENIO, DEBERÁ ABSTENERSE DE USAR Y VER{" "}
				<strong> www.contishop.com.mx</strong> PARA EL CASO QUE EL USUARIO
				CONTINÚE EN EL USO DE <strong>www.contishop.com.mx</strong>, DICHA
				ACCIÓN SE CONSIDERARÁ COMO SU ABSOLUTA ACEPTACIÓN A LOS TÉRMINOS Y
				CONDICIONES AQUÍ ESTABLECIDOS. LA SOLA UTILIZACIÓN DEL SITIO LE OTORGA
				AL PÚBLICO EN GENERAL O A QUIEN LO USE, LA CONDICIÓN DE USUARIO (EN
				ADELANTE REFERIDO COMO EL "USUARIO" O LOS "USUARIOS") E IMPLICA LA
				ACEPTACIÓN, PLENA E INCONDICIONAL, DE TODAS Y CADA UNA DE LAS
				CONDICIONES GENERALES Y PARTICULARES INCLUIDAS EN ESTOS TÉRMINOS DE USO
				EN LA VERSIÓN PUBLICADA POR CONTINENTAL EN EL MOMENTO MISMO EN QUE EL
				USUARIO ACCEDA AL SITIO. CUALQUIER MODIFICACIÓN A LOS PRESENTES TÉRMINOS
				DE USO SERÁ REALIZADA CUANDO CONTINENTAL LO CONSIDERE APROPIADO, SIENDO
				EXCLUSIVA RESPONSABILIDAD DEL USUARIO ASEGURARSE DE TOMAR CONOCIMIENTO
				DE TALES MODIFICACIONES.
			</p>
			<p>
				<strong>
					CONTRATO DE ADHESIÓN PARA USO DE SITIO DE INTERNET, QUE CELEBRAN POR
					UNA PARTE CONTINENTAL TIRE DE MÉXICO S.A. DE C.V. Y/O SUS FILIALES (EN
					LO SUCESIVO CONTINENTAL) Y, POR LA OTRA, EL USUARIO, SUJETÁNDOSE,
					AMBAS PARTES, A LO ESTABLECIDO EN EL TEXTO DEL PRESENTE CONTRATO.
				</strong>
			</p>
			<h3>
				<Element id="licencia" name="licencia" className="element">
					De la Licencia
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				Por virtud de la celebración de este convenio, CONTINENTAL otorga y
				concede al Usuario el derecho no exclusivo, revocable y no transferible
				de ver y usar <strong>www.contishop.com.mx</strong> de conformidad con
				los términos y condiciones que se estipulan en este Convenio. Para los
				efectos del presente convenio, las Partes acuerdan que por Usuario se
				entenderá a cualquier persona de cualquier naturaleza que ingrese a{" "}
				<strong>www.contishop.com.mx</strong> y/o a cualquiera de las subpáginas
				que despliegan su contenido.
			</p>
			<p>
				El Usuario sólo podrá imprimir y/o copiar cualquier información
				contenida o publicada en <strong>www.contishop.com.mx</strong>{" "}
				exclusivamente para su uso personal, no comercial. En caso de ser
				persona moral se estará a lo dispuesto por el artículo 148, fracción IV
				de la Ley Federal del Derecho de Autor.
			</p>
			<p>
				La reimpresión, re publicación, distribución, asignación, sub licencia,
				venta, reproducción electrónica o por cualquier otro medio de cualquier
				información, documento o gráfico de o que aparezca en{" "}
				<strong>www.contishop.com.mx</strong>, en todo o en parte, para
				cualquier uso distinto al personal no comercial le está expresamente
				prohibido al Usuario, a menos que CONTINENTAL le haya otorgado su
				autorización previamente y por escrito.
			</p>
			<p>
				Cuando la conducta del Usuario sea contraria a los intereses de
				CONTINENTAL, éste se reserva el derecho de negar el servicio, cancelar
				envíos y finiquitar cuentas o membrecías a su consideración, dejando a
				salvo cualquier otra acción legal que pudiere corresponder.
			</p>
			<h3>
				<Element name="informacion" className="element">
					De la información contenida en <strong>www.contishop.com.mx</strong>
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				El Usuario reconoce y acepta que la información publicada o contenida en{" "}
				<strong>www.contishop.com.mx</strong> generada por CONTINENTAL o por los
				Proveedores (según dicho término se define más adelante), será
				claramente identificada de forma tal que se reconozca que la misma
				proviene de CONTINENTAL o de los Proveedores.
			</p>
			<p>
				Las informaciones, conceptos y opiniones publicadas en{" "}
				<strong>www.contishop.com.mx</strong> no necesariamente reflejan la
				posición de CONTINENTAL ni de sus empleados, oficiales, directores,
				accionistas, licenciatarios y concesionarios (en lo sucesivo los
				"Afiliados"). Por esta razón, CONTINENTAL no se hace responsable por
				ninguna de las informaciones, opiniones y conceptos que se emitan en{" "}
				<strong>www.contishop.com.mx</strong> o a través de los foros públicos o
				de cualquier otro espacio o servicio que se preste a través del sitio.
				Asimismo, CONTINENTAL, no se hace responsable de las consecuencias que
				se puedan derivar del uso de consejos, procedimientos, etc., incluidas
				en la página de Internet, en el entendido de que es bajo el propio
				riesgo y responsabilidad del Usuario el uso y seguimiento a dichos
				consejos, comentarios, procedimientos, tips, etc., que se den en los
				espacios referidos. CONTINENTAL se reserva el derecho de bloquear el
				acceso o remover en forma parcial o total, toda información,
				comunicación o material que a su exclusivo juicio pueda resultar: (i)
				abusivo, difamatorio u obsceno, sexual, racial (ii) fraudulento,
				artificioso o engañoso, (iii) violatorio de derechos de autor, marcas o
				cualquier derecho de propiedad intelectual de un tercero, (iv) ofensivo
				o (v) de cualquier forma contravenga lo establecido en este Convenio.
			</p>
			<p>
				El Usuario reconoce que CONTINENTAL no trata de controlar o censurar
				previamente el contenido disponible en el sitio. Por tal motivo,
				CONTINENTAL no asume ninguna responsabilidad por el contenido provisto
				al sitio por proveedores independientes ajenos a CONTINENTAL.
				CONTINENTAL no tiene el control editorial sobre el contenido y quedará
				exento de cualquier responsabilidad por información y/o material
				generado y/o provisto por terceros.
			</p>
			<p>
				Todas las opiniones, consejos, declaraciones, servicios, ofertas, u
				otras informaciones o contenidos expresados o puestos a disposición del
				público por terceros, pertenecen a su respectivo autor y CONTINENTAL no
				asume responsabilidad alguna frente a ello. CONTINENTAL no garantiza la
				exactitud, veracidad, amplitud y/o utilidad de cualquier contenido
				provisto por tales terceros. Adicionalmente, CONTINENTAL no es
				responsable ni garantiza la exactitud, exhaustividad, veracidad y/o
				confiabilidad de cualquier opinión, información, consejo o declaración
				provistos por CONTINENTAL a través de su sitio. Bajo ninguna
				circunstancia CONTINENTAL será responsable de cualquier daño y/o
				perjuicio, directo o indirecto, causado en virtud de la confianza del
				Usuario en información obtenida a través de este sitio.
			</p>
			<p>
				CONTINENTAL se reserva el derecho de suprimir o modificar el contenido
				del sitio que, a exclusivo juicio de CONTINENTAL, no cumpla con los
				estándares de CONTINENTAL o que pudiera resultar contrario al
				ordenamiento jurídico vigente. Pero CONTINENTAL no será responsable por
				cualquier falla o tardanza que se genere al eliminar tal material.
			</p>
			<p>
				En caso de duda, el cliente y/o Usuario podrá verificar la autenticidad
				de la liga, dirección electrónica o correo electrónico en la que le
				soliciten información personal llamando a nuestro Centro de Atención
				Telefónica (CAT) al D.F. y del interior al 01 (55) 1500 0935 ó enviando
				un correo electrónico a la dirección ventas@contishop.com.mx o entrando
				a la página www.continentaltire.com.mx.
			</p>
			<h3>
				<Element name="politicasPrivacidad" className="element">
					De las Políticas de Privacidad Sobre el Uso de Información
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				Toda la Información Agregada e Información Personal que se obtenga del
				Usuario por el uso de <strong>www.contishop.com.mx</strong>,
				constituirán una base de datos (en lo sucesivo "Base de Datos")
				propiedad de CONTINENTAL, información que se almacena para protegerla y
				evitar su pérdida, uso indebido, o alteración. No obstante lo anterior,
				CONTINENTAL en ninguna forma garantiza su seguridad, ni que la misma
				puede ser interceptada, reproducida o robada por terceros.
			</p>
			<p>
				El Usuario reconoce y acepta que CONTINENTAL no obtiene la Información
				Personal automáticamente, sino que requiere que el Usuario se la
				proporcione en forma directa a través de{" "}
				<strong>www.contishop.com.mx</strong>.
			</p>
			<p>
				Queda a la libre discreción del Usuario, sin responsabilidad para
				CONTINENTAL, proporcionar su Información Personal. En caso de que el
				Usuario decida enviar Información Personal a través de los medios
				dispuestos para tal efecto, el Usuario, para efectos de lo dispuesto en
				el artículo 76bis de la Ley Federal de Protección al Consumidor, otorga
				su consentimiento expreso de manera electrónica, para que CONTINENTAL
				utilice, difunda y/o transmita su información a terceras personas, sin
				su previo consentimiento. Asimismo, para efectos de lo dispuesto en los
				artículo 16, 18 y 18bis de la Ley Federal de Protección al Consumidor,
				el Usuario otorga su consentimiento expreso y aceptación para recibir
				información con carácter o fines mercadotécnicos o publicitarios.
			</p>
			<p>
				En cualquier caso, el Usuario faculta a CONTINENTAL, de la forma más
				amplia que legalmente proceda para utilizar la Información Personal y
				Agregada del Usuario:
			</p>
			<ol className="roman">
				<li>
					-a fin de resolver controversias o disputas relacionadas con{" "}
					<strong>www.contishop.com.mx</strong> y violaciones al presente
					Convenio:
				</li>
				<li>-para posibilitar a CONTINENTAL que opere correctamente; y </li>
				<li>
					-en los casos que la ley así lo requiera. El Usuario faculta a
					CONTINENTAL, de la forma más amplia que legalmente proceda, así como a
					Proveedores y Asesores (según se definen más adelante) y demás
					terceros con quien contrate, a utilizar la Información Personal e
					Información Agregada del Usuario, (incluyendo, sin limitación alguna,
					información sobre el número de ocasiones que ha visto y/o ingresado a
					las ligas de otros sitios o páginas electrónicas a través de los
					anuncios y demás información que se publique en{" "}
					<strong>www.contishop.com.mx</strong>).
				</li>
			</ol>
			<p>
				Debido a que a través de <strong>www.contishop.com.mx</strong> se
				recopilan ciertos tipos de información acerca de los usuarios de este
				sitio y de los servicios que a través de él se ofrecen, es
				importantísimo para CONTINENTAL que el Usuario entienda plenamente
				nuestra política con respeto al uso de la información que reunimos. A
				continuación exponemos la política de privacidad, la cual, le explica
				qué información recopilamos, cómo la usamos y cómo puede corregirse o
				cambiarse.
			</p>
			<p>
				CONTINENTAL puede compartir información estadística agregada sobre el
				uso de <strong>www.contishop.com.mx</strong> con los usuarios del sitio
				de la Web, para adaptar mejor el contenido de la página a las
				necesidades de los Usuarios, con el objeto de prevenirlos sobre los
				actos ilícitos que se cometen en los medios electrónicos.
			</p>
			<p>
				Cualquier cambio en las políticas sobre privacidad para{" "}
				<strong>www.contishop.com.mx</strong>, serán anunciadas aquí, de manera
				que usted siempre pueda saber qué información recogemos, cómo podríamos
				usar dicha información y si la revelaremos a alguien.
			</p>
			<p>
				Para realizar sus compras en línea o acceder a determinados servicios,
				deberá registrar sus datos personales, proporcionar su dirección de
				correo electrónico y elegir una contraseña que le permitirá acceder a
				todas aquellas zonas que requieran una identificación previa. El Usuario
				reconoce que al proporcionar la información de carácter personal
				requerida en alguno de los servicios que se prestan en este sitio web,
				otorga a CONTINENTAL, la autorización señalada en el artículo 109 de la
				Ley Federal del Derecho de Autor. En todos los casos, el Usuario
				responderá de la veracidad de la información proporcionada a
				CONTINENTAL.
			</p>
			<p>
				En <strong>www.contishop.com.mx</strong> estamos especialmente
				preocupados por la seguridad y por asegurar la confidencialidad de los
				datos aportados por nuestros clientes. Por ello{" "}
				<strong>www.contishop.com.mx</strong> se compromete a acatar las
				disposiciones de los artículos 16, 17, 18 bis, 76 bis de la Ley Federal
				de Protección al Consumidor. En el momento en que el Usuario se registre
				en nuestro servidor seguro o realice un pedido, sus datos personales,
				domiciliarios, y los relativos a su forma de pago, serán incorporados a
				nuestra base de datos, utilizándose únicamente para tramitar el pedido,
				así como para enviar información sobre ofertas y servicios que puedan
				resultar de su interés; si es que el Usuario así lo solicita. Por lo
				que, el envío por correo electrónico de promociones será mediante previa
				autorización del Usuario y se podrá dar de baja de este servicio, en el
				momento que el Usuario así lo solicite dando clic en la liga
				correspondiente.
			</p>
			<p>
				Conforme a nuestra garantía de seguridad y confidencialidad, en{" "}
				<strong>www.contishop.com.mx</strong> estamos especialmente interesados
				en ofrecer a nuestros clientes el más alto nivel de seguridad y proteger
				la confidencialidad de los datos que nos aportan. Por ello, las
				transacciones comerciales son realizadas en un entorno de servidor
				seguro bajo protocolo SSL (Secure Socket Layer) y todas las
				comunicaciones se transmiten encriptados,
			</p>
			<p>
				Para efectos de validación y seguridad de la operación, CONTINENTAL
				podrá solicitar en cualquier momento los documentos que avalen la
				autenticidad de la identidad del Usuario (identificación oficial,
				comprobante de domicilio, entre otros documentos)
			</p>
			<h3>
				<Element name="horariosOperacion" className="element">
					De los horarios de Operación
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				<strong>Recepción de Pedidos.</strong>
				<br /> En el sitio <strong>www.contishop.com.mx</strong> las 24
				(veinticuatro) horas del día, los 365 (trescientos sesenta y cinco) días
				del año.
			</p>
			<p>
				<strong>
					Cobro en Línea. (A través del portal Bancomer en línea)
				</strong>
				<br /> En el sitio <strong>www.contishop.com.mx</strong> las 24
				(veinticuatro) horas del día, los 365 (trescientos sesenta y cinco) días
				del año.
			</p>
			<p>
				<strong>Cobro en ventanilla.</strong>
				<br /> En los horarios establecidos por las sucursales Bancomer.
			</p>
			<p>
				<strong>Verificación de Pago en Ventanilla.</strong>
				<br /> De Lunes a Viernes de 9:00 a 12:00 hrs.
			</p>
			<p>
				<strong>Procesamiento de Pedidos.</strong>
				<br /> De Lunes a Viernes de 8:00 a 17:00 hrs.
			</p>
			<p>
				<strong>Facturación.</strong>
				<br /> De Lunes a Viernes de 8:00 a 17:00 hrs.
			</p>
			<p>
				<strong>Envío.</strong>
				<br /> De Lunes a Viernes de 9:00 a 18:00 hrs.
			</p>
			<p>
				<strong>Entregas.</strong>
				<br /> Por Mensajería: De Lunes a Sábado de 9:00 a 18:00 hrs.
			</p>
			<h3>
				<Element name="gastosEnvio" className="element">
					Políticas de entrega y costos de envío
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				<strong>Entrega por mensajería:</strong>
				<br />
				Los clientes que lo deseen, podrán recibir sus productos en el domicilio
				que definan para la entrega.
			</p>
			<p>
				Es importante mencionarle que para poder cumplir con la entrega, es
				necesario que la dirección de facturación y del destinatario contenga
				datos completos.
			</p>
			<ul>
				<li>No hacemos entregas en apartados postales.</li>
				<li>
					No está disponible la entrega de órdenes en direcciones
					internacionales.
				</li>
				<li>La factura se entrega junto con la mercancía.</li>
			</ul>
			<p>Todas las entregas por mensajería son gratis.</p>
			<p>
				Una vez autorizado el cargo por el banco, se realizara una validación
				que puede tomar hasta 72 horas, si es rechazado se cancelara la
				transacción y se reembolsara el pago en 5 días hábiles. Si el cargo es
				autorizado se comienza el proceso de envío de mercancía para entregar al
				cliente.
			</p>
			<p>
				Los pedidos recibidos después de las 18:00 hrs y en días no hábiles,
				serán procesados al siguiente día hábil.
			</p>
			<p>
				La entrega de la mercancía se realizará en un período de 5 (cinco) días
				hábiles una vez procesada la orden de compra, misma que estará sujeta a
				disponibilidad de la mercancía.
			</p>
			<p>
				La entrega se realizará solamente al Usuario registrado, o a las
				personas autorizadas por él, previa presentación de identificación
				oficial (Credencial de Elector, Licencia de Manejo, Pasaporte, Cartilla
				del Servicio Militar Nacional), al domicilio registrado por el Usuario.
				En el caso de que la entrega se haga a un tercero designado por el
				Usuario registrado, el mismo deberá presentar copia de identificación
				oficial vigente del Usuario registrado, así como de la persona que
				recibirá la mercancía.
			</p>
			<p>
				El personal de envíos de Continental Tire de México no esta autorizado
				para realizar el montaje de las llantas.
			</p>
			<p>
				En caso de que la orden de compra no se complete dentro del tiempo antes
				mencionado por diversas circunstancias de inventario, en las que se
				señalan de manera enunciativa más no limitativa, la falta de inventario
				y/o el artículo se encuentre descontinuado, en ese caso CONTINENTAL
				podrá regresar la cantidad pagada por el cliente en los términos y forma
				que convengan ambas Partes.
			</p>
			<h3>
				<Element name="cancelaciones" className="element">
					De las Cancelaciones
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				Una vez recibido el pago, el Usuario no podrá bajo ninguna circunstancia
				cancelar una orden; sin embargo, CONTINENTAL podrá cancelar una orden
				por diversas circunstancias de inventario, en las que se señalan de
				manera enunciativa más no limitativa, la falta de inventario y/o el
				artículo se encuentre descontinuado, en ese caso CONTINENTAL podrá
				regresar la cantidad pagada por el Usuario en los términos y forma que
				convengan ambas partes.
			</p>
			<p>
				Si el Usuario así lo desea, podrá realizar la devolución del artículo
				bajo los términos que se enlistan en el apartado siguiente.
			</p>
			<h3>
				<Element name="garantias" className="element">
					De las Garantías y Devoluciones
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				Si el Usuario desea hacer válida una garantía y/o realizar la devolución
				de un producto adquirido, ya sea porque este no fue de su entera
				satisfacción o porque presenta alguna falla, el Usuario deberá contactar
				a CONTINENTAL a través del Centro de Atención Telefónica 01 (55) 1500
				0935 o al correo electrónico{" "}
				<a href="mailto:contishop.mexico@conti.com.mx">
					contishop.mexico@conti.com.mx
				</a>{" "}
				o a través de{" "}
				<a href="www.continentaltire.mx" target="_blank">
					www.continentaltire.mx
				</a>
				, tomando en cuenta las siguientes consideraciones:
			</p>
			<p>
				En caso de devoluciones por un error atribuible a CONTINENTAL, no se
				cobrará cargo alguno al Usuario .
			</p>
			<p>
				Si el Usuario manifiesta No-Conformidad de materiales o mano de obra,
				deberá someterse al dictamen de un ajustador asignado por CONTINENTAL.
			</p>
			<p>
				Si la devolución obedece a un error del Usuario en la selección del
				producto, el mismo deberá absorber los gastos que se generen por
				concepto de envío y/o dictamen.
			</p>
			<p>
				En caso de que proceda la devolución, CONTINENTAL podrá regresar la
				cantidad (Total o proporcional) pagada por el Usuario en los términos y
				forma que convengan ambas partes.
			</p>
			<p>
				Para más detalles de las políticas de garantía consultar…Documento
				adjunto. De garantías y Devoluciones.
			</p>
			<h3>
				<Element name="formasPago" className="element">
					De las Formas de Pago
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<ol>
				<li>
					Aceptamos las siguientes tarjetas de crédito a través de Openpay:
					<ul>
						<li>VISA</li>
						<li>Mastercard</li>
						<li>American Express</li>
					</ul>
					<p>
						Aceptamos todas las tarjetas VISA, Mastercard o American Express emitidas en México.
					</p>
				</li>
				<li>
					Paynet de Openpay (pago en cualquier establecimiento de la red de
					Paynet. Consultar establecimientos en el siguiente enlace.{" "}
					<a
						href="https://www.paynet.com.mx/"
						target="_blank"
						rel="noopener noreferrer"
					>
						www.paynet.com.mx
					</a>
					)
				</li>
			</ol>
			<p>
				Continental Tire de México, S.A. de C.V. se reserva el derecho de
				solicitar documentos oficiales a sus clientes, como medio de validación
				al proceso de adquisición de productos a través de su sitio en Internet.
			</p>
			<p>
				El número de orden que se asigna al realizar la transacción en el sitio
				de Internet de Continental Tire de México, S.A. de C.V. no implica la
				aceptación de la transacción. En caso de tener algún problema con su
				orden le será comunicado por correo electrónico o vía telefónica.
			</p>
			<h3>
				<Element name="precios" className="element">
					De los precios.
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				Los precios publicados en <strong>www.contishop.com.mx</strong> son
				exclusivos para este canal y no aplican con nuestros distribuidores y
				pueden cambiar sin previo aviso. Si por alguna razón el precio se
				encuentra en $ 0.00 ó $ 0.01, suplicamos nos realice una llamada a los
				teléfonos 01 (55) 1500 0935 donde uno de nuestros ejecutivos le atenderá
				y proporcionará el precio correcto del artículo.
			</p>
			<p>
				Por ninguna razón, se entenderá que aquellos productos que presentan
				precio en $0.00 ó $0.01 no tengan precio o se regalen y los pedidos que
				se realicen bajo esta situación, serán cancelados sin previo aviso, sin
				ninguna responsabilidad para Continental Tire de México, S.A. de C.V..
			</p>
			<h3>
				<Element name="disponibilidad" className="element">
					Disponibilidad de mercancía y existencias.
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				Todos los productos que se ofrecen en el sitio de Internet de
				Continental Tire de México, S.A de C.V., se encuentran sujetos a
				disponibilidad.
			</p>
			<h3>
				<Element name="derechosAutor" className="element">
					De los Derechos de Autor y Propiedad Industrial
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				Los signos distintivos que de manera enunciativa más no limitativa son
				CONTINENTAL, EUZKADI, BARUM, GENERAL TIRE, y{" "}
				<strong>www.contishop.com.mx</strong> y sus logotipos, son marcas,
				nombres de dominio y/o nombres comerciales propiedad de sus respectivos
				titulares protegidas por los tratados internacionales y leyes aplicables
				en materia de propiedad intelectual y derechos de autor.
			</p>
			<p>
				Los derechos de autor sobre el contenido, organización, recopilación,
				compilación, información, transferencias magnéticas o electrónicas,
				conversión digital, logotipos, fotografías, imágenes, programas,
				aplicaciones, o en general cualquier información contenida o publicada
				en <strong>www.contishop.com.mx</strong>, se encuentran debidamente
				protegidos a favor de CONTINENTAL, sus Afiliados, Proveedores y/o de sus
				respectivos propietarios, de conformidad con la legislación aplicable en
				materia de propiedad intelectual e industrial.
			</p>
			<p>
				Se prohíbe expresamente al Usuario modificar, alterar o suprimir, ya sea
				en forma total o parcial, los avisos, marcas, nombres comerciales,
				señas, anuncios, logotipos o en general cualquier indicación que se
				refiera a la propiedad de información contenida en{" "}
				<strong>www.contishop.com.mx</strong>
			</p>
			<p>
				En caso de que el Usuario transmita a CONTINENTAL cualquier información,
				programas, aplicaciones, software o en general cualesquier material que
				requiera ser licenciado a través de{" "}
				<strong>www.contishop.com.mx</strong>, el Usuario le otorga a
				CONTINENTAL en este acto, una licencia perpetua, universal, gratuita, no
				exclusiva, mundial y libre de regalías, incluyendo entre los derechos
				otorgados, el derecho de sublicenciar, vender, reproducir, distribuir,
				transmitir, crear trabajos derivados, exhibirlos y ejecutarlos
				públicamente. Lo establecido en el inciso anterior se aplicará
				igualmente a cualquier otra información que el Usuario envíe o transmita
				a CONTINENTAL (incluyendo, sin limitación alguna, ideas para renovar o
				mejorar el sitio <strong>www.contishop.com.mx</strong>), sea que éstas
				hayan sido incluidas en cualquier área de{" "}
				<strong>www.contishop.com.mx</strong> en virtud de otros medios o modos
				de transmisión ya conocidos o en el futuro desarrollados.
			</p>
			<p>
				Por lo anterior, el Usuario renuncia expresamente en este acto a
				intentar cualquier acción, demanda o reclamación en contra de
				CONTINENTAL, sus Afiliados o Proveedores por cualquier actual o eventual
				violación de cualquier derecho de autor o propiedad intelectual derivado
				de la información, programas, aplicaciones, software, ideas y demás
				material que el propio Usuario envíe a{" "}
				<strong>www.contishop.com.mx</strong>
			</p>
			<p>
				Es nuestra política actuar contra las violaciones que en materia de
				Propiedad Intelectual se pudieran generar u originar según lo estipulado
				en la legislación aplicable y en otras leyes de propiedad intelectual
				aplicables, incluyendo la eliminación o el bloqueo del acceso a material
				que se encuentra sujeto a actividades que infrinjan el derecho de
				propiedad intelectual, de terceros.
			</p>
			<p>
				Para reportar cualquier infracción sobre los contenidos de la página
				web, por favor contacte a:
			</p>
			<p>
				At´n. Guillermo Alagón:
				<br /> e-mail: guillermo.alagon@conti.com.mx
				<br /> DIRECCION COMPLETA Av. Santa Fe No. 170 7 piso Col. Lomas de
				Santa Fe C.P. 01210 México D.F.
				<br /> Teléfono y Fax: 52 (55) 1500 0917 Fax 52 (55) 1500 6725
			</p>
			<p>
				En el caso de que algún Usuario o un tercero consideren que cualquiera
				de los Contenidos que se encuentren o sean introducidos en{" "}
				<strong>www.contishop.com.mx</strong> y/o cualquiera de sus Servicios,
				violen sus derechos de propiedad intelectual, deberán enviar una
				notificación a <strong>www.contishop.com.mx</strong>, a la dirección que
				arriba aparece, en la que indiquen, cuando menos:
			</p>
			<ul>
				<li>
					Los datos personales verídicos (nombre, dirección, número de teléfono
					y dirección de correo electrónico del reclamante)
				</li>
				<li>
					La firma autógrafa con los datos personales del titular de los
					derechos de propiedad intelectual
				</li>
				<li>
					La indicación precisa y completa del (los)Contenido(s) protegido(s)
					mediante los derechos de propiedad intelectual supuestamente
					infringidos, así como de su localización en{" "}
					<strong>www.contishop.com.mx</strong>
				</li>
				<li>
					Una declaración expresa y clara de que la introducción del (los)
					Contenido(s) indicado(s) se ha realizado sin el consentimiento del
					titular de los derechos de propiedad intelectual supuestamente
					infringidos
				</li>
				<li>
					Una declaración expresa, clara y bajo la responsabilidad del
					reclamante de que la información proporcionada en la notificación es
					exacta y de que la introducción del (los) Contenido(s) constituye una
					violación de sus derechos de propiedad intelectual
				</li>
			</ul>
			<h3>
				<Element name="publicidad" className="element">
					Del Material Publicitario
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				El Usuario reconoce y acepta que CONTINENTAL es una organización
				independiente de terceros patrocinadores y anunciantes cuya información,
				imágenes, anuncios y demás material publicitario o promocional (el
				"Material Publicitario") pudiera estar publicado en{" "}
				<strong>www.contishop.com.mx</strong>
			</p>
			<p>
				El Usuario reconoce y acepta que el Material Publicitario no forma parte
				del contenido principal que, se publica en{" "}
				<strong>www.contishop.com.mx</strong>. Asimismo, el Usuario reconoce y
				acepta en este acto, que el Material Publicitario se encuentra protegido
				por las leyes que en materia de propiedad intelectual e industrial
				resulten aplicables.
			</p>
			<h3>
				<Element name="negacionGarantias" className="element">
					Negación de Garantías.
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				El Usuario está de acuerdo que el uso de{" "}
				<strong>www.contishop.com.mx</strong> se realiza bajo su propio riesgo,
				que los servicios y productos que se prestan y ofrecen en{" "}
				<strong>www.contishop.com.mx</strong> se prevén sobre una base "tal
				cual" y "según sean disponibles".
			</p>
			<p>
				CONTINENTAL no garantiza que <strong>www.contishop.com.mx</strong>{" "}
				satisfaga los requerimientos del Usuario, o que los servicios de{" "}
				<strong>www.contishop.com.mx</strong> se mantengan siempre
				ininterrumpidos, en tiempo, seguros o libres de errores.
			</p>
			<p>
				La información contenida y/o publicada a través de{" "}
				<strong>www.contishop.com.mx</strong> sirve para fines exclusivamente de
				información general sobre productos o servicios comercializados por
				CONTINENTAL. Las opiniones, comentarios, referencias, indicaciones o
				consejos contenidos en <strong>www.contishop.com.mx</strong>, aún y
				cuando son emitidas por profesionales de diversas materias, no se
				encaminan a sustituir el consejo o asesoría personalizada y
				especializada por profesionales, de conformidad con las necesidades
				especificas del Usuario. Queda bajo la absoluta responsabilidad del
				Usuario la aplicación, uso o interpretación de cualquier opinión,
				procedimiento, tips, etc., publicada en o a través de{" "}
				<strong>www.contishop.com.mx</strong>.
			</p>
			<p>
				CONTINENTAL no asume responsabilidad alguna por la forma en que el
				Usuario emplee la información contenida o publicada en o a través de{" "}
				<strong>www.contishop.com.mx</strong>
			</p>
			<p>
				CONTINENTAL no garantiza o avala en ninguna forma la veracidad,
				precisión, legalidad, moralidad o ninguna otra característica del
				contenido del material que se publique en{" "}
				<strong>www.contishop.com.mx</strong> CONTINENTAL se libera de cualquier
				responsabilidad y condiciones, tanto expresas como implícitas, en
				relación con los servicios e información contenida o disponible en o a
				través de <strong>www.contishop.com.mx</strong>, incluyendo, sin
				limitación alguna:
			</p>
			<ul>
				<li>
					La disponibilidad de uso de <strong>www.contishop.com.mx</strong>
				</li>
				<li>
					La ausencia de virus, errores, desactivadores o cualesquier otro
					material contaminante o con funciones destructivas en la información o
					programas disponibles en o a través de{" "}
					<strong>www.contishop.com.mx</strong>
				</li>
				<li>
					En general cualquier falla en <strong>www.contishop.com.mx</strong>
				</li>
			</ul>
			<p>
				No obstante lo anterior, CONTINENTAL o sus proveedores podrán actualizar
				el contenido de <strong>www.contishop.com.mx</strong> constantemente,
				por lo que se pide al Usuario tomar en cuenta que, alguna información
				publicitada o contenida en o a través de{" "}
				<strong>www.contishop.com.mx</strong> puede haber quedado obsoleta y/o
				contener imprecisiones o errores tipográficos u ortográficos.
			</p>
			<h3>
				<Element name="limiteResponsabilidad" className="element">
					Limite de Responsabilidades.
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				Hasta el máximo permitido por las leyes aplicables, CONTINENTAL no será
				responsable, en ningún caso, por cualquier daño directo, especial,
				incidental, indirecto, o consecuencia que en cualquier forma se deriven
				o se relacionen con:
			</p>
			<ul>
				<li>
					El uso o ejecución de <strong>www.contishop.com.mx</strong> con el
					retraso o falta de disponibilidad de uso de{" "}
					<strong>www.contishop.com.mx</strong>
				</li>
				<li>
					La proveeduría de o falta de proveeduría de servicios de cualesquier
					información o gráficos contenidos o publicados en o a través de{" "}
					<strong>www.contishop.com.mx</strong>
				</li>
				<li>De la actualización o falta de actualización de la información</li>
				<li>
					Que la información haya sido alterada o modificada, en todo o en
					parte, luego de haber sido incluída en{" "}
					<strong>www.contishop.com.mx</strong>
				</li>
				<li>
					Cualquier otro aspecto o característica de la información contenida o
					publicada en <strong>www.contishop.com.mx</strong> o a través de las
					ligas que eventualmente se incluyan en el mismo
				</li>
				<li>
					La proveeduría o falta de proveeduría que los demás servicios
					establecidos en el presente contrato.{" "}
				</li>
			</ul>
			<p>
				Todos los supuestos anteriores serán vigentes, aún en los casos en que
				se le hubiere notificado o avisado a CONTINENTAL acerca de la
				posibilidad de que se ocasionaran dichos daños.
			</p>
			<h3>
				<Element name="tarjetasRobadas" className="element">
					En el caso de uso de tarjetas robadas
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				CONTINENTAL no se hace responsable por la utilización de tarjetas de
				crédito reportadas como robadas, una vez que la transacción ha sido
				&nbsp;autorizada por el banco emisor. CONTINENTAL y sus empresas
				filiales y subsidiarias, no tiene posibilidad de negar la venta cuando
				los datos del Usuario coinciden con nuestros registros. La clave de
				acceso, contraseña y número de tarjeta de crédito quedan completamente
				bajo responsabilidad del Usuario o propietario. No se hará ninguna
				bonificación por compra fraudulenta.
			</p>
			<h3>
				<Element name="usoProductos" className="element">
					Uso de productos
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				Cualquier uso de los productos adquiridos en nuestro sitio es
				responsabilidad completa del Usuario. CONTINENTAL no acepta ninguna
				relación y/o responsabilidad por cualquier evento que se derive del uso
				de los productos adquiridos en <strong>www.contishop.com.mx</strong>.
			</p>
			<p className="red">
				Una vez entregados los productos al Usuario, este será el único
				responsable del buen uso de los mismos.
			</p>
			<h3>
				<Element name="controlParental" className="element">
					Control Parental
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				Para hacer uso del sitio y de los Servicios, los menores de edad deben
				obtener previamente permiso de sus padres, tutores o representantes
				legales, quienes serán considerados responsables de todos los actos
				realizados por los menores a su cargo. La plena responsabilidad en la
				determinación de los contenidos y Servicios a los que acceden los
				menores de edad corresponde a los mayores a cuyo cargo se encuentran.
			</p>
			<h3>
				<Element name="recomendaciones" className="element">
					Recomendaciones y Precauciones
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<ul>
				<li>
					No escriba su Usuario y/0 Contraseña en lugares de fácil acceso.
				</li>
				<li>
					Desconectar siempre la sesión del navegador después de haber accedido
					a una zona de seguridad o de haber introducido al sistema el Usuario o
					contraseña.
				</li>
				<li>
					Nunca solicitamos información personal a través del correo
					electrónico, por lo que se recomienda al Usuario no proporcionar
					ningún tipo de información que le sea solicitada por este medio.
				</li>
				<li>
					Para realizar cualquier compra u operación se requiere firmarse a la
					página con el Usuario y contraseña que el Usuario registró.{" "}
				</li>
			</ul>
			<p>
				Si en algún momento tiene problemas para acceder a alguna parte de
				nuestro sitio, puede deberse al modelo o versión de su navegador y/o
				sistema operativo o a la configuración de las opciones de los mismos.
			</p>
			<p>
				En caso de duda, el cliente y/o Usuario podrá verificar la autenticidad
				de la liga, dirección electrónica o correo electrónico en la que le
				soliciten información personal llamando a nuestro Centro de Atención
				Telefónica (CAT) &nbsp; D.F. y del interior al 01 (55) 1500 0935 ó
				enviando un correo electrónico a la dirección{" "}
				<a href="mailto:contishop.mexico@contishop.com.mx">
					contishop.mexico@contishop.com.mx
				</a>{" "}
				o accediendo a la página{" "}
				<a href="www.continentaltire.mx" target="_blank">
					www.continentaltire.mx
				</a>
				.
			</p>
			<p>
				El Usuario es el único responsable de mantener confidencial la
				contraseña, números de cuenta, información personal de identificación y
				otros datos que se soliciten u obtengan a través del sitio y es
				responsable del uso que se le dé a su computadora y a la navegación de
				sitios ajenos a <strong>www.contishop.com.mx</strong> que puedan
				comprometer la seguridad e integridad de la información contenida en su
				equipo de cómputo.
			</p>
			<p>
				CONTINENTAL no se hace responsable de errores del cliente o Usuario o
				negligencia al usar los Servicios que se ofrecen a través del sitio y no
				cubre pérdidas ocasionadas por:
			</p>
			<ul>
				<li>Errores de captura o el uso indebido del servicio.</li>
				<li>
					Negligencia en el manejo o compartir la contraseña que ocasione
					accesos no autorizados a cuentas.
				</li>
				<li>Dejar la computadora sin atención durante una sesión en línea.</li>
			</ul>
			<h3>
				<Element name="modificaciones" className="element">
					De las Modificaciones a <strong>www.contishop.com.mx</strong>
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				CONTINENTAL podrá en cualquier momento y cuando lo considere
				conveniente, sin necesidad de aviso al Usuario, realizar correcciones,
				adiciones, mejoras o modificaciones al contenido, presentación,
				información, servicios, áreas, bases de datos y demás elementos de{" "}
				<strong>www.contishop.com.mx</strong> sin que ello de lugar ni derecho a
				ninguna reclamación o indemnización, ni que lo mismo implique
				reconocimiento de responsabilidad alguna a favor del Usuario.
			</p>
			<p>
				CONTINENTAL se reserva el derecho de modificar los términos y
				condiciones de éste Convenio en cualquier tiempo, siendo efectivas
				dichas modificaciones de forma inmediata:
			</p>
			<ul>
				<li>
					Por la publicación en <strong>www.contishop.com.mx</strong> del
					Convenio modificado ó
				</li>
				<li>Por la notificación al Usuario sobre dichas modificaciones. </li>
			</ul>
			<p>
				El Usuario está de acuerdo en revisar este Convenio periódicamente a fin
				de estar al tanto de dichas modificaciones. No obstante lo anterior,
				cada ocasión que el Usuario accede a{" "}
				<strong>www.contishop.com.mx</strong> se considerará como aceptación
				absoluta a las modificaciones del Convenio.
			</p>
			<p>
				Ocasionalmente, CONTINENTAL podrá adicionar los términos y condiciones
				del presente Convenio con provisiones adicionales relativas a áreas
				específicas o nuevos servicios que se proporcionen en o a través de{" "}
				<strong>www.contishop.com.mx</strong> ("Términos Adicionales"), los
				cuales serán publicados en las áreas específicas o nuevos servicios de{" "}
				<strong>www.contishop.com.mx</strong> para su lectura y aceptación. El
				Usuario reconoce y acepta que dichos Términos Adicionales forman parte
				integrante del presente Convenio para todos los efectos legales a que
				haya lugar.
			</p>
			<h3>
				<Element name="cesionDerechos" className="element">
					De la Cesión de Derechos
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				CONTINENTAL podrá, en cualquier tiempo y cuando así lo estime
				conveniente, ceder total o parcialmente sus derechos y obligaciones
				derivados del presente Convenio. En virtud de dicha cesión, CONTINENTAL
				quedará liberado de cualquier obligación a favor del Usuario establecida
				en el presente convenio.
			</p>
			<h3>
				<Element name="indemnizaciones" className="element">
					De las Indemnizaciones
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				El Usuario está de acuerdo en indemnizar a CONTINENTAL, sus Afiliados,
				Proveedores, Vendedores y Asesores de cualquier acción, demanda o
				reclamación (incluso de honorarios de abogados y de costas judiciales)
				derivadas de cualesquier incumplimiento por parte del Usuario al
				presente Convenio, incluyendo, sin limitación de alguna de las
				derivadas:
			</p>
			<ul>
				<li>
					De cualquier aspecto relativo al uso de{" "}
					<strong>www.contishop.com.mx</strong>
				</li>
				<li>
					De la información contenida o disponible en o a través de{" "}
					<strong>www.contishop.com.mx</strong>
				</li>
				<li>
					De injurias, difamación o cualesquier otra conducta violatoria del
					presente convenio por parte del Usuario en el uso de{" "}
					<strong>www.contishop.com.mx</strong>
				</li>
				<li>
					De violación a las leyes aplicables o tratados internacionales
					relativos a los derechos de autor o propiedad intelectual, contenidos
					o disponibles en, o a través de <strong>www.contishop.com.mx</strong>
				</li>
			</ul>
			<h3>
				<Element name="terminacion" className="element">
					De la Terminación del presente contrato
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				CONTINENTAL se reserva el derecho, a su exclusiva discreción, y sin
				necesidad de aviso o notificación al Usuario, para:
			</p>
			<ul>
				<li>Terminar definitivamente el presente Convenio</li>
				<li>
					Descontinuar o dejar de publicar definitivamente{" "}
					<strong>www.contishop.com.mx</strong> sin responsabilidad alguna para
					CONTINENTAL, sus Afiliados o Proveedores
				</li>
			</ul>
			<h3>
				<Element name="vigencia" className="element">
					De la Vigencia
				</Element>
			</h3>
			<p className="right">
				<ScrollLink
					activeClass="active"
					containerId="login-content"
					offset={offsetTop}
					to="terms-top"
					smooth={true}
					duration={500}
				>
					Arriba
				</ScrollLink>
			</p>
			<p>
				Estos términos y condiciones, así como los Términos Adicionales
				constituyen el acuerdo íntegro entre las partes, y sustituye cualquier
				otro acuerdo o convenio celebrado con anterioridad. Cualesquier cláusula
				o provisión del presente Convenio así como de los Términos Adicionales,
				legalmente declarada inválida, será eliminada o modificada, a elección
				de CONTINENTAL, a fin de corregir su vicio o defecto. Sin embargo, el
				resto de las cláusulas o provisiones mantendrán su fuerza,
				obligatoriedad y validez.
			</p>
			<p>
				El presente convenio tendrá un vigencia indefinida y obligatoria para
				ambas Partes; no obstante lo anterior, CONTINENTAL se reserva el derecho
				de modificar en cualquier momento su contenido, así como de terminar la
				vigencia del mismo sin necesidad de previo aviso o declaración judicial
				alguna.
			</p>
		</div>
	);
}
