import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { getMatchingItems } from "features/functions";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	list: null,
};

const vehicleBrands = createSlice({
	name: "vehicleBrands",
	initialState,
	reducers: {
		requestVehicleBrandsList(state) {
			state.isLoading = true;
		},
		receiveVehicleBrandsList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		loadVehicleBrandsSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateVehicleBrandsSuggestions(state, action) {
			state.isLoading = false;
			if (state.value !== action.payload.value) {
			} else {
				state.suggestions = action.payload.suggestions;
			}
		},
		updateVehicleBrandValue(state, action) {
			state.value = action.payload.value;
		},
		updateVehicleBrandItem(state, action) {
			if (
				action.payload.selectedItem != null &&
				action.payload.selectedItem.Name != null
			) {
				ReactGA.event({
					category: "Vehiculo",
					action: "Seleccionar Marca",
					label: action.payload.selectedItem.Name,
					value: action.payload.selectedItem.ID,
				});
			}
			state.selectedItem = action.payload.selectedItem;
		},
		clearVehicleBrandsSuggestions(state) {
			state.suggestions = [];
		},
		clearVehicleBrandValue(state) {
			state.value = "";
			state.selectedItem = {};
		},
		clearVehicleBrandData: () => initialState,
	},
});

export const {
	requestVehicleBrandsList,
	receiveVehicleBrandsList,
	loadVehicleBrandsSuggestionsBegin,
	maybeUpdateVehicleBrandsSuggestions,
	updateVehicleBrandValue,
	updateVehicleBrandItem,
	clearVehicleBrandsSuggestions,
	clearVehicleBrandValue,
	clearVehicleBrandData,
} = vehicleBrands.actions;

const shouldFetchVehicleBrands = (state) => {
	if (state.vehicleBrands.list == null) {
		return true;
	}
	return false;
};
export const fetchVehicleBrandsIfNeeded = () => async (dispatch, getState) => {
	if (shouldFetchVehicleBrands(getState())) {
		return dispatch(fetchVehicleBrands());
	}
};
export const loadVehicleBrandsSuggestions =
	(value) => async (dispatch, getState) => {
		dispatch(loadVehicleBrandsSuggestionsBegin());
		dispatch(fetchVehicleBrandsIfNeeded());
		const theState = getState();
		setTimeout(() => {
			dispatch(
				maybeUpdateVehicleBrandsSuggestions({
					suggestions: getMatchingItems(theState.vehicleBrands, value),
					value,
				})
			);
		});
	};
export const fetchVehicleBrands = () => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	dispatch(requestVehicleBrandsList());
	return fetch(BASE_API_URL + "/api/Vehiculos/Buscar/Marcas", options)
		.then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				return response.text().then((error) => Promise.reject(error));
			}
		})
		.then((json) => {
			dispatch(receiveVehicleBrandsList({ list: json }));
		})
		.catch(() => {
			// TODO: fetch error actions
		});
};

export default vehicleBrands.reducer;
