import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import ReactGA from "react-ga4";
import axios from "axios";
import { BASE_API_URL } from "../constants";
import {
	clearCartShipAddress,
	setCartShipAddress,
} from "features/cart/cartSlice";
import { updateCityValue } from "./citiesSlice";
import { updateCountyValue } from "./countiesSlice";
import { setAlert, toggleShowAlert } from "features/alerts/alertsSlice";
import { runUnauthorizedActions } from "features/account/loginSlice";
import { response } from "express";

const initialState = {
	isLoading: false,
	list: null,
	isOpenAdd: false,
	isOpen: false,
	selectedState: null,
	city: { Cd: "", Municipio: "" },
	errorMsg: null,
};

const shipAddress = createSlice({
	name: "shipAddress",
	initialState,
	reducers: {
		requestShipAddress(state) {
			state.isLoading = true;
		},
		receiveShipAddress(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		toggleShowShipAddressAdd(state, action) {
			if (!action.payload.isOpenAdd) {
				ReactGA.event({
					category: "Cuenta",
					action: "Agregar Dirección de Envío"});
			}
			state.isOpenAdd = !action.payload.isOpenAdd;
		},
		toggleShowShipAddress(state, action) {
			if (!action.payload.isOpen) {
				ReactGA.event({
					category: "Cuenta",
					action: "Mostrar Direcciones de Envío"});
			}
			state.isOpen = !action.payload.isOpen;
		},
		setShipAddressSelectedState(state, action) {
			state.selectedState = action.payload.selectedState;
		},
		requestShipAddressCity(state) {
			state.isLoading = true;
		},
		receiveShipAddressCity(state, action) {
			state.isLoading = false;
			state.city = action.payload.city;
		},
		setShipAddressErrorMessage(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
		setShipAddressAddFinish(state) {
			state.isLoading = false;
		},
		clearShipAddressAddData(state) {
			state.isLoading = false;
			state.isOpenAdd = false;
			state.selectedState = null;
			state.city = { Cd: "", Municipio: "" };
			state.errorMsg = null;
		},
		clearShipAddressData: () => initialState,
	},
});

export const {
	requestShipAddress,
	receiveShipAddress,
	toggleShowShipAddressAdd,
	toggleShowShipAddress,
	setShipAddressSelectedState,
	requestShipAddressCity,
	receiveShipAddressCity,
	setShipAddressErrorMessage,
	setShipAddressAddFinish,
	clearShipAddressAddData,
	clearShipAddressData,
} = shipAddress.actions;

const shouldFetchShipAddressList = (state) => {
	const shipAddress = state.shipAddress;
	if (shipAddress.list == null) {
		if (!shipAddress.isLoading) {
			return true;
		}
		return false;
	}
	return false;
};
export const fetchShipAddressListIfNeeded =
	() => async (dispatch, getState) => {
		await sessionService.loadSession().then(() => {
			if (shouldFetchShipAddressList(getState())) {
				return dispatch(fetchShipAddress());
			}
			const theState = getState();
			if (theState.shipAddress.list !== null) {
				if (
					theState.shipAddress.list.length > 0 &&
					theState.cart.shipAddress === null
				) {
					return dispatch(
						setCartShipAddress({
							shipAddress: 0,
							shipAddressItem: theState.shipAddress.list[0],
						})
					);
				}
			}
		});
	};

export const fetchShipAddress = () => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				//method: "GET",
				headers: headers,
			};
			//let responseStatus;
			dispatch(requestShipAddress());
			const url = BASE_API_URL + "/api/Cuenta/MiCuenta/MisDirecciones/2";
			return axios
				.get(url, options)
				.then((response) => {
					if (response.data.Envio.length === 0) {
						dispatch(clearCartShipAddress());
						dispatch(receiveShipAddress({ list: null }));
					} else {
						dispatch(
							setCartShipAddress({
								shipAddress: 0,
								shipAddressItem: response.data.Envio[0],
							})
						);
						dispatch(receiveShipAddress({ list: response.data.Envio }));
					}
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};
export const fetchShipAddressCity = (zipCode) => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	dispatch(requestShipAddressCity());
	return fetch(BASE_API_URL + "/api/Cuenta/Ciudad/" + zipCode, options)
		.then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				return response.text().then((error) => Promise.reject(error));
			}
		})
		.then((json) => {
			dispatch(receiveShipAddressCity({ city: json }));
			dispatch(updateCityValue({ value: json.Cd }));
			dispatch(updateCountyValue({ value: json.Municipio }));
		})
		.catch(() => {
			// TODO: fetch error actions
		});
};

export const fetchAddShipAddress = (address) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const body = JSON.stringify({
				tipo: 2,
				alias: address.alias,
				tel: address.phone,
				calle: address.street,
				numext: address.ext,
				numint: address.int,
				cp: address.zip,
				col: address.neighborhood,
				cd: address.city,
				mpio: address.county,
				edo: address.stateId,
				rfc: "",
				rsoc: "",
				lat: "",
				lon: "",
			});
			dispatch(requestShipAddress());
			const url = BASE_API_URL + "/api/Cuenta/MiCuenta/AgregaDireccion/";
			return axios
				.post(url, body, options)
				.then((response) => {
					if (response.data.Envio.length > 0) {
						dispatch(receiveShipAddress({ list: response.data.Envio }));
						dispatch(
							setCartShipAddress({
								shipAddress: 0,
								shipAddressItem: response.data.Envio[0],
							})
						);
					} else {
						dispatch(clearCartShipAddress());
						dispatch(receiveShipAddress({ list: null }));
					}
					dispatch(toggleShowShipAddressAdd({ isOpenAdd: true }));
					dispatch(setShipAddressErrorMessage({ errorMsg: null }));
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
					// If 400 BadRequest model state
					if (error.response.status === 400) {
						if (error.response.data.Message != null) {
							dispatch(
								setAlert({
									title: "¡Lo sentimos!",
									description:
										"No se ha podido agregar la dirección. Verifique los datos y vuelva a intentar.",
									details: error.response.data.Message,
								})
							);
							dispatch(setInvoiceAddressAddFinish());
							dispatch(
								setInvoiceAddressErrorMessage({
									errorMsg: { error: error.response.data.Message },
								})
							);
							dispatch(toggleShowAlert({ isOpen: false }));
						}
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default shipAddress.reducer;
