import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import SliderItem from "./SliderItem";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Loader from "components/Loader";

const sliderDivStyle = {
	height: "100%",
};

export default function BannerSlider() {
	// Selectors
	const { isLoading, list } = useSelector((state) => state.banners);
	// Render Functions
	function renderLoader() {
		if (isLoading) {
			return <Loader />;
		}
		return null;
	}
	function renderCarousel() {
		if (!isLoading && list != null) {
			var settings = {
				dots: false,
				infinite: true,
				slidesToShow: 1,
				speed: 500,
				autoplaySpeed: 4000,
				autoplay: true,
				swipeToSlide: true,
			};
			return (
				<Slider {...settings}>
						{list.map((el, index) =>{
							return (
								<SliderItem 
									key={index} 
									index={index} 
									image={"/images/slider/" + el.Imagen} 
									name={el.Titulo} 
									text={el.Texto}
									targetUrl={el.Url} />
							)
						})}
				</Slider>
			);
		}
		return null;
	}
	return (
		<div className="main-slider" style={sliderDivStyle}>
			<div className="content-box">
				{renderLoader()}
				{renderCarousel()}
			</div>
		</div>
	);
}
