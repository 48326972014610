import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_API_URL } from "../constants";
import { isServer } from "features/functions";

const initialState = {
	isLoading: false,
	list: null,
};

const banners = createSlice({
	name: "banners",
	initialState,
	reducers: {
		requestBanners(state) {
			state.isLoading = true;
		},
		receiveBanners(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
	},
});

export const { requestBanners, receiveBanners } = banners.actions;

const shouldFetchBannersList = (state) => {
	if (state.banners.list == null) {
		return true;
	}
	return false;
};
export const fetchBannersListIfNeeded = () => async (dispatch, getState) => {
	if (shouldFetchBannersList(getState())) {
		return dispatch(fetchBannersList());
	}
};
export const fetchBannersList = () => async (dispatch) => {
	const headers = isServer
		? {
				"Content-Type": "application/json",
				Origin: [process.env.RAZZLE_SRV_HOST],
		  }
		: {
				"Content-Type": "application/json",
		  };
	const options = {
		//method: "GET",
		headers: headers,
	};
	dispatch(requestBanners());
	const url = BASE_API_URL + "/api/Banners";
	await axios
		.get(url, options)
		.then((response) => {
			dispatch(receiveBanners({ list: response.data }));
		})
		.catch((error) => {});
};

export default banners.reducer;
