import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import { toggleShowOrderConfirmation } from "features/orders/orderConfirmationSlice";
import Loader from "../Loader";
import OrderConfirmation from "./OrderConfirmation";
import { useHistory, useLocation } from "react-router";

export default function OrderConfirmationModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, isOpen, order, orderId } = useSelector(
		(state) => state.orderConfirmation
	);
	// History
	const history = useHistory();
	const location = useLocation();
	// Handlers
	function handleModalClose() {
		dispatch(toggleShowOrderConfirmation({ isOpen }));
		if (location !== "/") {
			history.replace("/");
		}
	}
	// Render functions
	function renderLoader() {
		if (isLoading) {
			return (
				<div className="order-main">
					<Loader msg="Cargando..." />
				</div>
			);
		}
	}
	function renderOrderConfirmation() {
		if (!isLoading) {
			return <OrderConfirmation />;
		}
		return null;
	}
	// Render
	if (!isLoading && (orderId == null || order == null)) {
		return null;
	}
	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal"
				overlayClassName="modal-overlay"
				contentLabel="Carrito"
			>
				<div className="modal-inner" id="order-inner">
					<div className="modal-close" onClick={handleModalClose}></div>
					<div>
						<span className="logo-conti"></span>
					</div>
					<h2>Pedido ContiShop</h2>
					{renderLoader()}
					{renderOrderConfirmation()}
				</div>
			</Modal>
		</div>
	);
}
