import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import Modal from "react-modal";
import es from "date-fns/locale/es";
import {
	PAYMENT_METHODS as p,
	SHIP_TYPES as s,
	ORDER_STATUS_TYPES as o,
} from "features/constants";
import { currencyFormat } from "features/functions";
import {
	toggleShowOrdersList,
	fetchOrderIfNeeded,
} from "features/orders/ordersSlice";
import Loader from "../Loader";

export default function AccountOrders() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, list, isOpen } = useSelector((state) => state.orders);
	// Handlers
	function handleClose() {
		dispatch(toggleShowOrdersList({ isOpen }));
	}
	function handleOrderOpen(selected) {
		dispatch(fetchOrderIfNeeded(selected));
	}
	// Render functions
	function renderHistoryList() {
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		if (list != null && list.length > 0) {
			return (
				<div className="history-list">
					<div>
						{list.map((element, index) => {
							return (
								<div onClick={() => handleOrderOpen(element)} key={index}>
									<div>{element.Numero}</div>
									<div>
										{format(parseISO(element.Fecha), "dd/MMM/yyyy", {
											locale: es,
										})}
										<span>
											{" "}
											{format(parseISO(element.Fecha), "hh:mm aaaa", {
												locale: es,
											})}
										</span>
									</div>
									<div>{currencyFormat(element.Total)}</div>
									<div>{p.properties[element.MetodoPago].shortname}</div>
									<div>{s.properties[element.TipoEnvio].shortname}</div>
									<div>
										<span
											className={o.properties[element.Estatus].className}
										></span>
										<span className="status-text">
											{o.properties[element.Estatus].shortname}
										</span>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			);
		}
		return <div>No hay pedidos que mostrar.</div>;
	}
	// Render
	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal"
				overlayClassName="modal-overlay"
				onRequestClose={handleClose}
				contentLabel="Nueva Dirección de Envío"
			>
				<div className="modal-inner" id="history-inner">
					<div className="modal-close" onClick={handleClose}></div>
					<h2>Historial de Pedidos</h2>
					<div className="history-content">
						<div className="history-headers">
							<div>
								<div>Num</div>
								<div>Fecha</div>
								<div>Importe</div>
								<div>Forma Pago</div>
								<div>Tipo Envío</div>
								<div>Estatus</div>
							</div>
						</div>
						{renderHistoryList()}
					</div>
				</div>
			</Modal>
		</div>
	);
}
