import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	toggleShowOrdersList,
	fetchOrdersList,
} from "features/orders/ordersSlice";
import { toggleShowShipAddress } from "features/account/shipAddressSlice";
import { toggleShowInvoiceAddress } from "features/account/invoiceAddressSlice";
import { toggleShowAccountData } from "features/account/accountSlice";
import { doLogout } from "features/account/loginSlice";
import { toggleShowAccountCards } from "features/account/accountCardsSlice";

export default function AccountMenu() {
	const dispatch = useDispatch();
	// Selectors
	const { user } = useSelector((state) => state.session);
	// State
	const [dropdownClass, setDropdownClass] = useState(
		"dropdown-menu dropdown-select"
	);
	const [dropdownIsActive, setDropdownIsActive] = useState(false);
	// Handlers
	function handleBlur() {
		setDropdownClass("dropdown-menu dropdown-select");
		setDropdownIsActive(false);
	}
	function handleMouseDown(e) {
		if (dropdownIsActive) {
			e.preventDefault();
			e.stopPropagation();
		}
		return;
	}
	function toggleShowMenu() {
		if (dropdownIsActive) {
			setDropdownClass("dropdown-menu dropdown-select");
		} else {
			setDropdownClass("dropdown-menu dropdown-select show-menu");
		}
		setDropdownIsActive(!dropdownIsActive);
	}
	function handleShowOrders() {
		toggleShowMenu();
		dispatch(toggleShowOrdersList({ isOpen: false }));
		dispatch(fetchOrdersList());
	}
	function handleShowCards() {
		toggleShowMenu();
		dispatch(toggleShowAccountCards({ isOpen: false }));
	}
	function handleShowShipAddress() {
		toggleShowMenu();
		dispatch(toggleShowShipAddress({ isOpen: false }));
	}
	function handleShowInvoiceAddress() {
		toggleShowMenu();
		dispatch(toggleShowInvoiceAddress({ isOpen: false }));
	}
	function handleShowDetails() {
		toggleShowMenu();
		dispatch(toggleShowAccountData({ isOpen: false }));
	}
	function handleLogOut() {
		toggleShowMenu();
		dispatch(doLogout());
	}
	// Render
	const buttonText = user.user != null ? user.user.name : "Mi Cuenta";
	return (
		<div id="account">
			<div
				className="dropdown"
				tabIndex={1}
				onMouseDown={handleMouseDown}
				onBlur={handleBlur}
			>
				<div className="dropdown-container">
					<p onClick={toggleShowMenu} className="dropdown-button">
						<span className="menu-icon"></span>
						<span className="mainmenu-text">{buttonText}</span>
					</p>
					<ul tabIndex="2" className={dropdownClass}>
						<li onClick={handleShowOrders}>
							<span className="order-icon"></span>Pedidos
						</li>
						<li onClick={handleShowCards}>
							<span className="card-icon"></span>Medios de Pago
						</li>
						<li onClick={handleShowInvoiceAddress}>
							<span className="invoice-icon"></span>Datos Facturación
						</li>
						<li onClick={handleShowShipAddress}>
							<span className="address-icon"></span>Direcciones Envío
						</li>
						<li onClick={handleShowDetails}>
							<span className="userinfo-icon"></span>Mis Datos
						</li>
						<li className="dark" onClick={handleLogOut}>
							<span className="logout-icon"></span>Cerrar Sesión
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
