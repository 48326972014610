import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { CFDI_TYPE } from "features/constants";
import { BASE_API_URL } from "features/constants";

const initialState = {
	isLoading: false,
	list: null,
};

const cfdiRegime = createSlice({
	name: "cfdiRegime",
	initialState,
	reducers: {
		requestCfdiRegimeList: (state) => {
			state.isLoading = true;
		},
		receiveCfdiRegimeList: (state, action) => {
			state.list = action.payload.list;
			state.isLoading = false;
		},
	},
});

export const {
	requestCfdiRegimeList,
	receiveCfdiRegimeList,
} = cfdiRegime.actions;

export const fetchCfdiRegimeList = () => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	const options = {
		headers: headers,
	};
	dispatch(requestCfdiRegimeList());
	const url = BASE_API_URL + "/api/Cfdi/RegimenesFiscales";
	await axios
		.get(url, options)
		.then((response) => {
			dispatch(receiveCfdiRegimeList({ list: response.data }));
		})
		.catch((error) => {
			dispatch(receiveCfdiRegimeList({ list: [] }));
		});
};

export default cfdiRegime.reducer;
