import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { sessionService } from "redux-react-session";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { runUnauthorizedActions } from "./loginSlice";
import { toggleShowAccountData } from "./accountSlice";

const initialState = {
	isLoading: false,
	isOpen: false,
	errorMsg: null,
};

const accountEditSlice = createSlice({
	name: "accountEdit",
	initialState,
	reducers: {
		requestAccountEdit(state) {
			state.isLoading = true;
		},
		receiveAccountEdit(state) {
			state.isLoading = false;
		},
		toggleShowAccountEdit(state, action) {
			if (!action.payload.isOpen) {
				ReactGA.event({ category: "Cuenta", action: "Editar Datos" });
			}
			state.isOpen = !action.payload.isOpen;
		},
		setAccountEditErrorMsg(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
		clearAccountEditData: () => initialState,
	},
});

export const {
	requestAccountEdit,
	receiveAccountEdit,
	toggleShowAccountEdit,
	setAccountEditErrorMsg,
	clearAccountEditData,
} = accountEditSlice.actions;

export const fetchAccountEdit = (data) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const body = JSON.stringify({
				name: data.name.trim(),
				lastname: data.lastName.trim(),
				mothername: data.motherName.trim(),
				gender: data.gender,
				birthdate: data.birthdate,
				phone: data.phone,
				subscribe: data.subscribe,
			});
			dispatch(requestAccountEdit());
			const url = BASE_API_URL + "/api/Cuenta/MiCuenta/ActualizaDatos";
			return axios
				.post(url, body, options)
				.then((response) => {
					sessionService
						.saveUser({ user: response.data })
						.then()
						.catch((error) => console.log("error", error));
					dispatch(receiveAccountEdit());
					dispatch(toggleShowAccountEdit({ isOpen: true }));
					dispatch(toggleShowAccountData({ isOpen: false }));
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
					if (error.response.status === 401) {
						dispatch(receiveAccountEdit());
						dispatch(setAccountEditErrorMsg({ error: error.Message }));
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default accountEditSlice.reducer;
