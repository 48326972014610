import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import {
	fetchLogin,
	toggleShowLogin,
	setLoginErrorMessage,
} from "features/account/loginSlice";
import { toggleShowRegister } from "features/account/registerSlice";
import { toggleShowPassword } from "features/account/resetPasswordSlice";
import { toggleShowConfirmation } from "features/account/confirmationSlice";
import Loader from "../Loader";

const renderError = (error) => {
	if (error != null) {
		return <li>{error}</li>;
	}
	return null;
};

export default function LoginModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, errorMsg, isOpen } = useSelector((state) => state.login);
	const { checked, authenticated } = useSelector((state) => state.session);
	// State
	const [usrname, setUsrname] = useState("");
	const [pwd, setPwd] = useState("");
	// Refs
	const usrnameRef = useRef();
	const pwdRef = useRef();
	// Effect
	useEffect(() => {
		usrnameRef.current && usrnameRef.current.focus();
		let prevAuthenticated;
		if (authenticated && !prevAuthenticated) {
			setUsrname("");
			setPwd("");
		}
		return () => {
			prevAuthenticated = authenticated;
		};
	}, [authenticated]);
	// Handlers
	function handleUsrnameChange(e) {
		if (errorMsg != null && errorMsg.usrname != null) {
			dispatch(
				setLoginErrorMessage({
					errorMsg: { ...errorMsg, usrname: null, error: null },
				})
			);
		}
		setUsrname(e.target.value);
	}
	function handleUsrnameKeyPress(e) {
		if (e.key === "Enter") {
			e.preventDefault();
			pwdRef.current.focus();
		}
	}
	function handlePwdChange(e) {
		if (errorMsg != null && errorMsg.pwd != null) {
			dispatch(
				setLoginErrorMessage({
					errorMsg: { ...errorMsg, pwd: null, error: null },
				})
			);
		}
		setPwd(e.target.value);
	}
	function handleReset() {
		setUsrname("");
		setPwd("");
		dispatch(setLoginErrorMessage({ errorMsg: null }));
	}
	function handleCloseClick() {
		handleReset();
		dispatch(toggleShowLogin({ isOpen }));
	}
	function handleOverlayCloseClick() {
		dispatch(toggleShowLogin({ isOpen }));
	}
	function handleRegisterClick() {
		handleReset();
		dispatch(toggleShowLogin({ isOpen: true }));
		dispatch(toggleShowRegister({ isOpen: false }));
	}
	function handleResetPasswordClick() {
		handleReset();
		dispatch(toggleShowLogin({ isOpen: true }));
		dispatch(toggleShowPassword({ isOpen: false }));
	}
	function handleConfirmationClick() {
		handleReset();
		dispatch(toggleShowLogin({ isOpen: true }));
		dispatch(toggleShowConfirmation({ isOpen: false }));
	}
	function handleSubmit(e) {
		e.preventDefault();
		let valid = true;
		let errorMessages = { ...errorMsg };
		if (usrname.trim() === "") {
			errorMessages = {
				...errorMessages,
				usrname: "Debes escribir tu correo electrónico",
			};
			valid = false;
		}
		if (pwd.trim() === "") {
			errorMessages = {
				...errorMessages,
				pwd: "Debes escribir tu contraseña",
			};
			valid = false;
		}
		if (valid) {
			dispatch(fetchLogin(usrname, pwd));
			setPwd("");
		} else {
			dispatch(setLoginErrorMessage({ errorMsg: errorMessages }));
		}
	}
	// Render
	function renderLoader() {
		if (isLoading) {
			return <Loader msg="Iniciando sesión..." />;
		}
		return null;
	}
	function renderErrorMsg() {
		if (errorMsg != null) {
			return (
				<div className="error-list">
					<ul>
						{renderError(errorMsg.error)}
						{renderError(errorMsg.usrname)}
						{renderError(errorMsg.pwd)}
					</ul>
				</div>
			);
		}
		return null;
	}
	function renderLoginForm() {
		if (!isLoading) {
			let errClass = { usrname: {}, pwd: {} };
			if (errorMsg != null) {
				if (errorMsg.usrname != null) {
					errClass = { ...errClass, usrname: { className: "error" } };
				}
				if (errorMsg.pwd != null) {
					errClass = { ...errClass, pwd: { className: "error" } };
				}
			}
			return (
				<form onSubmit={handleSubmit} onReset={handleReset}>
					<div className="login-form">
						<div {...errClass.usrname}>
							<label>Correo Electrónico</label>
							<input
								ref={usrnameRef}
								type="email"
								className="modal-field"
								placeholder="Correo Electrónico"
								autoComplete="username"
								value={usrname}
								onChange={handleUsrnameChange}
								onKeyPress={handleUsrnameKeyPress}
							/>
						</div>
						<div {...errClass.pwd}>
							<label>Contraseña</label>
							<input
								ref={pwdRef}
								type="password"
								className="modal-field"
								placeholder="Contraseña"
								autoComplete="current-password"
								value={pwd}
								onChange={handlePwdChange}
							/>
						</div>
						{renderErrorMsg()}
						<div className="center">
							<button type="submit" className="button main">
								Iniciar Sesión
							</button>
						</div>
						<div>
							<div className="modal-link" onClick={handleRegisterClick}>
								Registrarme
							</div>
						</div>
						<div>
							<div className="modal-link" onClick={handleResetPasswordClick}>
								Olvidé mi contraseña
							</div>
						</div>
						<div>
							<div className="modal-link" onClick={handleConfirmationClick}>
								No recibí correo de confirmación
							</div>
						</div>
					</div>
				</form>
			);
		}
		return null;
	}
	if (checked && authenticated) {
		return null;
	}
	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal small"
				overlayClassName="modal-overlay"
				onRequestClose={handleOverlayCloseClick}
				contentLabel="Login"
			>
				<div className="modal-inner" id="login-inner">
					<div className="modal-close" onClick={handleCloseClick}></div>
					<h2>Login</h2>
					{renderLoader()}
					{renderLoginForm()}
				</div>
			</Modal>
		</div>
	);
}
