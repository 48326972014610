import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_API_URL } from "../constants";
import { isServer } from "features/functions";

const initialState = {
	isLoading: false,
	list: null,
	hide: false,
};

const highlightsTires = createSlice({
	name: "highlightsTires",
	initialState,
	reducers: {
		requestHighlightsTiresList(state) {
			state.isLoading = true;
		},
		receiveHighlightsTiresList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		clearHighlightsTiresList(state) {
			state.list = null;
		},
		hideHighlightsTiresList(state, action) {
			state.hide = action.payload.hide;
		},
		clearHighlightsData: () => initialState,
	},
});

export const {
	requestHighlightsTiresList,
	receiveHighlightsTiresList,
	clearHighlightsTiresList,
	hideHighlightsTiresList,
	clearHighlightsData,
} = highlightsTires.actions;

const shouldFetchHighlightsTiresList = (state) => {
	if (state.highlightsTires.list == null) {
		return true;
	}
	return false;
};

export const fetchHighlightsTiresListIfNeeded =
	() => async (dispatch, getState) => {
		const theState = getState();
		if (shouldFetchHighlightsTiresList(theState)) {
			return dispatch(fetchHighlightsTiresList());
		}
	};

export const fetchHighlightsTiresList = () => async (dispatch, getState) => {
	const headers = isServer
		? {
				"Content-Type": "application/json",
				"Origin": [process.env.RAZZLE_SRV_HOST],
		  }
		: {
				"Content-Type": "application/json",
		  };
	const options = {
		//method: "GET",
		headers: headers,
	};
	const theState = getState();
	const coupon =
		theState.cart.coupon == null ? null : theState.cart.coupon.Code;
	dispatch(requestHighlightsTiresList());
	const url =
		coupon != null
			? BASE_API_URL + "/api/Productos/Home/?cupon=" + coupon
			: BASE_API_URL + "/api/Productos/Home";
	await axios
		.get(url, options)
		.then((response) => {
			dispatch(receiveHighlightsTiresList({ list: response.data }));
		})
		.catch((error) => {
		});
	// return fetch(url, options)
	// 	.then((response) => {
	// 		if (response.ok) {
	// 			return response.json();
	// 		} else {
	// 			return response.text().then((error) => Promise.reject(error));
	// 		}
	// 	})
	// 	.then((json) => {
	// 		dispatch(receiveHighlightsTiresList({ list: json }));
	// 	})
	// 	.catch(() => {
	// 		// TODO: fetch error actions
	// 	});
};

export default highlightsTires.reducer;
