import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import { toggleShowPoints, fetchPaymentCard } from "features/cart/cartSlice";

export default function OpenPayPointsModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isOpenPoints, pointsData } = useSelector((state) => state.cart);
	// Handlers
	function handleYesClick() {
		const data = { ...pointsData, usePoints: true };
		dispatch(fetchPaymentCard(data));
		dispatch(toggleShowPoints({ isOpenPoints }));
	}
	function handleNoClick() {
		const data = { ...pointsData, usePoints: false };
		dispatch(fetchPaymentCard(data));
		dispatch(toggleShowPoints({ isOpenPoints }));
	}
	return (
		<div>
			<Modal
				isOpen={isOpenPoints}
				className="modal small"
				overlayClassName="modal-overlay"
				onRequestClose={handleNoClick}
				contentLabel="OpenPayPoints"
			>
				<div className="modal-inner" id="alert-inner">
					<div className="modal-close" onClick={handleNoClick}></div>
					<h2>Pagar con Puntos Bancarios</h2>
					<div className="alert-main">
						<div>
							¿Deseas usar los puntos bancarios de tu tarjeta para realizar este
							pago?
						</div>
						<div
							style={{ minWidth: "100px", marginRight: "20px" }}
							className="modal-button"
							onClick={handleNoClick}
						>
							No
						</div>
						<div
							style={{ minWidth: "100px" }}
							className="modal-button alternate"
							onClick={handleYesClick}
						>
							Sí
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
}
