import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import ReactGA from "react-ga4";
import Scroll from "react-scroll";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { IMAGE_TYPES as i } from "features/constants";
import { currencyFormat, imageFormat, getBrandClass } from "features/functions";
import { clearSearchActions } from "features/tireFinder/searchSlice";
import Loader from "../Loader";
import TireDetails from "./TireDetails";

const ScrollLink = Scroll.Link;
const scroll = Scroll.animateScroll;

const getTirePrice = (tire) => {
	if (tire.Discount > 0 && tire.Minimum <= 1) return tire.Price * 1.16;
	else {
		return tire.ListPrice * 1.16;
	}
};

export default function ResultVehicle(props) {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, list } = useSelector((state) => state.vehicleTires);
	// State
	const [activeProduct, setActiveProduct] = useState(0);
	const [activeSize, setActiveSize] = useState(0);
	const [activeAlternative, setActiveAlternative] = useState(0);
	// History
	const history = useHistory();
	const location = useLocation();
	// Effect
	useEffect(() => {
		setActiveProduct(0);
		setActiveSize(0);
		setActiveAlternative(0);
	}, [list]);
	// Handlers
	function handleCloseClick() {
		dispatch(clearSearchActions());
		const target = document.getElementById("tireFinderElement");
		const targetPos =
			target.offsetWidth > 980 ? target.offsetTop - 100 : target.offsetTop - 50;
		scroll.scrollTo(targetPos, { smooth: true, delay: 100, duration: 500 });
		if (location !== "/") {
			history.push("/");
		}
	}
	function handleOriginalClick() {
		ReactGA.event({
			category: "Vehiculo",
			action: "Seleccionar Medida",
			label: "Original",
			value: list.Id,
		});
		setActiveAlternative(0);
	}
	function handleAlternativeClick() {
		ReactGA.event({
			category: "Vehiculo",
			action: "Seleccionar Medida",
			label: "Alternativa",
			value: list.Id,
		});
		setActiveAlternative(1);
	}
	function handleSportsClick() {
		ReactGA.event({
			category: "Vehiculo",
			action: "Seleccionar Medida",
			label: "Deportivo",
			value: list.Id,
		});
		setActiveAlternative(2);
	}
	function handleFrontSizeClick() {
		ReactGA.event({
			category: "Vehiculo",
			action: "Seleccionar Eje",
			label: "Delantero",
			value: list.Id,
		});
		setActiveSize(0);
	}
	function handleRearSizeClick() {
		ReactGA.event({
			category: "Vehiculo",
			action: "Seleccionar Eje",
			label: "Trasero",
			value: list.Id,
		});
		setActiveSize(1);
	}
	function setCurrentSlide(currentSlide) {
		setActiveProduct(currentSlide);
	}
	// Render functions
	function renderAlternatives() {
		return (
			<div className="result-type">
				<div
					id="result-original"
					{...(activeAlternative === 0 ? { className: "active" } : {})}
					onClick={handleOriginalClick}
				>
					Equipo Original
				</div>
				<div
					id="result-alternativas"
					{...(activeAlternative === 1 ? { className: "active" } : {})}
					onClick={handleAlternativeClick}
				>
					Alternativas
				</div>
				<div
					id="result-deportivo"
					{...(activeAlternative === 2 ? { className: "active" } : {})}
					onClick={handleSportsClick}
				>
					<span className="icon-sport"></span>Deportivo
				</div>
			</div>
		);
	}
	function renderPositions() {
		if (list.Sizes.length === 2) {
			return (
				<div className="result-position">
					<div
						id="result-position-front"
						{...(activeSize === 0 ? { className: "active" } : {})}
						onClick={handleFrontSizeClick}
					>
						Delantera
					</div>
					<div
						id="result-position-rear"
						{...(activeSize === 1 ? { className: "active" } : {})}
						onClick={handleRearSizeClick}
					>
						Trasera
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
	function renderProducts(activeList, active) {
		if (activeList.length > 0) {
			let data = Array.from(activeList);
			if (data.length === 2) {
				data.push(data[0]);
				data.push(data[1]);
			} else if (data.length === 3) {
				data.push(data[0]);
				data.push(data[1]);
				data.push(data[2]);
			}
			var settings = {
				dots: false,
				centerMode: true,
				infinite: true,
				initialSlide: 0,
				className: "center",
				slidesToShow: data.length === 1 ? 1 : 3,
				centerPadding: 0,
				afterChange: (currentSlide) => {
					setCurrentSlide(currentSlide);
				},
				responsive: [
					{
						breakpoint: 980,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							initialSlide: 0,
						},
					},
				],
			};
			let w = window.innerWidth;
			let offset = w > 980 ? -100 : -50;
			return (
				<>
					<Slider {...settings}>
						{data.map((tire, index) => {
							if (index === active) {
								return (
									<div id={tire.ProductId} key={index}>
										<div>
											<ScrollLink
												to="resultTireDetails"
												smooth={true}
												duration={500}
												offset={offset}
											>
												<div>
													<img
														src={imageFormat(
															tire.TireProduct.Image,
															i.CAROUSEL
														)}
														alt={tire.TireProduct.Name}
													/>
													<span
														className={getBrandClass(tire.TireProduct.Brand)}
													></span>
												</div>
												<div>
													<h2>{tire.TireProduct.Name}</h2>
												</div>
												<div>{tire.Size}</div>
												<div>
													{currencyFormat(getTirePrice(tire))} <span>MXN</span>
												</div>
											</ScrollLink>
										</div>
									</div>
								);
							} else {
								return (
									<div id={tire.ProductId} key={index}>
										<div>
											<div>
												<img
													src={imageFormat(tire.TireProduct.Image, i.CAROUSEL)}
													alt={tire.TireProduct.Name}
												/>
												<span
													className={getBrandClass(tire.TireProduct.Brand)}
												></span>
											</div>
											<div>
												<h2>{tire.TireProduct.Name}</h2>
											</div>
											<div>{tire.Size}</div>
											<div>
												{currencyFormat(getTirePrice(tire))} <span>MXN</span>
											</div>
										</div>
									</div>
								);
							}
						})}
					</Slider>
					{activeAlternative === 0 && (
						<div className="alert-box" style={{ paddingTop: "20px" }}>
							<span className="close-box"></span> Las medidas presentadas son
							solo una sugerencia basada en equipo original, para mayor
							precisión, se sugiere revisar la medida en sus llantas actuales o
							en el manual de usuario de su vehículo
						</div>
					)}
				</>
			);
		} else {
			return (
				<div className="no-results">
					<div>No hay productos disponibles para esta opción</div>
				</div>
			);
		}
	}
	function renderTireDetails(tireDetails) {
		if (tireDetails !== null) {
			return <TireDetails {...tireDetails} />;
		} else return null;
	}
	// Render
	if (isLoading) {
		return (
			<div id="result-view">
				<div className="result-stripe vehicle-result">
					<div>0</div>
					<div>Resultados</div>
					<div>&nbsp;</div>
					<div>Cargando...</div>
				</div>
				<div className="result-products short">
					<div className="result-products-slider">
						<div className="no-results">
							<Loader msg="" />
						</div>
					</div>
				</div>
			</div>
		);
	}
	if (list == null || list.length === 0) {
		return null;
	}
	let activeList = [];
	let tireDetails = null;
	switch (activeAlternative) {
		case 0:
		default:
			activeList = list.Sizes[activeSize].Tires;
			if (activeList.length > 0)
				tireDetails =
					activeProduct >= activeList.length
						? activeList[activeProduct - activeList.length]
						: activeList[activeProduct];
			break;
		case 1:
			activeList = list.Sizes[activeSize].Alternatives;
			if (activeList.length > 0)
				tireDetails =
					activeProduct >= activeList.length
						? activeList[activeProduct - activeList.length]
						: activeList[activeProduct];
			break;
		case 2:
			activeList = list.Sizes[activeSize].Sports;
			if (activeList.length > 0)
				tireDetails =
					activeProduct >= activeList.length
						? activeList[activeProduct - activeList.length]
						: activeList[activeProduct];
			break;
	}
	return (
		<div id="result-view">
			<div className="result-stripe vehicle-result">
				<div>{activeList.length}</div>
				<div>Resultados</div>
				<div onClick={handleCloseClick}>
					<span className="text-remove"></span>
					<span className="icon-remove"></span>
				</div>
				<div>{list.Name + " " + list.Year}</div>
			</div>
			{renderAlternatives()}
			{renderPositions()}
			<div className="result-products short">
				<div className="result-products-slider">
					{renderProducts(activeList, activeProduct)}
				</div>
			</div>
			{renderTireDetails(tireDetails)}
		</div>
	);
}
