import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import ReactGA from "react-ga4";
import axios from "axios";
import { BASE_API_URL } from "../constants";
import {
	clearCartInvoiceAddress,
	setCartInvoiceAddress,
} from "features/cart/cartSlice";
import { updateCityValue } from "./citiesSlice";
import { updateCountyValue } from "./countiesSlice";
import { setAlert, toggleShowAlert } from "features/alerts/alertsSlice";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	list: null,
	isOpenAdd: false,
	isOpen: false,
	selectedState: null,
	city: { Cd: "", Municipio: "" },
	errorMsg: null,
};

const invoiceAddress = createSlice({
	name: "invoiceAddress",
	initialState,
	reducers: {
		requestInvoiceAddress(state) {
			state.isLoading = true;
		},
		receiveInvoiceAddress(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		toggleShowInvoiceAddressAdd(state, action) {
			if (!action.payload.isOpenAdd) {
				ReactGA.event({
					category: "Cuenta",
					action: "Agregar Dirección de Facturación"});
			}
			state.isOpenAdd = !action.payload.isOpenAdd;
		},
		toggleShowInvoiceAddress(state, action) {
			if (!action.payload.isOpen) {
				ReactGA.event({
					category: "Cuenta",
					action: "Mostrar Direcciones de Facturaicón"});
			}
			state.isOpen = !action.payload.isOpen;
		},
		setInvoiceAddressSelectedState(state, action) {
			state.selectedState = action.payload.selectedState;
		},
		requestInvoiceAddressCity(state) {
			state.isLoading = true;
		},
		receiveInvoiceAddressCity(state, action) {
			state.isLoading = false;
			state.city = action.payload.city;
		},
		setInvoiceAddressErrorMessage(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
		setInvoiceAddressAddFinish(state) {
			state.isLoading = false;
		},
		clearInvoiceAddressAddData(state) {
			state.isLoading = false;
			state.isOpenAdd = false;
			state.selectedState = null;
			state.city = { Cd: "", Municipio: "" };
			state.errorMsg = null;
		},
		clearInvoiceAddressData: () => initialState,
	},
});

export const {
	requestInvoiceAddress,
	receiveInvoiceAddress,
	toggleShowInvoiceAddressAdd,
	toggleShowInvoiceAddress,
	setInvoiceAddressSelectedState,
	requestInvoiceAddressCity,
	receiveInvoiceAddressCity,
	setInvoiceAddressErrorMessage,
	setInvoiceAddressAddFinish,
	clearInvoiceAddressAddData,
	clearInvoiceAddressData,
} = invoiceAddress.actions;

const shouldFetchInvoiceAddressList = (state) => {
	const invoiceAddress = state.invoiceAddress;
	if (invoiceAddress.list == null) {
		if (!invoiceAddress.isLoading) {
			return true;
		}
		return false;
	}
	return false;
};
export const fetchInvoiceAddressListIfNeeded =
	() => async (dispatch, getState) => {
		await sessionService.loadSession().then(() => {
			if (shouldFetchInvoiceAddressList(getState())) {
				return dispatch(fetchInvoiceAddress());
			}
			const theState = getState();
			if (theState.invoiceAddress.list !== null) {
				if (
					theState.invoiceAddress.list.length > 0 &&
					theState.cart.invoiceAddress === null
				) {
					return dispatch(
						setCartInvoiceAddress({
							invoiceAddress: 0,
							invoiceAddressItem: theState.invoiceAddress.list[0],
						})
					);
				}
			}
		});
	};

export const fetchInvoiceAddress = () => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				//method: "GET",
				headers: headers,
			};
			dispatch(requestInvoiceAddress());
			const url = BASE_API_URL + "/api/Cuenta/MiCuenta/MisDirecciones/3";
			return axios
				.get(url, options)
				.then((response) => {
					if (response.data.Facturacion.length === 0) {
						dispatch(clearCartInvoiceAddress());
						dispatch(receiveInvoiceAddress({ list: null }));
					} else {
						dispatch(
							setCartInvoiceAddress({
								invoiceAddress: 0,
								invoiceAddressItem: response.data.Facturacion[0],
							})
						);
						dispatch(
							receiveInvoiceAddress({ list: response.data.Facturacion })
						);
					}
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};
export const fetchInvoiceAddressCity = (zipCode) => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	dispatch(requestInvoiceAddressCity());
	return fetch(BASE_API_URL + "/api/Cuenta/Ciudad/" + zipCode, options)
		.then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				return response.text().then((error) => Promise.reject(error));
			}
		})
		.then((json) => {
			dispatch(receiveInvoiceAddressCity({ city: json }));
			dispatch(updateCityValue({ value: json.Cd }));
			dispatch(updateCountyValue({ value: json.Municipio }));
		})
		.catch(() => {
			// TODO: fetch error actions
		});
};

export const fetchAddInvoiceAddress = (address) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const data = JSON.stringify({
				tipo: 3,
				alias: address.alias,
				calle: address.street,
				numext: address.ext,
				numint: address.int,
				cp: address.zip,
				col: address.neighborhood,
				cd: address.city,
				mpio: address.county,
				edo: address.stateId,
				tel: "",
				rfc: address.rfc,
				rsoc: address.name,
				lat: "",
				lon: "",
				regimen: address.regime,
				uso: address.uso,
			});
			const url = BASE_API_URL + "/api/Cuenta/MiCuenta/AgregaDireccion/";
			dispatch(requestInvoiceAddress());
			return axios
				.post(url, data, options)
				.then((response) => {
					if (response.data.Facturacion.length > 0) {
						dispatch(receiveInvoiceAddress({ list: response.data.Facturacion }));
						dispatch(
							setCartInvoiceAddress({
								invoiceAddress: 0,
								invoiceAddressItem: response.data.Facturacion[0],
							})
						);
					} else {
						dispatch(clearCartInvoiceAddress());
						dispatch(receiveInvoiceAddress({ list: null }));
					}
					dispatch(toggleShowInvoiceAddressAdd({ isOpenAdd: true }));
					dispatch(setInvoiceAddressErrorMessage({ errorMsg: null }));
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
					// If 400 BadRequest model state
					if (error.response.status === 400) {
						if (error.response.data.Message != null) {
							dispatch(
								setAlert({
									title: "¡Lo sentimos!",
									description:
										"No se ha podido agregar la dirección. Verifique los datos y vuelva a intentar.",
									details: error.response.data.Message,
								})
							);
							dispatch(setInvoiceAddressAddFinish());
							dispatch(
								setInvoiceAddressErrorMessage({
									errorMsg: { error: error.response.data.Message},
								})
							);
							dispatch(toggleShowAlert({ isOpen: false }));
						}
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default invoiceAddress.reducer;
