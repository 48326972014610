import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Slider from "react-slick";
import Scroll from "react-scroll";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { IMAGE_TYPES } from "features/constants";
import { imageFormat, getBrandClass } from "features/functions";
import { clearSearchActions } from "features/tireFinder/searchSlice";
import Loader from "components/Loader";
import ProductDetails from "./ProductDetails";

const mapOptions = (list) => {
	return list.map(function (tire, index) {
		return { label: tire.Size, value: tire.Code, index: index };
	});
};

var ScrollLink = Scroll.Link;

export default function ResultProduct() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, list, hide } = useSelector((state) => state.searchTires);
	// History
	const history = useHistory();
	const location = useLocation();
	// Handlers
	function handleCloseClick() {
		dispatch(clearSearchActions());
		let scroll = Scroll.animateScroll;
		scroll.scrollTo(0, { smooth: true, delay: 100, duration: 500 });
		if (location !== "/") {
			history.push("/");
		}
	}
	// Render
	if (hide === true) {
		return null;
	}
	if (isLoading) {
		return (
			<div id="result-view">
				<div className="result-stripe">
					<div>0</div>
					<div>Resultados</div>
					<div onClick={handleCloseClick}>
						<span className="text-remove"></span>
						<span className="icon-remove"></span>
					</div>
				</div>
				<div className="result-products">
					<div className="result-products-slider">
						<Loader msg="Buscando..." />
					</div>
				</div>
			</div>
		);
	}
	if (list == null || list.length === 0) {
		return null;
	}
	// Render
	const productDetails = {
		product: list,
		options: mapOptions(list.Tires),
	};
	function renderProducts(product) {
		var settings = {
			dots: false,
			centerMode: true,
			infinite: true,
			className: "center",
			slidesToShow: 1,
			centerPadding: "0px",
			responsive: [
				{
					breakpoint: 980,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						initialSlide: 0,
					},
				},
			],
		};
		let w = window.innerWidth;
		let offset = w > 980 ? -100 : -50;
		if (product != null) {
			return (
				<Slider {...settings}>
					<div id={product.Id}>
						<div>
							<ScrollLink
								to="resultProductDetails"
								smooth={true}
								duration={500}
								offset={offset}
							>
								<div>
									<img
										src={imageFormat(product.Image, IMAGE_TYPES.CAROUSEL)}
										alt={product.Name}
									/>
									<span className={getBrandClass(product.Brand)}></span>
								</div>
								<div>
									<h2>{product.Name}</h2>
								</div>
							</ScrollLink>
						</div>
					</div>
				</Slider>
			);
		}
	}

	return (
		<div id="result-view">
			<div className="result-stripe vehicle-result">
				<div>1</div>
				<div>Resultado</div>
				<div onClick={handleCloseClick}>
					<span className="text-remove"></span>
					<span className="icon-remove"></span>
				</div>
				<div>{list.Name}</div>
			</div>
			<div className="result-products">
				<div className="result-products-slider">{renderProducts(list)}</div>
			</div>
			<ProductDetails {...productDetails} />
		</div>
	);
}
