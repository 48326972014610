import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import { validateMail } from "features/functions";
import { toggleShowLogin } from "features/account/loginSlice";
import { toggleShowRegister } from "features/account/registerSlice";
import { toggleShowPassword, setPasswordErrorMsg, fetchPasswordReset } from "features/account/resetPasswordSlice";
import { toggleShowConfirmation } from "features/account/confirmationSlice";
import Loader from "../Loader";

const renderError = (error) => {
	if (error != null) {
		return <li>{error}</li>;
	}
	return null;
};

export default function PasswordModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, errorMsg, isOpen } = useSelector((state) => state.resetPassword);
	const { checked, authenticated } = useSelector((state) => state.session);
	// State
	const [usrname, setUsrname] = useState("");
	// Refs
	const usrnameRef = useRef();
	// Handlers
	function handleUsrnameChange(e) {
		if (errorMsg != null && errorMsg.usrname != null) {
			dispatch(
				setPasswordErrorMsg({
					errorMsg: { ...errorMsg, usrname: null, error: null },
				})
			);
		}
		setUsrname(e.target.value);
	}
	function handleReset() {
		setUsrname("");
		dispatch(setPasswordErrorMsg({ errorMsg: null }));
	}
	function handleCloseClick() {
		handleReset();
		dispatch(toggleShowPassword({ isOpen }));
	}
	function handleOverlayCloseClick() {
		dispatch(toggleShowPassword({ isOpen }));
	}
	function handleRegisterClick() {
		handleReset();
		dispatch(toggleShowPassword({ isOpen: true }));
		dispatch(toggleShowRegister({ isOpen: false }));
	}
	function handleLoginClick() {
		handleReset();
		dispatch(toggleShowLogin({ isOpen: false }));
		dispatch(toggleShowPassword({ isOpen: true }));
	}
	function handleConfirmationClick() {
		handleReset();
		dispatch(toggleShowPassword({ isOpen: true }));
		dispatch(toggleShowConfirmation({ isOpen: false }));
	}
	function handleSubmit(e) {
		e.preventDefault();
		let valid = true;
		let errorMessages = { ...errorMsg };
		if (usrname.trim() === "") {
			errorMessages = {
				...errorMessages,
				usrname: "Debes escribir tu correo electrónico",
			};
			valid = false;
		}
		else if (!validateMail(usrname.trim())) {
			errorMessages = {
				...errorMessages,
				usrname: "El correo electrónico no es válido",
			};
			valid = false;
		};
		if (valid) {
			dispatch(fetchPasswordReset(usrname));
		} else {
			dispatch(setPasswordErrorMsg({ errorMsg: errorMessages }));
		}
	}
	// Render
	function renderLoader() {
		if (isLoading) {
			return <Loader msg="Enviando..." />;
		}
		return null;
	}
	function renderErrorMsg() {
		if (errorMsg != null) {
			return (
				<div className="error-list">
					<ul>
						{renderError(errorMsg.error)}
						{renderError(errorMsg.usrname)}
					</ul>
				</div>
			);
		}
		return null;
	}
	function renderForm() {
		if (!isLoading) {
			let errClass = { usrname: {} };
			if (errorMsg != null) {
				if (errorMsg.usrname != null) {
					errClass = { ...errClass, usrname: { className: "error" } };
				}
			}
			return (
				<form onSubmit={handleSubmit} onReset={handleReset}>
					<div className="login-form">
						<div {...errClass.usrname}>
							<label>Correo Electrónico</label>
							<input
								ref={usrnameRef}
								type="email"
								className="modal-field"
								placeholder="Correo Electrónico"
								autoComplete="username"
								value={usrname}
								onChange={handleUsrnameChange}
							/>
						</div>
						{renderErrorMsg()}
						<div className="center">
							<button type="submit" className="button main">
								Enviar
							</button>
						</div>
						<div>
							<div className="modal-link" onClick={handleLoginClick}>
								Login
							</div>
						</div>
						<div>
							<div className="modal-link" onClick={handleRegisterClick}>
								Registrarme
							</div>
						</div>
						<div>
							<div className="modal-link" onClick={handleConfirmationClick}>
								No recibí correo de confirmación
							</div>
						</div>
					</div>
				</form>
			);
		}
		return null;
	}
	if (checked && authenticated) {
		return null;
	}
	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal small"
				overlayClassName="modal-overlay"
				onRequestClose={handleOverlayCloseClick}
				contentLabel="Login"
			>
				<div className="modal-inner" id="login-inner">
					<div className="modal-close" onClick={handleCloseClick}></div>
					<h2>Recuperar Contraseña</h2>
					{renderLoader()}
					{renderForm()}
				</div>
			</Modal>
		</div>
	);
}
