import React from "react";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { fetchDeleteItemFromCartObject } from "features/cart/cartSlice";

export default function CartDetailsRemove(props) {
	const dispatch = useDispatch();
	// Handlers
	function handleClick() {
		ReactGA.event({
			category: "Compra",
			action: "Quitar Producto",
			label: props.Kartis,
		});
		dispatch(fetchDeleteItemFromCartObject(props.Kartis));
	}
	// Render
	return (
		<div onMouseDown={handleClick}>
			<span className="icon-remove"></span>
		</div>
	);
}
