import React, { useState } from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import {
	toggleShowShipAddress,
	toggleShowShipAddressAdd,
} from "features/account/shipAddressSlice";
import Loader from "components/Loader";
import AddressItem from "./AddressItem";
import { setShipAddressEdit } from "features/account/shipAddressEditSlice";
import { toggleShowShipAddressEdit } from "features/account/shipAddressEditSlice";

export default function ShipAddressModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, isOpen, list } = useSelector((state) => state.shipAddress);
	// State
	const [selectedIndex, setSelectedIndex] = useState(0);
	// Handlers
	function handleCloseClick() {
		setSelectedIndex(0);
		dispatch(toggleShowShipAddress({ isOpen: true }));
	}
	function handleSelect(index) {
		setSelectedIndex(index);
	}
	function handleAddClick() {
		dispatch(toggleShowShipAddressAdd({ isOpenAdd: false }));
	}
	function handleEditClick() {
		const selectedItem = list[selectedIndex];
		dispatch(setShipAddressEdit({ address: selectedItem }));
		dispatch(toggleShowShipAddressEdit({ isOpen: false }));
	}
	// Render Functions
	function formatAddress() {
		const selectedItem = list[selectedIndex];
		let result = <div>&nbsp;</div>;
		let numint = "";
		if (selectedItem != null) {
			if (selectedItem.Numero_Interior.length > 0) {
				numint = " - " + selectedItem.Numero_Interior;
			}
			result = (
				<div>
					{selectedItem.Calle + " " + selectedItem.Numero_Exterior + numint}
					<br />
					Col. {selectedItem.Colonia}
					<br />
					{selectedItem.Codigo_Postal + " " + selectedItem.Delegacion}
					<br />
					{selectedItem.Estado_Descripcion}
					<br />
					Tel. {selectedItem.Telefono}
				</div>
			);
		}
		return result;
	}
	function renderAddressList() {
		if (list === null) {
			return (
				<div id="address-list">
					<div>No hay direcciones de envío registradas</div>
				</div>
			);
		}
		return (
			<div id="address-list">
				<div>
					{list.map((element, index) => {
						return (
							<AddressItem
								className={
									selectedIndex === index
										? "address-item selected"
										: "address-item"
								}
								index={index}
								alias={element.Alias}
								item={element}
								key={index}
								onClick={handleSelect}
							/>
						);
					})}
					<div>&nbsp;</div>
				</div>
			</div>
		);
	}
	function renderEditButton() {
		return (
			<div>
				<div className="button alternate" onClick={handleEditClick}>
					Editar
				</div>
			</div>
		);
	}
	function renderSelectedAddress() {
		if (list != null && list.length > 0) {
			return (
				<div id="address-details">
					{formatAddress()}
					{renderEditButton()}
				</div>
			);
		}
		return null;
	}
	function renderLoader() {
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	}
	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal"
				overlayClassName="modal-overlay"
				onRequestClose={handleCloseClick}
				contentLabel="Direcciones de Envío"
			>
				<div className="modal-inner" id="cart-inner">
					<div className="modal-close" onClick={handleCloseClick}></div>
					<h2>Direcciones de Envío</h2>
					<div className="history-content">
						<div id="cart-content-address">
							<div id="address-add" onClick={handleAddClick}>
								<span className="add-text"></span>
								<span className="icon-add" onClick={handleAddClick}></span>
							</div>
							{renderAddressList()}
							{renderSelectedAddress()}
						</div>
						{renderLoader()}
					</div>
				</div>
			</Modal>
		</div>
	);
}
