const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
	focus: null,
	isSAE: false,
	first: null,
	second: null,
	third: null,
	firstLoad: true,
};

const sizeCanvas = createSlice({
	name: "sizeCanvas",
	initialState,
	reducers: {
		setCanvasFirstItem(state, action) {
			state.first = action.payload.first;
			state.second = null;
			state.third = null;
			state.focus = null;
			state.isSAE = action.payload.isSAE;
			state.firstLoad = false;
		},
		setCanvasSecondItem(state, action) {
			state.second = action.payload.second;
			state.third = null;
			state.firstLoad = false;
		},
		setCanvasThirdItem(state, action) {
			state.third = action.payload.third;
			state.firstLoad = false;
		},
		setCanvasFocus(state, action) {
			state.focus = action.payload.focus;
			state.firstLoad = false;
		},
	},
});

export const { 
	setCanvasFirstItem, 
	setCanvasSecondItem,
	setCanvasThirdItem,
	setCanvasFocus,
 } = sizeCanvas.actions;

export default sizeCanvas.reducer;
