import { createSlice } from "@reduxjs/toolkit";
import { BASE_API_URL } from "../constants";
import { getMatchingItemsZipCodes } from "../functions";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	list: null,
	stateId: null,
};

const zipCodes = createSlice({
	name: "zipCodes",
	initialState,
	reducers: {
		requestZipCodesList(state) {
			state.isLoading = true;
		},
		receiveZipCodesList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		loadZipCodesSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateZipCodesSuggestions(state, action) {
			state.isLoading = false;
			if (state.value === action.payload.value) {
				state.suggestions = action.payload.suggestions;
			}
		},
		updateZipCodeValue(state, action) {
			state.value = action.payload.value;
		},
		updateZipCodeItem(state, action) {
			state.selectedItem = action.payload.selectedItem;
		},
		setZipCodesStateId(state, action) {
			state.stateId = action.payload.stateId;
		},
		clearZipCodesSuggestions(state) {
			state.suggestions = [];
		},
		clearZipCodeValue(state) {
			state.value = "";
			state.selectedItem = {};
		},
	},
});

export const {
	requestZipCodesList,
	receiveZipCodesList,
	loadZipCodesSuggestionsBegin,
	maybeUpdateZipCodesSuggestions,
	updateZipCodeValue,
	updateZipCodeItem,
	setZipCodesStateId,
	clearZipCodesSuggestions,
	clearZipCodeValue,
} = zipCodes.actions;

const shouldFetchZipCodes = (state, stateId) => {
	if (state.zipCodes.list == null) {
		return true;
	}
	if (state.zipCodes.stateId !== stateId) {
		return true;
	}
	return false;
};

export const fetchZipCodesIfNeeded =
	(stateId) => async (dispatch, getState) => {
		if (shouldFetchZipCodes(getState(), stateId)) {
			return dispatch(fetchZipCodes(stateId));
		}
	};

export const loadZipCodesSuggestions =
	(value) => async (dispatch, getState) => {
		const theState = getState();
		dispatch(loadZipCodesSuggestionsBegin());
		dispatch(fetchZipCodesIfNeeded(theState.zipCodes.stateId));
		setTimeout(() => {
			dispatch(
				maybeUpdateZipCodesSuggestions({
					suggestions: getMatchingItemsZipCodes(theState.zipCodes, value),
					value,
				})
			);
		});
	};

export const fetchZipCodes = (stateId) => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	dispatch(requestZipCodesList());
	return fetch(BASE_API_URL + "/api/Cuenta/CodigosPostales/" + stateId, options)
		.then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				return response.text().then((error) => Promise.reject(error));
			}
		})
		.then((json) => {
			dispatch(receiveZipCodesList({ list: json }));
		})
		.catch(() => {
			// If 401 Unauthorized login failed
		});
};

export default zipCodes.reducer;