import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import {
	fetchSendConfirmation,
	setConfirmationErrorMsg,
	clearConfirmationData,
	toggleShowConfirmation,
} from "features/account/confirmationSlice";
import { toggleShowLogin } from "features/account/loginSlice";
import { toggleShowPassword } from "features/account/resetPasswordSlice";
import { toggleShowRegister } from "features/account/registerSlice";
import Loader from "components/Loader";

const renderError = (error) => {
	if (error != null) {
		return <li>{error}</li>;
	}
	return null;
};

export default function ConfirmationModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, errorMsg, isOpen } = useSelector(
		(state) => state.sendConfirmation
	);
	const { checked, authenticated } = useSelector((state) => state.session);
	// State
	const [usrname, setUsrname] = useState("");
	// Refs
	const usernameRef = useRef();
	useEffect(() => usernameRef.current && usernameRef.current.focus(), []);
	// Handlers
	function handleUsrnameChange(e) {
		if (errorMsg != null && errorMsg.usrname != null) {
			dispatch(
				setConfirmationErrorMsg({
					errorMsg: { ...errorMsg, usrname: null, error: null },
				})
			);
		}
		setUsrname(e.target.value);
	}
	function handleReset() {
		setUsrname("");
		dispatch(setConfirmationErrorMsg({ errorMsg: null }));
	}
	function handleCloseClick() {
		handleReset();
		dispatch(clearConfirmationData());
		dispatch(toggleShowConfirmation({ isOpen: true }));
	}
	function handleOverlayCloseClick() {
		dispatch(toggleShowConfirmation({ isOpen: true }));
	}
	function handleRegisterClick() {
		handleReset();
		dispatch(toggleShowConfirmation({ isOpen: true }));
		dispatch(toggleShowRegister({ isOpen: false }));
	}
	function handleResetPasswordClick() {
		handleReset();
		dispatch(toggleShowConfirmation({ isOpen: true }));
		dispatch(toggleShowPassword({ isOpen: false }));
	}
	function handleLoginClick() {
		handleReset();
		dispatch(toggleShowConfirmation({ isOpen: true }));
		dispatch(toggleShowLogin({ isOpen: false }));
	}
	function handleSubmit(e) {
		e.preventDefault();
		let valid = true;
		let errorMessages = { ...errorMsg };
		if (usrname.trim() === "") {
			errorMessages = {
				...errorMessages,
				usrname: "Debes escribir tu correo electrónico",
			};
			valid = false;
		}
		if (valid) {
			dispatch(fetchSendConfirmation(usrname));
		} else {
			dispatch(setConfirmationErrorMsg({ errorMsg: errorMessages }));
		}
	}
	// Render Functions
	function renderLoader() {
		if (isLoading) {
			return <Loader msg="Enviando..." />;
		}
		return null;
	}
	function renderErrorMsg() {
		if (errorMsg != null) {
			return (
				<div className="error-list">
					<ul>
						{renderError(errorMsg.error)}
						{renderError(errorMsg.usrname)}
					</ul>
				</div>
			);
		}
	}
	function renderForm() {
		if (!isLoading) {
			let errClass = { usrname: {} };
			if (errorMsg != null) {
				if (errorMsg.usrname != null) {
					errClass = { ...errClass, usrname: { className: "error" } };
				}
			}
			return (
				<form onSubmit={handleSubmit} onReset={handleReset}>
					<div className="login-form">
						<div {...errClass.usrname}>
							<label>Correo Electrónico</label>
							<input
								type="email"
								ref={usernameRef}
								placeholder="Correo Electrónico"
								onChange={handleUsrnameChange}
								autoComplete="email"
								className="modal-field"
								tabIndex={1}
							/>
						</div>
						{renderErrorMsg()}
						<div className="center">
							<button type="submit" className="button main">
								Solicitar
							</button>
						</div>
						<div>
							<div className="modal-link" onClick={handleLoginClick}>
								Login
							</div>
						</div>
						<div>
							<div className="modal-link" onClick={handleResetPasswordClick}>
								Olvidé mi contraseña
							</div>
						</div>
						<div>
							<div className="modal-link" onClick={handleRegisterClick}>
								Registrarme
							</div>
						</div>
					</div>
				</form>
			);
		}
		return null;
	}
	if (checked && !authenticated) {
		return (
			<div>
				<Modal
					isOpen={isOpen}
					className="modal small"
					overlayClassName="modal-overlay"
					onRequestClose={handleOverlayCloseClick}
					contentLabel="Login"
				>
					<div className="modal-inner" id="password-inner">
						<div className="modal-close" onClick={handleCloseClick}></div>
						<h2>Reenviar Confirmación</h2>
						{renderLoader()}
						{renderForm()}
					</div>
				</Modal>
			</div>
		);
	}
	return null;
}
