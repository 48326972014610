import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SHIP_TYPES } from "features/constants";
import {
	getBrandClass,
	imageFormat,
	currencyFormat,
	doesDiscountAplies,
	getModalButtonClass,
} from "features/functions";
import { evaluateCartNextStep } from "features/cart/cartSlice";
import Loader from "../Loader";
import CartDetailsQty from "./CartDetailsQty";
import CartDetailsRemove from "./CartDetailsRemove";
import CartCoupon from "./CartCoupon";

export default function CartDetails() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, cartObject, shipType, showTab } = useSelector(
		(state) => state.cart
	);
	// Handlers
	function handleNextClick() {
		if (cartObject.Articulos > 0) {
			dispatch(evaluateCartNextStep(showTab + 1));
		}
	}
	// Render functions
	function renderShipPrice() {
		if (shipType === SHIP_TYPES.ADDRESS) {
			return <div>{currencyFormat(cartObject.Costo_Envio)}</div>;
		}
		return <div>{currencyFormat(0)}</div>;
	}
	function renderProducts() {
		const campania = cartObject.CuponDescuento.Campania;
		const data = cartObject.Elementos;
		const aplicaDescuento = doesDiscountAplies(
			cartObject.ArticulosDescuento,
			cartObject.CuponDescuento
		);
		return (
			<div id="cart-content-products">
				{data.map((element, index) => {
					// Generador de Precio y Subtotal
					let price = null;
					let subtotal = null;
					if (aplicaDescuento) {
						// Si el tipo de campaña es 6 (SinCuponPorProductoMinPorCodigo)
						if (campania.Tipo === 6) {
							// Si aplica el descuento
							if (
								element.Tire.Discount > 0 &&
								element.Cantidad >= campania.MinProductos
							) {
								// Si la llanta tiene descuento y aplica
								price = (
									<div>
										<h4>Precio</h4>
										<h3 className="discount">
											{currencyFormat(element.Tire.ListPrice * 1.16)}
										</h3>
										<h3>{currencyFormat(element.Tire.Price * 1.16)}</h3>
										<p>MXN</p>
									</div>
								);
								subtotal = (
									<div>
										<h4>Importe</h4>
										<h3>
											{currencyFormat(
												element.Tire.Price * element.Cantidad * 1.16
											)}
										</h3>
										<p>MXN</p>
									</div>
								);
							} else {
								// Si la llanta no tiene descuento
								price = (
									<div>
										<h4>Precio</h4>
										<h3>{currencyFormat(element.Tire.ListPrice * 1.16)} </h3>
										<p>MXN</p>
									</div>
								);
								subtotal = (
									<div>
										<h4>Importe</h4>
										<h3>
											{currencyFormat(
												element.Tire.ListPrice * element.Cantidad * 1.16
											)}{" "}
										</h3>
										<p>MXN</p>
									</div>
								);
							}
						} else {
							// Si aplica el descuento
							if (element.Tire.Discount > 0) {
								// Si la llanta tiene descuento
								price = (
									<div>
										<h4>Precio</h4>
										<h3 className="discount">
											{currencyFormat(element.Tire.ListPrice * 1.16)}{" "}
										</h3>
										<h3>{currencyFormat(element.Tire.Price * 1.16)} </h3>
										<p>MXN</p>
									</div>
								);
								subtotal = (
									<div>
										<h4>Importe</h4>
										<h3>
											{currencyFormat(
												element.Tire.Price * element.Cantidad * 1.16
											)}{" "}
										</h3>
										<p>MXN</p>
									</div>
								);
							} else {
								// Si la llanta no tiene descuento
								price = (
									<div>
										<h4>Precio</h4>
										<h3>{currencyFormat(element.Tire.ListPrice * 1.16)} </h3>
										<p>MXN</p>
									</div>
								);
								subtotal = (
									<div>
										<h4>Importe</h4>
										<h3>
											{currencyFormat(
												element.Tire.ListPrice * element.Cantidad * 1.16
											)}{" "}
										</h3>
										<p>MXN</p>
									</div>
								);
							}
						}
					} else {
						// Si no aplica el descuento
						price = (
							<div>
								<h4>Precio</h4>
								<h3>{currencyFormat(element.Tire.ListPrice * 1.16)} </h3>
								<p>MXN</p>
							</div>
						);
						subtotal = (
							<div>
								<h4>Importe</h4>
								<h3>
									{currencyFormat(
										element.Tire.ListPrice * element.Cantidad * 1.16
									)}{" "}
								</h3>
								<p>MXN</p>
							</div>
						);
					}
					return (
						<div className="item" id={element.Kartis} key={index}>
							<div>
								<img
									src={imageFormat(element.Tire.TireProduct.Image)}
									alt={element.Tire.TireProduct.Name}
								/>
								<span
									className={
										"cart-" + getBrandClass(element.Tire.TireProduct.Brand)
									}
								/>
							</div>
							<div>
								<h2>{element.Tire.TireProduct.Name}</h2>
								<h4>Medida</h4>
								<h3>{element.Tire.Size}</h3>
								<p>{element.Kartis}</p>
							</div>
							<div>
								<h4>Cantidad</h4>
								<CartDetailsQty
									Kartis={element.Kartis}
									Cantidad={element.Cantidad}
								/>
							</div>
							{price}
							{subtotal}
							<CartDetailsRemove Kartis={element.Kartis} />
						</div>
					);
				})}
			</div>
		);
	}
	// Render
	if (isLoading) {
		return <Loader msg="Cargando..." />;
	}
	if (cartObject == null || cartObject === {}) {
		return null;
	}
	return (
		<div id="cart-content-1">
			{renderProducts(
				cartObject.Elementos,
				doesDiscountAplies(
					cartObject.ArticulosDescuento,
					cartObject.CuponDescuento
				)
			)}
			<div id="cart-summary">
				<CartCoupon />
				<div id="cart-totals">
					<div>
						<div>Subtotal</div>
						<div>{currencyFormat(cartObject.Total * 1.16)}</div>
					</div>
					<div>
						<div>Envío</div>
						{renderShipPrice()}
					</div>
					<div>
						<div className="totals">Total</div>
						<div className="totals">
							{currencyFormat(cartObject.Total * 1.16)}{" "}
						</div>
					</div>
					<div>
						<div>Desglose IVA</div>
						<div>{currencyFormat(cartObject.Total * 0.16)} </div>
					</div>
					<div>
						<div>&nbsp;</div>
						<div>
							<div
								className={getModalButtonClass(cartObject.Articulos === 0)}
								onClick={handleNextClick}
							>
								Continuar
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
