import { configureStore } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "features/reducers";
import { gtmMiddleware }  from "features/tagmanager";

const debug = process.env.RAZZLE_REDUX_DEBUG === "true";

const initStore = (history, initialState = {}) => {
	const store = configureStore({
		reducer: createRootReducer(history),
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware().concat(routerMiddleware(history),gtmMiddleware),
		devTools: debug,
		preloadedState: initialState,
	});
	if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('../features/reducers', (history) => {
      const nextRootReducer = require('../features/reducers').default(history);
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
export default initStore;