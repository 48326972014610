import React from "react";
import { hydrate } from "react-dom";
import { sessionService } from "redux-react-session";
import { Provider } from "react-redux";
import { setDefaultLocale } from "react-datepicker";
import { ConnectedRouter } from "connected-react-router";
import { Switch, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import Modal from "react-modal";
import es from "date-fns/locale/es";
import "./fonts.css";
import "react-datepicker/dist/react-datepicker.css";
import history from "features/history";
import routes from "features/routes";
import initStore from "app/store";
import { GOOGLE_ANALYTICS, SITE_DOMAIN } from "features/constants";
import { isServer } from "features/functions";
import packageJson from "../package.json";

import "./fonts.css";
import "./style.css";
import CacheBuster from "components/CacheBuster";

if (!isServer) {
	ReactGA.initialize(GOOGLE_ANALYTICS, {
	});
	ReactGA.event({
		category: "Software",
		action: "Version",
		label: packageJson.version,
	});
}
Modal.setAppElement("#root");
setDefaultLocale(es);

const store = initStore(history, window.__PRELOADED_STATE__);

sessionService.initSessionService(store);

hydrate(
	<CacheBuster>
		{({ loading, isLatestVersion, refreshCacheAndReload }) => {
			if (loading) return null;
			if (!loading && !isLatestVersion) {
				// You can decide how and when you want to force reload
				refreshCacheAndReload();
			}
			return (
				<React.StrictMode>
					<Provider store={store}>
						<ConnectedRouter history={history}>
							<Switch>
								{routes.map((route, index) => (
									<Route {...route} key={index} />
								))}
							</Switch>
						</ConnectedRouter>
					</Provider>
				</React.StrictMode>
			);
		}}
	</CacheBuster>,
	document.getElementById("root")
);

if (module.hot) {
	module.hot.accept("components/App", () => {
		hydrate(
			<React.StrictMode>
				<Provider store={store}>
					<ConnectedRouter history={history}>
						<Switch>
							{routes.map((route, index) => (
								<Route {...route} key={index} />
							))}
						</Switch>
					</ConnectedRouter>
				</Provider>
			</React.StrictMode>,
			document.getElementById("root")
		);
	});
}
