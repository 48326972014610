import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { sessionService } from "redux-react-session";
import { BASE_API_URL } from "features/constants";
import { stringNumberFormat } from "features/functions";
import { setCartPaymentCard } from "features/cart/cartSlice";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	isOpen: false,
	list: null,
	selectedCard: null,
	options: [{ value: 0, label: "Nueva tarjeta" }],
};

const accountCards = createSlice({
	name: "accountCards",
	initialState,
	reducers: {
		requestCards: (state) => {
			state.isLoading = true;
		},
		receiveCards: (state, action) => {
			state.list = action.payload.list;
			state.options = action.payload.options;
			state.isLoading = false;
		},
		toggleShowAccountCards: (state, action) => {
			state.isOpen = !action.payload.isOpen;
		},
		setCardsIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading;
		},
		setSelectedCard: (state,action) => {
			state.selectedCard = action.payload.selectedCard;
		},
		clearAccountCardsData: () => initialState,
	},
});

export const {
	requestCards,
	receiveCards,
	toggleShowAccountCards,
	toggleShowSelectedCard,
	setCardsIsLoading,
	setSelectedCard,
	clearAccountCardsData,
} = accountCards.actions;

const mapOptions = (list) => {
	if (list == null || (list != null && list.length === 0)) {
		return [{ value: 0, label: "Nueva tarjeta" }];
	}
	let options = list.map((el) => {
		return {
			value: el.Id,
			label:
				el.Marca.toUpperCase() +
				" ***" +
				el.Numero +
				" - " +
				stringNumberFormat(el.Mes, 2) +
				"/" +
				stringNumberFormat(el.Anio, 2),
		};
	});
	options.push({ value: 0, label: "Otra tarjeta" });
	return options;
};
export const fetchAccountCards = () => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const url = BASE_API_URL + "/api/Cuenta/MiCuenta/Tarjetas";
			dispatch(requestCards());
			return axios
				.get(url, options)
				.then((response) => {
					const cardOptions = mapOptions(response.data);
					dispatch(
						receiveCards({
							list: response.data,
							options: cardOptions,
						})
					);
					dispatch(
						setCartPaymentCard({
							paymentCard: cardOptions[0].value,
							paymentCardItem: cardOptions[0],
						})
					);
				})
				.catch((error) => {
					dispatch(receiveCards({ list: [], options: mapOptions() }));
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default accountCards.reducer;
