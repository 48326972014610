import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { load } from "opentype.js";
import { Matrix } from "transformation-matrix-js";
import canvasFont from "css/fonts/Stag-Sans-Bold-Italic.otf";
import canvasImage from "images/tire-finder-art.png";

var canvasParams = {
	image: null,
	shadowsImage: null,
	radius: 309,
	center: { x: 341.5, y: 417 },

	//by default
	values: ["", "", ""],
	template: ["185", "65", "15"],
	white_color: "rgba(255, 255, 255, 0.8)",
	yellow_color: "rgba(254, 167, 0, 1.0)",
	soft_light: "rgba(255, 255, 255, 0.05)", //pure white with alpha 0.05

	indexFocus: -1,
	separator: "/",

	// SAE Mode (true|false)
	sae_mode: false,
	sae_separator: "X",

	build: "R",
	offset: -90.0,
	//angle: this.offset / 180 * Math.PI,
	angle: (-90.0 / 180) * Math.PI,

	imageWidth: 0,
	imageHeight: 0,

	//context
	matrix: null,
	path2d: null,
	path2d_inverse: null,

	firstPath: null,
	separatorPath: null,
	secondPath: null,
	buildPath: null,
	thirdPath: null,

	//OpenType
	ottfont: null,
};
const getGlyphWidth = (glyph, fontSize) => {
	var width = 0;
	var scale = (1 / canvasParams.ottfont.unitsPerEm) * fontSize;

	if (glyph.advanceWidth) {
		width = glyph.advanceWidth;
	}

	return width * scale;
};
export default function TireCanvas() {
	// Selectors
	const { focus, isSAE, first, second, third, firstLoad } = useSelector(
		(state) => state.sizeCanvas
	);
	// Refs
	const canvasRef = useRef();
	// Functions
	function drawType(context) {
		if (canvasParams.image !== null) {
			context.drawImage(
				canvasParams.image,
				0,
				0,
				canvasParams.imageWidth,
				canvasParams.imageHeight
			);

			context.save();
			context.clip(canvasParams.path2d_inverse);
			context.drawImage(
				canvasParams.shadowsImage,
				0,
				0,
				canvasParams.imageWidth,
				canvasParams.imageHeight
			);
			context.restore();

			context.save();
			context.fillStyle = canvasParams.soft_light;
			context.fill(canvasParams.path2d);
			context.restore();

			//draw filled inputs
			context.save();
			context.fillStyle = canvasParams.white_color;

			if (canvasParams.values[0] !== "" && canvasParams.indexFocus !== 0)
				context.fill(canvasParams.firstPath);
			if (canvasParams.values[1] !== "" && canvasParams.indexFocus !== 1)
				context.fill(canvasParams.secondPath);
			if (canvasParams.values[0] !== "" && canvasParams.values[1] !== "")
				context.fill(canvasParams.separatorPath);
			if (canvasParams.values[2] !== "" && canvasParams.indexFocus !== 2)
				context.fill(canvasParams.thirdPath);
			if (
				canvasParams.values[0] !== "" &&
				canvasParams.values[1] !== "" &&
				canvasParams.values[2] !== ""
			)
				context.fill(canvasParams.buildPath);

			context.restore();

			if (canvasParams.indexFocus !== -1) {
				context.save();
				context.fillStyle = canvasParams.yellow_color;

				switch (canvasParams.indexFocus) {
					case 0:
						context.fill(canvasParams.firstPath);
						break;
					case 1:
						context.fill(canvasParams.secondPath);
						break;
					case 2:
						context.fill(canvasParams.thirdPath);
						break;
					default:
				}

				context.restore();
			}
		}
	}
	function blurSize() {
		canvasParams.indexFocus = -1;
	}
	function setMode(mode) {
		if (mode) {
			canvasParams.template = ["31", "10.5", "15"];
		} else {
			canvasParams.template = ["185", "65", "15"];
		}
		//SAE
		canvasParams.sae_mode = mode;
	}
	function getTextWidth(string, fontSize, kerning) {
		var width = 0;
		var glyphs = canvasParams.ottfont.stringToGlyphs(string);
		var scale = (1 / canvasParams.ottfont.unitsPerEm) * fontSize;

		for (var i = 0; i < glyphs.length; i++) {
			if (glyphs[i].advanceWidth) {
				width += glyphs[i].advanceWidth;
			}

			if (kerning && i < glyphs.length - 1) {
				var kerningValue = canvasParams.ottfont.getKerningValue(
					glyphs[i],
					glyphs[i + 1]
				);
				width += kerningValue;
			}
		}

		return width * scale;
	}
	// function getKerning(glyph, nextGlyph, fontSize) {
	// 	var scale = (1 / canvasParams.ottfont.unitsPerEm) * fontSize;
	// 	let kerningValue =
	// 		canvasParams.ottfont.getKerningValue(glyph, nextGlyph) * scale;
	// 	return kerningValue;
	// }
	// returns convert degree to radian
	// function toRadian(degree) {
	// 	return degree * (Math.PI / 180);
	// }
	// returns convert radian to degree
	// function toDegree(radian) {
	// 	return radian * (180 / Math.PI);
	// }
	// Effect
	useEffect(() => {
		const canvas = canvasRef.current;
		const context = canvas.getContext("2d");
		// Functions
		function updateCanvas(canvas, context, imgSrc, fontSrc) {
			//load image
			var img = new Image();
			img.onload = () => {
				canvasParams.imageWidth = img.width;
				canvasParams.imageHeight = img.height;

				context.canvas.width = canvasParams.imageWidth;
				context.canvas.height = canvasParams.imageHeight;

				canvasParams.image = img;
				loadOpenTypeFont(context, canvas, fontSrc);
			};
			img.src = imgSrc;
		}
		function focusSize(context, i) {
			canvasParams.indexFocus = i;
			drawType(context);
		}
		function loadOpenTypeFont(context, canvas, fontSrc) {
			load(fontSrc, (err, _font) => {
				if (err) {
					alert("Font could not be loaded: " + err);
				} else {
					canvasParams.ottfont = _font;

					reshape(context, canvas);
				}
			});
		}
		function setValues(context, canvas, newValues) {
			canvasParams.values = newValues;
			reshape(context, canvas);
		}

		function reshape(context, canvas) {
			let fontSize = 42;
			buildFontPath(fontSize);
			context.clearRect(0, 0, canvas.width, canvas.height);
			context.drawImage(
				canvasParams.image,
				0,
				0,
				canvasParams.imageWidth,
				canvasParams.imageHeight
			);
			renderShadows(context);
		}
		function buildFontPath(fontSize) {
			if (!canvasParams.ottfont) return;

			let string =
				"" +
				(canvasParams.values[0] === ""
					? canvasParams.template[0]
					: canvasParams.values[0]) +
				canvasParams.separator +
				(canvasParams.values[1] === ""
					? canvasParams.template[1]
					: canvasParams.values[1]) +
				" " +
				canvasParams.build +
				" " +
				(canvasParams.values[2] === ""
					? canvasParams.template[2]
					: canvasParams.values[2]);

			canvasParams.path2d = new Path2D();
			canvasParams.path2d_inverse = new Path2D();

			//fill paths
			canvasParams.firstPath = new Path2D();
			canvasParams.separatorPath = new Path2D();
			canvasParams.secondPath = new Path2D();
			canvasParams.buildPath = new Path2D();
			canvasParams.thirdPath = new Path2D();

			//reset start angle
			var perimeter = 2 * Math.PI * canvasParams.radius;
			var textWidth = getTextWidth(string, fontSize, false);
			var centerAngle = (textWidth / 2 / perimeter) * 2 * Math.PI; //center text

			//reset
			canvasParams.angle = (canvasParams.offset / 180) * Math.PI;
			canvasParams.angle -= centerAngle; //align to center

			//first value
			var firstPart =
				"" +
				(canvasParams.values[0] === ""
					? canvasParams.template[0]
					: canvasParams.values[0]);

			var point;
			//iterate each glyph
			canvasParams.ottfont.forEachGlyph(
				firstPart,
				0,
				0,
				fontSize,
				{},
				(glyph, gX, gY, gFontSize) => {
					var glyphPath = glyph.getPath(0, 0, gFontSize);

					var letterDim = getGlyphWidth(glyph, fontSize);
					var letterAngle = (letterDim / perimeter) * 2 * Math.PI; //unit

					var x = canvasParams.radius * Math.cos(canvasParams.angle);
					var y = canvasParams.radius * Math.sin(canvasParams.angle);

					//matrix
					canvasParams.matrix = new Matrix(); //reset
					canvasParams.matrix.translate(
						x + canvasParams.center.x,
						y + canvasParams.center.y
					);
					canvasParams.matrix.rotate(canvasParams.angle + 0.5 * Math.PI); //

					canvasParams.angle += letterAngle;

					for (var i = 0; i < glyphPath.commands.length; i++) {
						switch (glyphPath.commands[i].type) {
							case "M":
								point = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x,
									glyphPath.commands[i].y
								);

								canvasParams.path2d.moveTo(point.x, point.y);
								canvasParams.path2d_inverse.moveTo(point.x, point.y);
								canvasParams.firstPath.moveTo(point.x, point.y);

								break;
							case "L":
								point = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x,
									glyphPath.commands[i].y
								);

								canvasParams.path2d.lineTo(point.x, point.y);
								canvasParams.path2d_inverse.lineTo(point.x, point.y);
								canvasParams.firstPath.lineTo(point.x, point.y);

								break;
							case "C":
								point = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x,
									glyphPath.commands[i].y
								);
								var point1 = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x1,
									glyphPath.commands[i].y1
								);
								var point2 = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x2,
									glyphPath.commands[i].y2
								);

								canvasParams.path2d.bezierCurveTo(
									point1.x,
									point1.y,
									point2.x,
									point2.y,
									point.x,
									point.y
								);
								canvasParams.path2d_inverse.bezierCurveTo(
									point1.x,
									point1.y,
									point2.x,
									point2.y,
									point.x,
									point.y
								);
								canvasParams.firstPath.bezierCurveTo(
									point1.x,
									point1.y,
									point2.x,
									point2.y,
									point.x,
									point.y
								);

								break;
							case "Z":
								canvasParams.path2d.closePath(); //end close
								canvasParams.path2d_inverse.closePath(); //end close
								canvasParams.firstPath.closePath(); //end close
								break;
							default:
						}
					}
				}
			);

			var separatorPart =
				"" +
				(canvasParams.sae_mode
					? canvasParams.sae_separator
					: canvasParams.separator);

			//separator
			canvasParams.ottfont.forEachGlyph(
				separatorPart,
				0,
				0,
				fontSize,
				{},
				function (glyph, gX, gY, gFontSize) {
					var glyphPath = glyph.getPath(0, 0, gFontSize);

					var letterDim = getGlyphWidth(glyph, fontSize);
					var letterAngle = (letterDim / perimeter) * 2 * Math.PI; //unit

					var x = canvasParams.radius * Math.cos(canvasParams.angle);
					var y = canvasParams.radius * Math.sin(canvasParams.angle);

					//matrix
					canvasParams.matrix = new Matrix(); //reset
					canvasParams.matrix.translate(
						x + canvasParams.center.x,
						y + canvasParams.center.y
					);
					canvasParams.matrix.rotate(canvasParams.angle + 0.5 * Math.PI); //

					canvasParams.angle += letterAngle;

					for (var i = 0; i < glyphPath.commands.length; i++) {
						switch (glyphPath.commands[i].type) {
							case "M":
								point = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x,
									glyphPath.commands[i].y
								);

								canvasParams.path2d.moveTo(point.x, point.y);
								canvasParams.path2d_inverse.moveTo(point.x, point.y);
								canvasParams.separatorPath.moveTo(point.x, point.y);

								break;
							case "L":
								point = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x,
									glyphPath.commands[i].y
								);

								canvasParams.path2d.lineTo(point.x, point.y);
								canvasParams.path2d_inverse.lineTo(point.x, point.y);
								canvasParams.separatorPath.lineTo(point.x, point.y);

								break;
							case "C":
								point = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x,
									glyphPath.commands[i].y
								);
								var point1 = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x1,
									glyphPath.commands[i].y1
								);
								var point2 = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x2,
									glyphPath.commands[i].y2
								);

								canvasParams.path2d.bezierCurveTo(
									point1.x,
									point1.y,
									point2.x,
									point2.y,
									point.x,
									point.y
								);
								canvasParams.path2d_inverse.bezierCurveTo(
									point1.x,
									point1.y,
									point2.x,
									point2.y,
									point.x,
									point.y
								);
								canvasParams.separatorPath.bezierCurveTo(
									point1.x,
									point1.y,
									point2.x,
									point2.y,
									point.x,
									point.y
								);

								break;
							case "Z":
								canvasParams.path2d.closePath(); //end close
								canvasParams.path2d_inverse.closePath(); //end close
								canvasParams.separatorPath.closePath(); //end close
								break;
							default:
						}
					}
				}
			);

			//second value
			var secondPart =
				"" +
				(canvasParams.values[1] === ""
					? canvasParams.template[1]
					: canvasParams.values[1]);

			//iterate each glyph
			canvasParams.ottfont.forEachGlyph(
				secondPart,
				0,
				0,
				fontSize,
				{},
				function (glyph, gX, gY, gFontSize) {
					var glyphPath = glyph.getPath(0, 0, gFontSize);

					var letterDim = getGlyphWidth(glyph, fontSize);
					var letterAngle = (letterDim / perimeter) * 2 * Math.PI; //unit

					var x = canvasParams.radius * Math.cos(canvasParams.angle);
					var y = canvasParams.radius * Math.sin(canvasParams.angle);

					//matrix
					canvasParams.matrix = new Matrix(); //reset
					canvasParams.matrix.translate(
						x + canvasParams.center.x,
						y + canvasParams.center.y
					);
					canvasParams.matrix.rotate(canvasParams.angle + 0.5 * Math.PI); //

					canvasParams.angle += letterAngle;

					for (var i = 0; i < glyphPath.commands.length; i++) {
						switch (glyphPath.commands[i].type) {
							case "M":
								point = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x,
									glyphPath.commands[i].y
								);

								canvasParams.path2d.moveTo(point.x, point.y);
								canvasParams.path2d_inverse.moveTo(point.x, point.y);
								canvasParams.secondPath.moveTo(point.x, point.y);

								break;
							case "L":
								point = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x,
									glyphPath.commands[i].y
								);

								canvasParams.path2d.lineTo(point.x, point.y);
								canvasParams.path2d_inverse.lineTo(point.x, point.y);
								canvasParams.secondPath.lineTo(point.x, point.y);

								break;
							case "C":
								point = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x,
									glyphPath.commands[i].y
								);
								var point1 = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x1,
									glyphPath.commands[i].y1
								);
								var point2 = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x2,
									glyphPath.commands[i].y2
								);

								canvasParams.path2d.bezierCurveTo(
									point1.x,
									point1.y,
									point2.x,
									point2.y,
									point.x,
									point.y
								);
								canvasParams.path2d_inverse.bezierCurveTo(
									point1.x,
									point1.y,
									point2.x,
									point2.y,
									point.x,
									point.y
								);
								canvasParams.secondPath.bezierCurveTo(
									point1.x,
									point1.y,
									point2.x,
									point2.y,
									point.x,
									point.y
								);

								break;
							case "Z":
								canvasParams.path2d.closePath(); //end close
								canvasParams.path2d_inverse.closePath(); //end close
								canvasParams.secondPath.closePath(); //end close
								break;
							default:
						}
					}
				}
			);

			//build
			var buildPart = " " + canvasParams.build;

			//iterate each glyph
			canvasParams.ottfont.forEachGlyph(
				buildPart,
				0,
				0,
				fontSize,
				{},
				function (glyph, gX, gY, gFontSize) {
					var glyphPath = glyph.getPath(0, 0, gFontSize);

					var letterDim = getGlyphWidth(glyph, fontSize);
					var letterAngle = (letterDim / perimeter) * 2 * Math.PI; //unit

					var x = canvasParams.radius * Math.cos(canvasParams.angle);
					var y = canvasParams.radius * Math.sin(canvasParams.angle);

					//matrix
					canvasParams.matrix = new Matrix(); //reset
					canvasParams.matrix.translate(
						x + canvasParams.center.x,
						y + canvasParams.center.y
					);
					canvasParams.matrix.rotate(canvasParams.angle + 0.5 * Math.PI); //

					canvasParams.angle += letterAngle;

					for (var i = 0; i < glyphPath.commands.length; i++) {
						switch (glyphPath.commands[i].type) {
							case "M":
								point = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x,
									glyphPath.commands[i].y
								);

								canvasParams.path2d.moveTo(point.x, point.y);
								canvasParams.path2d_inverse.moveTo(point.x, point.y);
								canvasParams.buildPath.moveTo(point.x, point.y);

								break;
							case "L":
								point = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x,
									glyphPath.commands[i].y
								);

								canvasParams.path2d.lineTo(point.x, point.y);
								canvasParams.path2d_inverse.lineTo(point.x, point.y);
								canvasParams.buildPath.lineTo(point.x, point.y);

								break;
							case "C":
								point = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x,
									glyphPath.commands[i].y
								);
								var point1 = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x1,
									glyphPath.commands[i].y1
								);
								var point2 = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x2,
									glyphPath.commands[i].y2
								);

								canvasParams.path2d.bezierCurveTo(
									point1.x,
									point1.y,
									point2.x,
									point2.y,
									point.x,
									point.y
								);
								canvasParams.path2d_inverse.bezierCurveTo(
									point1.x,
									point1.y,
									point2.x,
									point2.y,
									point.x,
									point.y
								);
								canvasParams.buildPath.bezierCurveTo(
									point1.x,
									point1.y,
									point2.x,
									point2.y,
									point.x,
									point.y
								);

								break;
							case "Z":
								canvasParams.path2d.closePath(); //end close
								canvasParams.path2d_inverse.closePath(); //end close
								canvasParams.buildPath.closePath(); //end close
								break;
							default:
						}
					}
				}
			);

			//third value
			var thirdPart =
				" " +
				(canvasParams.values[2] === ""
					? canvasParams.template[2]
					: canvasParams.values[2]);

			//iterate each glyph
			canvasParams.ottfont.forEachGlyph(
				thirdPart,
				0,
				0,
				fontSize,
				{},
				function (glyph, gX, gY, gFontSize) {
					var glyphPath = glyph.getPath(0, 0, gFontSize);

					var letterDim = getGlyphWidth(glyph, fontSize);
					var letterAngle = (letterDim / perimeter) * 2 * Math.PI; //unit

					var x = canvasParams.radius * Math.cos(canvasParams.angle);
					var y = canvasParams.radius * Math.sin(canvasParams.angle);

					//matrix
					canvasParams.matrix = new Matrix(); //reset
					canvasParams.matrix.translate(
						x + canvasParams.center.x,
						y + canvasParams.center.y
					);
					canvasParams.matrix.rotate(canvasParams.angle + 0.5 * Math.PI); //

					canvasParams.angle += letterAngle;

					for (var i = 0; i < glyphPath.commands.length; i++) {
						switch (glyphPath.commands[i].type) {
							case "M":
								point = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x,
									glyphPath.commands[i].y
								);

								canvasParams.path2d.moveTo(point.x, point.y);
								canvasParams.path2d_inverse.moveTo(point.x, point.y);
								canvasParams.thirdPath.moveTo(point.x, point.y);

								break;
							case "L":
								point = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x,
									glyphPath.commands[i].y
								);

								canvasParams.path2d.lineTo(point.x, point.y);
								canvasParams.path2d_inverse.lineTo(point.x, point.y);
								canvasParams.thirdPath.lineTo(point.x, point.y);

								break;
							case "C":
								point = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x,
									glyphPath.commands[i].y
								);
								var point1 = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x1,
									glyphPath.commands[i].y1
								);
								var point2 = canvasParams.matrix.applyToPoint(
									glyphPath.commands[i].x2,
									glyphPath.commands[i].y2
								);

								canvasParams.path2d.bezierCurveTo(
									point1.x,
									point1.y,
									point2.x,
									point2.y,
									point.x,
									point.y
								);
								canvasParams.path2d_inverse.bezierCurveTo(
									point1.x,
									point1.y,
									point2.x,
									point2.y,
									point.x,
									point.y
								);
								canvasParams.thirdPath.bezierCurveTo(
									point1.x,
									point1.y,
									point2.x,
									point2.y,
									point.x,
									point.y
								);

								break;
							case "Z":
								canvasParams.path2d.closePath(); //end close
								canvasParams.path2d_inverse.closePath(); //end close
								canvasParams.thirdPath.closePath(); //end close
								break;
							default:
						}
					}
				}
			);

			//add clip box
			canvasParams.path2d_inverse.moveTo(0, 0);
			canvasParams.path2d_inverse.lineTo(canvasParams.imageWidth, 0);
			canvasParams.path2d_inverse.lineTo(
				canvasParams.imageWidth,
				canvasParams.imageHeight
			);
			canvasParams.path2d_inverse.lineTo(0, canvasParams.imageHeight);
			canvasParams.path2d_inverse.closePath(); //end close
			//*/
		}
		function renderShadows(context) {
			var _canvas = document.createElement("canvas");
			document.getElementsByTagName("body")[0].appendChild(_canvas);
			var _context = _canvas.getContext("2d");
			_context.canvas.width = canvasParams.imageWidth;
			_context.canvas.height = canvasParams.imageHeight;

			//glow
			_context.save();
			_context.fillStyle = "rgba(45, 45, 45, 1.0)";
			_context.shadowColor = "rgba(0, 0, 0, 0.5)";
			_context.shadowBlur = 15;
			_context.shadowOffsetX = 0;
			_context.shadowOffsetY = 0;
			_context.fill(canvasParams.path2d);
			_context.restore();

			//fine contour
			_context.save();
			_context.fillStyle = "rgba(45, 45, 45, 1.0)";
			_context.shadowColor = "rgba(0, 0, 0, 0.25)";
			_context.shadowBlur = 0;
			_context.shadowOffsetX = 0;
			_context.shadowOffsetY = 0;
			_context.fill(canvasParams.path2d);
			_context.restore();

			//left drop shadow
			_context.save();
			_context.fillStyle = "rgba(45, 45, 45, 1.0)";
			_context.shadowColor = "rgba(0, 0, 0, 0.25)";
			_context.shadowBlur = 2;
			_context.shadowOffsetX = -2;
			_context.shadowOffsetY = 2;
			_context.fill(canvasParams.path2d);
			_context.restore();

			//right drop shadow
			_context.save();
			_context.fillStyle = "rgba(45, 45, 45, 1.0)";
			_context.shadowColor = "rgba(0, 0, 0, 0.15)";
			_context.shadowBlur = 2;
			_context.shadowOffsetX = 2;
			_context.shadowOffsetY = -2;
			_context.fill(canvasParams.path2d);
			_context.restore();

			//finally fill solid color
			canvasParams.shadowsImage = new Image();
			canvasParams.shadowsImage.src = _canvas.toDataURL();
			canvasParams.shadowsImage.onload = () => {
				drawType(context);
			};

			document.getElementsByTagName("body")[0].removeChild(_canvas);
		}

		updateCanvas(canvas, context, canvasImage, canvasFont);
		if (focus != null) {
			focusSize(context, focus);
		}
		if (!firstLoad && focus == null) {
			blurSize();
			setValues(context, canvas, ["", "", ""]);
		}
		setMode(isSAE);
		if (!firstLoad && first == null && second == null && third == null) {
			setValues(context, canvas, ["", "", ""]);
		}
		if (first != null && second == null && third == null) {
			setValues(context, canvas, [first, "", ""]);
		}
		if (first != null && second != null && third == null) {
			setValues(context, canvas, [first, second, ""]);
		}
		if (first != null && second != null && third != null) {
			setValues(context, canvas, [first + "", second + "", third + ""]);
		}
	}, [
		// updateCanvas,
		// focusSize,
		// setMode,
		// setValues,
		// isSAE,
		// focus,
		first,
		second,
		third,
		focus,
		firstLoad,
		isSAE,
	]);
	// Render
	return (
		<div id="tireSizeDisplay">
			<canvas ref={canvasRef} />
		</div>
	);
}
