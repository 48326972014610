import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { fetchAddItemToCartObject } from "features/cart/cartSlice";

export default function CartDetailsQty(props) {
	const dispatch = useDispatch();
	// State
	const [qty, setQty] = useState(1);
	const [defaultQty, setDefaultQty] = useState(1);
	// Effect
	useEffect(() => {
		setQty(props.Cantidad);
		setDefaultQty(props.Cantidad);
	}, [props.Cantidad]);
	// Handlers
	function handleFocus(e) {
		e.target.select();
	}
	function handleBlur(e) {
		const newQty = Number(e.target.value.replace(/[^0-9]/g, ""));
		if (newQty > 0 && newQty !== defaultQty) {
			ReactGA.event({
				category: "Compra",
				action: "Modificar Cantidad de Producto",
				label: props.Kartis,
				value: newQty,
			});
			dispatch(fetchAddItemToCartObject(props.Kartis, newQty));
		} else {
			setQty(defaultQty);
		}
	}
	function handleKeyPress(e) {
		if (e.key === "Enter") {
			handleBlur(e);
		}
	}
	function handleKeyDown(e) {
		if (e.key === "Tab") {
			handleBlur(e);
		}
		if (e.key === "Escape") {
			setQty(defaultQty);
		}
	}
	function handleChange(e) {
		setQty(e.target.value.replace(/[^0-9]/g, ""));
	}
	// Render
	return (
		<div>
			<input
				type="text"
				id={props.Kartis + "-qty"}
				value={qty}
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
				onKeyPress={handleKeyPress}
				onKeyDown={handleKeyDown}
			/>
		</div>
	);
}
