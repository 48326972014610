import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import { sub, parseISO } from "date-fns";
import { validatePhone } from "features/functions";
import {
	clearAccountEditData,
	setAccountEditErrorMsg,
	fetchAccountEdit,
	toggleShowAccountEdit,
} from "../../features/account/accountEditSlice";
import Loader from "components/Loader";

export default function AccountEditModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, errorMsg, isOpen } = useSelector(
		(state) => state.accountEdit
	);
	const { checked, authenticated, user } = useSelector(
		(state) => state.session
	);
	// State
	const [name, setName] = useState("");
	const [lastName, setLastName] = useState("");
	const [motherName, setMotherName] = useState("");
	const [phone, setPhone] = useState("");
	const [gender, setGender] = useState(null);
	const [birthdate, setBirthdate] = useState(null);
	const [subscribe, setSubscribe] = useState(true);
	// Refs
	const nameRef = useRef();
	useEffect(() => {
		nameRef.current && nameRef.current.focus();
		if (authenticated && user.user != null) {
			setName(user.user.name);
			setLastName(user.user.lastname);
			setMotherName(user.user.mothername);
			setPhone(user.user.phone);
			setGender(user.user.gender);
			setBirthdate(parseISO(user.user.birthdate));
			setSubscribe(user.user.subscribe);
		}
	}, [authenticated, user]);
	// Handlers
	function handleNameChange(e) {
		if (errorMsg != null && (errorMsg.name != null || errorMsg.error != null)) {
			dispatch(
				setAccountEditErrorMsg({
					errorMsg: { ...errorMsg, name: null, error: null },
				})
			);
		}
		setName(e.target.value);
	}
	function handleLastNameChange(e) {
		if (errorMsg != null && errorMsg.lastName != null) {
			dispatch(
				setAccountEditErrorMsg({
					errorMsg: { ...errorMsg, lastName: null, error: null },
				})
			);
		}
		setLastName(e.target.value);
	}
	function handleMotherNameChange(e) {
		setMotherName(e.target.value);
	}
	function handlePhoneChange(e) {
		if (errorMsg != null && errorMsg.phone != null) {
			dispatch(
				setAccountEditErrorMsg({
					errorMsg: { ...errorMsg, phone: null, error: null },
				})
			);
		}
		setPhone(e.target.value);
	}
	function handleGenderChange(e) {
		if (errorMsg != null && errorMsg.gender != null) {
			dispatch(
				setAccountEditErrorMsg({
					errorMsg: { ...errorMsg, gender: null, error: null },
				})
			);
		}
		setGender(e.target.value);
	}
	function handleDateChange(date) {
		if (errorMsg != null && errorMsg.birthdate != null) {
			dispatch(
				setAccountEditErrorMsg({
					errorMsg: { ...errorMsg, birthdate: null, error: null },
				})
			);
		}
		setBirthdate(date);
	}
	function handleSubscriptionChange(e) {
		setSubscribe(e.target.checked);
	}
	function handleReset() {
		if (user.user != null) {
			setName(user.user.name);
			setLastName(user.user.lastname);
			setMotherName(user.user.mothername);
			setPhone(user.user.phone);
			setGender(user.user.gender);
			setBirthdate(parseISO(user.user.birthdate));
			setSubscribe(user.user.subscribe === true);
		}
	}
	function handleCloseClick() {
		handleReset();
		dispatch(clearAccountEditData());
		dispatch(toggleShowAccountEdit({ isOpen }));
	}
	function handleSubmit(e) {
		e.preventDefault();
		let valid = true;
		let errorMessages = { ...errorMsg };
		if (name.trim() === "") {
			errorMessages = { ...errorMessages, name: "Debes escribir tu nombre" };
			valid = false;
		}
		if (lastName.trim() === "") {
			errorMessages = {
				...errorMessages,
				lastName: "Debes escribir tu apellido paterno",
			};
			valid = false;
		}
		if (phone.trim() === "") {
			errorMessages = {
				...errorMessages,
				phone: "Debes escribir un teléfono para poder contactarte",
			};
			valid = false;
		} else {
			if (!validatePhone(phone)) {
				errorMessages = {
					...errorMessages,
					phone: "Debes escribir un teléfono válido",
				};
				valid = false;
			}
		}
		if (gender == null) {
			errorMessages = {
				...errorMessages,
				gender: "Debes seleccionar el género",
			};
			valid = false;
		}
		if (name === user.user.name 
			&& lastName === user.user.lastname
			&& motherName === user.user.mothername
			&& phone === user.user.phone
			&& birthdate === birthdate
			&& gender === gender
			&& subscribe === subscribe
			) {
				errorMessages = {
					...errorMessages,
					error: "No se detectaron cambios",
				};
				valid = false;
			}
		if (valid) {
			dispatch(
				fetchAccountEdit({
					name,
					lastName,
					motherName,
					phone,
					birthdate,
					gender,
					subscribe,
				})
			);
		} else {
			dispatch(setAccountEditErrorMsg({ errorMsg: errorMessages }));
		}
	}
	// Render Functions
	if (checked && !authenticated) {
		return null;
	}
	function renderError(error) {
		if (error != null) {
			return <li>{error}</li>;
		}
		return null;
	}
	function renderErrorList() {
		if (errorMsg != null) {
			return (
				<div className="error-list">
					<ul>
						{renderError(errorMsg.error)}
						{renderError(errorMsg.name)}
						{renderError(errorMsg.lastName)}
						{renderError(errorMsg.phone)}
						{renderError(errorMsg.birthdate)}
						{renderError(errorMsg.gender)}
						{renderError(errorMsg.coupon)}
					</ul>
				</div>
			);
		}
		return null;
	}
	function getFieldProps(property) {
		if (errorMsg == null) {
			return {};
		}
		if (property in errorMsg && errorMsg[property] != null) {
			return { className: "error" };
		}
		return {};
	}
	function renderLoader() {
		if (isLoading) {
			return <Loader msg="Por favor espere..." />;
		}
		return null;
	}
	function renderForm() {
		if (!isLoading) {
			return (
				<form onSubmit={handleSubmit} onReset={handleReset}>
					<div className="register-form">
						<div>
							<div {...getFieldProps("name")}>
								<label>Nombre *</label>
								<input
									type="text"
									placeholder="Nombre"
									value={name}
									ref={nameRef}
									onChange={handleNameChange}
									autoComplete="given-name"
									className="modal-field"
									tabIndex={3}
								/>
							</div>
							<div {...getFieldProps("lastName")}>
								<label>Apellido Paterno *</label>
								<input
									type="text"
									placeholder="Apellido Paterno"
									value={lastName}
									onChange={handleLastNameChange}
									autoComplete="additional-name"
									className="modal-field"
									tabIndex={4}
								/>
							</div>
						</div>
						<div>
							<div>
								<label>Apellido Materno</label>
								<input
									type="text"
									placeholder="Apellido Materno"
									value={motherName}
									onChange={handleMotherNameChange}
									autoComplete="family-name"
									className="modal-field"
									tabIndex={5}
								/>
							</div>
							<div {...getFieldProps("phone")}>
								<label>Teléfono *</label>
								<input
									type="text"
									placeholder="Teléfono"
									value={phone}
									onChange={handlePhoneChange}
									autoComplete="tel"
									className="modal-field"
									tabIndex={6}
								/>
							</div>
						</div>
						<div>
							<div {...getFieldProps("birthdate")}>
								<label>Fecha de Nacimiento *</label>
								<DatePicker
									selected={birthdate}
									onChange={handleDateChange}
									minDate={sub(new Date(), { years: 100 })}
									maxDate={sub(new Date(), { years: 18 })}
									showYearDropdown
									showMonthDropdown
									dropdownMode="select"
									calendarClassName="conti-calendar"
									shouldCloseOnSelect={true}
									strictParsing={true}
									placeholderText="Fecha de Nacimiento"
									tabIndex={7}
									className="modal-field"
								/>
							</div>
							<div {...getFieldProps("gender")}>
								<label>Género</label>
								<div className="gender">
									<ul>
										<li>
											<input
												type="radio"
												value="M"
												name="gender-type"
												id="gender-type-male"
												checked={gender === "M"}
												onChange={handleGenderChange}
												tabIndex={8}
											/>
											<label htmlFor="gender-type-male" tabIndex={8}>
												<span className="icon-male"></span>
											</label>
										</li>
										<li>
											<input
												type="radio"
												value="F"
												name="gender-type"
												id="gender-type-female"
												checked={gender === "F"}
												onChange={handleGenderChange}
												tabIndex={9}
											/>
											<label htmlFor="gender-type-female" tabIndex={9}>
												<span className="icon-female"></span>
											</label>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div>
							<div className="full">
								<div className="switch">
									<input
										type="checkbox"
										name="subscribe"
										id="subscribe"
										checked={subscribe}
										onChange={handleSubscriptionChange}
										tabIndex={10}
									/>
									<label htmlFor="subscribe" tabIndex={10}>
										<span className="icon-switch"></span>
										<div>
											Deseo recibir noticias y promociones de Continental Tire
											de México.
										</div>
									</label>
								</div>
							</div>
							<div className="full">{renderErrorList()}</div>
							<div className="full center">
								<div onClick={handleCloseClick} className="modal-button">
									Cancelar
								</div>
								<button type="submit" tabIndex={12} className="button main">
									Guardar
								</button>
							</div>
						</div>
					</div>
				</form>
			);
		}
		return null;
	}
	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal"
				overlayClassName="modal-overlay"
				onRequestClose={handleCloseClick}
				contentLabel="MisDatosEditar"
			>
				<div className="modal-inner" id="login-inner">
					<div className="modal-close" onClick={handleCloseClick}></div>
					<h2>Editar Mis Datos</h2>
					{renderLoader()}
					{renderForm()}
				</div>
			</Modal>
		</div>
	);
}
