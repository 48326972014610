import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import ReactGA from "react-ga4";
import qs from "qs";
import { BASE_API_URL } from "features/constants";
import history from "features/history";
import { fetchAccount } from "./accountSlice";
import { toggleShowAlert, setAlert } from "features/alerts/alertsSlice";
import { clearHighlightsData } from "features/tireResults/highlightsTiresSlice";
import { clearVehicleBrandData } from "features/tireFinder/vehicleBrandsSlice";
import { clearVehicleModelData } from "features/tireFinder/vehicleModelsSlice";
import { clearVehicleYearData } from "features/tireFinder/vehicleYearsSlice";
import { clearVehicleVersionData } from "features/tireFinder/vehicleVersionsSlice";
import { clearVehicleTiresData } from "features/tireResults/vehicleTiresSlice";
import { clearWidthDiameterData } from "features/tireFinder/sizeWidthDiametersSlice";
import { clearSizeRatiosData } from "features/tireFinder/sizeRatiosSlice";
import { clearSizeRimData } from "features/tireFinder/sizeRimsSlice";
import { clearSizeTiresData } from "features/tireResults/sizeTiresSlice";
import { clearWidthSaeData } from "features/tireFinder/sizeSaeWidthsSlice";
import { clearSizeRimSaesData } from "features/tireFinder/sizeSaeRimsSlice";
import { clearSizeSaeTiresData } from "features/tireResults/sizeSaeTiresSlice";
import {
	clearCartData,
	fetchCartObjectIfNeeded,
} from "features/cart/cartSlice";
import {
	clearDealerShipAddressData,
	fetchDealerShipAddressListIfNeeded,
} from "features/cart/dealerShipAddressSlice";
import { clearSearchData } from "features/tireFinder/searchSlice";
import { clearSearchResultData } from "features/tireResults/searchTiresSlice";
import { clearOrdersData } from "features/orders/ordersSlice";
import { clearOrderConfirmationData } from "features/orders/orderConfirmationSlice";
import {
	fetchShipAddressListIfNeeded,
	clearShipAddressData,
} from "./shipAddressSlice";
import {
	fetchInvoiceAddressListIfNeeded,
	clearInvoiceAddressData,
} from "./invoiceAddressSlice";
import { fetchVehicleBrandsIfNeeded } from "features/tireFinder/vehicleBrandsSlice";
import { fetchWidthDiametersIfNeeded } from "features/tireFinder/sizeWidthDiametersSlice";
import {
	fetchHighlightsTiresListIfNeeded,
	fetchHighlightsTiresList,
} from "features/tireResults/highlightsTiresSlice";
import { fetchStates, fetchStatesIfNeeded } from "./statesSlice";
import { fetchSearchIfNeeded } from "features/tireFinder/searchSlice";
import { fetchAccountCards, clearAccountCardsData } from "./accountCardsSlice";
import { cartLoadOpenPay } from "features/cart/cartSlice";
import axios from "axios";
import { clearContactForm } from "features/contact/contactSlice";

const initialState = {
	isLoading: false,
	isOpen: false,
	errorMsg: null,
	fromCart: false,
	success: null,
};
const loginSlice = createSlice({
	name: "login",
	initialState,
	reducers: {
		requestLoginData(state) {
			state.isLoading = true;
		},
		receiveLoginData(state) {
			state.isLoading = false;
		},
		toggleShowLogin(state, action) {
			if (!action.payload.isOpen) {
				ReactGA.event({
					category: "Usuario",
					action: "Mostrar Inicio de Sesión"});
			}
			state.isOpen = !action.payload.isOpen;
		},
		setFromCart(state, action) {
			state.fromCart = action.payload.fromCart;
		},
		setLoginSuccess(state, action) {
			state.success = action.payload.success;
		},
		setLoginErrorMessage(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
	},
});

export const {
	requestLoginData,
	receiveLoginData,
	toggleShowLogin,
	setFromCart,
	setLoginSuccess,
	setLoginErrorMessage,
} = loginSlice.actions;

export const fetchLogin = (user, password) => async (dispatch) => {
	let headers = {
		"Content-Type": "application/x-www-form-urlencoded",
	};
	let options = {
		headers: headers,
	};
	const data = qs.stringify({
		username: user,
		password: password,
		grant_type: "password",
	});
	const url = BASE_API_URL + "/Token";
	dispatch(requestLoginData());
	return axios
		.post(url, data, options)
		.then((response) => {
			sessionService
				.saveSession({ loginData: response.data })
				.then(() => dispatch(fetchAccount()))
				.catch((error) => console.log("error", error));
			dispatch(receiveLoginData());
			dispatch(setLoginSuccess({ success: true }));
			dispatch(setLoginErrorMessage({ errorMsg: null }));
			dispatch(toggleShowLogin({ isOpen: true }));
			ReactGA.event({
				category: "Usuario",
				action: "Iniciar Sesión",
			});
		})
		.catch((error) => {
			if (
				error.response.data != null &&
				error.response.data.error_description != null
			) {
				dispatch(
					setLoginErrorMessage({
						errorMsg: { error: error.response.data.error_description },
					})
				);
			} else {
				dispatch(
					setLoginErrorMessage({
						errorMsg: { error: "El usuario o la contraseña son incorrectos." },
					})
				);
			}
			dispatch(receiveLoginData());
			history.push("/");
		});
};
export const runAuthenticatedActions = () => async (dispatch) => {
	await sessionService.loadSession().then(() => {
		dispatch(fetchCartObjectIfNeeded());
		dispatch(fetchDealerShipAddressListIfNeeded());
		dispatch(fetchShipAddressListIfNeeded());
		dispatch(fetchInvoiceAddressListIfNeeded());
		dispatch(fetchAccountCards());
		dispatch(cartLoadOpenPay());
		dispatch(clearContactForm());
	});
};
export const runUnauthorizedActions =
	(logout = false) =>
	async (dispatch) => {
		sessionService.deleteSession();
		sessionService.deleteUser();
		dispatch(clearCartData());
		dispatch(clearAccountCardsData());
		dispatch(clearDealerShipAddressData());
		dispatch(clearVehicleBrandData());
		dispatch(clearVehicleModelData());
		dispatch(clearVehicleYearData());
		dispatch(clearVehicleVersionData());
		dispatch(clearVehicleTiresData());
		dispatch(clearWidthDiameterData());
		dispatch(clearSizeRatiosData());
		dispatch(clearSizeRimData());
		dispatch(clearSizeTiresData());
		dispatch(clearWidthSaeData());
		dispatch(clearSizeRimSaesData());
		dispatch(clearSizeSaeTiresData());
		// dispatch(clearCodeTiresList());
		dispatch(clearHighlightsData());
		dispatch(clearSearchData());
		dispatch(clearSearchResultData());
		dispatch(clearOrdersData());
		dispatch(clearOrderConfirmationData());
		dispatch(clearShipAddressData());
		dispatch(clearInvoiceAddressData());
		dispatch(clearContactForm());
		if (logout === false) {
			dispatch(
				setAlert({
					alert: {
						title: "Aviso",
						description:
							"Tu sesión ha caducado, para continuar con tu compra debes volver a iniciar sesión.",
						details: null,
					},
				})
			);
		}
		dispatch(toggleShowAlert({ isOpen: false }));
	};
export const doLogout = () => async (dispatch) => {
	ReactGA.event({
		category: "Usuario",
		action: "Cerrar Sesión",
	});
	dispatch(runUnauthorizedActions(true));
	dispatch(
		setAlert({
			alert: {
				title: "Aviso",
				description: "La sesión se cerró con éxito.",
				details: null,
			},
		})
	);
	dispatch(toggleShowAlert({ isOpen: false }));
};

export const runLoadActions = () => async (dispatch) => {
	dispatch(fetchVehicleBrandsIfNeeded());
	dispatch(fetchWidthDiametersIfNeeded());
	dispatch(fetchHighlightsTiresListIfNeeded());
	dispatch(fetchStatesIfNeeded());
	dispatch(fetchSearchIfNeeded());
	dispatch(fetchVehicleBrandsIfNeeded());
};
export default loginSlice.reducer;
