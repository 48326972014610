import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SHIP_TYPES as s } from "features/constants";
import { currencyFormat, getModalButtonClass } from "features/functions";
import { setCartActiveTab, evaluateCartNextStep } from "features/cart/cartSlice";
import CartShipTo from "./CartShipTo";
import CartShipAddress from "./CartShipAddress";
import CartShipDealer from "./CartShipDealer";

export default function CartShip() {
	const dispatch = useDispatch();
	// Selectors
	const { cartObject, shipType, showTab, shipAddress } = useSelector((state) => state.cart);
	// Handlers
	function handlePrevClick() {
		dispatch(setCartActiveTab({ showTab: showTab - 1 }));
	}
	function handleNextClick() {
		if (shipType !== 0) {
			dispatch(evaluateCartNextStep( showTab + 1 ));
		}
	}
	// Render
	const totalAmount =
		shipType === s.DHL_EMPLEADOS
			? cartObject.Total * 1.16 + cartObject.Costo_Envio
			: cartObject.Total * 1.16;
	return (
		<div id="cart-content-2">
			<CartShipTo />
			<CartShipDealer />
			<CartShipAddress />
			<div id="cart-summary-2">
				{/*<div id="cart-back">
					<FactoryButton shipType={this.props.shipType} isDisabled={true} />
				</div>*/}
				<div id="cart-totals">
					<div>
						<div>Subtotal</div>
						<div>{currencyFormat(cartObject.Total * 1.16)}</div>
					</div>
					<div>
						<div>Envío</div>
						<div>
							{currencyFormat(
								shipType === s.DHL_EMPLEADOS
									? cartObject.Costo_Envio
									: 0
							)}
						</div>
					</div>
					<div>
						<div className="totals">Total</div>
						<div className="totals">{currencyFormat(totalAmount)}</div>
					</div>
					<div>
						<div>Desglose IVA</div>
						<div>{currencyFormat(cartObject.Total * 0.16)} </div>
					</div>
					<div>
						<div>
							<div className="modal-button" onClick={handlePrevClick}>
								Anterior
							</div>
						</div>
						<div>
							<div
								className={getModalButtonClass(shipType === 0 || shipAddress == null)}
								onClick={handleNextClick}
							>
								Continuar
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
