import { createMiddleware } from "redux-beacon";
import GoogleTagManager from "@redux-beacon/google-tag-manager";
import logger from "@redux-beacon/logger";
import { PAYMENT_METHODS as p, SHIP_TYPES as s } from "./constants";

const GTM_PURCHASE_BUTTON = "GTM_PURCHASE_BUTTON";
const GTM_CART_LOGIN_BUTTON = "GTM_CART_LOGIN_BUTTON";
const GTM_CART_SHIP_BUTTON = "GTM_CART_SHIP_BUTTON";
const GTM_CART_BILL_BUTTON = "GTM_CART_BILL_BUTTON";
const GTM_CART_PAYMENT_BUTTON = "GTM_CART_PAYMENT_BUTTON";
const GTM_CART_CHECKOUT_BUTTON = "GTM_CART_CHECKOUT_BUTTON";
const GTM_ORDER_CONFIRMATION = "GTM_ORDER_CONFIRMATION";

const gtm = GoogleTagManager();

const eventsMap = {
	[GTM_PURCHASE_BUTTON]: (action) => {
		return {
			event: "comprar",
			code: action.data.Code,
			product: action.data.Name,
			brand: action.data.Brand,
			size: action.data.Size,
			qty: action.data.qty,
		};
	},
	[GTM_CART_LOGIN_BUTTON]: (action) => {
		return {
			event: "continuarcarrito",
		};
	},
	[GTM_CART_SHIP_BUTTON]: (action) => {
		return {
			event: "continuarenvio",
		};
	},
	[GTM_CART_BILL_BUTTON]: (action) => {
		return {
			event: "continuarfacturacion",
		};
	},
	[GTM_CART_PAYMENT_BUTTON]: (action) => {
		return {
			event: "formadepago",
		};
	},
	[GTM_CART_CHECKOUT_BUTTON]: (action) => {
		return {
			event: "finalizar",
		};
	},
	[GTM_ORDER_CONFIRMATION]: (action) => {
		return {
			event: "compra",
			transactionId: action.data.Numero,
			transactionTotal: action.data.Total,
			transactionTax: action.data.IVA,
			transactionShip: action.data.Envio,
			transactionDate: action.data.Fecha,
			transactionProducts: action.data.Elementos.map((i) => {
				return {
					id: i.Codigo,
					name: i.Nombre,
					brand: i.Marca,
					category: "Llantas/" + i.Tipo,
					variant: i.Medida,
					price: i.Precio,
					quantity: i.Cantidad,
				};
			}),
			paymentType: p.properties[action.data.MetodoPago].name,
			shipType: s.properties[action.data.TipoEnvio].fullname,
		};
	},
};
export const gtmMiddleware = createMiddleware(eventsMap, gtm, { logger });

export const gtmTriggerAddItemToCart = (data) => {
	return {
		type: GTM_PURCHASE_BUTTON,
		data,
	};
};
export const gtmTriggerCartLoginButton = (data) => {
	return {
		type: GTM_CART_LOGIN_BUTTON,
		data,
	};
};
export const gtmTriggerCartShipButton = (data) => {
	return {
		type: GTM_CART_SHIP_BUTTON,
		data,
	};
};
export const gtmTriggerCartBillButton = (data) => {
	return {
		type: GTM_CART_BILL_BUTTON,
		data,
	};
};
export const gtmTriggerCartPaymentButton = (data) => {
	return {
		type: GTM_CART_PAYMENT_BUTTON,
		data,
	};
};
export const gtmTriggerCartCheckoutButton = (data) => {
	return {
		type: GTM_CART_CHECKOUT_BUTTON,
		data,
	};
};
export const gtmTriggerOrderConfirmation = (data) => {
	return {
		type: GTM_ORDER_CONFIRMATION,
		data,
	};
};
