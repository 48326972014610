import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import ReactGA from "react-ga4";
import axios from "axios";
import {
	BASE_API_URL,
	SHIP_TYPES,
	PAYMENT_METHODS,
	COUPON_STATUS,
	CART_STEPS as s,
	CART_STEPS,
} from "../constants";
import { isEmpty } from "features/functions";
import {
	runUnauthorizedActions,
	toggleShowLogin,
	setFromCart,
} from "features/account/loginSlice";
import {
	gtmTriggerCartLoginButton,
	gtmTriggerCartShipButton,
	gtmTriggerCartBillButton,
	gtmTriggerCartPaymentButton,
} from "features/tagmanager";
import { fetchShipAddressListIfNeeded } from "features/account/shipAddressSlice";
import { fetchInvoiceAddressListIfNeeded } from "features/account/invoiceAddressSlice";
import { fetchPaymentCardListIfNeeded } from "./paymentCardSlice";
import { setAlert, toggleShowAlert } from "features/alerts/alertsSlice";
import {
	setOrderConfirmationId,
	fetchOrderConfirmation,
	toggleShowOrderConfirmation,
	receiveOrderConfirmation,
} from "features/orders/orderConfirmationSlice";
import { fetchHighlightsTiresList } from "features/tireResults/highlightsTiresSlice";
import { fetchVehicleTiresList } from "features/tireResults/vehicleTiresSlice";
import { fetchSizeTiresList } from "features/tireResults/sizeTiresSlice";
import { fetchSizeSaeTiresList } from "features/tireResults/sizeSaeTiresSlice";
import { fetchSearchResultsTiresList } from "features/tireResults/searchTiresSlice";
import { fetchDealerShipAddressListIfNeeded } from "./dealerShipAddressSlice";
import { fetchAccountCards } from "features/account/accountCardsSlice";
import { response } from "express";

const initialState = {
	isLoading: false,
	isProcessing: false,
	isOpen: false,
	showTab: 1,
	cartObject: null,
	shipType: 1,
	shipAddress: null,
	shipAddressItem: null,
	shipLocationId: null,
	invoiceAddress: null,
	invoiceAddressItem: null,
	paymentType: 2,
	paymentCard: 0,
	paymentCardItem: null,
	creditCardResponse: null,
	payPalResponse: null,
	safetyPayResponse: null,
	depositResponse: null,
	paynetResponse: null,
	coupon: null,
	openPayLoaded: false,
	isOpenPoints: false,
	pointsData: null,
};
const cart = createSlice({
	name: "cart",
	initialState,
	reducers: {
		requestCartObject(state) {
			state.isLoading = true;
		},
		receiveCartObject(state, action) {
			state.isLoading = false;
			state.cartObject = action.payload.cartObject;
		},
		removeCartLoading(state) {
			state.isLoading = false;
		},
		setCartActiveTab(state, action) {
			switch (action.payload.showTab) {
				case 1:
				default:
					ReactGA.event({
						category: "Carrito",
						action: "Detalles",
					});
					break;
				case 2:
					ReactGA.event({
						category: "Carrito",
						action: "Envío",
					});
					break;
				case 3:
					ReactGA.event({
						category: "Carrito",
						action: "Facturación",
					});
					break;
				case 4:
					ReactGA.event({
						category: "Carrito",
						action: "Pago",
					});
					break;
				case 5:
					ReactGA.event({
						category: "Carrito",
						action: "Resumen",
					});
					break;
			}
			state.showTab = action.payload.showTab;
		},
		setCartShipType(state, action) {
			const shipType = action.payload.shipType;
			if (shipType > 0) {
				ReactGA.event({
					category: "Compra",
					action: "Seleccionar Tipo de Envío",
					label: SHIP_TYPES.properties[shipType].name,
				});
			}
			state.shipType = shipType;
		},
		setCartShipAddress(state, action) {
			state.shipAddress = action.payload.shipAddress;
			state.shipAddressItem = action.payload.shipAddressItem;
		},
		clearCartShipAddress(state) {
			state.shipAddress = null;
			state.shipAddressItem = null;
		},
		setCartInvoiceAddress(state, action) {
			state.invoiceAddress = action.payload.invoiceAddress;
			state.invoiceAddressItem = action.payload.invoiceAddressItem;
		},
		clearCartInvoiceAddress(state) {
			state.invoiceAddress = null;
			state.invoiceAddressItem = null;
		},
		setCartShipLocationId(state, action) {
			state.shipLocationId = action.payload.shipLocationId;
		},
		clearCartShipLocationId(state) {
			state.shipLocationId = null;
		},
		setCartPaymentType(state, action) {
			const paymentType = action.payload.paymentType;
			ReactGA.event({
				category: "Compra",
				action: "Seleccionar Método de Pago",
				label: PAYMENT_METHODS.properties[paymentType].name,
			});
			state.paymentType = paymentType;
		},
		toggleShowCart(state, action) {
			state.isOpen = !action.payload.isOpen;
		},
		setPaymentProcess(state, action) {
			state.isProcessing = action.payload.isProcessing;
		},
		receivePaymentCard(state, action) {
			state.isProcessing = false;
			state.creditCardResponse = action.payload.creditCardResponse;
		},
		receivePaymentPayPal(state, action) {
			state.isProcessing = false;
			state.payPalResponse = action.payload.payPalResponse;
		},
		receivePaymentSafetyPay(state, action) {
			state.isProcessing = false;
			state.safetyPayResponse = action.payload.safetyPayResponse;
		},
		receivePaymentDeposit(state, action) {
			state.isProcessing = false;
			state.depositResponse = action.payload.depositResponse;
		},
		receivePaymentPaynet(state, action) {
			state.isProcessing = false;
			state.paynetResponse = action.payload.paynetResponse;
		},
		requestValidateCoupon(state) {
			ReactGA.event({
				category: "Compra",
				action: "Carga Cupon",
				label: "Carga un cupón",
			});
			state.isLoading = true;
		},
		receiveValidateCoupon(state, action) {
			state.isLoading = false;
			state.coupon = action.payload.coupon;
		},
		setCartOpenPayLoaded: (state, action) => {
			state.openPayLoaded = action.payload.openPayLoaded;
		},
		setCartPaymentCard: (state, action) => {
			state.paymentCard = action.payload.paymentCard;
			state.paymentCardItem = action.payload.paymentCardItem;
		},
		toggleShowPoints(state, action) {
			state.isOpenPoints = !action.payload.isOpenPoints;
			state.pointsData =
				action.payload.pointsData != null ? action.payload.pointsData : null;
		},
		clearCartData: () => initialState,
	},
});

export const {
	requestCartObject,
	receiveCartObject,
	removeCartLoading,
	setCartActiveTab,
	setCartShipType,
	setCartShipAddress,
	clearCartShipAddress,
	setCartInvoiceAddress,
	clearCartInvoiceAddress,
	setCartUserData,
	setCartShipLocationId,
	clearCartShipLocationId,
	setCartPaymentType,
	toggleShowCart,
	setPaymentProcess,
	receivePaymentCard,
	receivePaymentPayPal,
	receivePaymentSafetyPay,
	receivePaymentDeposit,
	receivePaymentPaynet,
	requestValidateCoupon,
	receiveValidateCoupon,
	setCartOpenPayLoaded,
	setCartPaymentCard,
	toggleShowPoints,
	clearCartData,
} = cart.actions;

const getCartAlias = (cart) => {
	let alias = "ContiShop";
	switch (cart.shipType) {
		case SHIP_TYPES.ADDRESS:
			alias = cart.shipAddressItem.Alias;
			break;
		case SHIP_TYPES.FACTORY:
			alias = "Factory";
			break;
		case SHIP_TYPES.DEALER:
			alias = cart.shipAddressItem.Name;
			break;
		default:
			alias = "ContiShop";
			break;
	}
	return alias;
};

const shouldFetchCartObject = (state) => {
	const { cart } = state;
	if (cart.cartObject == null) {
		return true;
	}
	if (cart.cartObject.strIdBolsa === "") {
		return true;
	}
	return false;
};

export const fetchCartObjectIfNeeded = () => async (dispatch, getState) => {
	const theState = getState();
	if (shouldFetchCartObject(theState)) {
		return dispatch(fetchCartObject());
	}
};

export const fetchCartObject = () => async (dispatch, getState) => {
	let headers;
	await sessionService
		.loadSession()
		.then((session) => {
			headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
		})
		.catch(() => {
			headers = {
				"Content-Type": "application/json",
			};
		});
	const options = {
		headers: headers,
	};
	const theState = getState();
	let qs = [];
	if (theState.cart.coupon != null)
		qs.push("cupon=" + theState.cart.coupon.Code);
	if (
		theState.cart.cartObject != null &&
		theState.cart.cartObject.strIdBolsa !== ""
	) {
		qs.push("id=" + theState.cart.cartObject.strIdBolsa);
	}
	dispatch(requestCartObject());
	let url = BASE_API_URL + "/api/Carritos/MiCarrito";
	if (qs.length > 0) {
		url += "/?" + qs.join("&");
	}
	return axios
		.get(url, options)
		.then((response) => {
			dispatch(receiveCartObject({ cartObject: response.data }));
		})
		.catch((error) => {
			// If 401 Unauthorized login failed
			if (error.response.status === 401) {
				dispatch(runUnauthorizedActions());
			}
		});
};

export const fetchAddItemToCartObject =
	(tireId, qty) => async (dispatch, getState) => {
		let headers;
		await sessionService
			.loadSession()
			.then((session) => {
				headers = {
					Authorization: "bearer " + session.loginData.access_token,
					"Content-Type": "application/json",
				};
			})
			.catch(() => {
				headers = {
					"Content-Type": "application/json",
				};
			});
		const theState = getState();
		const cartId =
			theState.cart.cartObject != null &&
			theState.cart.cartObject.strIdBolsa !== ""
				? theState.cart.cartObject.strIdBolsa
				: null;
		const coupon =
			theState.cart.coupon == null ? null : theState.cart.coupon.Code;
		const options = {
			headers: headers,
		};
		const body = {
			Id: cartId,
			TireId: tireId,
			Qty: qty,
			Coupon: coupon,
		};
		dispatch(requestCartObject());
		const url = BASE_API_URL + "/api/Carritos/Agregar";
		return axios
			.post(url, body, options)
			.then((response) => {
				dispatch(receiveCartObject({ cartObject: response.data }));
			})
			.catch((error) => {
				// If 401 Unauthorized login failed
				if (error.response.status === 401) {
					dispatch(runUnauthorizedActions());
				}
				if (error.response.status === 400) {
					dispatch(removeCartLoading());
					if (error.response.data.Message != null) {
						dispatch(
							setAlert({
								alert: {
									title: "¡Lo sentimos!",
									description: "No se pudo agregar el producto al carrito.",
									details: error.response.data.Message,
								},
							})
						);
						dispatch(toggleShowAlert({ isOpen: false }));
					}
				}
			});
	};

export const fetchDeleteItemFromCartObject =
	(tireId) => async (dispatch, getState) => {
		let headers = {
			"Content-Type": "application/json",
		};
		sessionService
			.loadSession()
			.then((session) => {
				headers = {
					Authorization: "bearer " + session.loginData.access_token,
					"Content-Type": "application/json",
				};
			})
			.catch((err) => console.log("Error", err));
		const theState = getState();
		const cartId =
			theState.cart.cartObject != null &&
			theState.cart.cartObject.strIdBolsa !== ""
				? theState.cart.cartObject.strIdBolsa
				: null;
		const coupon =
			theState.cart.coupon == null ? null : theState.cart.coupon.Code;
		const options = {
			headers: headers,
		};
		const body = {
			Id: cartId,
			TireId: tireId,
			Coupon: coupon,
		};
		dispatch(requestCartObject());
		const url = BASE_API_URL + "/api/Carritos/Eliminar";
		return axios
			.post(url, body, options)
			.then((response) => {
				dispatch(receiveCartObject({ cartObject: response.data }));
			})
			.catch((error) => {
				// If 401 Unauthorized login failed
				if (error.response.status === 401) {
					dispatch(runUnauthorizedActions());
				}
			});
	};

export const fetchPaymentCard = (data) => async (dispatch, getState) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const theState = getState();
			const alias = getCartAlias(theState.cart);
			const coupon =
				theState.cart.coupon == null ? null : theState.cart.coupon.Code;
			const token =
				theState.cart.paymentCard === 0
					? {
							tokenId: theState.accountCardAdd.token.id,
							deviceSessionId: theState.accountCardAdd.sessId,
							card: theState.accountCardAdd.token.card,
							save: data.save,
							usePoints: data.usePoints,
					  }
					: null;
			const card =
				theState.cart.paymentCard > 0
					? {
							cardId: theState.cart.paymentCard,
							cvv: data.code,
							deviceSessionId: theState.accountCardAdd.sessId,
							usePoints: data.usePoints,
					  }
					: null;
			const body = {
				id: theState.cart.cartObject.strIdBolsa,
				delivery: theState.cart.shipType,
				alias: alias,
				invoice: theState.cart.invoiceAddressItem.Alias,
				payment: theState.cart.paymentType,
				promo: data.cardPromo,
				coupon: coupon,
				authorized: "",
				LocationId:
					theState.cart.shipLocationId != null
						? theState.cart.shipLocationId
						: 0,
				card: card,
				token: token,
			};
			const options = {
				headers: headers,
			};
			ReactGA.event({
				category: "Compra",
				action: "Finalizar compra",
				label: "Compra con Tarjeta de Crédito",
			});
			dispatch(setPaymentProcess({ isProcessing: true }));
			const url = BASE_API_URL + "/api/Carritos/Finalizar/OpenPayTarjeta";
			return axios
				.post(url, JSON.stringify(body), options)
				.then((response) => {
					if (response.data.Url != null) {
						window.location.replace(response.data.Url);
					}
					if (response.data.Id != null) {
						dispatch(setOrderConfirmationId({ orderId: response.data.Id }));
						dispatch(receiveOrderConfirmation({ order: response.data }));
						dispatch(setPaymentProcess({ isProcessing: false }));
						dispatch(toggleShowCart({ isOpen: true }));
						dispatch(toggleShowOrderConfirmation({ isOpen: false }));
						dispatch(fetchCartObject());
						dispatch(fetchAccountCards());
					}
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					} else {
						dispatch(setPaymentProcess({ isProcessing: false }));
						// 404 Not Found
						if (error.response.status === 404) {
							dispatch(fetchAccountCards());
							dispatch(setCartPaymentCard({paymentCard: 0}))
							dispatch(setCartActiveTab({showTab: CART_STEPS.PAYMENT}))
							dispatch(
								setAlert({
									alert: {
										title: "¡Lo sentimos!",
										description:
											"No se ha podido crear el pedido por la siguiente causa:",
										details: "No se encontró la tarjeta guardada.",
									},
								})
							);
							dispatch(toggleShowAlert({ isOpen: false }));
						}
						// 400 BadRequest
						if (error.response.status === 400) {
							if (error.response.data.Message != null) {
								dispatch(
									setAlert({
										alert: {
											title: "¡Lo sentimos!",
											description:
												"No se ha podido crear el pedido por la siguiente causa:",
											details: error.response.data.Message,
										},
									})
								);
								dispatch(toggleShowAlert({ isOpen: false }));
							}
						}
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export const fetchPaymentPayPal = () => async (dispatch, getState) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const theState = getState();
			const alias = getCartAlias(theState.cart);
			const coupon =
				theState.cart.coupon == null ? null : theState.cart.coupon.Code;
			const options = {
				method: "POST",
				headers: headers,
				body: JSON.stringify({
					id: theState.cart.cartObject.strIdBolsa,
					delivery: theState.cart.shipType,
					alias: alias,
					invoice: theState.cart.invoiceAddressItem.Alias,
					payment: theState.cart.paymentType,
					promo: 0,
					coupon: coupon,
					authorized: "",
					LocationId:
						theState.cart.shipLocationId != null
							? theState.cart.shipLocationId
							: 0,
				}),
			};
			ReactGA.event({
				category: "Compra",
				action: "Finalizar compra",
				label: "Compra con PayPal",
			});
			dispatch(setPaymentProcess({ isProcessing: true }));
			const url = BASE_API_URL + "/api/Carritos/Finalizar/PayPal";
			let responseStatus = 0;
			return fetch(url, options)
				.then((response) => {
					responseStatus = response.status;
					if (response.ok) {
						return response.json();
					} else {
						return response.json().then((error) => Promise.reject(error));
					}
				})
				.then((json) => {
					dispatch(receivePaymentPayPal({ payPalResponse: json }));
					dispatch(fetchCartObject());
				})
				.catch((error) => {
					dispatch(setPaymentProcess({ isProcessing: false }));
					// TODO: fetch error actions
					// If 401 Unauthorized login failed
					if (responseStatus === 401) {
						dispatch(runUnauthorizedActions());
					} else {
						dispatch(
							setAlert({
								alert: {
									title: "¡Lo sentimos!",
									description:
										"No se ha podido crear el pedido por la siguiente causa:",
									details: error.Message,
								},
							})
						);
						dispatch(toggleShowAlert({ isOpen: false }));
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export const fetchPaymentSafetyPay = () => async (dispatch, getState) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const theState = getState();
			const alias = getCartAlias(theState.cart);
			const coupon =
				theState.cart.coupon == null ? null : theState.cart.coupon.Code;
			const options = {
				method: "POST",
				headers: headers,
				body: JSON.stringify({
					id: theState.cart.cartObject.strIdBolsa,
					delivery: theState.cart.shipType,
					alias: alias,
					invoice: theState.cart.invoiceAddressItem.Alias,
					payment: theState.cart.paymentType,
					promo: 0,
					coupon: coupon,
					authorized: "",
					LocationId:
						theState.cart.shipLocationId != null
							? theState.cart.shipLocationId
							: 0,
				}),
			};
			ReactGA.event({
				category: "Compra",
				action: "Finalizar compra",
				label: "Compra con SafetyPay",
			});
			dispatch(setPaymentProcess({ isProcessing: true }));
			const url = BASE_API_URL + "/api/Carritos/Finalizar/SafetyPay";
			let responseStatus = 0;
			return fetch(url, options)
				.then((response) => {
					responseStatus = response.status;
					if (response.ok) {
						return response.json();
					} else {
						return response.json().then((error) => Promise.reject(error));
					}
				})
				.then((json) => {
					dispatch(receivePaymentSafetyPay({ safetyPayResponse: json }));
					dispatch(fetchCartObject());
				})
				.catch((error) => {
					dispatch(setPaymentProcess({ isProcessing: false }));
					// TODO: fetch error actions
					// If 401 Unauthorized login failed
					if (responseStatus === 401) {
						dispatch(runUnauthorizedActions());
					} else {
						dispatch(
							setAlert({
								alert: {
									title: "¡Lo sentimos!",
									description:
										"No se ha podido crear el pedido por la siguiente causa:",
									details: error.Message,
								},
							})
						);
						dispatch(toggleShowAlert({ isOpen: false }));
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export const fetchPaymentDeposit = () => async (dispatch, getState) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const theState = getState();
			const alias = getCartAlias(theState.cart);
			const coupon =
				theState.cart.coupon == null ? null : theState.cart.coupon.Code;
			const options = {
				method: "POST",
				headers: headers,
				body: JSON.stringify({
					id: theState.cart.cartObject.strIdBolsa,
					delivery: theState.cart.shipType,
					alias: alias,
					invoice: theState.cart.invoiceAddressItem.Alias,
					payment: theState.cart.paymentType,
					promo: 0,
					coupon: coupon,
					authorized: "",
					LocationId:
						theState.cart.shipLocationId != null
							? theState.cart.shipLocationId
							: 0,
				}),
			};
			ReactGA.event({
				category: "Compra",
				action: "Finalizar compra",
				label: "Compra con SafetyPay",
			});
			dispatch(setPaymentProcess({ isProcessing: true }));
			const url = BASE_API_URL + "/api/Carritos/Finalizar/Deposito";
			let responseStatus = 0;
			return fetch(url, options)
				.then((response) => {
					responseStatus = response.status;
					if (response.ok) {
						return response.json();
					} else {
						return response.json().then((error) => Promise.reject(error));
					}
				})
				.then((json) => {
					dispatch(receivePaymentDeposit({ depositResponse: json }));
					dispatch(setOrderConfirmationId({ orderId: json.Order }));
					dispatch(fetchOrderConfirmation());
					dispatch(toggleShowCart({ isOpen: theState.cart.isOpen }));
					dispatch(clearCartData());
					dispatch(fetchCartObject());
					dispatch(toggleShowOrderConfirmation({ isOpen: false }));
				})
				.catch((error) => {
					dispatch(setPaymentProcess({ isProcessing: false }));
					// TODO: fetch error actions
					// If 401 Unauthorized login failed
					if (responseStatus === 401) {
						dispatch(runUnauthorizedActions());
					} else {
						dispatch(
							setAlert({
								alert: {
									title: "¡Lo sentimos!",
									description:
										"No se ha podido crear el pedido por la siguiente causa:",
									details: error.Message,
								},
							})
						);
						dispatch(toggleShowAlert({ isOpen: false }));
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export const fetchPaymentPaynet = () => async (dispatch, getState) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const theState = getState();
			const alias = getCartAlias(theState.cart);
			const coupon =
				theState.cart.coupon == null ? null : theState.cart.coupon.Code;
			const options = {
				headers: headers,
			};
			const body = {
					id: theState.cart.cartObject.strIdBolsa,
					delivery: theState.cart.shipType,
					alias: alias,
					invoice: theState.cart.invoiceAddressItem.Alias,
					payment: theState.cart.paymentType,
					promo: 0,
					coupon: coupon,
					authorized: "",
					LocationId:
						theState.cart.shipLocationId != null
							? theState.cart.shipLocationId
							: 0,
				};

			ReactGA.event({
				category: "Compra",
				action: "Finalizar compra",
				label: "Compra con Paynet",
			});
			const url = BASE_API_URL + "/api/Carritos/Finalizar/Paynet";
			dispatch(setPaymentProcess({ isProcessing: true }));
			return axios
				.post(url, body, options)
				.then((response) => {
					dispatch(receivePaymentPaynet({ paynetResponse: response.data }));
					dispatch(setOrderConfirmationId({ orderId: response.data.Order }));
					dispatch(fetchOrderConfirmation());
					dispatch(toggleShowCart({ isOpen: true }));
					dispatch(clearCartData());
					dispatch(fetchCartObject());
					dispatch(toggleShowOrderConfirmation({ isOpen: false }));
				})
				.catch((error) => {
					dispatch(setPaymentProcess({ isProcessing: false }));
					// 401 Unauthorized
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					} else {
						dispatch(
							setAlert({
								alert: {
									title: "¡Lo sentimos!",
									description:
										"No se ha podido crear el pedido por la siguiente causa:",
									details: error.response.data.Message,
								},
							})
						);
						dispatch(toggleShowAlert({ isOpen: false }));
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export const removeCoupon = () => async (dispatch, getState) => {
	const theState = getState();
	dispatch(receiveValidateCoupon({ coupon: null }));
	dispatch(fetchHighlightsTiresList());
	dispatch(fetchCartObject());
	// Reload vehicle tires results
	if (theState.vehicleTires.list != null) {
		dispatch(
			fetchVehicleTiresList(
				theState.vehicleTires.list.BrandId,
				theState.vehicleTires.list.ModelId,
				theState.vehicleTires.list.Year,
				theState.vehicleTires.list.VersionId
			)
		);
	}
	// Reload size search results
	if (theState.sizeTires.list != null) {
		dispatch(
			fetchSizeTiresList(
				theState.sizeTires.width,
				theState.sizeTires.ratio,
				theState.sizeTires.rim,
				theState.sizeTires.dept,
				0 // brand
			)
		);
	}
	// Reload SAE size results
	if (theState.sizeSaeTires.list != null) {
		dispatch(
			fetchSizeSaeTiresList(
				theState.sizeSaeTires.diameter,
				theState.sizeSaeTires.width,
				theState.sizeSaeTires.rim,
				theState.sizeSaeTires.dept,
				0 // brand
			)
		);
	}
	// Reload tire search results
	if (!isEmpty(theState.search.selectedItem)) {
		dispatch(fetchSearchResultsTiresList(theState.search.selectedItem.Id));
	}
};
export const fetchValidateCoupon =
	(couponCode) => async (dispatch, getState) => {
		let headers = {
			"Content-Type": "application/json",
		};
		sessionService
			.loadSession()
			.then((session) => {
				headers = {
					Authorization: "bearer " + session.loginData.access_token,
					"Content-Type": "application/json",
				};
			})
			.catch((err) => console.log("Error", err));
		const theState = getState();
		const options = {
			headers: headers}
			;
		const body ={
				cupon: couponCode,
		};
		const url = BASE_API_URL + "/api/Cupones/Valida";
		dispatch(requestValidateCoupon());
		return axios
			.post(url, body, options)
			.then((response) => {
				if (response.data.Code == null) {
					dispatch(receiveValidateCoupon({ coupon: null }));
					dispatch(
						setAlert({
							alert: {
								title: "¡Lo sentimos!",
								description: "No se ha podido cargar el cupón",
								details:
									COUPON_STATUS.properties[response.data.Status].message,
							},
						})
					);
					dispatch(toggleShowAlert({ isOpen: false }));
					} else {
						dispatch(receiveValidateCoupon({ coupon: response.data }));
						dispatch(fetchHighlightsTiresList());
						dispatch(fetchCartObject());
						// Reload vehicle tires results
						if (theState.vehicleTires.list != null) {
							dispatch(
								fetchVehicleTiresList(
									theState.vehicleTires.list.BrandId,
									theState.vehicleTires.list.ModelId,
									theState.vehicleTires.list.Year,
									theState.vehicleTires.list.VersionId
								)
							);
						}
						// Reload size search results
						if (theState.sizeTires.list != null) {
							dispatch(
								fetchSizeTiresList(
									theState.sizeTires.width,
									theState.sizeTires.ratio,
									theState.sizeTires.rim,
									theState.sizeTires.dept,
									0 // brand
								)
							);
						}
						// Reload SAE size results
						if (theState.sizeSaeTires.list != null) {
							dispatch(
								fetchSizeSaeTiresList(
									theState.sizeSaeTires.diameter,
									theState.sizeSaeTires.width,
									theState.sizeSaeTires.rim,
									theState.sizeSaeTires.dept,
									0 // brand
								)
							);
						}
						// Reload tire search results
						if (!isEmpty(theState.search.selectedItem)) {
							dispatch(
								fetchSearchResultsTiresList(theState.search.selectedItem.Id)
							);
						}
					}
				})
			.catch((error) => {
				dispatch(receiveValidateCoupon({ coupon: null }));
				dispatch(
					setAlert({
						alert: {
							title: "¡Lo sentimos!",
							description: "No se ha podido cargar el cupón",
							details: "El cupón no es válido",
						},
					})
				);
				dispatch(toggleShowAlert({ isOpen: false }));
			});
	};

export const evaluateCartNextStep = (nextTab) => async (dispatch, getState) => {
	const theState = getState();
	const { cartObject, shipType, paymentType } = theState.cart;
	let cartItems = [];
	for (let i = 0; i < cartObject.Elementos.length; i++) {
		cartItems.push({
			id: cartObject.Elementos[i].Tire.Code,
			name: cartObject.Elementos[i].Tire.TireProduct.Name,
			brand: cartObject.Elementos[i].Tire.TireProduct.Brand,
			category: "Llantas/" + cartObject.Elementos[i].Tire.VehicleType,
			variant: cartObject.Elementos[i].Tire.Size,
			price: cartObject.Elementos[i].Tire.Price,
			quantity: cartObject.Elementos[i].Cantidad,
		});
	}
	sessionService
		.loadSession()
		.then(() => {
			dispatch(setCartActiveTab({ showTab: nextTab }));
			dispatch(fetchShipAddressListIfNeeded());
			dispatch(fetchInvoiceAddressListIfNeeded());
			dispatch(fetchPaymentCardListIfNeeded());
			dispatch(fetchDealerShipAddressListIfNeeded());
			switch (nextTab) {
				case s.SHIP:
					ReactGA.gtag("event", "begin_checkout", {
						currency: "MXN",
						value: cartObject.Total,
						coupon: cartObject.CuponDescuento.CodigoCupon,
						items: cartItems,
					});
					ReactGA.event({
						category: "Checkout",
						action: "Paso 1 - Carrito",
					});
					dispatch(gtmTriggerCartLoginButton());
					break;
				case s.INVOICE:
					ReactGA.gtag("event", "add_shipping_info", {
						currency: "MXN",
						value: cartObject.Total,
						coupon: cartObject.CuponDescuento.CodigoCupon,
						shipping_tier: SHIP_TYPES.properties[shipType].name,
						items: cartItems,
					});
					ReactGA.event({
						category: "Checkout",
						action: "Paso 2 - Envío",
					});
					dispatch(gtmTriggerCartShipButton());
					break;
				case s.PAYMENT:
					ReactGA.event({
						category: "Checkout",
						action: "Paso 3 - Facturación",
					});
					dispatch(gtmTriggerCartBillButton());
					break;
				case s.SUMMARY:
					ReactGA.gtag("event", "add_payment_info", {
						currency: "MXN",
						value: cartObject.Total,
						coupon: cartObject.CuponDescuento.CodigoCupon,
						items: cartItems,
						payment_type: PAYMENT_METHODS.properties[paymentType].name,
					});
					ReactGA.event({
						category: "Checkout",
						action: "Paso 4 - Método de Pago",
					});
					dispatch(gtmTriggerCartPaymentButton());
					break;
				default:
					break;
			}
		})
		.catch((err) => {
			dispatch(toggleShowLogin({ isOpen: false }));
			dispatch(setFromCart({ fromCart: true }));
			dispatch(gtmTriggerCartLoginButton());
			ReactGA.gtag("event", "begin_checkout", {
				currency: "MXN",
				value: cartObject.Total,
				coupon: cartObject.CuponDescuento.CodigoCupon,
				items: cartItems,
			});
			ReactGA.event({
				category: "Checkout",
				action: "Paso 1 - Carrito Not Logged",
			});
		});
};
export const cartLoadOpenPay = () => async (dispatch) => {
	dispatch(requestCartObject());
	const openpay = window.OpenPay;
	openpay.setId(process.env.RAZZLE_OPENPAY_ID);
	openpay.setApiKey(process.env.RAZZLE_OPENPAY_PUBLIC);
	openpay.setSandboxMode(
		process.env.RAZZLE_OPENPAY_SANDBOX === "true" ? true : false
	);
	dispatch(setCartOpenPayLoaded({ openPayLoaded: true }));
	dispatch(removeCartLoading());
};
export default cart.reducer;
