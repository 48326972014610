import { createSlice } from "@reduxjs/toolkit";
import { toggleShowPoints } from "features/cart/cartSlice";
import { setPaymentProcess } from "features/cart/cartSlice";
import { fetchPaymentCard } from "features/cart/cartSlice";
import { OPENPAY_TOKEN_ACTION as op } from "features/constants";

const initialState = {
	isLoading: false,
	isOpen: false,
	errorMsg: null,
	token: null,
	sessId: null,
};

const accountCardAdd = createSlice({
	name: "accountCardAdd",
	initialState,
	reducers: {
		requestCardAdd: (state) => {
			state.isLoading = true;
		},
		receiveCardAdd: (state, action) => {
			state.isLoading = false;
			state.token = action.payload.token;
		},
		setCardAddErrorMsg: (state, action) => {
			state.errorMsg = action.payload.errorMsg;
		},
		setCardAddId: (state, action) => {
			state.sessId = action.payload.sessId;
		},
		clearCardAddToken: (state) => {
			state.token = null;
		},
		clearCardAddData: () => initialState,
	},
});

export const {
	requestCardAdd,
	receiveCardAdd,
	setCardAddErrorMsg,
	setCardAddId,
	clearCardAddToken,
	clearCardAddData,
} = accountCardAdd.actions;

export const fetchCardToken = (data, tokenAction) => async (dispatch) => {
	if (tokenAction === op.SAVE) {
		dispatch(requestCardAdd());
	}
	if (tokenAction === op.PAY) {
		dispatch(setPaymentProcess({ isProcessing: true }));
	}
	const content = {
		card_number: data.card,
		holder_name: data.name,
		expiration_year: data.year,
		expiration_month: data.month,
		cvv2: data.code,
		device_session_id: data.sessId,
	};
	window.OpenPay.token.create(
		content,
		(response) => {
			dispatch(receiveCardAdd({ token: response.data }));
			if (tokenAction === op.PAY) {
				const { card } = response.data;
				if (card.points_card != null && card.points_card === true && data.cardPromo === 0) {
					dispatch(
						toggleShowPoints({
							isOpenPoints: false,
							pointsData: { save: data.save, usePoints: false, cardPromo: data.cardPromo },
						})
					);
				} else {
					dispatch(fetchPaymentCard({ save: data.save, usePoints: false, cardPromo: data.cardPromo }));
				}
			}
			if (tokenAction === op.SAVE) {
				dispatch(fetchAddCard(data));
			}
		},
		(error) => {
			dispatch(receiveCardAdd({ token: null }));
			dispatch(setCardAddErrorMsg({ errorMsg: { error } }));
		}
	);
};
export const fetchCardAdd = (data) => async (dispatch) => {};

export default accountCardAdd.reducer;
