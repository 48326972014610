import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Autosuggest from "react-autosuggest";
import { 
	updateCountyItem,
	updateCountyValue,
	loadCountiesSuggestions,
	clearCountiesSuggestions,
 } from "features/account/countiesSlice";

export default function CountyAutosuggest(props) {
	const dispatch = useDispatch();
	// Selectors
	const { value, suggestions } = useSelector((state) => state.counties);
	// Handlers
	function handleChange(event, { newValue }) {
		dispatch(updateCountyValue({ value: newValue }));
	}
	function onSuggestionSelected(event, { suggestion }) {
		dispatch(updateCountyItem({ selectedItem: suggestion }));
	}
	function onSuggestionsFetchRequested({ value }) {
		dispatch(loadCountiesSuggestions(value));
	}
	function onSuggestionsClearRequested() {
		dispatch(clearCountiesSuggestions());
	}
	function getSuggestionValue(suggestion) {
		return suggestion.Nombre;
	}
	function shouldRenderSuggestions() {
		return true;
	}
	function renderSuggestion(suggestion) {
		return <div>{suggestion.Nombre}</div>;
	}
	// Render
	const inputProps = {
		placeholder: "Delegación / Municipio",
		value,
		onChange: handleChange,
		autoComplete: "erp-county-from-database",
		onSelect: props.onSelect,
	};
	return (
		<div className="field">
			<Autosuggest
				suggestions={suggestions}
				onSuggestionsFetchRequested={onSuggestionsFetchRequested}
				onSuggestionsClearRequested={onSuggestionsClearRequested}
				getSuggestionValue={getSuggestionValue}
				shouldRenderSuggestions={shouldRenderSuggestions}
				renderSuggestion={renderSuggestion}
				onSuggestionSelected={onSuggestionSelected}
				inputProps={inputProps}
				id="county"
			/>
		</div>
	);
}
