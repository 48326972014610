import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { toggleShowAlert, setAlert } from "features/alerts/alertsSlice";

const initialState = {
	isLoading: false,
	errorMsg: null,
};
const register = createSlice({
	name: "register",
	initialState,
	reducers: {
		requestRegistration(state) {
			state.isLoading = true;
		},
		completeRegistration(state) {
			state.isLoading = false;
		},
		toggleShowRegister(state, action) {
			if (!action.payload.isOpen) {
				ReactGA.event({
					category: "Usuario",
					action: "Mostrar Registro"});
			}
			state.isOpen = !action.payload.isOpen;
		},
		setRegistrationErrorMsg(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
		clearRegisterData: () => initialState,
	},
});

export const {
	requestRegistration,
	completeRegistration,
	toggleShowRegister,
	setRegistrationErrorMsg,
	clearRegisterData,
} = register.actions;

export const fetchRegister = (data) => async (dispatch, getState) => {
	const theState = getState();
	const headers = {
		"Content-Type": "application/json",
	};
	const options = {
		method: "POST",
		headers: headers,
		body: JSON.stringify({
			email: data.usrname,
			password: data.pwd,
			name: data.name,
			lastname: data.lastName,
			mothername: data.motherName,
			gender: data.gender,
			birthdate: data.birthdate,
			phone: data.phone,
			subscribe: data.subscribe,
			kart: theState.cart.cartObject.strIdBolsa,
		}),
	};
	const url = BASE_API_URL + "/api/Cuenta/Registro";
	dispatch(requestRegistration());
	return fetch(url, options)
		.then((response) => {
			if (response.ok) {
				return response.text();
			} else {
				return response.text().then((text) => Promise.reject(text));
			}
		})
		.then(() => {
			ReactGA.event({
				category: "Usuario",
				action: "Registro Exitoso",
			});
			dispatch(
				setAlert({
					alert: {
						title: "¡Excelente!",
						description:
							"Se ha completado el registro. Recibirás un correo de confirmación para activar tu cuenta.",
						details: null,
					},
				})
			);
			dispatch(completeRegistration());
			dispatch(toggleShowRegister({ isOpen: true }));
			dispatch(toggleShowAlert({ isOpen: false }));
			dispatch(setRegistrationErrorMsg({ errorMsg: null }));
		})
		.catch((error) => {
			ReactGA.event({
				category: "Usuario",
				action: "Registro Fallido",
			});
			// TODO: fetch error actions
			if (error.includes("duplicate key")) {
				dispatch(
					setRegistrationErrorMsg({ errorMsg: { usrname: "Este correo electrónico ya está registrado." } })
				);
				dispatch(
					setAlert({
						alert: {
							title: "¡Lo sentimos!",
							description: "Este correo electrónico ya está registrado.",
							details:
								"Si no recuerdas tu contraseña puedes solicitar una nueva.",
						},
					})
				);
			} else {
				if (!error.toLowerCase().includes("modelstate")) {
					const json = JSON.parse(error);
					dispatch(
						setRegistrationErrorMsg({ errorMsg: { coupon: json.Message } })
					);
					dispatch(
						setAlert({
							alert: {
								title: "¡Lo sentimos!",
								description: "No se ha podido completar el registro.",
								details: json.Message,
							},
						})
					);
				} else {
					dispatch(
						setAlert({
							alert: {
								title: "¡Lo sentimos!",
								description:
									"No se ha podido completar el registro. Verifica los datos y vuelve a intentar.",
							},
						})
					);
				}
			}
			dispatch(completeRegistration());
			dispatch(toggleShowAlert({ isOpen: false }));
		});
};

export default register.reducer;
