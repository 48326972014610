import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import Modal from "react-modal";
import { PAYMENT_METHODS } from "features/constants";
import { toggleShowCart } from "features/cart/cartSlice.js";
import Cart from "./Cart";

export default function CartModal(props) {
	const dispatch = useDispatch();
	// Selectors
	const { isOpen, showTab, paymentType, invoiceAddress } = useSelector(
		(state) => state.cart
	);
	// Handlers
	function handleCloseClick() {
		ReactGA.send({ hitType: "pageview", page: "/"});
		dispatch(toggleShowCart({ isOpen: true }));
	}
	// Render Functions
	function renderCartDisclaimer() {
		// Depósito bancario
		if (
			showTab >= 4 &&
			paymentType != null &&
			paymentType === PAYMENT_METHODS.DEPOSIT
		) {
			return (
				<div className="alert-box">
					<span className="close-box" />
					Al seleccionar la forma de pago “Depósito Bancario” tus llantas están
					sujetas a disponibilidad debido a que el inventario con que se cuenta
					al momento de hacer tu pedido puede variar a la fecha de la
					confirmación de tu pago y validación de tu pago.
				</div>
			);
		}
		// Paynet
		if (
			showTab >= 4 &&
			paymentType != null &&
			paymentType === PAYMENT_METHODS.PAYNET
		) {
			return (
				<div className="alert-box">
					<span className="close-box" />
					Al seleccionar la forma de pago “Pago en Establecimientos” tus llantas
					están sujetas a disponibilidad debido a que el inventario con que se
					cuenta al momento de hacer tu pedido puede variar a la fecha de la
					confirmación de tu pago.
				</div>
			);
		}
		if (showTab === 3 && invoiceAddress !== 0) {
			return (
				<div className="alert-box">
					<span className="close-box" />
					Con la última legislación fiscal, será importante verificar la
					información del Régimen fiscal, así como colocar correctamente tu
					nombre/razón social tal como está registrado ante el SAT, ejemplo sin
					el “S.A. de C.V.” o el equivalente dependiendo de la constitución de
					la empresa.
				</div>
			);
		}
		return null;
	}
	function getTabProps(tab) {
		if (showTab === tab) {
			return { className: "active" };
		}
		return {};
	}
	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal"
				overlayClassName="modal-overlay"
				onRequestClose={handleCloseClick}
				contentLabel="Carrito"
			>
				<div className="modal-inner" id="cart-inner">
					<div className="modal-close" onClick={handleCloseClick} />
					<h2>Mi Carrito</h2>
					<div className="cart-steps">
						<div id="cart-1" {...getTabProps(1)}>
							<span>1</span>
							Carrito
						</div>
						<div id="cart-2" {...getTabProps(2)}>
							<span>2</span>
							Envío
						</div>
						<div id="cart-3" {...getTabProps(3)}>
							<span>3</span>
							Facturación
						</div>
						<div id="cart-4" {...getTabProps(4)}>
							<span>4</span>
							Forma de Pago
						</div>
						<div id="cart-5" {...getTabProps(5)}>
							<span>5</span>
							Resumen
						</div>
					</div>
					{renderCartDisclaimer()}
					<Cart />
				</div>
			</Modal>
		</div>
	);
}
