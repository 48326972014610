import React from "react";

export default function AddressItem(props) {
	// Handlers
	function handleSelectAddress() {
		props.onClick(props.index)
	}
	return (
		<div
			className={props.className}
			id={props.index}
			onClick={handleSelectAddress}
			key={props.index}
		>
			<div>
				<span className="icon-location"></span>
			</div>
			<div>{props.item.Alias}</div>
		</div>
	);
}
