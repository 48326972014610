import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleShowInvoiceAddressAdd } from "features/account/invoiceAddressSlice";
import Loader from "../Loader";
import CartInvoiceAddressItem from "./CartInvoiceAddressItem";
import { toggleShowInvoiceAddressEdit } from "features/account/invoiceAddressEditSlice";
import { setInvoiceAddressEdit } from "features/account/invoiceAddressEditSlice";
import { validateInvoiceAddress } from "features/functions";

export default function CartInvoiceAddress() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, list } = useSelector((state) => state.invoiceAddress);
	const { invoiceAddress, invoiceAddressItem } = useSelector(
		(state) => state.cart
	);
	const regimeList = useSelector((state) => state.cfdiRegime.list);
	const usoList = useSelector((state) => state.cfdiUso.list);
	// Handlers
	function handleAddClick() {
		dispatch(toggleShowInvoiceAddressAdd({ isOpenAdd: false }));
	}
	function handleEditClick() {
		dispatch(setInvoiceAddressEdit({ address: invoiceAddressItem }));
		dispatch(toggleShowInvoiceAddressEdit({ isOpen: false }));
	}
	// Render Functions
	function formatAddress() {
		let result = <div>&nbsp;</div>;
		let numint = "";
		const cssAlert = { color: "red" };
		const cssTag = { color: "orange", fontWeight: "400", fontStyle: "normal" };
		const addressRegime =
			invoiceAddressItem.RegimenFiscal != null &&
			invoiceAddressItem.RegimenFiscal !== "" ? (
				regimeList.filter((el) => el.Id === invoiceAddressItem.RegimenFiscal)[0]
					.Nombre
			) : (
				<span style={cssAlert}>N/A</span>
			);
		const addressUso =
			invoiceAddressItem.Uso != null && invoiceAddressItem.Uso !== "" ? (
				usoList.filter((el) => el.Id === invoiceAddressItem.Uso)[0].Nombre
			) : (
				<span style={cssAlert}>N/A</span>
			);
		if (invoiceAddressItem !== null) {
			if (invoiceAddressItem.Numero_Interior.length > 0) {
				numint = " - " + invoiceAddressItem.Numero_Interior;
			}
			result = (
				<div>
					{invoiceAddressItem.RFC}
					<br />
					{invoiceAddressItem.RazonSocial}
					<br />
					{invoiceAddressItem.Calle +
						" " +
						invoiceAddressItem.Numero_Exterior +
						numint}
					<br />
					Col. {invoiceAddressItem.Colonia}
					<br />
					{invoiceAddressItem.Codigo_Postal +
						" " +
						invoiceAddressItem.Delegacion}
					<br />
					{invoiceAddressItem.Estado_Descripcion}
					<br />
					<br />
					<span style={cssTag}>Régimen Fiscal:</span> {addressRegime}
					<br />
					<span style={cssTag}>Uso de CFDI:</span> {addressUso}
				</div>
			);
		}
		return result;
	}
	function renderInvalid() {
		if (invoiceAddressItem.Alias === "Sin RFC") {
			return null;
		}
		const isValid = validateInvoiceAddress(invoiceAddressItem, regimeList, usoList);
		if (!isValid) {
			return (
				<div className="alert-box" style={{ textAlign: "left" }}>
					<span className="close-box"></span>Los datos fiscales están
					incompletos o no son válidos. Por favor complete o corrija la
					información para poder utilizar estos datos.
				</div>
			);
		}
	}
	function renderEditButton() {
		if (invoiceAddress != null && invoiceAddress > 0) {
			return (
				<div>
					<div className="button alternate" onClick={handleEditClick}>
						Editar
					</div>
				</div>
			);
		}
		return null;
	}
	function renderAddressList() {
		if (list == null) {
			return (
				<div id="address-list">
					<div>No hay direcciones de facturación registradas</div>
				</div>
			);
		}
		return (
			<div id="address-list">
				<div>
					{list.map((element, index) => {
						return (
							<CartInvoiceAddressItem
								className={
									invoiceAddress === index
										? "address-item selected"
										: "address-item"
								}
								index={index}
								alias={element.Alias}
								item={element}
								key={index}
							/>
						);
					})}
					<div>&nbsp;</div>
				</div>
			</div>
		);
	}
	function renderSelectedAddress() {
		if (invoiceAddressItem != null) {
			return (
				<div id="address-details">
					{formatAddress()}
					{renderInvalid()}
					{renderEditButton()}
				</div>
			);
		}
		return null;
	}
	if (isLoading) {
		return (
			<div id="cart-content-address">
				<Loader msg="Espere..." />
			</div>
		);
	}
	return (
		<div id="cart-content-address">
			<div id="address-add" onClick={handleAddClick}>
				<span className="add-text"></span>
				<span className="icon-add"></span>
			</div>
			{renderAddressList()}
			{renderSelectedAddress()}
		</div>
	);
}
