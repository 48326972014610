import App from "components/App";
import Confirmation from "components/account/Confirmation";
import Terms from "components/account/Terms";
import { fetchCodeTiresList } from "./tireResults/codeTiresSlice";
import { fetchBannersList } from "./banners/bannersSlice";
import { fetchActivation } from "./account/activationSlice";
import { fetchSizeStringTireList } from "./tireResults/sizeTiresSlice";
import { fetchSizeSaeStringTireList } from "./tireResults/sizeSaeTiresSlice";

const routes = [
	{
		path: "/confirmacion",
		component: Confirmation,
		exact: true,
	},
	{
		path: "/terminos",
		component: Terms,
		exact: true,
	},
	{
		path: [
			"/eventos/:eventKey",
			"/product/:productid",
			"/llanta/:productcode/:brand/:vehicletype/:product/:s1/:s2",
			"/llanta/:productcode/:brand/:vehicletype/:product/:s1",
			"/llantas/:searchtype/:vehicletype/:s1/:s2",
			"/llantas/:searchtype/:vehicletype/:s1",
			"/order/:orderId",
			"/activacion",
			"/",
		],
		component: App,
		loadData: (store, match, query) => {
			const promises = [];
			const { productcode, searchtype, vehicletype, s1, s2, orderId } =
				match.params;
			if (productcode != null) {
				promises.push(store.dispatch(fetchCodeTiresList(productcode)));
			}
			if (query != null && query.key != null) {
				promises.push(store.dispatch(fetchActivation(query.key)));
			}
			if (orderId != null) {
				promises.push(
					new Promise((resolve, reject) => {
						setTimeout(resolve, 3000);
					})
				);
			}
			if (searchtype != null && searchtype === "medida") {
				if (s2 != null) {
					promises.push(
						store.dispatch(fetchSizeStringTireList(vehicletype, s1 + "/" + s2))
					);
				} else {
					promises.push(
						store.dispatch(fetchSizeSaeStringTireList(vehicletype, s1))
					);
				}
			}
			promises.push(store.dispatch(fetchBannersList()));
			return Promise.all(promises);
		},
	},
];

export default routes;
