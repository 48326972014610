import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autosuggest from "react-autosuggest";
import Scroll from "react-scroll";
import {
	clearVehicleBrandActions,
	clearVehicleModelActions,
	clearVehicleYearActions,
	clearVehicleVersionActions,
} from "features/tireFinder/searchSlice";
import {
	updateVehicleBrandValue,
	updateVehicleBrandItem,
	loadVehicleBrandsSuggestions,
	clearVehicleBrandsSuggestions,
} from "features/tireFinder/vehicleBrandsSlice";
import {
	fetchVehicleModelsIfNeeded,
	updateVehicleModelItem,
	updateVehicleModelValue,
	loadVehicleModelsSuggestions,
	clearVehicleModelsSuggestions,
} from "features/tireFinder/vehicleModelsSlice";
import {
	fetchVehicleYearsIfNeeded,
	updateVehicleYearItem,
	updateVehicleYearValue,
	loadVehicleYearsSuggestions,
	clearVehicleYearsSuggestions,
} from "features/tireFinder/vehicleYearsSlice";
import {
	fetchVehicleVersionsIfNeeded,
	updateVehicleVersionItem,
	updateVehicleVersionValue,
	loadVehicleVersionsSuggestions,
	clearVehicleVersionsSuggestions,
} from "features/tireFinder/vehicleVersionsSlice";
import { fetchVehicleTiresListIfNeeded } from "features/tireResults/vehicleTiresSlice";
import { clearSizeTiresList } from "features/tireResults/sizeTiresSlice";
import { clearSizeSaeTiresList } from "features/tireResults/sizeSaeTiresSlice";
import { hideHighlightsTiresList } from "features/tireResults/highlightsTiresSlice";

const renderSuggestion = (suggestion) => {
	return <div>{suggestion.Name}</div>;
};
const getSuggestionValue = (suggestion) => {
	return suggestion.Name + "";
};
const renderYearSuggestion = (suggestion) => {
	return <div>{suggestion.anio}</div>;
};
const getYearSuggestionValue = (suggestion) => {
	return suggestion.anio + "";
};

const scroll = Scroll.animateScroll;

export default function VehicleFinder() {
	const dispatch = useDispatch();
	// Selectors
	const {
		vehicleBrands,
		vehicleModels,
		vehicleYears,
		vehicleVersions,
	} = useSelector((state) => state);
	const tireList = useSelector((state) => state.vehicleTires.list);
	// Refs
	const modelRef = useRef();
	const yearRef = useRef();
	const versionRef = useRef();
	// Effect
	useEffect(() => {
		if (vehicleModels.renderInput && tireList == null) {
			modelRef.current.focus();
		}
		if (vehicleYears.renderInput && tireList == null) {
			yearRef.current.focus();
		}
		if (vehicleVersions.renderInput && tireList == null) {
			versionRef.current.focus();
		}
	}, [
		vehicleModels.renderInput,
		vehicleYears.renderInput,
		vehicleVersions.renderInput,
		tireList,
	]);
	// Brand
	function handleBrandChange(event, { newValue }) {
		dispatch(clearVehicleBrandActions());
		dispatch(updateVehicleBrandValue({ value: newValue }));
		dispatch(updateVehicleBrandItem({ selectedItem: {} }));
	}
	function handleFocus(e) {
		e.target.select();
	}
	function onBrandSelected(event, { suggestion }) {
		dispatch(fetchVehicleModelsIfNeeded(suggestion.ID));
		dispatch(updateVehicleBrandItem({ selectedItem: suggestion }));
	}
	function onBrandFetchRequested({ value }) {
		dispatch(loadVehicleBrandsSuggestions(value));
	}
	function onBrandClearRequested() {
		dispatch(clearVehicleBrandsSuggestions());
	}
	// Model
	function handleModelChange(event, { newValue }) {
		dispatch(clearVehicleModelActions());
		dispatch(updateVehicleModelValue({ value: newValue }));
		dispatch(updateVehicleModelItem({ selectedItem: {} }));
	}
	function onModelSelected(event, { suggestion }) {
		dispatch(fetchVehicleYearsIfNeeded(suggestion.ID));
		dispatch(updateVehicleModelItem({ selectedItem: suggestion }));
	}
	function onModelFetchRequested({ value }) {
		dispatch(loadVehicleModelsSuggestions(value));
	}
	function onModelClearRequested() {
		dispatch(clearVehicleModelsSuggestions());
	}
	// Year
	function handleYearChange(event, { newValue }) {
		dispatch(clearVehicleYearActions());
		dispatch(updateVehicleYearValue({ value: newValue }));
		dispatch(updateVehicleYearItem({ selectedItem: {} }));
	}
	function onYearSelected(event, { suggestion }) {
		dispatch(fetchVehicleVersionsIfNeeded(suggestion.anio));
		dispatch(updateVehicleYearItem({ selectedItem: suggestion }));
	}
	function onYearFetchRequested({ value }) {
		dispatch(loadVehicleYearsSuggestions(value));
	}
	function onYearClearRequested() {
		dispatch(clearVehicleYearsSuggestions());
	}
	// Version
	function handleVersionChange(event, { newValue }) {
		dispatch(updateVehicleVersionValue({ value: newValue }));
		dispatch(updateVehicleVersionItem({ selectedItem: {} }));
		dispatch(clearVehicleVersionActions());
	}
	function onVersionSelected(event, { suggestion }) {
		dispatch(updateVehicleVersionItem({ selectedItem: suggestion }));
		dispatch(fetchVehicleTiresListIfNeeded(suggestion.ID));
		dispatch(clearSizeTiresList());
		dispatch(clearSizeSaeTiresList());
		dispatch(hideHighlightsTiresList({ hide: true }));
		setTimeout(() => {
			const target = document.getElementById("resultCarousel");
			const targetPos =
				target.offsetWidth > 980
					? target.offsetTop - 100
					: target.offsetTop - 50;
			scroll.scrollTo(targetPos, { smooth: true, delay: 100, duration: 500 });
		}, 500);
		versionRef.current.blur();
	}
	function onVersionFetchRequested({ value }) {
		dispatch(loadVehicleVersionsSuggestions(value));
	}
	function onVersionClearRequested() {
		dispatch(clearVehicleVersionsSuggestions());
	}
	// Input props
	const brandProps = {
		placeholder: "Escribe la marca...",
		value: vehicleBrands.value,
		onChange: handleBrandChange,
		onFocus: handleFocus,
		tabIndex: 101,
	};
	const modelProps = {
		placeholder: "MODELO",
		value: vehicleModels.value,
		onChange: handleModelChange,
		onFocus: handleFocus,
		tabIndex: 102,
		ref: modelRef,
	};
	const yearProps = {
		placeholder: "AÑO",
		value: vehicleYears.value,
		onChange: handleYearChange,
		onFocus: handleFocus,
		tabIndex: 103,
		ref: yearRef,
	};
	const versionProps = {
		placeholder: "VERSION",
		value: vehicleVersions.value,
		onChange: handleVersionChange,
		onFocus: handleFocus,
		tabIndex: 104,
		ref: versionRef,
	};
	// Render functions
	function renderModel() {
		if (vehicleModels.renderInput) {
			return (
				<div className="field">
					<div>
						<Autosuggest
							highlightFirstSuggestion
							suggestions={vehicleModels.suggestions}
							onSuggestionsFetchRequested={onModelFetchRequested}
							onSuggestionsClearRequested={onModelClearRequested}
							getSuggestionValue={getSuggestionValue}
							shouldRenderSuggestions={() => true}
							renderSuggestion={renderSuggestion}
							onSuggestionSelected={onModelSelected}
							inputProps={modelProps}
							id="vehicleModel"
						/>
					</div>
				</div>
			);
		}
		return (
			<div className="field disabled">
				<div className="vehiclefinder-empty">
					<div>MODELO</div>
				</div>
			</div>
		);
	}
	function renderYear() {
		if (vehicleYears.renderInput) {
			return (
				<div className="field">
					<div>
						<Autosuggest
							highlightFirstSuggestion
							suggestions={vehicleYears.suggestions}
							onSuggestionsFetchRequested={onYearFetchRequested}
							onSuggestionsClearRequested={onYearClearRequested}
							getSuggestionValue={getYearSuggestionValue}
							shouldRenderSuggestions={() => true}
							renderSuggestion={renderYearSuggestion}
							onSuggestionSelected={onYearSelected}
							inputProps={yearProps}
							id="vehicleYear"
						/>
					</div>
				</div>
			);
		}
		return (
			<div className="field disabled">
				<div className="vehiclefinder-empty">
					<div>AÑO</div>
				</div>
			</div>
		);
	}
	function renderVersion() {
		if (vehicleVersions.renderInput) {
			return (
				<div className="field">
					<div>
						<Autosuggest
							highlightFirstSuggestion
							suggestions={vehicleVersions.suggestions}
							onSuggestionsFetchRequested={onVersionFetchRequested}
							onSuggestionsClearRequested={onVersionClearRequested}
							getSuggestionValue={getSuggestionValue}
							shouldRenderSuggestions={() => true}
							renderSuggestion={renderSuggestion}
							onSuggestionSelected={onVersionSelected}
							inputProps={versionProps}
							id="vehicleVersion"
						/>
					</div>
				</div>
			);
		}
		return (
			<div className="field disabled">
				<div className="vehiclefinder-empty">
					<div>VERSION</div>
				</div>
			</div>
		);
	}
	// Render
	return (
		<div id="vehicle-finder">
			<h3>¿Qué vehículo tienes?</h3>
			<div className="field">
				<div>
					<Autosuggest
						highlightFirstSuggestion
						suggestions={vehicleBrands.suggestions}
						onSuggestionsFetchRequested={onBrandFetchRequested}
						onSuggestionsClearRequested={onBrandClearRequested}
						getSuggestionValue={getSuggestionValue}
						renderSuggestion={renderSuggestion}
						onSuggestionSelected={onBrandSelected}
						inputProps={brandProps}
						id="vehicleBrand"
					/>
				</div>
			</div>
			{renderModel()}
			{renderYear()}
			{renderVersion()}
		</div>
	);
}
