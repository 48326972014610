import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Autosuggest from "react-autosuggest";
import {
	updateZipCodeItem,
	updateZipCodeValue,
	loadZipCodesSuggestions,
	clearZipCodesSuggestions,
} from "features/account/zipCodesSlice";
import {
	fetchNeighborhoodsIfNeeded,
	setNeighborhoodsZipCode,
} from "features/account/neighborhoodsSlice";
import { fetchShipAddressCity } from "features/account/shipAddressSlice";
import { fetchInvoiceAddressCity } from "features/account/invoiceAddressSlice";

export default function ZipCodeAutosuggest(props) {
	const dispatch = useDispatch();
	// Selectors
	const { value, suggestions } = useSelector((state) => state.zipCodes);
	// Handlers
	function handleChange(event, { newValue }) {
		const fixed = newValue.replace(/[^0-9]/g, "");
		dispatch(updateZipCodeValue({value: fixed}));
	}
	function onSuggestionSelected(event, { suggestion }) {
		dispatch(updateZipCodeItem({selectedItem: suggestion}));
		dispatch(fetchNeighborhoodsIfNeeded(suggestion.Codigo));
		dispatch(setNeighborhoodsZipCode(suggestion.Codigo));
		dispatch(fetchShipAddressCity(suggestion.Codigo));
		dispatch(fetchInvoiceAddressCity(suggestion.Codigo));
	}
	function onSuggestionsFetchRequested({ value }) {
		dispatch(loadZipCodesSuggestions(value));
	}
	function onSuggestionsClearRequested() {
		dispatch(clearZipCodesSuggestions());
	}
	function getSuggestionValue(suggestion) {
		return suggestion.Codigo + "";
	}
	function shouldRenderSuggestions() {
		return true;
	}
	function renderSuggestion(suggestion) {
		return <div>{suggestion.Codigo}</div>;
	}
	// Render
	const inputProps = {
		placeholder: "Código Postal",
		value,
		onChange: handleChange,
		autoComplete: "erp-zipcode-from-database",
		onSelect: props.onSelect,
	};
	return (
		<div className="field">
			<Autosuggest
				suggestions={suggestions}
				onSuggestionsFetchRequested={onSuggestionsFetchRequested}
				onSuggestionsClearRequested={onSuggestionsClearRequested}
				getSuggestionValue={getSuggestionValue}
				shouldRenderSuggestions={shouldRenderSuggestions}
				renderSuggestion={renderSuggestion}
				onSuggestionSelected={onSuggestionSelected}
				inputProps={inputProps}
				id="zipcode"
			/>
		</div>
	);
}
