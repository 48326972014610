import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import ReactDropdown from "react-dropdown";
import { validateZip, validatePhone } from "features/functions";
import ZipCodeAutoSuggest from "components/autosuggest/ZipCodeAutosuggest";
import NeighborhoodAutosuggest from "components/autosuggest/NeighborhoodAutosuggest";
import CityAutosuggest from "components/autosuggest/CityAutosuggest";
import CountyAutosuggest from "components/autosuggest/CountyAutosuggest";
import {
	setShipAddressErrorMessage,
	clearShipAddressAddData,
	toggleShowShipAddressAdd,
	fetchAddShipAddress,
} from "features/account/shipAddressSlice";
import {
	fetchZipCodesIfNeeded,
	setZipCodesStateId,
	clearZipCodeValue,
} from "features/account/zipCodesSlice";
import { clearNeighborhoodValue } from "features/account/neighborhoodsSlice";
import {
	fetchCitiesIfNeeded,
	clearCityValue,
} from "features/account/citiesSlice";
import {
	fetchCountiesIfNeeded,
	clearCountyValue,
} from "features/account/countiesSlice";
import Loader from "components/Loader";

const clearCityCountyError = (errorMsg) => async (dispatch) => {
	if (errorMsg != null && errorMsg.city != null) {
		dispatch(
			setShipAddressErrorMessage({
				errorMsg: { ...errorMsg, city: null, county: null },
			})
		);
	}
};

export default function ShipAddressAddModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isOpenAdd, isLoadingAdd, errorMsg } = useSelector(
		(state) => state.shipAddress
	);
	const { options } = useSelector((state) => state.states);
	const zipValue = useSelector((state) => state.zipCodes.value);
	const cityValue = useSelector((state) => state.cities.value);
	const countyValue = useSelector((state) => state.counties.value);
	// State
	const [alias, setAlias] = useState("");
	const [phone, setPhone] = useState("");
	const [street, setStreet] = useState("");
	const [ext, setExt] = useState("");
	const [int, setInt] = useState("");
	const [selectedState, setSelectedState] = useState(null);
	const [zip, setZip] = useState("");
	const [neighborhood, setNeighborhood] = useState("");
	const [city, setCity] = useState("");
	const [county, setCounty] = useState("");
	// Effect
	useEffect(() => {
		let prevZipValue;
		let prevCityValue;
		let prevCountyValue;
		if (
			prevZipValue !== zipValue &&
			prevCityValue !== cityValue &&
			cityValue !== "" &&
			prevCountyValue !== countyValue &&
			countyValue !== ""
		) {
			setCity(cityValue);
			setCounty(countyValue);
			dispatch(clearCityCountyError(errorMsg));
		}
		return () => {
			prevZipValue = zipValue;
			prevCityValue = cityValue;
			prevCountyValue = countyValue;
		};
	}, [dispatch, errorMsg, zipValue, cityValue, countyValue]);
	// Handlers
	function handleFocus(e) {
		e.target.select();
	}
	function handleAliasChange(e) {
		if (errorMsg != null && errorMsg.alias != null) {
			dispatch(
				setShipAddressErrorMessage({
					errorMsg: { ...errorMsg, alias: null, error: null },
				})
			);
		}
		setAlias(e.target.value);
	}
	function handlePhoneChange(e) {
		if (errorMsg != null && errorMsg.phone != null) {
			dispatch(
				setShipAddressErrorMessage({
					errorMsg: { ...errorMsg, phone: null, error: null },
				})
			);
		}
		setPhone(e.target.value);
	}
	function handleStreetChange(e) {
		if (errorMsg != null && errorMsg.street != null) {
			dispatch(
				setShipAddressErrorMessage({
					errorMsg: { ...errorMsg, street: null, error: null },
				})
			);
		}
		setStreet(e.target.value);
	}
	function handleExtChange(e) {
		if (errorMsg != null && errorMsg.ext != null) {
			dispatch(
				setShipAddressErrorMessage({
					errorMsg: { ...errorMsg, ext: null, error: null },
				})
			);
		}
		setExt(e.target.value);
	}
	function handleIntChange(e) {
		if (errorMsg != null && errorMsg.int != null) {
			dispatch(
				setShipAddressErrorMessage({
					errorMsg: { ...errorMsg, int: null, error: null },
				})
			);
		}
		setInt(e.target.value);
	}
	function handleSelectState(option) {
		if (errorMsg != null && errorMsg.selectedState != null) {
			dispatch(
				setShipAddressErrorMessage({
					errorMsg: { ...errorMsg, selectedState: null, error: null },
				})
			);
		}
		const stateId = option.value + "";
		setSelectedState(option);
		setZip("");
		setNeighborhood("");
		setCity("");
		setCounty("");
		dispatch(clearNeighborhoodValue());
		dispatch(clearZipCodeValue());
		dispatch(clearCityValue());
		dispatch(clearCountyValue());
		dispatch(fetchZipCodesIfNeeded(stateId));
		dispatch(fetchCitiesIfNeeded(stateId));
		dispatch(fetchCountiesIfNeeded(stateId));
		dispatch(setZipCodesStateId({ stateId }));
	}
	function handleZipChange(e) {
		if (errorMsg != null && errorMsg.zip != null) {
			dispatch(
				setShipAddressErrorMessage({
					errorMsg: { ...errorMsg, zip: null, error: null },
				})
			);
		}
		setZip(e.target.value);
		setNeighborhood("");
		setCity("");
		setCounty("");
		dispatch(clearNeighborhoodValue());
		dispatch(clearCityValue());
		dispatch(clearCountyValue());
	}
	function handleNeighborhoodChange(e) {
		if (errorMsg != null && errorMsg.neighborhood != null) {
			dispatch(
				setShipAddressErrorMessage({
					errorMsg: { ...errorMsg, neighborhood: null, error: null },
				})
			);
		}
		setNeighborhood(e.target.value);
	}
	function handleCityChange(e) {
		if (errorMsg != null && errorMsg.city != null) {
			dispatch(
				setShipAddressErrorMessage({
					errorMsg: { ...errorMsg, city: null, error: null },
				})
			);
		}
		setCity(e.target.value);
	}
	function handleCountyChange(e) {
		if (errorMsg != null && errorMsg.county != null) {
			dispatch(
				setShipAddressErrorMessage({
					errorMsg: { ...errorMsg, county: null, error: null },
				})
			);
		}
		setCounty(e.target.value);
	}
	function handleReset() {
		setAlias("");
		setPhone("");
		setStreet("");
		setExt("");
		setInt("");
		setSelectedState(null);
		setZip("");
		setNeighborhood("");
		setCity("");
		setCounty("");
		dispatch(clearZipCodeValue());
		dispatch(clearShipAddressAddData());
		dispatch(clearNeighborhoodValue());
		dispatch(clearCityValue());
		dispatch(clearCountyValue());
	}
	function handleCloseClick() {
		handleReset();
		dispatch(toggleShowShipAddressAdd({ isOpenAdd: true }));
	}
	function handleOverlayCloseClick() {
		dispatch(toggleShowShipAddressAdd({ isOpenAdd: true }));
	}
	function handleSubmit(e) {
		e.preventDefault();
		let valid = true;
		let errorMessages = { ...errorMsg };
		if (alias.trim() === "") {
			errorMessages = {
				...errorMessages,
				alias: "Debes escribir un alias único para esta dirección",
			};
			valid = false;
		}
		if (phone.trim() === "") {
			errorMessages = {
				...errorMessages,
				phone: "Debes escribir un teléfono de contacto",
			};
			valid = false;
		} else {
			if (!validatePhone(phone)) {
				errorMessages = {
					...errorMessages,
					phone: "Debes escribir un teléfono válido",
				};
				valid = false;
			}
		}
		if (street.trim() === "") {
			errorMessages = {
				...errorMessages,
				street: "Debes escribir la calle",
			};
			valid = false;
		}
		if (ext.trim() === "") {
			errorMessages = {
				...errorMessages,
				ext: "Debes escribir el número exterior",
			};
			valid = false;
		}
		if (selectedState == null) {
			errorMessages = {
				...errorMessages,
				selectedState: "Debes seleccionar un estado",
			};
			valid = false;
		}
		const zipString = zip + "";
		if (zipString.trim() === "") {
			errorMessages = {
				...errorMessages,
				zip: "Debes escribir el código postal",
			};
			valid = false;
		} else {
			if (!validateZip(zip)) {
				errorMessages = {
					...errorMessages,
					zip: "El código postal no es válido",
				};
				valid = false;
			}
		}
		if (neighborhood.trim() === "") {
			errorMessages = {
				...errorMessages,
				neighborhood: "Debes escribir la colonia",
			};
			valid = false;
		}
		if (city.trim() === "") {
			errorMessages = {
				...errorMessages,
				city: "Debes escribir la ciudad",
			};
			valid = false;
		}
		if (county.trim() === "") {
			errorMessages = {
				...errorMessages,
				county: "Debes escribir el municipio",
			};
			valid = false;
		}
		if (valid) {
			dispatch(
				fetchAddShipAddress({
					alias,
					phone,
					street,
					ext,
					int,
					zip,
					neighborhood,
					city,
					county,
					stateId: selectedState.value,
				})
			);
			dispatch(setShipAddressErrorMessage({ errorMsg: null }));
		} else {
			dispatch(setShipAddressErrorMessage({ errorMsg: errorMessages }));
		}
	}
	// Render Functions
	function renderError(error) {
		if (error != null) {
			return <li>{error}</li>;
		}
		return null;
	}
	function renderErrorList() {
		if (errorMsg != null) {
			return (
				<div className="error-list">
					<ul>
						{renderError(errorMsg.alias)}
						{renderError(errorMsg.phone)}
						{renderError(errorMsg.street)}
						{renderError(errorMsg.ext)}
						{renderError(errorMsg.int)}
						{renderError(errorMsg.selectedState)}
						{renderError(errorMsg.zip)}
						{renderError(errorMsg.neighborhood)}
						{renderError(errorMsg.city)}
						{renderError(errorMsg.county)}
						{renderError(errorMsg.error)}
					</ul>
				</div>
			);
		}
		return null;
	}
	function getFieldProps(p) {
		if (
			errorMsg == null ||
			!(errorMsg != null && p in errorMsg) ||
			(errorMsg != null && p in errorMsg && errorMsg[p] == null)
		) {
			if (p === "street") {
				return { className: "full" };
			}
			if (p === "ext" || p === "int") {
				return { className: "half" };
			}
		}
		if (errorMsg != null && p in errorMsg && errorMsg[p] != null) {
			if (p === "street") {
				return { className: "full error" };
			}
			if (p === "ext" || p === "int") {
				return { className: "half error" };
			}
			return { className: "error" };
		}
		return {};
	}
	function renderLoader() {
		if (isLoadingAdd) {
			return <Loader msg="Por favor espere..." />;
		}
		return null;
	}
	const defaultOption = selectedState == null ? "Estado" : selectedState;
	function renderForm() {
		if (!isLoadingAdd) {
			return (
				<form onSubmit={handleSubmit}>
					<div className="address-form">
						<div>
							<div {...getFieldProps("alias")}>
								<label>Alias</label>
								<input
									type="text"
									className="modal-field"
									placeholder="Alias"
									onChange={handleAliasChange}
									value={alias}
									autoComplete="custom-addr-alias"
									tabIndex={1}
									onFocus={handleFocus}
								/>
							</div>
							<div {...getFieldProps("phone")}>
								<label>Teléfono *</label>
								<input
									type="text"
									placeholder="Teléfono"
									value={phone}
									onChange={handlePhoneChange}
									autoComplete="tel"
									className="modal-field"
									tabIndex={2}
									onFocus={handleFocus}
								/>
							</div>
						</div>
						<div>
							<div {...getFieldProps("street")}>
								<label>Calle</label>
								<input
									type="text"
									className="modal-field"
									placeholder="Calle"
									onChange={handleStreetChange}
									value={street}
									autoComplete="address-line1"
									tabIndex={3}
									onFocus={handleFocus}
								/>
							</div>
						</div>
						<div>
							<div {...getFieldProps("ext")}>
								<label>Num Ext</label>
								<input
									type="text"
									className="modal-field"
									placeholder="Num Ext"
									onChange={handleExtChange}
									value={ext}
									tabIndex={4}
									onFocus={handleFocus}
								/>
							</div>
							<div {...getFieldProps("int")}>
								<label>Num Int</label>
								<input
									type="text"
									className="modal-field"
									placeholder="Num Int"
									onChange={handleIntChange}
									value={int}
									tabIndex={5}
									onFocus={handleFocus}
								/>
							</div>
							<div {...getFieldProps("selectedState")}>
								<label>Estado</label>
								<ReactDropdown
									options={options}
									onChange={handleSelectState}
									value={defaultOption}
									placeholder="Estado"
								/>
							</div>
						</div>
						<div>
							<div {...getFieldProps("zip")}>
								<label>Código Postal</label>
								<ZipCodeAutoSuggest onSelect={handleZipChange} />
							</div>
							<div {...getFieldProps("neighborhood")}>
								<label>Colonia</label>
								<NeighborhoodAutosuggest onSelect={handleNeighborhoodChange} />
							</div>
						</div>
						<div>
							<div {...getFieldProps("city")}>
								<label>Ciudad</label>
								<CityAutosuggest onSelect={handleCityChange} />
							</div>
							<div {...getFieldProps("county")}>
								<label>Delegación o Municipio</label>
								<CountyAutosuggest onSelect={handleCountyChange} />
							</div>
						</div>
						<div>
							<div className="full">{renderErrorList()}</div>
						</div>
						<div>
							<div className="full center">
								<div className="right">
									<div onClick={handleCloseClick} className="modal-button ">
										Cancelar
									</div>
									<button type="submit" tabIndex={12} className="button main">
										Guardar
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			);
		}
		return null;
	}
	return (
		<div>
			<Modal
				isOpen={isOpenAdd}
				className="modal"
				overlayClassName="modal-overlay"
				onRequestClose={handleOverlayCloseClick}
				contentLabel="Nueva Dirección de Envío"
			>
				<div className="modal-inner" id="register-inner">
					<div className="modal-close" onClick={handleCloseClick}></div>
					<h2>Nueva Dirección de Envío</h2>
					{renderLoader()}
					{renderForm()}
				</div>
			</Modal>
		</div>
	);
}
