import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Scroll from "react-scroll";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { IMAGE_TYPES as i } from "features/constants";
import {
	isServer,
	currencyFormat,
	imageFormat,
	getBrandClass,
	getDept,
	getSizeTags,
} from "features/functions";
import { clearSearchActions } from "features/tireFinder/searchSlice";
import { scrollToSizeTires } from "features/tireResults/sizeTiresSlice";
import Loader from "../Loader";
import TireDetails from "./TireDetails";
import { VEHICLE_TYPES } from "features/constants";

const ScrollLink = Scroll.Link;
const animateScroll = Scroll.animateScroll;

const getTirePrice = (tire) => {
	if (tire.Discount > 0 && tire.Minimum <= 1) return tire.Price * 1.16;
	else {
		return tire.ListPrice * 1.16;
	}
};

export default function ResultSize(props) {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, list, width, ratio, rim, dept, scroll } = useSelector(
		(state) => state.sizeTires
	);
	// State
	const [activeProduct, setActiveProduct] = useState(0);
	// Effect
	useEffect(() => {
		setActiveProduct(0);
		if (scroll) {
			dispatch(scrollToSizeTires());
		}
	}, [list]);
	// History
	const history = useHistory();
	const location = useLocation();
	// Handlers
	function handleCloseClick() {
		dispatch(clearSearchActions());
		const target = document.getElementById("tireFinderElement");
		const targetPos =
			target.offsetWidth > 980 ? target.offsetTop - 100 : target.offsetTop - 50;
		animateScroll.scrollTo(targetPos, {
			smooth: true,
			delay: 100,
			duration: 500,
		});
		if (location !== "/") {
			history.push("/");
		}
	}
	function setCurrentSlide(currentSlide) {
		setActiveProduct(currentSlide);
	}
	// Render functions
	function renderProducts(activeList, active) {
		if (activeList.length > 0) {
			let data = Array.from(activeList);
			if (data.length === 2) {
				data.push(data[0]);
				data.push(data[1]);
			} else if (data.length === 3) {
				data.push(data[0]);
				data.push(data[1]);
				data.push(data[2]);
			}
			var settings = {
				dots: false,
				centerMode: true,
				infinite: true,
				initialSlide: 0,
				className: "center",
				slidesToShow: data.length === 1 ? 1 : 3,
				centerPadding: 0,
				afterChange: (currentSlide) => {
					setCurrentSlide(currentSlide);
				},
				responsive: [
					{
						breakpoint: 980,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							initialSlide: 0,
						},
					},
				],
			};
			let w = isServer ? 1024 : window.innerWidth;
			let offset = w > 980 ? -100 : -50;
			return (
				<>
					<Slider {...settings}>
						{data.map((tire, index) => {
							if (index === active) {
								return (
									<div id={tire.ProductId} key={index}>
										<div>
											<ScrollLink
												to="resultTireDetails"
												smooth={true}
												duration={500}
												offset={offset}
											>
												<div>
													<img
														src={imageFormat(
															tire.TireProduct.Image,
															i.CAROUSEL
														)}
														alt={tire.TireProduct.Name}
													/>
													<span
														className={getBrandClass(tire.TireProduct.Brand)}
													></span>
												</div>
												<div>
													<h2>{tire.TireProduct.Name}</h2>
												</div>
												<div>{tire.Size}</div>
												<div>
													{currencyFormat(getTirePrice(tire))} <span>MXN</span>
												</div>
											</ScrollLink>
										</div>
									</div>
								);
							} else {
								return (
									<div id={tire.ProductId} key={index}>
										<div>
											<div>
												<img
													src={imageFormat(tire.TireProduct.Image, i.CAROUSEL)}
													alt={tire.TireProduct.Name}
												/>
												<span
													className={getBrandClass(tire.TireProduct.Brand)}
												></span>
											</div>
											<div>
												<h2>{tire.TireProduct.Name}</h2>
											</div>
											<div>{tire.Size}</div>
											<div>
												{currencyFormat(getTirePrice(tire))} <span>MXN</span>
											</div>
										</div>
									</div>
								);
							}
						})}
					</Slider>
					{data[0]?.VehicleTypeId === 3 && (
						<div className="alert-box" style={{ paddingTop: "20px" }}>
							<span className="close-box"></span> Imagen únicamente para fines
							ilustrativos, el precio mostrado es por una llanta
						</div>
					)}
				</>
			);
		} else {
			return (
				<div className="no-results">
					<div>No hay productos disponibles para esta opción</div>
				</div>
			);
		}
	}
	function renderTireDetails(tireDetails) {
		if (tireDetails !== null) {
			return <TireDetails {...tireDetails} />;
		} else return null;
	}
	// Render
	if (isLoading) {
		return (
			<div id="result-view">
				<div className="result-stripe vehicle-result">
					<div>0</div>
					<div>Resultados</div>
					<div>&nbsp;</div>
					<div>{"Medida: Cargando..."}</div>
				</div>
				<div className="result-products short">
					<div className="result-products-slider">
						<div className="no-results">
							<Loader msg="" />
						</div>
					</div>
				</div>
			</div>
		);
	}
	if (list == null || list.length === 0) {
		return null;
	}
	const tireDetails =
		activeProduct >= list.length
			? list[activeProduct - list.length]
			: list[activeProduct];
	const tireTags = getSizeTags(width, ratio, rim, dept, list[activeProduct]);
	return (
		<div id="result-view">
			<Helmet>
				<title>{tireTags.title}</title>
				<meta name="description" content={tireTags.description} />
				<meta property="og:title" content={tireTags.title} />
				<meta property="og:descritpion" content={tireTags.description} />
				<meta property="og:url" content={tireTags.url} />
				<meta property="og:image" content={tireTags.image} />
				<meta property="og:image:type" content="image/png" />
				<meta property="og:image:width" content="432" />
				<meta property="og:image:height" content="480" />
			</Helmet>
			<div className="result-stripe vehicle-result">
				<div>{list.length}</div>
				<div>Resultados</div>
				<div onClick={handleCloseClick}>
					<span className="text-remove"></span>
					<span className="icon-remove"></span>
				</div>
				<div>
					{"Medida " + getDept(dept) + ": " + width + "/" + ratio + "R" + rim}
				</div>
			</div>
			<div className="result-products short">
				<div className="result-products-slider">
					{renderProducts(list, activeProduct)}
				</div>
			</div>
			{renderTireDetails(tireDetails)}
		</div>
	);
}
