import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SHIP_TYPES } from "features/constants";
import { toggleShowShipAddressAdd } from "features/account/shipAddressSlice";
import Loader from "../Loader";
import CartShipAddressItem from "./CartShipAddressItem";
import { setShipAddressEdit } from "features/account/shipAddressEditSlice";
import { toggleShowShipAddressEdit } from "features/account/shipAddressEditSlice";

export default function CartShipAddress() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, list } = useSelector((state) => state.shipAddress);
	const { shipType, shipAddress, shipAddressItem } = useSelector(
		(state) => state.cart
	);
	// Handlers
	function handleAddClick() {
		dispatch(toggleShowShipAddressAdd({ isOpenAdd: false }));
	}
	function handleEditClick() {
		dispatch(setShipAddressEdit({ address: shipAddressItem }));
		dispatch(toggleShowShipAddressEdit({ isOpen: false }));
	}
	// Render Functions
	function formatAddress() {
		let result = <div>&nbsp;</div>;
		let numint = "";
		if (shipAddressItem != null) {
			if (shipAddressItem.Numero_Interior.length > 0) {
				numint = " - " + shipAddressItem.Numero_Interior;
			}
			result = (
				<div>
					{shipAddressItem.Calle +
						" " +
						shipAddressItem.Numero_Exterior +
						numint}
					<br />
					Col. {shipAddressItem.Colonia}
					<br />
					{shipAddressItem.Codigo_Postal + " " + shipAddressItem.Delegacion}
					<br />
					{shipAddressItem.Estado_Descripcion}
					<br />
					Tel. {shipAddressItem.Telefono}
				</div>
			);
		}
		return result;
	}
	function renderEditButton() {
		return (
			<div>
				<div className="button alternate" onClick={handleEditClick}>
					Editar
				</div>
			</div>
		);
	}
	function renderAddressList() {
		if (list === null) {
			return (
				<div id="address-list">
					<div>No hay direcciones de envío registradas</div>
				</div>
			);
		}
		return (
			<div id="address-list">
				<div>
					{list.map((element, index) => {
						return (
							<CartShipAddressItem
								className={
									shipAddress === index
										? "address-item selected"
										: "address-item"
								}
								index={index}
								alias={element.Alias}
								item={element}
								key={index}
							/>
						);
					})}
					<div>&nbsp;</div>
				</div>
			</div>
		);
	}
	function renderSelectedAddress() {
		if (shipAddressItem != null) {
			return (
				<div id="address-details">
					{formatAddress()}
					{renderEditButton()}
				</div>
			);
		}
		return null;
	}
	if (shipType !== SHIP_TYPES.ADDRESS) {
		return null;
	}
	if (isLoading) {
		return (
			<div id="cart-content-address">
				<Loader msg="Espere..." />
			</div>
		);
	}
	return (
		<div id="cart-content-address">
			<div id="address-add" onClick={handleAddClick}>
				<span className="add-text"></span>
				<span className="icon-add" onClick={handleAddClick}></span>
			</div>
			{renderAddressList()}
			{renderSelectedAddress()}
		</div>
	);
}
