import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import axios from "axios";
import { BASE_API_URL } from "features/constants";
import {	
	clearCartShipAddress,	
	setCartShipAddress,
} from "features/cart/cartSlice";
import { receiveShipAddress } from "./shipAddressSlice";
import { runUnauthorizedActions } from "./loginSlice";
import { setAlert, toggleShowAlert } from "features/alerts/alertsSlice";

const initialState = {
	isLoading: false,
	isOpen: false,
	address: null,
	errorMsg: null,
	selectedState: null,
	city: { Cd: "", Municipio: "" },
	loaded: false,
};

const shipAddressEdit = createSlice({
	name: "shipAddressEdit",
	initialState,
	reducers: {
		requestShipAddressEdit: (state) => {
			state.isLoading = true;
		},
		receiveShipAddressEdit: (state) => {
			state.isLoading = false;
		},
		setShipAddressEdit: (state, action) => {
			state.address = action.payload.address;
		},
		setShipAddressEditErrorMsg: (state, action) => {
			state.errorMsg = action.payload.errorMsg;
		},
		setShipAddressEditLoaded: (state, action) => {
			state.loaded = action.payload.loaded;
		},
		toggleShowShipAddressEdit: (state, action) => {
			state.isOpen = !action.payload.isOpen;
		},
		clearShipAddressEdit: () => initialState,
	},
});

export const {
	requestShipAddressEdit,
	receiveShipAddressEdit,
	setShipAddressEdit,
	setShipAddressEditErrorMsg,
	setShipAddressEditLoaded,
	toggleShowShipAddressEdit,
	clearShipAddressEdit,
} = shipAddressEdit.actions;

export const fetchShipAddressEdit = (address) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const data = JSON.stringify({
				tipo: 2,
				nuevoAlias: address.newAlias,
				alias: address.alias,
				tel: address.phone,
				calle: address.street,
				numext: address.ext,
				numint: address.int,
				cp: address.zip,
				col: address.neighborhood,
				cd: address.city,
				mpio: address.county,
				edo: address.stateId,
				rfc: "",
				rsoc: "",
				lat: "",
				lon: ""
			});
			dispatch(requestShipAddressEdit());
			const url = BASE_API_URL + "/api/Cuenta/MiCuenta/ActualizaDireccion/";
			return axios
				.post(url, data, options)
				.then((response) => {
					dispatch(receiveShipAddressEdit());
					if (response.data.Envio.length > 0) {
						dispatch(
							receiveShipAddress({ list: response.data.Envio })
						);
						const index = response.data.Envio.findIndex(
							(item) => item.Alias === address.newAlias
						);
						dispatch(
							setCartShipAddress({
								shipAddress: index,
								shipAddressItem: response.data.Envio[index],
							})
						);
					} else {
						dispatch(clearCartShipAddress());
						dispatch(receiveShipAddress({ list: null }));
					}
					dispatch(toggleShowShipAddressEdit({ isOpen: true }));
					dispatch(setShipAddressEditErrorMsg({ errorMsg: null }));
					dispatch(clearShipAddressEdit());
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
					// If 400 BadRequest model state
					if (error.response.status === 400) {
						if (error.response.data.Message != null) {
							dispatch(
								setAlert({
									title: "¡Lo sentimos!",
									description:
										"No se ha podido guardar la dirección. Verifique los datos y vuelva a intentar.",
									details: error.response.data.Message,
								})
							);
							dispatch(receiveShipAddressEdit());
							dispatch(
								setShipAddressEditErrorMsg({
									errorMsg: { error: error.response.data.Message },
								})
							);
							dispatch(toggleShowAlert({ isOpen: false }));
						}
					}
				});
		})
		.catch((err) => console.log("Error", err));
};
export default shipAddressEdit.reducer;
