import {
	IMAGE_TYPES,
	TIRE_SPECS,
	SITE_TITLE,
	SITE_HOST,
	VEHICLE_TYPES as vt,
	CFDI_TYPE,
} from "./constants";

export const isServer = !(
	typeof window !== "undefined" &&
	window.document &&
	window.document.createElement
);

export const currencyFormat = (num) => {
	return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
export const numberFormat = (num, places = 0) => {
	return num.toFixed(places).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
export const stringNumberFormat = (num, length = 11) => {
	const stringNumber = "00000000000000000000" + num;
	return stringNumber.substring(stringNumber.length - length);
};
export const validateMail = (email) => {
	return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
		email
	);
};
export const validatePwd = (pwd) => {
	return /^(?=.*[0-9])(?=.*[-_.=()[\]?/\\:,+!¡¿@#$%^&*])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9\-_.=()[\]?/\\:,+!¡¿@#$%^&*]{8,16}$/.test(
		pwd
	);
};
export const validatePhone = (phone) => {
	const cleanPhone = phone.replace(/([^0-9])/g, "");
	return /^([0-9]{9,15})\w$/.test(cleanPhone);
};
export const validateZip = (zip) => {
	return /^([0-9]{5})$/.test(zip);
};
export const validateRFC = (rfc) => {
	return /^([a-zA-Z&]{3,4})([0-9]{2})((([0]{1})([1-9]{1}))|(([1]{1})([0-2]{1})))(((([0]{1})([1-9]{1}))|(([1-2]{1})([0-9]{1})))|(([3]{1})([0-1]{1})))([a-zA-Z0-9]{3})$/.test(
		rfc
	);
};
export const validateRazonSocial = (name) => {
	const hasMatch = name.match(/,\sSA$|\sS.A.|\sDE\sCV$|\sSA$|\sDE C.V.|\sDE\sC.V|S\sDE\sRL|\sS\sDE\sRL|\sS.\sDE\sR.L.|\sDE\sRL|\sDE\sR.L|\sAC$|\sA.C.$|\sSAPI\s|\sS.A.P.I.\s|\sSC$|\sS.C.$|\sS.\sEN\sC.$|\sS\sEN\sC$/);
	return hasMatch == null;
};
export const validateInvoiceAddress = (address, regimenes, usos) => {
	if (address == null) return false;
	if (address.Alias === "Sin RFC") return true;
	if (!validateRFC(address.RFC)) return false;
	if (address.RegimenFiscal == null) return false;
	if (address.Uso == null) return null;
	const cfdiType = getCfdiType(address.RFC);
	const regime = regimenes.filter((el) => el.Id === address.RegimenFiscal);
	const uso = usos.filter((el) => el.Id === address.Uso);
	if (
		(cfdiType === CFDI_TYPE.MORAL && !regime[0].Moral) ||
		(cfdiType === CFDI_TYPE.FISICA && !regime[0].Fisica)
	) {
		return false;
	}
	if (
		(cfdiType === CFDI_TYPE.MORAL && !uso[0].Moral) ||
		(cfdiType === CFDI_TYPE.FISICA && !uso[0].Fisica)
	) {
		return false;
	}
	if (!validateRazonSocial(address.RazonSocial)) {
		return null;
	}
	return true;
};
export const getCfdiType = (rfc) => {
	if (rfc == null) return CFDI_TYPE.FISICA;
	return rfc.trim().length === 12 ? CFDI_TYPE.MORAL : CFDI_TYPE.FISICA;
};
export const mapCfdiOptions = (list, cfdiType) => {
	if (isServer) return [];
	if (list == null || cfdiType == null) return [];
	let filtered;
	switch (cfdiType) {
		case CFDI_TYPE.FISICA:
			filtered = list.filter((el) => el.Fisica === true);
			break;
		case CFDI_TYPE.MORAL:
		default:
			filtered = list.filter((el) => el.Moral === true);
			break;
	}
	return filtered.map(function (item, index) {
		return { label: item.Nombre, value: item.Id, index: index };
	});
};
export const validateCardYear = (cardYear) => {
	return /^([0]){1}([1-9]){1}|([1-9]){1}([0-9]){1}$/.test(cardYear);
};
export const validateCardMonth = (cardMonth) => {
	return /^(0){1}([1-9]){1}|(1){1}([0-2]){1}$/.test(cardMonth);
};
export const mmToIn = (mm, pos = 2) => {
	const digits = pos > 0 ? Math.pow(10, pos) : 1;
	return Math.round((mm * digits) / 25.4) / digits;
};
export const isEmpty = (obj) => {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
};
export const getPropertyArray = (array, key, filter) => {
	const filteredArray = filter != null ? array.filter(filter) : array;
	const listArray = filteredArray.map((item) => {
		return { label: item[key], value: item[key] };
	});
	const resultArray = listArray.filter(
		(item, index, self) =>
			index ===
			self.findIndex((t) => t.label === item.label && t.value === item.value)
	);
	return resultArray;
};

// Product & Tire Details
export const imageFormat = (image, type = IMAGE_TYPES.CART) => {
	switch (type) {
		case IMAGE_TYPES.CART:
		default:
			return (
				"/images/tires/cart/" + image.substring(0, image.search("[.]")) + ".png"
			);
		case IMAGE_TYPES.DETAILS:
			return (
				"/images/tires/details/" +
				image.substring(0, image.search("[.]")) +
				".png"
			);
		case IMAGE_TYPES.CAROUSEL:
			return (
				"/images/tires/carousel/" +
				image.substring(0, image.search("[.]")) +
				".png"
			);
	}
};
export const getTireTags = (tire) => {
	return {
		title: tire.TireProduct.Name + " " + tire.Size + " - " + SITE_TITLE,
		description: tire.TireProduct.Slogan,
		url:
			SITE_HOST +
			"/llanta/" +
			tire.Code +
			"/" +
			tire.TireProduct.BrandSlug +
			"/" +
			vt.properties[tire.VehicleTypeId].slug +
			"/" +
			tire.TireProduct.Slug +
			"/" +
			tire.SizeShort,
	};
};
export const getSizeTags = (width, ratio, rim, dept, tire = null) => {
	const size = width + "/" + ratio + "R" + rim;
	const type = vt.properties[dept].name;
	const img =
		tire != null ? SITE_HOST + imageFormat(tire.TireProduct.Image) : null;
	return {
		title: "Llantas " + size + " para " + type + " - " + SITE_TITLE,
		description:
			"Resultado de búsqueda de medidas para " + type + " medida: " + size,
		url: SITE_HOST + "/llantas/medida/" + vt.properties[dept].slug + "/" + size,
		image: img,
	};
};
export const getSizeSaeTags = (diameter, width, rim, dept, tire = null) => {
	const size = diameter + "X" + width + "R" + rim;
	const type = vt.properties[dept].name;
	const img =
		tire != null ? SITE_HOST + imageFormat(tire.TireProduct.Image) : null;
	return {
		title: "Llantas " + size + " para " + type + " - " + SITE_TITLE,
		description:
			"Resultado de búsqueda de medidas para " + type + " medida: " + size,
		url: SITE_HOST + "/llantas/medida/" + vt.properties[dept].slug + "/" + size,
		image: img,
	};
};

export const getBrandClass = (brand) => {
	return "brand-" + brand.toLowerCase().replace(/ /g, "");
};
export const getModalButtonClass = (disabled) => {
	if (!disabled) {
		return "modal-button";
	}
	return "modal-button disabled";
};
export const getVehicleTypeClass = (vehicleType) => {
	return "product-type-" + vehicleType.toLowerCase().replace(/ /g, "");
};
export const renderOriginalPrice = (tire, qty) => {
	if (tire.Discount > 0 && qty >= tire.Minimum) {
		return <h3>{currencyFormat(tire.ListPrice * 1.16)}</h3>;
	} else {
		return null;
	}
};
export const renderPrice = (tire, qty) => {
	if (tire.Discount > 0 && qty >= tire.Minimum) {
		return (
			<h2>
				{currencyFormat(tire.Price * 1.16)}
				<span>MXN</span>
			</h2>
		);
	} else {
		return (
			<h2>
				{currencyFormat(tire.ListPrice * 1.16)}
				<span>MXN</span>
			</h2>
		);
	}
};
export const formatDetails = (details) => {
	let sep = ["<br>", "<br />"];
	let infoArray = details.split(new RegExp(sep.join("|"), "g"));
	return (
		<div className="product-description-box">
			<h2>Características / Beneficios</h2>
			<div>
				{infoArray.map((item, index) => {
					return <p key={index}>{item}</p>;
				})}
			</div>
		</div>
	);
};
export const getDept = (deptId) => {
	let result = " para auto";
	switch (deptId) {
		case "1":
		default:
			result = " para auto";
			break;
		case "2":
			result = " para camioneta";
			break;
		case "3":
			result = " para moto";
			break;
		case "4":
			result = " para van";
			break;
	}
	return result;
};

export const renderRadial = (valor) => {
	if (valor !== "") {
		let items = TIRE_SPECS.Radial.filter(function (el) {
			return el.Key.trim() === valor;
		});
		return items.map(function (item, index) {
			return (
				<div key={index}>
					<div>Construcción Radial</div>
					<div>{item.Key}</div>
					<div>{item.Value}</div>
				</div>
			);
		});
	} else {
		return null;
	}
};
export const renderIndiceCarga = (value) => {
	if (value !== "") {
		var index = value.search("/");
		if (index === -1) {
			return (
				<div>
					<div>Índice de Carga</div>
					<div>{value}</div>
					<div></div>
				</div>
			);
		} else {
			return (
				<div>
					<div>Índice de Carga</div>
					<div>{value.substring(0, index)}</div>
					<div>{value.substring(index)}</div>
				</div>
			);
		}
	} else {
		return null;
	}
};
export const renderIndiceVelocidad = (valor) => {
	if (valor !== "") {
		let items = TIRE_SPECS.Velocidad.filter(function (el) {
			return el.Key.trim() === valor;
		});
		return items.map(function (item, index) {
			return (
				<div key={index}>
					<div>Índice de Velocidad</div>
					<div>{item.Key}</div>
					<div>{item.Value}</div>
				</div>
			);
		});
	} else {
		return null;
	}
};
export const renderSsr = (value) => {
	if (value !== "") {
		return (
			<div>
				<div>Runflat</div>
				<div>{value}</div>
				<div>Self Supporting</div>
			</div>
		);
	} else {
		return null;
	}
};
export const renderDureza = (value) => {
	if (value !== "") {
		return (
			<div>
				<div>Dureza de Piso</div>
				<div>{value.substring(0, 1)}</div>
				<div>{value}</div>
			</div>
		);
	} else {
		return null;
	}
};
export const renderCamara = (valor) => {
	if (valor !== "") {
		let items = TIRE_SPECS.Camara.filter(function (el) {
			return el.Key.trim() === valor;
		});
		return items.map(function (item, index) {
			return (
				<div key={index}>
					<div>Cámara</div>
					<div>{item.Key}</div>
					<div>{item.Value}</div>
				</div>
			);
		});
	} else {
		return null;
	}
};

// Orders
export const formatShipAddress = (address) => {
	let result = <div>&nbsp;</div>;
	let numint = "";
	if (address !== null) {
		if (address.NumInt.length > 0) {
			numint = " - " + address.NumInt;
		}
		result = (
			<div>
				{address.Calle + " " + address.NumExt + numint}
				<br />
				Col. {address.Colonia}
				<br />
				{address.CP + " " + address.Municipio}
				<br />
				{address.Estado}
				<br />
			</div>
		);
	}
	return result;
};
export const formatInvoiceAddress = (address) => {
	let result = <div>&nbsp;</div>;
	let numint = "";
	if (address !== null) {
		if (address.NumInt.length > 0) {
			numint = " - " + address.NumInt;
		}
		result = (
			<div>
				{address.RFC}
				<br />
				{address.RazonSocial}
				<br />
				{address.Calle + " " + address.NumExt + numint}
				<br />
				Col. {address.Colonia}
				<br />
				{address.CP + " " + address.Municipio}
				<br />
				{address.Estado}
				<br />
			</div>
		);
	}
	return result;
};
// Cart
export const doesDiscountAplies = (articulosDescuento, cuponDescuento) => {
	let aplicaDescuento = false;
	if (cuponDescuento.Campania.CampaniaId !== 0) {
		if (articulosDescuento >= cuponDescuento.Campania.MinProductos) {
			aplicaDescuento = true;
		}
	}
	return aplicaDescuento;
};
export const formatProductCount = (qty) => {
	if (qty === 1) {
		return qty + " Artículo";
	}
	return qty + " Artículos";
};
// OpenPay Paynet
export const openPaynetUrl = () => {
	window.open("https://www.paynet.com.mx/", "_blank");
};
// AutoSuggest
export const escapeRegexCharacters = (str) => {
	return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};
export const getMatchingItems = (state, value) => {
	let escapedValue = escapeRegexCharacters(value.trim());
	if (escapedValue === "") {
		return [];
	}
	let itemList = state.list;
	if (itemList == null) {
		return [];
	}
	let regex = new RegExp("^" + escapedValue, "i");
	return itemList.filter((item) => regex.test(item.Name));
};
export const getMatchingItemsBlank = (state, value) => {
	let escapedValue = escapeRegexCharacters(value.trim());
	let itemList = state.list;
	if (itemList == null) {
		return [];
	}
	let regex = new RegExp("^" + escapedValue, "i");
	return itemList.filter((item) => regex.test(item.Name));
};
export const getMatchingItemsYears = (state, value) => {
	let escapedValue = escapeRegexCharacters(value.trim());
	let itemList = state.list;
	if (itemList == null) {
		return [];
	}
	let regex = new RegExp("^" + escapedValue, "i");
	return itemList.filter((item) => regex.test(item.anio));
};
export const getMatchingItemsTireSize = (state, value) => {
	let escapedValue = escapeRegexCharacters(value.trim());
	let itemList = state.list;
	if (itemList == null) {
		return [];
	}
	let regex = new RegExp("^" + escapedValue, "i");
	return itemList.filter((item) => regex.test(item.Value));
};
export const getMatchingItemsZipCodes = (state, value) => {
	let escapedValue = escapeRegexCharacters(value.trim());
	if (escapedValue.length < 3) {
		return [];
	}
	let itemList = state.list;
	if (itemList == null) {
		return [];
	}
	let regex = new RegExp("^" + escapedValue, "i");
	return itemList.filter((item) => regex.test(item.Codigo));
};
export const getMatchingItemsNeighborhoods = (state, value) => {
	let escapedValue = escapeRegexCharacters(value.trim());
	let itemList = state.list;
	if (itemList == null) {
		return [];
	}
	let regex = new RegExp("^" + escapedValue, "i");
	return itemList.filter((item) => regex.test(item.Nombre));
};
export const getMatchingItemsCities = (state, value) => {
	let escapedValue = escapeRegexCharacters(value.trim());
	let itemList = state.list;
	if (itemList == null) {
		return [];
	}
	let regex = new RegExp("^" + escapedValue, "i");
	return itemList.filter((item) => regex.test(item.Cd));
};
export const getMatchingItemsCounties = (state, value) => {
	let escapedValue = escapeRegexCharacters(value.trim());
	let itemList = state.list;
	if (itemList == null) {
		return [];
	}
	let regex = new RegExp("^" + escapedValue, "i");
	return itemList.filter((item) => regex.test(item.Nombre));
};

export const getMatchingSearchItems = (state, value) => {
	let escapedValue = escapeRegexCharacters(value.trim());
	if (escapedValue === "") {
		return [];
	}
	let itemList = state.list;
	if (itemList == null) {
		return [];
	}
	let regex = new RegExp(escapedValue, "i");
	return itemList.filter((item) => regex.test(item.Name));
};

export const getMatchingItemById = (state, productId) => {
	let escapedValue = escapeRegexCharacters(productId.trim());
	if (escapedValue === "") {
		return null;
	}
	let itemList = state.list;
	if (itemList == null) {
		return [];
	}
	let regex = new RegExp(escapedValue, "i");
	let result = itemList.filter((item) => regex.test(item.Id));
	return result ? result[0] : null;
};

