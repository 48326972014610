import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchValidateCoupon, removeCoupon } from "features/cart/cartSlice";

export default function CartCoupon() {
	const dispatch = useDispatch();
	// Selectors
	const { coupon } = useSelector((state) => state.cart);
	// State
	const [couponCode, setCouponCode] = useState("");
	// Handlers
	function handleClick() {
		if (couponCode !== "") {
			dispatch(fetchValidateCoupon(couponCode));
		}
	}
	function handleRemove() {
		dispatch(removeCoupon());
	}
	function handleFocus(e) {
		e.target.select();
	}
	function handleKeyPress(e) {
		if (e.key === "Enter") {
			handleClick();
		}
	}
	function handleChange(e) {
		setCouponCode(e.target.value);
	}
	// Render
	if (coupon != null) {
		return (
			<div id="cart-coupon">
				<p>Cupón de Descuento: {coupon.Code}</p>
				<p>{coupon.Campaign}</p>
				<p>Capacidad: {coupon.MaxProducts} productos</p>
				<div className="right">
					<div className="modal-button" onClick={handleRemove}>
						Quitar
					</div>
				</div>
			</div>
		);
	}
	return (
		<div id="cart-coupon">
			<p>Cupón de Descuento</p>
			<p>
				<input
					type="text"
					id="csCouponCode"
					className="modal-field"
					placeholder="Código"
					onChange={handleChange}
					onFocus={handleFocus}
					onKeyPress={handleKeyPress}
				/>
			</p>
			<div className="right">
				<div className="modal-button" onClick={handleClick}>
					Cargar
				</div>
			</div>
		</div>
	);
}
