import { combineReducers } from "redux";
import { sessionReducer } from "redux-react-session";
import { connectRouter } from "connected-react-router";

import accountReducer from "./account/accountSlice";
import accountCardAddReducer from "./account/accountCardAddSlice";
import accountCardEditReducer from "./account/accountCardEditSlice";
import accountCardsReducer from "./account/accountCardsSlice";
import accountEditReducer from "./account/accountEditSlice";
import activationReducer from "./account/activationSlice";
import alertReducer from "./alerts/alertsSlice";
import bannersReducer from "./banners/bannersSlice";
import cartReducer from "./cart/cartSlice";
import cfdiRegimeReducer from "./cfdi/cfdiRegimeSlice";
import cfdiUsoReducer from "./cfdi/cfdiUsoSlice";
import citiesReducer from "./account/citiesSlice";
import codeTiresReducer from "./tireResults/codeTiresSlice";
import contactReducer from "./contact/contactSlice";
import countiesReducer from "./account/countiesSlice";
import dealerShipAddressReducer from "./cart/dealerShipAddressSlice";
import footerReducer from "./account/footerSlice";
import highlightsTiresReducer from "./tireResults/highlightsTiresSlice";
import invoiceAddressReducer from "./account/invoiceAddressSlice";
import invoiceAddressEditReducer from "./account/invoiceAddressEditSlice";
import loginReducer from "./account/loginSlice";
import neighborhoodsReducer from "./account/neighborhoodsSlice";
import ordersReducer from "./orders/ordersSlice";
import orderConfirmationReducer from "./orders/orderConfirmationSlice";
import paymentCardReducer from "./cart/paymentCardSlice";
import registerReducer from "./account/registerSlice";
import resetPasswordReducer from "./account/resetPasswordSlice";
import searchReducer from "./tireFinder/searchSlice";
import searchTiresReducer from "./tireResults/searchTiresSlice";
import sendConfirmationReducer from "./account/confirmationSlice";
import shipAddressReducer from "./account/shipAddressSlice";
import shipAddressEditReducer from "./account/shipAddressEditSlice";
import sizeCanvasReducer from "./tireFinder/sizeCanvasSlice";
import sizeWidthDiametersReducer from "./tireFinder/sizeWidthDiametersSlice";
import sizeRatiosReducer from "./tireFinder/sizeRatiosSlice";
import sizeRimsReducer from "./tireFinder/sizeRimsSlice";
import sizeTiresReducer from "./tireResults/sizeTiresSlice";
import sizeSaeWidthsReducer from "./tireFinder/sizeSaeWidthsSlice";
import sizeSaeRimsReducer from "./tireFinder/sizeSaeRimsSlice";
import sizeSaeTiresReducer from "./tireResults/sizeSaeTiresSlice";
import statesReducer from "./account/statesSlice";
import vehicleTypeReducer from "./tireFinder/vehicleTypeSlice";
import vehicleTiresReducer from "./tireResults/vehicleTiresSlice";
import vehicleBrandsReducer from "./tireFinder/vehicleBrandsSlice";
import vehicleModelsReducer from "./tireFinder/vehicleModelsSlice";
import vehicleYearsReducer from "./tireFinder/vehicleYearsSlice";
import vehicleVersionsReducer from "./tireFinder/vehicleVersionsSlice";
import zipCodesReducer from "./account/zipCodesSlice";

const createRootReducer = (history) =>
	combineReducers({
		account: accountReducer,
		accountCardAdd: accountCardAddReducer,
		accountCardEdit: accountCardEditReducer,
		accountCards: accountCardsReducer,
		accountEdit: accountEditReducer,
		activation: activationReducer,
		alert: alertReducer,
		banners: bannersReducer,
		cart: cartReducer,
		cfdiRegime: cfdiRegimeReducer,
		cfdiUso: cfdiUsoReducer,
		cities: citiesReducer,
		codeTires: codeTiresReducer,
		contact: contactReducer,
		counties: countiesReducer,
		dealerShipAddress: dealerShipAddressReducer,
		footer: footerReducer,
		highlightsTires: highlightsTiresReducer,
		invoiceAddress: invoiceAddressReducer,
		invoiceAddressEdit: invoiceAddressEditReducer,
		login: loginReducer,
		neighborhoods: neighborhoodsReducer,
		orders: ordersReducer,
		orderConfirmation: orderConfirmationReducer,
		paymentCard: paymentCardReducer,
		register: registerReducer,
		resetPassword: resetPasswordReducer,
		router: connectRouter(history),
		search: searchReducer,
		searchTires: searchTiresReducer,
		sendConfirmation: sendConfirmationReducer,
		session: sessionReducer,
		shipAddress: shipAddressReducer,
		shipAddressEdit: shipAddressEditReducer,
		sizeCanvas: sizeCanvasReducer,
		sizeWidthDiameters: sizeWidthDiametersReducer,
		sizeRatios: sizeRatiosReducer,
		sizeRims: sizeRimsReducer,
		sizeTires: sizeTiresReducer,
		sizeSaeWidths: sizeSaeWidthsReducer,
		sizeSaeRims: sizeSaeRimsReducer,
		sizeSaeTires: sizeSaeTiresReducer,
		states: statesReducer,
		vehicleType: vehicleTypeReducer,
		vehicleTires: vehicleTiresReducer,
		vehicleBrands: vehicleBrandsReducer,
		vehicleModels: vehicleModelsReducer,
		vehicleYears: vehicleYearsReducer,
		vehicleVersions: vehicleVersionsReducer,
		zipCodes: zipCodesReducer,
	});
export default createRootReducer;
