import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_API_URL } from "features/constants";

const initialState = {
	isLoading: false,
	list: null,
};

export const cfdiUso = createSlice({
	name: "cfdiUso",
	initialState,
	reducers: {
		requestCfdiUsoList: (state) => {
			state.isLoading = true;
		},
		receiveCfdiUsoList: (state, action) => {
			state.list = action.payload.list;
			state.isLoading = false;
		},
	},
});

export const { requestCfdiUsoList, receiveCfdiUsoList } =
	cfdiUso.actions;

export const fetchCfdiUsoList = () => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	const options = {
		headers: headers,
	};
	dispatch(requestCfdiUsoList());
	const url = BASE_API_URL + "/api/Cfdi/Usos";
	await axios
		.get(url, options)
		.then((response) => {
			dispatch(receiveCfdiUsoList({ list: response.data }));
		})
		.catch((error) => {
			dispatch(receiveCfdiUsoList({ list: [] }));
		});
};

export default cfdiUso.reducer;
