import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import ReactGA from "react-ga4";
import {
	SHIP_TYPES as s,
	PAYMENT_METHODS as p,
	ORDER_STATUS_TYPES as o,
} from "features/constants";
import { currencyFormat } from "features/functions";
import { gtmTriggerOrderConfirmation } from "features/tagmanager";
import PaynetOrderConfirmation from "./PaynetOrderConfirmation";

export default function OrderConfirmation() {
	const dispatch = useDispatch();
	// Selectors
	const { order } = useSelector((state) => state.orderConfirmation);
	// Handlers
	const purchaseLog = () => {
		const orderItems = [];
		order.Elementos.forEach((el) => {
			orderItems.push({
				item_id: el.Codigo,
				item_name: el.Nombre,
				item_brand: el.Marca,
				item_category: "Llantas/" + el.Tipo,
				item_variant: el.Medida,
				price: el.Precio,
				quantity: el.Cantidad,
			});
		});
		ReactGA.gtag("event", "purchase", {
			transaction_id: order.Numero,
			value: order.Importe,
			tax: order.IVA,
			shipping: order.Envio,
			currency: "MXN",
			items: orderItems,
		});
	}
	// Render functions
	function renderOk() {
		return (
			<div className="order-main">
				<h3>{order.Nombre}</h3>
				<p>
					Tu pedido ha sido completado satisfactoriamente, los datos de tu
					compra son:
				</p>
				<div className="order-details">
					<div>
						<div>Número de Pedido:</div>
						<div>
							<strong>{order.Numero}</strong>
						</div>
					</div>
					<div>
						<div>Fecha y hora:</div>
						<div>{format(parseISO(order.Fecha), "dd/MMM/yyyy hh:mm aaaa")}</div>
					</div>
					<div>
						<div>Importe:</div>
						<div>{currencyFormat(order.Total)}</div>
					</div>
					<div>
						<div>Estatus</div>
						<div>{o.properties[order.Estatus].name}</div>
					</div>
					<div>
						<div>Articulos:</div>
						<div>{order.Articulos}</div>
					</div>
					<div>
						<div>Forma de Pago:</div>
						<div>{p.properties[order.MetodoPago].name}</div>
					</div>
					<div>
						<div>Envío:</div>
						<div>{s.properties[order.TipoEnvio].fullname}</div>
					</div>
				</div>
				<p>Conserve todos estos datos para cualquier aclaración.</p>
			</div>
		);
	}
	function renderNO() {
		return (
			<div className="order-main">
				<h3>{order.Nombre}</h3>
				<p>Lo sentimos, pero tu pedido No se ha sido podido completar.</p>
				<p>Los detalles de tu compra son:</p>
				<div className="order-details">
					<div>
						<div>Fecha y hora:</div>
						<div>{format(parseISO(order.Fecha), "dd/MMM/yyyy hh:mm aaaa")}</div>
					</div>
					<div>
						<div>Importe:</div>
						<div>{currencyFormat(order.Total)}</div>
					</div>
					<div>
						<div>Estatus</div>
						<div>{o.properties[order.Estatus].name}</div>
					</div>
					<div>
						<div>Articulos:</div>
						<div>{order.Articulos}</div>
					</div>
					<div>
						<div>Forma de Pago:</div>
						<div>{p.properties[order.MetodoPago].name}</div>
					</div>
					<div>
						<div>Envío:</div>
						<div>{s.properties[order.TipoEnvio].fullname}</div>
					</div>
				</div>
				<p>Conserve todos estos datos para cualquier aclaración.</p>
			</div>
		);
	}
	// Render
	if (order == null) {
		return null;
	}
	// Render Paynet Order Confirmation
	if (order.Estatus === o.RESERVE && order.MetodoPago === p.PAYNET) {
		purchaseLog();
		dispatch(gtmTriggerOrderConfirmation(order));
		return <PaynetOrderConfirmation />;
	}
	// Render Order Confirmation Ok
	if (order.Estatus === o.VALIDATION) {
		purchaseLog();
		dispatch(gtmTriggerOrderConfirmation(order));
		return renderOk();
	}
	return renderNO();
}
