import React from "react";

export default function Loader(props) {
	const { msg } = props;
	return (
		<div className="loader">
			<div></div>
			<div>{msg}</div>
		</div>
	);
}
