import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import qs from "qs";
import Scroll from "react-scroll";
import { mmToIn } from "features/functions";
import { isServer } from "features/functions";
import ReactGA from "react-ga4";
import { BASE_API_URL, VEHICLE_TYPES as v } from "../constants";
import { hideHighlightsTiresList } from "./highlightsTiresSlice";
import { setVehicleType } from "features/tireFinder/vehicleTypeSlice";

const initialState = {
	isLoading: false,
	list: null,
	diameter: null,
	width: null,
	rim: null,
	dept: 0,
	scroll: false,
};
const sizeSaeTires = createSlice({
	name: "sizeSaeTires",
	initialState,
	reducers: {
		requestSizeSaeTiresList(state) {
			state.isLoading = true;
		},
		receiveSizeSaeTiresList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
			state.diameter = action.payload.diameter;
			state.width = action.payload.width;
			state.rim = action.payload.rim;
			state.dept = action.payload.dept;
		},
		setSizeSaeTiresScroll(state, action) {
			state.scroll = action.payload.scroll;
		},
		clearSizeSaeTiresList(state) {
			state.list = null;
			state.diameter = null;
			state.width = null;
			state.rim = null;
		},
		clearSizeSaeTiresData: () => initialState,
	},
});

export const {
	requestSizeSaeTiresList,
	receiveSizeSaeTiresList,
	setSizeSaeTiresScroll,
	clearSizeSaeTiresList,
	clearSizeSaeTiresData,
} = sizeSaeTires.actions;

const shouldFetchSizeSaeTiresList = (state, newRim) => {
	if (state.sizeSaeTires.list == null) {
		return true;
	}
	const oldRim = state.sizeSaeRims.selectedItem;
	if (oldRim !== newRim) {
		return true;
	}
	return false;
};

export const fetchSizeSaeTiresListIfNeeded =
	(selectedRim) => async (dispatch, getState) => {
		if (shouldFetchSizeSaeTiresList(getState(), selectedRim)) {
			const theState = getState();
			return dispatch(
				fetchSizeSaeTiresList(
					theState.vehicleType.value,
					theState.sizeWidthDiameters.selectedItem,
					theState.sizeSaeWidths.selectedItem,
					selectedRim.Value
				)
			);
		}
	};

export const fetchSizeSaeTiresList =
	(dept, diameter, width, rim, brand = 0) =>
	async (dispatch, getState) => {
		const headers = {
			"Content-Type": "application/json",
		};
		const options = {
			headers: headers,
		};
		const theState = getState();
		const coupon =
			theState.cart.coupon == null ? null : theState.cart.coupon.Code;
		dispatch(requestSizeSaeTiresList());
		const url =
			coupon != null
				? BASE_API_URL +
				  "/api/Productos/Buscar/SAE/?diametro=" +
				  diameter.OriginalValue +
				  "&anchoseccion=" +
				  width.OriginalValue +
				  "&rin=" +
				  rim +
				  "&depto=" +
				  dept +
				  "&marca=" +
				  brand +
				  "&cupon=" +
				  coupon
				: BASE_API_URL +
				  "/api/Productos/Buscar/SAE/?diametro=" +
				  diameter.OriginalValue +
				  "&anchoseccion=" +
				  width.OriginalValue +
				  "&rin=" +
				  rim +
				  "&depto=" +
				  dept +
				  "&marca=0";
		return axios
			.get(url, options)
			.then((response) => {
				ReactGA.send({
					hitType: "pageview",
					page:
						"/llantas/medida/" +
						v.properties[dept].slug +
						"/" +
						encodeURIComponent(diameter.Value + "X" + width.Value + "R" + rim),
				});
				dispatch(
					receiveSizeSaeTiresList({
						list: response.data,
						diameter: diameter.Value,
						width: width.Value,
						rim,
						dept,
					})
				);
			})
			.catch((error) => {
				// TODO: fetch error actions
			});
	};
export const fetchSizeSaeStringTireList =
	(vehicleType, size, brand = 0) =>
	async (dispatch, getState) => {
		const headers = isServer
			? {
					"Content-Type": "application/json",
					Origin: [process.env.RAZZLE_SRV_HOST],
			  }
			: {
					"Content-Type": "application/json",
			  };
		const options = {
			headers: headers,
		};
		const theState = getState();
		const coupon =
			theState.cart.coupon == null ? "0" : theState.cart.coupon.Code;
		const vType = Object.keys(v.properties).filter(
			(item) => v.properties[item]["slug"] === vehicleType
		);
		const queryString = {
			depto: vType[0],
			medida: size,
			marca: brand,
			cupon: coupon,
		};
		const url =
			BASE_API_URL +
			"/api/Productos/BuscarMedida?" +
			qs.stringify(queryString, { encodeValuesOnly: true });
		await axios
			.get(url, options)
			.then((response) => {
				const list = response.data;
				if (list != null && list.length > 0) {
					dispatch(
						receiveSizeSaeTiresList({
							list,
							dept: vType[0],
							diameter: mmToIn(list[0].Diameter, 1),
							width: mmToIn(list[0].Width),
							rim: list[0].Rim,
						})
					);
					dispatch(hideHighlightsTiresList({ hide: true }));
					dispatch(setVehicleType({ value: vType[0] }));
					dispatch(setSizeSaeTiresScroll({ scroll: true }));
				}
			})
			.catch((error) => {
				console.log("Error", error);
			});
	};
export const scrollToSizeSaeTires = () => async (dispatch, getState) => {
	const theState = getState();
	if (theState.sizeSaeTires.list != null && theState.sizeSaeTires.scroll) {
		let scroll = Scroll.animateScroll;
		let target = document.getElementById("resultCarousel");
		let targetPos =
			target.offsetWidth > 980 ? target.offsetTop - 100 : target.offsetTop - 50;
		scroll.scrollTo(targetPos, { smooth: true, delay: 100, duration: 500 });
		dispatch(setSizeSaeTiresScroll({ scroll: false }));
	}
};

export default sizeSaeTires.reducer;
