import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_API_URL } from "../constants";

const initialState = {
	isLoading: false,
	list: null,
	options: null,
};

const states = createSlice({
	name: "states",
	initialState,
	reducers: {
		requestStatesList(state) {
			state.isLoading = true;
		},
		receiveStatesList(state, action) {
			state.list = action.payload.list;
			state.isLoading = false;
		},
		setStatesOptions(state, action) {
			state.options = action.payload.options;
		},
	},
});

export const { requestStatesList, receiveStatesList, setStatesOptions } =
	states.actions;

const mapOptions = (list) => {
	return list.map(function (item, index) {
		return { label: item.Nombre, value: item.Id, index: index };
	});
};

const shouldFetchStates = (state) => {
	const list = state.states.list;
	if (!list) {
		return true;
	} else {
		return false;
	}
};
export const fetchStatesIfNeeded = () => async (dispatch, getState) => {
	if (shouldFetchStates(getState())) {
		return dispatch(fetchStates());
	}
};

export const fetchStates = () => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	const options = {
		// method: "GET",
		headers: headers,
	};
	dispatch(requestStatesList());
	const url = BASE_API_URL + "/api/Cuenta/Estados";
	await axios
		.get(url, options)
		.then((response) => {
			dispatch(receiveStatesList({ list: response.data }));
			if (response.data != null && response.data.length > 0) {
				dispatch(setStatesOptions({ options: mapOptions(response.data) }));
			}
		})
		.then((error) => {
			// If 401 Unauthorized login failed
		});
	// return fetch(BASE_API_URL + "/api/Cuenta/Estados", options)
	// 	.then((response) => {
	// 		if (response.ok) {
	// 			return response.json();
	// 		} else {
	// 			return response.text().then((error) => Promise.reject(error));
	// 		}
	// 	})
	// 	.then((json) => {
	// 		dispatch(receiveStatesList({ list: json }));
	// 		if (json != null && json.length > 0) {
	// 			dispatch(setStatesOptions({ options: mapOptions(json) }));
	// 		}
	// 	})
	// 	.catch(() => {
	// 		// If 401 Unauthorized login failed
	// 	});
};

export default states.reducer;
