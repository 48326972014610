import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import axios from "axios";
import { BASE_API_URL } from "features/constants";
import {
	clearCartInvoiceAddress,
	setCartInvoiceAddress,
} from "features/cart/cartSlice";
import { receiveInvoiceAddress } from "./invoiceAddressSlice";
import { runUnauthorizedActions } from "./loginSlice";
import { setAlert, toggleShowAlert } from "features/alerts/alertsSlice";

const initialState = {
	isLoading: false,
	isOpen: false,
	address: null,
	errorMsg: null,
	selectedState: null,
	city: { Cd: "", Municipio: "" },
	loaded: false,
};

const invoiceAddressEdit = createSlice({
	name: "invoiceAddressEdit",
	initialState,
	reducers: {
		requestInvoiceAddressEdit: (state) => {
			state.isLoading = true;
		},
		receiveInvoiceAddressEdit: (state) => {
			state.isLoading = false;
		},
		setInvoiceAddressEdit: (state, action) => {
			state.address = action.payload.address;
		},
		setInvoiceAddressEditErrorMsg: (state, action) => {
			state.errorMsg = action.payload.errorMsg;
		},
		setInvoiceAddressEditLoaded: (state, action) => {
			state.loaded = action.payload.loaded;
		},
		toggleShowInvoiceAddressEdit: (state, action) => {
			state.isOpen = !action.payload.isOpen;
		},
		clearInvoiceAddressEdit: () => initialState,
	},
});

export const {
	requestInvoiceAddressEdit,
	receiveInvoiceAddressEdit,
	setInvoiceAddressEdit,
	setInvoiceAddressEditErrorMsg,
	setInvoiceAddressEditLoaded,
	toggleShowInvoiceAddressEdit,
	clearInvoiceAddressEdit,
} = invoiceAddressEdit.actions;

export const fetchInvoiceAddressEdit = (address) => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const data = JSON.stringify({
				tipo: 3,
				nuevoAlias: address.newAlias,
				alias: address.alias,
				calle: address.street,
				numext: address.ext,
				numint: address.int,
				cp: address.zip,
				col: address.neighborhood,
				cd: address.city,
				mpio: address.county,
				edo: address.stateId,
				rfc: address.rfc,
				rsoc: address.name,
				lat: "",
				lon: "",
				regimen: address.regime,
				uso: address.uso,
			});
			dispatch(requestInvoiceAddressEdit());
			const url = BASE_API_URL + "/api/Cuenta/MiCuenta/ActualizaDireccion/";
			return axios
				.post(url, data, options)
				.then((response) => {
					dispatch(receiveInvoiceAddressEdit());
					if (response.data.Facturacion.length > 0) {
						dispatch(
							receiveInvoiceAddress({ list: response.data.Facturacion })
						);
						const index = response.data.Facturacion.findIndex(
							(item) => item.Alias === address.newAlias
						);
						dispatch(
							setCartInvoiceAddress({
								invoiceAddress: index,
								invoiceAddressItem: response.data.Facturacion[index],
							})
						);
					} else {
						dispatch(clearCartInvoiceAddress());
						dispatch(receiveInvoiceAddress({ list: null }));
					}
					dispatch(toggleShowInvoiceAddressEdit({ isOpen: true }));
					dispatch(setInvoiceAddressEditErrorMsg({ errorMsg: null }));
					dispatch(clearInvoiceAddressEdit());
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
					// If 400 BadRequest model state
					if (error.response.status === 400) {
						if (error.response.data.Message != null) {
							dispatch(
								setAlert({
									title: "¡Lo sentimos!",
									description:
										"No se ha podido guardar la dirección. Verifique los datos y vuelva a intentar.",
									details: error.response.data.Message,
								})
							);
							dispatch(receiveInvoiceAddressEdit());
							dispatch(
								setInvoiceAddressEditErrorMsg({
									errorMsg: { error: error.response.data.Message },
								})
							);
							dispatch(toggleShowAlert({ isOpen: false }));
						}
					}
				});
		})
		.catch((err) => console.log("Error", err));
};
export default invoiceAddressEdit.reducer;
