import React from "react";
import Scroll from "react-scroll";
import BannerSlider from "../slider/BannerSlider";
import TireFinder from "../tireFinder/TireFinder";
import Highlights from "../tireResults/Highlights";
import ResultProduct from "../tireResults/ResultProduct";
import ResultVehicle from "../tireResults/ResultVehicle";
import ResultSize from "../tireResults/ResultSize";
import ResultSizeSae from "../tireResults/ResultSizeSae";
import ResultCode from "../tireResults/ResultCode";
import HomeDealers from "./HomeDealers";
import HomeFooter from "./HomeFooter";

var Element = Scroll.Element;

export default function HomeContent() {
	return (
		<div className="contenido">
			<BannerSlider />
			<Element
				name="tireFinderElement"
				id="tireFinderElement"
				className="element"
			></Element>
			<TireFinder />
			<Element
				name="resultCarousel"
				id="resultCarousel"
				className="element"
			></Element>
			<Highlights />
			<ResultProduct />
			<ResultVehicle />
			<ResultSize />
			<ResultSizeSae />
			<ResultCode />
			<HomeDealers />
			<HomeFooter />
		</div>
	);
}
