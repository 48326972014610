import { createSlice } from "@reduxjs/toolkit";
import { BASE_API_URL } from "../constants";

const initialState = {
	isLoading: false,
	list: null,
	hide: false,
};
const searchTires = createSlice({
	name: "searchTires",
	initialState,
	reducers: {
		requestSearchResultsTiresList(state) {
			state.isLoading = true;
		},
		receiveSearchResultsTiresList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		clearSearchResultsTiresList(state) {
			state.list = null;
		},
		hideSearchResultsList(state, action) {
			state.hide = action.payload.hide;
		},
		clearSearchResultData: () => initialState,
	},
});

export const {
	requestSearchResultsTiresList,
	receiveSearchResultsTiresList,
	clearSearchResultsTiresList,
	hideSearchResultsList,
	clearSearchResultData,
} = searchTires.actions;

const shouldFetchSearchResultsTiresList = (state) => {
	const tires = state.searchTires;
	const search = state.search;
	if (tires.list == null) {
		return true;
	}
	if (tires.list.Id !== search.selectedItem.Id) {
		return true;
	}
	return false;
};

export const fetchSearchResultsTireListIfNeeded =
	() => async (dispatch, getState) => {
		const theState = getState();
		if (shouldFetchSearchResultsTiresList(theState)) {
			return dispatch(
				fetchSearchResultsTiresList(theState.search.selectedItem.Id)
			);
		}
	};

export const fetchSearchResultsTiresList =
	(id) => async (dispatch, getState) => {
		const headers = {
			"Content-Type": "application/json",
		};
		const options = {
			method: "GET",
			headers: headers,
		};
		const theState = getState();
		const coupon =
			theState.cart.coupon == null ? null : theState.cart.coupon.Code;
		dispatch(requestSearchResultsTiresList());
		const url =
			coupon != null
				? BASE_API_URL + "/api/Productos/?id=" + id + "&cupon=" + coupon
				: BASE_API_URL + "/api/Productos/?id=" + id;
		return fetch(url, options)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					return response.text().then((error) => Promise.reject(error));
				}
			})
			.then((json) => {
				dispatch(receiveSearchResultsTiresList({ list: json }));
			})
			.catch(() => {
				// TODO: fetch error actions
			});
	};

export default searchTires.reducer;
