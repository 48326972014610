import React, { useState } from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import {
	toggleShowInvoiceAddress,
	toggleShowInvoiceAddressAdd,
} from "features/account/invoiceAddressSlice";
import Loader from "components/Loader";
import AddressItem from "./AddressItem";
import { setInvoiceAddressEdit } from "features/account/invoiceAddressEditSlice";
import { toggleShowInvoiceAddressEdit } from "features/account/invoiceAddressEditSlice";

export default function InvoiceAddressModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, isOpen, list } = useSelector(
		(state) => state.invoiceAddress
	);
	const regimeList = useSelector((state) => state.cfdiRegime.list);
	const usoList = useSelector((state) => state.cfdiUso.list);
	// State
	const [selectedIndex, setSelectedIndex] = useState(0);
	// Handlers
	function handleCloseClick() {
		setSelectedIndex(0);
		dispatch(toggleShowInvoiceAddress({ isOpen: true }));
	}
	function handleSelect(index) {
		setSelectedIndex(index);
	}
	function handleAddClick() {
		dispatch(toggleShowInvoiceAddressAdd({ isOpenAdd: false }));
	}
	function handleEditClick() {
		const selectedItem = list[selectedIndex];
		dispatch(setInvoiceAddressEdit({ address: selectedItem }));
		dispatch(toggleShowInvoiceAddressEdit({ isOpen: false }));
	}
	// Render Functions
	function formatAddress() {
		const selectedItem = list[selectedIndex];
		let result = <div>&nbsp;</div>;
		let numint = "";
		if (selectedItem != null) {
			const cssAlert = { color: "red" };
			const cssTag = {
				color: "orange",
				fontWeight: "400",
				fontStyle: "normal",
			};
			const addressRegime =
				selectedItem.RegimenFiscal != null &&
				selectedItem.RegimenFiscal !== "" ? (
					regimeList.filter(
						(el) => el.Id === selectedItem.RegimenFiscal
					)[0].Nombre
				) : (
					<span style={cssAlert}>N/A</span>
				);
			const addressUso =
				selectedItem.Uso != null && selectedItem.Uso !== "" ? (
					usoList.filter((el) => el.Id === selectedItem.Uso)[0].Nombre
				) : (
					<span style={cssAlert}>N/A</span>
				);
			if (selectedItem !== null) {
				if (selectedItem.Numero_Interior.length > 0) {
					numint = " - " + selectedItem.Numero_Interior;
				}
				result = (
					<div>
						{selectedItem.RFC}
						<br />
						{selectedItem.RazonSocial}
						<br />
						{selectedItem.Calle +
							" " +
							selectedItem.Numero_Exterior +
							numint}
						<br />
						Col. {selectedItem.Colonia}
						<br />
						{selectedItem.Codigo_Postal +
							" " +
							selectedItem.Delegacion}
						<br />
						{selectedItem.Estado_Descripcion}
						<br />
						<br />
						<span style={cssTag}>Régimen Fiscal:</span> {addressRegime}
						<br />
						<span style={cssTag}>Uso de CFDI:</span> {addressUso}
					</div>
				);
			}
			return result;
		}
		return null;
	}
	function renderAddressList() {
		if (list === null) {
			return (
				<div id="address-list">
					<div>No hay direcciones de facturación registradas</div>
				</div>
			);
		}
		return (
			<div id="address-list">
				<div>
					{list.map((element, index) => {
						return (
							<AddressItem
								className={
									selectedIndex === index
										? "address-item selected"
										: "address-item"
								}
								index={index}
								alias={element.Alias}
								item={element}
								key={index}
								onClick={handleSelect}
							/>
						);
					})}
					<div>&nbsp;</div>
				</div>
			</div>
		);
	}
	function renderIncomplete() {
		const selectedItem = list[selectedIndex];
		if (
			selectedItem.RegimenFiscal == null ||
			selectedItem.Uso == null
		) {
			return (
				<div className="alert-box" style={{ textAlign: "left" }}>
					<span className="close-box"></span>Los datos fiscales están
					incompletos. Por favor complete la información faltante para poder
					utilizar estos datos.
				</div>
			);
		}
	}
	function renderEditButton() {
		if (selectedIndex != 0)
		return (
			<div>
				<div className="button alternate" onClick={handleEditClick}>
					Editar
				</div>
			</div>
		);
	}
	function renderSelectedAddress() {
		if (list != null && list.length > 0) {
			return (
				<div id="address-details">
					{formatAddress()}
					{renderIncomplete()}
					{renderEditButton()}
				</div>
			);
		}
		return null;
	}
	function renderLoader() {
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	}
	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal"
				overlayClassName="modal-overlay"
				onRequestClose={handleCloseClick}
				contentLabel="Direcciones de Facturación"
			>
				<div className="modal-inner" id="cart-inner">
					<div className="modal-close" onClick={handleCloseClick}></div>
					<h2>Direcciones de Facturación</h2>
					<div className="history-content">
						<div id="cart-content-address">
							<div id="address-add" onClick={handleAddClick}>
								<span className="add-text"></span>
								<span className="icon-add" onClick={handleAddClick}></span>
							</div>
							{renderAddressList()}
							{renderSelectedAddress()}
						</div>
						{renderLoader()}
					</div>
				</div>
			</Modal>
		</div>
	);
}
