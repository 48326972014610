import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Home from "components/home/Home";
import { runAuthenticatedActions } from "features/account/loginSlice";
import { fetchSearch } from "features/tireFinder/searchSlice";
import { hideHighlightsTiresList } from "features/tireResults/highlightsTiresSlice";
import {
	setOrderConfirmationId,
	fetchOrderConfirmation,
} from "features/orders/orderConfirmationSlice";
import { fetchVehicleBrandsIfNeeded } from "features/tireFinder/vehicleBrandsSlice";
import { fetchWidthDiametersIfNeeded } from "features/tireFinder/sizeWidthDiametersSlice";
import { fetchHighlightsTiresListIfNeeded } from "features/tireResults/highlightsTiresSlice";
import { fetchStatesIfNeeded } from "features/account/statesSlice";
import { fetchSearchIfNeeded } from "features/tireFinder/searchSlice";
import { fetchBannersListIfNeeded } from "features/banners/bannersSlice";
import { fetchCodeTiresListIfNeeded } from "features/tireResults/codeTiresSlice";
import { fetchCartObjectIfNeeded } from "features/cart/cartSlice";
import { fetchCfdiRegimeList } from "features/cfdi/cfdiRegimeSlice";
import { fetchCfdiUsoList } from "features/cfdi/cfdiUsoSlice";

function App(props) {
	const dispatch = useDispatch();
	// Selectors
	const { authenticated, checked } = useSelector((state) => state.session);
	// On load
	useEffect(() => {
		if (checked) {
			dispatch(fetchVehicleBrandsIfNeeded());
			dispatch(fetchWidthDiametersIfNeeded());
			dispatch(fetchHighlightsTiresListIfNeeded());
			dispatch(fetchStatesIfNeeded());
			dispatch(fetchSearchIfNeeded());
			dispatch(fetchBannersListIfNeeded());
			dispatch(fetchCartObjectIfNeeded());
			dispatch(fetchCfdiRegimeList());
			dispatch(fetchCfdiUsoList());
			if (authenticated) {
				dispatch(runAuthenticatedActions());
			}
		}
		const { params } = props.match;
		if (params != null) {
			if (params.productId != null) {
				dispatch(fetchSearch(params.productId));
				dispatch(hideHighlightsTiresList({ hide: true }));
			}
			if (params.productcode != null) {
				dispatch(fetchCodeTiresListIfNeeded(params.productcode));
				dispatch(hideHighlightsTiresList({ hide: true }));
			}
			if (params.orderId != null) {
				dispatch(setOrderConfirmationId({ orderId: params.orderId }));
				dispatch(fetchOrderConfirmation());
			}
		}
	}, [dispatch, props.match, checked, authenticated]);
	return <Home />;
}

export default App;
