import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import {
	fetchCardDelete,
	toggleShowDeleteCard,
} from "features/account/accountCardEditSlice";
import { setSelectedCard } from "features/account/accountCardsSlice";
import { stringNumberFormat } from "features/functions";
import Loader from "../Loader";

export default function AccountCardDelete() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, isOpenDelete } = useSelector(
		(state) => state.accountCardEdit
	);
	const { selectedCard } = useSelector((state) => state.accountCards);
	// Handlers
	function handleYesClick() {
		dispatch(fetchCardDelete(selectedCard.Id));
	}
	function handleNoClick() {
		dispatch(setSelectedCard({ selectedCard: null }));
		dispatch(toggleShowDeleteCard({ isOpenDelete }));
	}
	// Render
	function renderLoader() {
		if (isLoading) {
			return <Loader msg="Espere..." />;
		}
		return null;
	}
	function renderContent() {
		if (!isLoading && selectedCard != null) {
			return (
				<div className="alert-main">
					<div>¿Estás seguro de querer eliminar la siguiente tarjeta?</div>
					<div>
						<div style={{ fontWeight: "400", fontStyle: "italic" }}>
							<p>
							{selectedCard.Marca.toUpperCase() +
								" ***" +
								selectedCard.Numero +
								" - " +
								stringNumberFormat(selectedCard.Mes, 2) +
								"/" +
								stringNumberFormat(selectedCard.Anio, 2)}
								</p>
						</div>
					</div>
					<div
						style={{ minWidth: "100px", marginRight: "20px" }}
						className="modal-button"
						onClick={handleNoClick}
					>
						Cancelar
					</div>
					<div
						style={{ minWidth: "100px" }}
						className="modal-button alternate"
						onClick={handleYesClick}
					>
						Eliminar
					</div>
				</div>
			);
		}
		return null;
	}
	return (
		<div>
			<Modal
				isOpen={isOpenDelete}
				className="modal small"
				overlayClassName="modal-overlay"
				onRequestClose={handleNoClick}
				contentLabel="OpenPayDelete"
			>
				<div className="modal-inner" id="alert-inner">
					<div className="modal-close" onClick={handleNoClick}></div>
					<h2>Eliminar Tarjeta</h2>
					{renderLoader()}
					{renderContent()}
				</div>
			</Modal>
		</div>
	);
}
