import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Modal from "react-modal";
import { clearActivationData } from "features/account/activationSlice";
import Loader from "../Loader";

export default function ActivationModal() {
	const dispatch = useDispatch();
	// Selector
	const { isOpen, isLoading, message } = useSelector(
		(state) => state.activation
	);
	// History
	const history = useHistory();
	const location = useLocation();
	// Handlers
	function handleCloseClick() {
		dispatch(clearActivationData());
		if (location !== "/") {
			history.replace("/");
		}
	}
	// Render functions
	function renderAlert() {
		if (!isLoading) {
			return (
				<div className="alert-main">
					<p>{message}</p>
					<div className="modal-button alternate" onClick={handleCloseClick}>
						Cerrar
					</div>
				</div>
			);
		}
		return null;
	}
	function renderLoader() {
		if (isLoading) {
			return <Loader msg="Por favor espere..." />;
		}
		return null;
	}
	// Render
	if (!isOpen) {
		return null;
	}
	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal small"
				overlayClassName="modal-overlay"
				onRequestClose={handleCloseClick}
				contentLabel="Alert"
			>
				<div className="modal-inner" id="alert-inner">
					<div className="modal-close" onClick={handleCloseClick}></div>
					<h2>Activación</h2>
					{renderAlert()}
					{renderLoader()}
				</div>
			</Modal>
		</div>
	);
}
