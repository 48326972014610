import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_API_URL } from "../constants";
import { getMatchingItemsCities } from "../functions";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	list: null,
	stateId: null,
};

const cities = createSlice({
	name: "cities",
	initialState,
	reducers: {
		requestCitiesList(state) {
			state.isLoading = true;
		},
		receiveCitiesList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		loadCitiesSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateCitiesSuggestions(state, action) {
			state.isLoading = false;
			if (state.value === action.payload.value) {
				state.suggestions = action.payload.suggestions;
			}
		},
		setCitiesStateId(state, action) {
			state.stateId = action.payload.stateId;
		},
		updateCityValue(state, action) {
			state.value = action.payload.value;
		},
		updateCityItem(state, action) {
			state.selectedItem = action.payload.selectedItem;
		},
		clearCitiesSuggestions(state) {
			state.suggestions = [];
		},
		clearCityValue(state) {
			state.value = "";
			state.selectedItem = {};
		},
	},
});

export const {
	requestCitiesList,
	receiveCitiesList,
	loadCitiesSuggestionsBegin,
	maybeUpdateCitiesSuggestions,
	setCitiesStateId,
	updateCityValue,
	updateCityItem,
	clearCitiesSuggestions,
	clearCityValue,
} = cities.actions;

const shouldFetchCities = (state, stateId) => {
	if (state.cities.list == null) {
		return true;
	}
	if (state.cities.stateId !== stateId) {
		return true;
	}
	return false;
};

export const fetchCitiesIfNeeded = (stateId) => async (dispatch, getState) => {
	if (shouldFetchCities(getState(), stateId)) {
		return dispatch(fetchCities(stateId));
	}
};

export const loadCitiesSuggestions = (value) => async (dispatch, getState) => {
	const theState = getState();
	dispatch(loadCitiesSuggestionsBegin());
	dispatch(fetchCitiesIfNeeded(theState.cities.stateId));
	setTimeout(() => {
		dispatch(
			maybeUpdateCitiesSuggestions({
				suggestions: getMatchingItemsCities(theState.cities, value),
				value,
			})
		);
	});
};

export const fetchCities = (stateId) => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	const options = {
		//method: "GET",
		headers: headers,
	};
	dispatch(requestCitiesList());
	const url = BASE_API_URL + "/api/Cuenta/Ciudades/" + stateId;
	return axios
		.get(url, options)
		.then((response) => {
			dispatch(receiveCitiesList({ list: response.data }));
		})
		.catch((error) => {
			// If 401 Unauthorized login failed
		});
};

export default cities.reducer;
