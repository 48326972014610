import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-dropdown";
import { validateMail } from "features/functions";
import {
	setContactErrorMsg,
	clearContactForm,
	fetchContactMessage,
} from "features/contact/contactSlice";
import Loader from "components/Loader";
import classNames from "classnames";

export default function ContactForm() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, errorMsg, done } = useSelector((state) => state.contact);
	const { checked, authenticated, user } = useSelector(
		(state) => state.session
	);
	// State
	const [selected, setSelected] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [name, setName] = useState("");
	const [mail, setMail] = useState("");
	const [message, setMessage] = useState("");
	const [priority, setPriority] = useState(null);
	const [accept, setAccept] = useState(false);
	// Dropdown Options
	const options = [
		{ label: "Atención a Clientes", value: 1, index: 0 },
		{ label: "Garantías", value: 2, index: 1 },
	];
	// Handlers
	const handleSelect = (option) => {
		if (errorMsg != null && errorMsg.type != null) {
			const errorMessages = { ...errorMsg, type: null };
			dispatch(setContactErrorMsg({ errorMsg: errorMessages }));
		}
		setSelected(option);
		setSelectedIndex(options.findIndex((x) => x.value === option.value));
	};
	const handleNameChange = (e) => {
		if (errorMsg != null && errorMsg.name != null) {
			const errorMessages = { ...errorMsg, name: null };
			dispatch(setContactErrorMsg({ errorMsg: errorMessages }));
		}
		setName(e.target.value);
	};
	const handleMailChange = (e) => {
		if (errorMsg != null && errorMsg.mail != null) {
			const errorMessages = { ...errorMsg, mail: null };
			dispatch(setContactErrorMsg({ errorMsg: errorMessages }));
		}
		setMail(e.target.value);
	};
	const handleMessageChange = (e) => {
		if (errorMsg != null && errorMsg.message != null) {
			const errorMessages = { ...errorMsg, message: null };
			dispatch(setContactErrorMsg({ errorMsg: errorMessages }));
		}
		setMessage(e.target.value);
	};
	const handleAcceptChange = (e) => {
		if (errorMsg != null && errorMsg.accept != null) {
			const errorMessages = { ...errorMsg, accept: null };
			dispatch(setContactErrorMsg({ errorMsg: errorMessages }));
		}
		setAccept(e.target.checked);
	};
	const handleSubmit = () => {
		let valid = true;
		let errorMessages = {};
		if (checked && !authenticated) {
			if (name.trim() === "") {
				valid = false;
				errorMessages = {
					...errorMessages,
					name: "Debes escribir tu nombre.",
				};
			} else {
				if (name.trim().length < 5) {
					valid = false;
					errorMessages = {
						...errorMessages,
						name: "Debes escribir un nombre válido.",
					};
				}
			}
			if (mail.trim() === "") {
				valid = false;
				errorMessages = {
					...errorMessages,
					mail: "Debes escribir tu correo electrónico.",
				};
			} else {
				if (!validateMail(mail)) {
					valid = false;
					errorMessages = {
						...errorMessages,
						mail: "Debes escribir un correo electrónico válido.",
					};
				}
			}
			if (!accept) {
				valid = false;
				errorMessages = {
					...errorMessages,
					accept: "Debes aceptar los términos y condiciones.",
				};
			}
		}
		if (selected == null) {
			valid = false;
			errorMessages = {
				...errorMessages,
				type: "Debes seleccionar una opción.",
			};
		}
		if (message.trim() === "") {
			valid = false;
			errorMessages = {
				...errorMessages,
				message: "Debes escribir un mensaje.",
			};
		} else {
			if (message.trim().length < 15) {
				valid = false;
				errorMessages = {
					...errorMessages,
					message: "Debes escribir un mensaje válido.",
				};
			}
		}
		if (valid) {
			dispatch(
				fetchContactMessage({
					selected,
					selectedIndex,
					name,
					mail,
					message,
					priority,
				})
			);
		} else {
			dispatch(setContactErrorMsg({ errorMsg: errorMessages }));
		}
	};
	const handleDone = () => {
		dispatch(clearContactForm());
		setSelected(null);
		setSelectedIndex(0);
		setName("");
		setMail("");
		setMessage("");
		setPriority(null);
	};
	// Render functions

	const renderError = (error) => {
		if (error != null) {
			if (error !== "") {
				return <li>{error}</li>;
			} else return null;
		} else return null;
	};
	const renderErrorList = () => {
		if (!isLoading && errorMsg != null) {
			return (
				<div className="error-list">
					<ul>
						{renderError(errorMsg.system)}
						{renderError(errorMsg.name)}
						{renderError(errorMsg.mail)}
						{renderError(errorMsg.message)}
						{renderError(errorMsg.type)}
						{renderError(errorMsg.accept)}
					</ul>
				</div>
			);
		}
		return null;
	};
	const renderLoader = () => {
		if (isLoading) {
			return (
				<aside>
					<Loader msg="Enviando mensaje..." />
				</aside>
			);
		}
		return null;
	};
	const renderDone = () => {
		if (!isLoading && done) {
			return (
				<aside>
					<div>
						<h3>¡Gracias por contactarnos!</h3>
						<p>Tu mensaje fue enviado con éxito.</p>
						<p>
							En breve se pondrán en contacto contigo para dar seguimiento a tu
							mensaje.
						</p>
					</div>
					<fieldset>
						<div
							className="contact-submit"
							style={{ padding: "20px 0", margin: 0 }}
						>
							<input type="submit" value="Aceptar" onClick={handleDone} />
						</div>
					</fieldset>
				</aside>
			);
		}
		return null;
	};
	const renderCategory = () => {
		const defaultOption = selected;
		if (options.length > 1) {
			return (
				<div
					className={classNames("footer-dropdown", { error: errorMsg?.type })}
				>
					<Dropdown
						options={options}
						onChange={handleSelect}
						value={defaultOption}
						placeholder="Seleccione"
					/>
				</div>
			);
		} else {
			return null;
		}
	};
	const renderName = () => {
		if (checked && !authenticated) {
			return (
				<div className={classNames("campo", { error: errorMsg?.name != null })}>
					<input
						type="text"
						id="contact-name"
						placeholder="Nombre completo"
						value={name}
						onChange={handleNameChange}
					/>
				</div>
			);
		}
		return null;
	};
	const renderMail = () => {
		if (checked && !authenticated) {
			return (
				<div className={classNames("campo", { error: errorMsg?.mail != null })}>
					<input
						type="mail"
						id="contact-mail"
						placeholder="Correo"
						value={mail}
						onChange={handleMailChange}
					/>
				</div>
			);
		}
		return null;
	};
	const renderForm = () => {
		if (!isLoading && !done) {
			return (
				<aside>
					<fieldset>
						{renderName()}
						{renderMail()}
						<div className={classNames("campo", { error: errorMsg?.message })}>
							<textarea
								id="contact-message"
								placeholder="Mensaje"
								value={message}
								onChange={handleMessageChange}
							/>
						</div>
						{renderCategory()}
						{checked && !authenticated ? (
							<>
								<div
									className={classNames("switch", {
										error: errorMsg?.accept != null,
									})}
								>
									<input
										type="checkbox"
										id="accept-terms"
										checked={accept}
										onChange={handleAcceptChange}
									/>
									<label htmlFor="accept-terms">
										<span
											className={classNames("icon-switch", {
												error: errorMsg?.accept != null,
												inverted: errorMsg?.accept == null,
											})}
										></span>
										<div>Acepto términos y condiciones</div>
									</label>
								</div>
							</>
						) : null}
						<div className="contact-submit">
							<div>
								<input type="submit" value="Enviar" onClick={handleSubmit} />
							</div>
						</div>
					</fieldset>
					{renderErrorList()}
				</aside>
			);
		}
		return null;
	};
	return (
		<div id="contact-form">
			<h3>Contacto</h3>
			{renderLoader()}
			{renderDone()}
			{renderForm()}
			<article>
				<div className="contact-category">Atención a Clientes:</div>
				<div>
					<a href="mailto:servicioalcliente.ti_mj_sa@conti.com.mx">
						<span className="icon-mail" />{" "}
						servicioalcliente.ti_mj_sa@conti.com.mx
					</a>
				</div>
				<div className="contact-category">Garantías:</div>
				<div>
					<a href="mailto:servicioaclientes@conti.com.mx">
						<span className="icon-mail" /> servicioaclientes@conti.com.mx
					</a>
				</div>
				<div>
					<a href="tel:800-821-2000">
						<span className="icon-phone" />
						800 821 2000
					</a>
				</div>
				<div>
					<a
						href="https://www.facebook.com/ContinentalTireMex"
						target="_blank"
						rel="noopener noreferrer"
					>
						<span className="icon-facebook" /> Facebook
					</a>
				</div>
				<div>
					<a
						href="https://twitter.com/ContinentalMex"
						target="_blank"
						rel="noopener noreferrer"
					>
						<span className="icon-twitter" /> Twitter
					</a>
				</div>
				<div>
					<a
						href="https://www.instagram.com/ContinentalTireMx/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<span className="icon-instagram" /> Instagram
					</a>
				</div>
				<div>
					<a
						href="https://youtube.com/ContiMexico"
						target="_blank"
						rel="noopener noreferrer"
					>
						<span className="icon-youtube" /> Youtube
					</a>
				</div>
			</article>
		</div>
	);
}
