import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { getMatchingItemsTireSize } from "features/functions";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	list: null,
	renderInput: false,
	showInput: true,
};

const sizeRims = createSlice({
	name: "sizeRims",
	initialState,
	reducers: {
		requestSizeRimsList(state) {
			state.isLoading = true;
		},
		receiveSizeRimsList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
			state.renderInput = action.payload.renderInput;
		},
		loadSizeRimsSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateSizeRimsSuggestions(state, action) {
			state.isLoading = false;
			if (state.value === action.payload.value) {
				state.suggestions = action.payload.suggestions;
			}
		},
		updateSizeRimValue(state, action) {
			state.value = action.payload.value;
		},
		updateSizeRimItem(state, action) {
			const selectedItem = action.payload.selectedItem;
			if (selectedItem != null && selectedItem.Value != null) {
				ReactGA.event({
					category: "Medida",
					action: "Seleccionar Rim",
					label: selectedItem.Value + "",
					value: selectedItem.Value,
				});
			}
			state.selectedItem = selectedItem;
		},
		clearSizeRimsSuggestions(state) {
			state.suggestions = [];
		},
		clearSizeRimValue(state, action) {
			state.value = "";
			state.selectedItem = {};
			state.list = null;
			state.renderInput = action.payload.renderInput;
			state.showInput = action.payload.showInput;
		},
		clearSizeRimData: () => initialState,
	},
});

export const {
	requestSizeRimsList,
	receiveSizeRimsList,
	loadSizeRimsSuggestionsBegin,
	maybeUpdateSizeRimsSuggestions,
	updateSizeRimValue,
	updateSizeRimItem,
	clearSizeRimsSuggestions,
	clearSizeRimValue,
	clearSizeRimData,
} = sizeRims.actions;

const shouldFetchSizeRims = (state, newRatio) => {
	if (state.sizeRims.list == null) {
		return true;
	}
	const oldRatio = state.sizeRatios.selectedItem;
	if (oldRatio !== newRatio) {
		return true;
	}
	return false;
};
export const fetchSizeRimsIfNeeded = (selectedRatio) => async (
	dispatch,
	getState
) => {
	if (shouldFetchSizeRims(getState(), selectedRatio)) {
		const theState = getState();
		return dispatch(
			fetchSizeRims(
				theState.vehicleType.value,
				theState.sizeWidthDiameters.selectedItem.OriginalValue,
				selectedRatio.Value
			)
		);
	}
};
export const loadSizeRimsSuggestions = (value) => async (
	dispatch,
	getState
) => {
	let theState = getState();
	dispatch(loadSizeRimsSuggestionsBegin());
	if (theState.sizeRims.list == null) {
		dispatch(
			fetchSizeRims(
				theState.vehicleType.value,
				theState.sizeWidthDiameters.selectedItem.OriginalValue,
				theState.sizeRatios.selectedItem.Value
			)
		);
	}
	theState = getState();
	return setTimeout(() => {
		dispatch(
			maybeUpdateSizeRimsSuggestions({
				suggestions: getMatchingItemsTireSize(theState.sizeRims, value),
				value,
			})
		);
	});
};
export const fetchSizeRims = (dept, width, ratio) => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	dispatch(requestSizeRimsList());
	const url =
		BASE_API_URL +
		"/api/Medidas/Rin/?depto=" +
		dept +
		"&marca=0&anchoseccion=" +
		+width +
		"&serie=" +
		ratio;
	return fetch(url, options)
		.then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				return response.text().then((error) => Promise.reject(error));
			}
		})
		.then((json) => {
			dispatch(receiveSizeRimsList({ list: json, renderInput: true }));
		})
		.catch(() => {
			// TODO: fetch error actions
		});
};

export default sizeRims.reducer;
