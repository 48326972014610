import React, { useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import Scroll from "react-scroll";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {IMAGE_TYPES} from "features/constants"
import { imageFormat, getBrandClass } from "features/functions";
import Loader from "components/Loader";
import ProductDetails from "./ProductDetails";
import { isServer } from "features/functions";

const mapOptions = (list) => {
	return list.map(function (tire, index) {
		return { label: tire.Size, value: tire.Code, index: index };
	});
};

var Link = Scroll.Link;

export default function Highlights() {
	// Selectors
	const { isLoading, list, hide } = useSelector(
		(state) => state.highlightsTires
	);
	// State
	const [activeProduct, setActiveProduct] = useState(0);
	if (hide === true) {
		return null;
	}
	if (isLoading) {
		return (
			<div id="result-view">
				<div className="result-stripe">
					<h3>Productos Destacados</h3>
				</div>
				<div className="result-products">
					<div className="result-products-slider">
						<Loader msg="Cargando..." />
					</div>
				</div>
			</div>
		);
	}
	if (list == null || list.length === 0) {
		return null;
	}
	// Render
	const productDetails = {
		product: list[activeProduct],
		options: mapOptions(list[activeProduct].Tires),
	};
	function renderProducts(data, active) {
		var settings = {
			dots: false,
			centerMode: true,
			infinite: true,
			className: "center",
			slidesToShow: 3,
			centerPadding: "0px",
			afterChange: (current) => setActiveProduct(current),
			responsive: [
				{
					breakpoint: 980,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						initialSlide: 0,
					},
				},
			],
		};
		const w = isServer ? 1024 : window.innerWidth;
		let offset = w > 980 ? -100 : -50;
		if (data != null) {
			return (
				<Slider {...settings}>
					{data.map((product, index) => {
						if (index === active) {
							return (
								<div id={product.ProductId} key={index}>
									<div>
										<Link
											to="resultProductDetails"
											smooth={true}
											duration={500}
											offset={offset}
										>
											<div>
												<img
													src={imageFormat(product.Image, IMAGE_TYPES.CAROUSEL)}
													alt={product.Name}
												/>
												<span className={getBrandClass(product.Brand)}></span>
											</div>
											<div>
												<h2>{product.Name}</h2>
											</div>
										</Link>
									</div>
								</div>
							);
						} else {
							return (
								<div id={product.ProductId} key={index}>
									<div>
										<div>
											<img
												src={imageFormat(product.Image)}
												alt={product.Name}
											/>
											<span className={getBrandClass(product.Brand)}></span>
										</div>
										<div>
											<h2>{product.Name}</h2>
										</div>
									</div>
								</div>
							);
						}
					})}
				</Slider>
			);
		}
	};

	return (
		<div id="result-view">
			<div className="result-stripe">
				<h3>Productos Destacados</h3>
			</div>
			<div className="result-products">
				<div className="result-products-slider">
					{renderProducts(list, activeProduct)}
				</div>
			</div>
			<ProductDetails {...productDetails} />
		</div>
	);
}
