import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-dropdown";
import {
	setDealerShipAddressSelectedState,
	setDealerShipAddressCityList,
	setDealerShipAddressDefaultSelectedCity,
	setDealerShipAddressSelectedCity,
} from "features/cart/dealerShipAddressSlice";
import {
	clearCartShipAddress,
	clearCartShipLocationId,
	setCartShipAddress,
	setCartShipLocationId,
} from "features/cart/cartSlice";
import Loader from "../Loader";
import { SHIP_TYPES } from "features/constants";

const formatAddress = (address) => {
	let result = <div>&nbsp;</div>;
	let numint = "";
	if (address != null) {
		if (address.Apartment != null && address.Apartment.length > 0) {
			numint = " - " + address.Apartment;
		}
		result = (
			<div>
				{address.Street + " " + address.Number + numint}
				<br />
				Col. {address.Neighbourhood}
				<br />
				{address.Zip + " " + address.City}
				<br />
				{address.State}
				<br />
			</div>
		);
	}
	return result;
};

export default function CartShipDealer() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, list, stateList, selectedState, cityList, selectedCity } =
		useSelector((state) => state.dealerShipAddress);
	const { shipType, shipAddress, shipAddressItem } = useSelector(
		(state) => state.cart
	);
	// Handlers
	function handleStateSelect(option) {
		dispatch(setDealerShipAddressSelectedState({ selectedState: option }));
		dispatch(setDealerShipAddressCityList({ list, selectedState: option }));
		dispatch(setDealerShipAddressDefaultSelectedCity());
		dispatch(clearCartShipAddress());
		dispatch(clearCartShipLocationId());
	}
	function handleCitySelect(option) {
		dispatch(setDealerShipAddressSelectedCity({ selectedCity: option }));
		dispatch(clearCartShipAddress());
		dispatch(clearCartShipLocationId());
	}
	function handleDealerSelect(item) {
		dispatch(
			setCartShipAddress({
				shipAddress: item.index,
				shipAddressItem: item.element,
			})
		);
		dispatch(setCartShipLocationId({ shipLocationId: item.element.Id }));
	}
	// Render functions
	function renderLoader() {
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	}
	function renderFields() {
		if (!isLoading) {
			return (
				<div className="cart-form">
					<div>
						<div className="dropdown-field">
							<label>Estado</label>
							<Dropdown
								options={stateList}
								onChange={handleStateSelect}
								value={selectedState}
								placeholder="Estado"
							/>
						</div>
						<div className="dropdown-field">
							<label>Ciudad</label>
							<Dropdown
								options={cityList}
								onChange={handleCitySelect}
								value={selectedCity}
								placeholder="Ciudad"
							/>
						</div>
					</div>
				</div>
			);
		}
		return null;
	}
	function renderAddressList() {
		if (!isLoading) {
			const filter = (item) => {
				return (
					item.State === selectedState.value && item.City === selectedCity.value
				);
			};
			const filteredList = list.filter(filter);
			return (
				<div id="address-list">
					<div>
						{filteredList.map((element, index) => {
							return (
								<div
									className={
										shipAddress === index
											? "address-item selected"
											: "address-item"
									}
									id={element.Id}
									key={index}
									onClick={() => handleDealerSelect({ index, element })}
								>
									<div>
										<span className="icon-location"></span>
									</div>
									<div>{element.Dealer + " " + element.Name}</div>
								</div>
							);
						})}
						<div>&nbsp;</div>
					</div>
				</div>
			);
		}
	}
	function renderSelectedAddress() {
		if (!isLoading && shipAddress != null && shipAddressItem != null) {
			return <div id="address-details">{formatAddress(shipAddressItem)}</div>;
		} else {
			return (
				<div id="address-details">
					<div>
						<br />
						<br />
						POR FAVOR SELECCIONE UNA SUCURSAL
						<br />
						<br />
					</div>
				</div>
			);
		}
	}
	// Render
	if (shipType !== SHIP_TYPES.DEALER) {
		return null;
	}
	if (list == null) {
		return null;
	}
	return (
		<div id="cart-content-address">
			{renderLoader()}
			{renderFields()}
			{renderAddressList()}
			{renderSelectedAddress()}
		</div>
	);
}
