import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import ReactGA from "react-ga4";
import axios from "axios";
import { BASE_API_URL } from "../constants";
import { runAuthenticatedActions, runUnauthorizedActions } from "./loginSlice";

const initialState = {
	isLoading: false,
	isOpen: false,
};

const accountSlice = createSlice({
	name: "account",
	initialState,
	reducers: {
		requestAccountData(state) {
			state.isLoading = true;
		},
		receiveAccountData(state) {
			state.isLoading = false;
		},
		toggleShowAccountData(state, action) {
			if (!action.payload.isOpen) {
				ReactGA.event({
					category: "Cuenta",
					action: "Mostrar Detalles"});
			}
			state.isOpen = !action.payload.isOpen;
		},
	},
});

export const { requestAccountData, receiveAccountData, toggleShowAccountData } =
	accountSlice.actions;

export const fetchAccount = () => async (dispatch) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				//method: "GET",
				headers: headers,
			};
			dispatch(requestAccountData());
			const url = BASE_API_URL + "/api/Cuenta/MiCuenta";
			return axios
				.get(url, options)
				.then((response) => {
					sessionService
						.saveUser({ user: response.data })
						.then()
						.catch((error) => console.log("error", error));
					dispatch(receiveAccountData());
					dispatch(runAuthenticatedActions());
				})
				.catch((error) => {
					// If 401 Unauthorized login failed
					if (error.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default accountSlice.reducer;
