import { createSlice } from "@reduxjs/toolkit";
import { BASE_API_URL } from "../constants";
import { getMatchingItemsCounties } from "../functions";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	list: null,
	stateId: null,
};

const counties = createSlice({
	name: "counties",
	initialState,
	reducers: {
		requestCountiesList(state) {
			state.isLoading = true;
		},
		receiveCountiesList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		loadCountiesSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateCountiesSuggestions(state, action) {
			state.isLoading = false;
			if (state.value === action.payload.value) {
				state.suggestions = action.payload.suggestions;
			}
		},
		setCountiesStateId(state, action) {
			state.stateId = action.payload.stateId;
		},
		updateCountyValue(state, action) {
			state.value = action.payload.value;
		},
		updateCountyItem(state, action) {
			state.selectedItem = action.payload.selectedItem;
		},
		clearCountiesSuggestions(state) {
			state.suggestions = [];
		},
		clearCountyValue(state) {
			state.value = "";
			state.selectedItem = {};
		},
	},
});

export const {
	requestCountiesList,
	receiveCountiesList,
	loadCountiesSuggestionsBegin,
	maybeUpdateCountiesSuggestions,
	setCountiesStateId,
	updateCountyValue,
	updateCountyItem,
	clearCountiesSuggestions,
	clearCountyValue,
} = counties.actions;

const shouldFetchCounties = (state, stateId) => {
	if (state.counties.list == null) {
		return true;
	}
	if (state.counties.stateId !== stateId) {
		return true;
	}
	return false;
};

export const fetchCountiesIfNeeded =
	(stateId) => async (dispatch, getState) => {
		if (shouldFetchCounties(getState(), stateId)) {
			return dispatch(fetchCounties(stateId));
		}
	};

export const loadCountiesSuggestions =
	(value) => async (dispatch, getState) => {
		const theState = getState();
		dispatch(loadCountiesSuggestionsBegin());
		dispatch(fetchCountiesIfNeeded(theState.counties.stateId));
		setTimeout(() => {
			dispatch(
				maybeUpdateCountiesSuggestions({
					suggestions: getMatchingItemsCounties(theState.counties, value),
					value,
				})
			);
		});
	};

export const fetchCounties = (stateId) => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	dispatch(requestCountiesList());
	return fetch(BASE_API_URL + "/api/Cuenta/Municipios/" + stateId, options)
		.then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				return response.text().then((error) => Promise.reject(error));
			}
		})
		.then((json) => {
			dispatch(receiveCountiesList({ list: json }));
		})
		.catch(() => {
			// If 401 Unauthorized login failed
		});
};

export default counties.reducer;
