import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { getMatchingItemsYears } from "features/functions";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	renderInput: false,
	list: null,
};

const vehicleYears = createSlice({
	name: "vehicleYears",
	initialState,
	reducers: {
		requestVehicleYearsList(state) {
			state.isLoading = true;
		},
		receiveVehicleYearsList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
			state.renderInput = action.payload.renderInput;
		},
		loadVehicleYearsSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateVehicleYearsSuggestions(state, action) {
			state.isLoading = false;
			if (state.value !== action.payload.value) {
			} else {
				state.suggestions = action.payload.suggestions;
			}
		},
		updateVehicleYearValue(state, action) {
			state.value = action.payload.value;
		},
		updateVehicleYearItem(state, action) {
			if (
				Object.keys(action.payload.selectedItem).length !== 0 &&
				action.payload.selectedItem.anio != null
			) {
				ReactGA.event({
					category: "Vehiculo",
					action: "Seleccionar Año",
					label: action.payload.selectedItem.anio + "",
					value: action.payload.selectedItem.anio,
				});
			}
			state.selectedItem = action.payload.selectedItem;
		},
		clearVehicleYearsSuggestions(state) {
			state.suggestions = [];
		},
		clearVehicleYearValue(state, action) {
			state.value = "";
			state.selectedItem = {};
			state.renderInput = action.payload.renderInput;
		},
		clearVehicleYearData: () => initialState,
	},
});

export const {
	requestVehicleYearsList,
	receiveVehicleYearsList,
	loadVehicleYearsSuggestionsBegin,
	maybeUpdateVehicleYearsSuggestions,
	updateVehicleYearValue,
	updateVehicleYearItem,
	clearVehicleYearsSuggestions,
	clearVehicleYearValue,
	clearVehicleYearData,
} = vehicleYears.actions;

const shouldFetchVehicleYears = (state, modelId) => {
	const model = state.vehicleModels.selectedItem;
	if (state.vehicleYears.list == null) {
		if (model != null) {
			return true;
		}
		return false;
	}
	if (model?.ID !== modelId) {
		return true;
	}
	return false;
};
export const fetchVehicleYearsIfNeeded =
	(modelId) => async (dispatch, getState) => {
		const theState = getState();
		if (shouldFetchVehicleYears(theState, modelId)) {
			return dispatch(
				fetchVehicleYears(theState.vehicleBrands.selectedItem.ID, modelId)
			);
		}
	};
export const loadVehicleYearsSuggestions =
	(value) => async (dispatch, getState) => {
		dispatch(loadVehicleYearsSuggestionsBegin());
		const theState = getState();
		dispatch(fetchVehicleYearsIfNeeded(theState.vehicleModels.selectedItem.ID));
		setTimeout(() => {
			dispatch(
				maybeUpdateVehicleYearsSuggestions({
					suggestions: getMatchingItemsYears(theState.vehicleYears, value),
					value,
				})
			);
		});
	};
export const fetchVehicleYears = (brandId, modelId) => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	dispatch(requestVehicleYearsList());
	const url =
		BASE_API_URL +
		"/api/Vehiculos/Buscar/Anios/?marca=" +
		brandId +
		"&modelo=" +
		modelId;
	return fetch(url, options)
		.then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				return response.text().then((error) => Promise.reject(error));
			}
		})
		.then((json) => {
			dispatch(receiveVehicleYearsList({ list: json, renderInput: true }));
		})
		.catch(() => {
			// TODO: fetch error actions
		});
};

export default vehicleYears.reducer;
