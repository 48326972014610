import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import { sub } from "date-fns";
import { validateMail, validatePhone, validatePwd } from "features/functions";
import {
	setRegistrationErrorMsg,
	clearRegisterData,
	toggleShowRegister,
	fetchRegister,
} from "features/account/registerSlice";
import Loader from "components/Loader";

export default function RegisterModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, errorMsg, isOpen } = useSelector(
		(state) => state.register
	);
	// State
	const [usrname, setUsrname] = useState("");
	const [pwd, setPwd] = useState("");
	const [name, setName] = useState("");
	const [lastName, setLastName] = useState("");
	const [motherName, setMotherName] = useState("");
	const [phone, setPhone] = useState("");
	const [gender, setGender] = useState(null);
	const [birthdate, setBirthdate] = useState(null);
	const [accept, setAccept] = useState(true);
	const [subscribe, setSubscribe] = useState(true);
	const [defaultBirthdate, setDefaultBirthdate] = useState(true);
	// Refs
	const usernameRef = useRef();
	useEffect(() => usernameRef.current && usernameRef.current.focus(), []);
	// Handlers
	function handleUsrnameChange(e) {
		if (errorMsg != null && errorMsg.usrname != null) {
			dispatch(
				setRegistrationErrorMsg({
					errorMsg: { ...errorMsg, usrname: null, error: null },
				})
			);
		}
		setUsrname(e.target.value);
	}
	function handlePwdChange(e) {
		if (errorMsg != null && errorMsg.pwd != null) {
			dispatch(
				setRegistrationErrorMsg({
					errorMsg: { ...errorMsg, pwd: null, error: null },
				})
			);
		}
		setPwd(e.target.value);
	}
	function handleNameChange(e) {
		if (errorMsg != null && errorMsg.name != null) {
			dispatch(
				setRegistrationErrorMsg({
					errorMsg: { ...errorMsg, name: null, error: null },
				})
			);
		}
		setName(e.target.value);
	}
	function handleLastNameChange(e) {
		if (errorMsg != null && errorMsg.lastName != null) {
			dispatch(
				setRegistrationErrorMsg({
					errorMsg: { ...errorMsg, lastName: null, error: null },
				})
			);
		}
		setLastName(e.target.value);
	}
	function handleMotherNameChange(e) {
		setMotherName(e.target.value);
	}
	function handlePhoneChange(e) {
		if (errorMsg != null && errorMsg.phone != null) {
			dispatch(
				setRegistrationErrorMsg({
					errorMsg: { ...errorMsg, phone: null, error: null },
				})
			);
		}
		setPhone(e.target.value);
	}
	function handleGenderChange(e) {
		if (errorMsg != null && errorMsg.gender != null) {
			dispatch(
				setRegistrationErrorMsg({
					errorMsg: { ...errorMsg, gender: null, error: null },
				})
			);
		}
		setGender(e.target.value);
	}
	function handleDateChange(date) {
		if (errorMsg != null && errorMsg.birthdate != null) {
			dispatch(
				setRegistrationErrorMsg({
					errorMsg: { ...errorMsg, birthdate: null, error: null },
				})
			);
		}
		setBirthdate(date);
		setDefaultBirthdate(false);
	}
	function handleTermsChange(e) {
		if (errorMsg != null && errorMsg.accept != null) {
			dispatch(
				setRegistrationErrorMsg({
					errorMsg: { ...errorMsg, accept: null, error: null },
				})
			);
		}
		setAccept(e.target.checked);
	}
	function handleSubscriptionChange(e) {
		setSubscribe(e.target.checked);
	}
	function handleReset() {
		setUsrname("");
		setPwd("");
		setName("");
		setLastName("");
		setMotherName("");
		setPhone("");
		setGender(null);
		setBirthdate(null);
		setAccept(true);
		setSubscribe(true);
		setDefaultBirthdate(true);
	}
	function handleCloseClick() {
		handleReset();
		dispatch(clearRegisterData());
		dispatch(toggleShowRegister({ isOpen }));
	}
	function handleOverlayCloseClick() {
		dispatch(toggleShowRegister({ isOpen }));
	}
	function handleSubmit(e) {
		e.preventDefault();
		let valid = true;
		let errorMessages = { ...errorMsg };
		if (usrname.trim() === "") {
			errorMessages = {
				...errorMessages,
				usrname: "Debes escribir tu correo electrónico",
			};
			valid = false;
		} else {
			if (!validateMail(usrname)) {
				errorMessages = {
					...errorMessages,
					usrname: "Debes escribir un correo electrónico válido",
				};
				valid = false;
			}
		}
		if (pwd.trim() === "") {
			errorMessages = {
				...errorMessages,
				pwd: "Debes escribir una contraseña",
			};
			valid = false;
		} else {
			if (!validatePwd(pwd)) {
				errorMessages = {
					...errorMessages,
					pwd: "La contraseña debe tener entre 8 y 16 caracteres y debe contener al menos una Mayúscula, una minúscula, un número y un caracter especial",
				};
				valid = false;
			}
		}
		if (name.trim() === "") {
			errorMessages = { ...errorMessages, name: "Debes escribir tu nombre" };
			valid = false;
		}
		if (lastName.trim() === "") {
			errorMessages = {
				...errorMessages,
				lastName: "Debes escribir tu apellido paterno",
			};
			valid = false;
		}
		if (phone.trim() === "") {
			errorMessages = {
				...errorMessages,
				phone: "Debes escribir un teléfono para poder contactarte",
			};
			valid = false;
		} else {
			if (!validatePhone(phone)) {
				errorMessages = {
					...errorMessages,
					phone: "Debes escribir un teléfono válido",
				};
				valid = false;
			}
		}
		if (defaultBirthdate) {
			errorMessages = {
				...errorMessages,
				birthdate: "Debes seleccionar tu fecha de nacimiento",
			};
			valid = false;
		}
		if (gender == null) {
			errorMessages = {
				...errorMessages,
				gender: "Debes seleccionar el género",
			};
			valid = false;
		}
		if (!accept) {
			errorMessages = {
				...errorMessages,
				accept: "Debes aceptar los términos y condiciones de uso",
			};
			valid = false;
		}
		if (valid) {
			dispatch(
				fetchRegister({
					usrname,
					pwd,
					name,
					lastName,
					motherName,
					phone,
					birthdate,
					gender,
					accept,
					subscribe,
				})
			);
			setPwd("");
		} else {
			dispatch(setRegistrationErrorMsg({ errorMsg: errorMessages }));
		}
	}
	// Render Functions
	function renderError(error) {
		if (error != null) {
			return <li>{error}</li>;
		}
		return null;
	}
	function renderErrorList() {
		if (errorMsg != null) {
			return (
				<div className="error-list">
					<ul>
						{renderError(errorMsg.error)}
						{renderError(errorMsg.usrname)}
						{renderError(errorMsg.pwd)}
						{renderError(errorMsg.name)}
						{renderError(errorMsg.lastName)}
						{renderError(errorMsg.phone)}
						{renderError(errorMsg.birthdate)}
						{renderError(errorMsg.gender)}
						{renderError(errorMsg.coupon)}
						{renderError(errorMsg.accept)}
					</ul>
				</div>
			);
		}
		return null;
	}
	function getFieldProps(property) {
		if (errorMsg == null) {
			if (property === "terms") {
				return { className: "full" };
			}
			return {};
		}
		if (property in errorMsg && errorMsg[property] != null) {
			if (property === "terms") {
				return { className: "full error" };
			}
			return { className: "error" };
		}
		if (property === "terms") {
			return { className: "full" };
		}
		return {};
	}
	function renderLoader() {
		if (isLoading) {
			return <Loader msg="Por favor espere..." />;
		}
		return null;
	}
	function renderForm() {
		if (!isLoading) {
			return (
				<form onSubmit={handleSubmit} onReset={handleReset}>
					<div className="register-form">
						<div>
							<div {...getFieldProps("usrname")}>
								<label>Correo Electrónico *</label>
								<input
									type="email"
									ref={usernameRef}
									placeholder="Correo Electrónico"
									value={usrname}
									onChange={handleUsrnameChange}
									autoComplete="email"
									className="modal-field"
									tabIndex={1}
								/>
							</div>
							<div {...getFieldProps("pwd")}>
								<label>Contraseña *</label>
								<input
									type="password"
									value={pwd}
									onChange={handlePwdChange}
									placeholder="Contraseña"
									autoComplete="new-password"
									className="modal-field"
									tabIndex={2}
								/>
							</div>
						</div>
						<div>
							<div {...getFieldProps("name")}>
								<label>Nombre *</label>
								<input
									type="text"
									placeholder="Nombre"
									value={name}
									onChange={handleNameChange}
									autoComplete="given-name"
									className="modal-field"
									tabIndex={3}
								/>
							</div>
							<div {...getFieldProps("lastName")}>
								<label>Apellido Paterno *</label>
								<input
									type="text"
									placeholder="Apellido Paterno"
									value={lastName}
									onChange={handleLastNameChange}
									autoComplete="additional-name"
									className="modal-field"
									tabIndex={4}
								/>
							</div>
						</div>
						<div>
							<div>
								<label>Apellido Materno</label>
								<input
									type="text"
									placeholder="Apellido Materno"
									value={motherName}
									onChange={handleMotherNameChange}
									autoComplete="family-name"
									className="modal-field"
									tabIndex={5}
								/>
							</div>
							<div {...getFieldProps("phone")}>
								<label>Teléfono *</label>
								<input
									type="text"
									placeholder="Teléfono"
									value={phone}
									onChange={handlePhoneChange}
									autoComplete="tel"
									className="modal-field"
									tabIndex={6}
								/>
							</div>
						</div>
						<div>
							<div {...getFieldProps("birthdate")}>
								<label>Fecha de Nacimiento *</label>
								<DatePicker
									selected={birthdate}
									onChange={handleDateChange}
									minDate={sub(new Date(), { years: 100 })}
									maxDate={sub(new Date(), { years: 18 })}
									showYearDropdown
									showMonthDropdown
									dropdownMode="select"
									calendarClassName="conti-calendar"
									shouldCloseOnSelect={true}
									strictParsing={true}
									placeholderText="Fecha de Nacimiento"
									tabIndex={7}
									className="modal-field"
								/>
							</div>
							<div {...getFieldProps("gender")}>
								<label>Género</label>
								<div className="gender">
									<ul>
										<li>
											<input
												type="radio"
												value="M"
												name="gender-type"
												id="gender-type-male"
												checked={gender === "M"}
												onChange={handleGenderChange}
												tabIndex={8}
											/>
											<label htmlFor="gender-type-male" tabIndex={8}>
												<span className="icon-male"></span>
											</label>
										</li>
										<li>
											<input
												type="radio"
												value="F"
												name="gender-type"
												id="gender-type-female"
												checked={gender === "F"}
												onChange={handleGenderChange}
												tabIndex={9}
											/>
											<label htmlFor="gender-type-female" tabIndex={9}>
												<span className="icon-female"></span>
											</label>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div>
							<div className="modal-disclaimer full">
								<h3>TÉRMINOS Y CONDICIONES DE USO</h3>
								<p>
									CONTINENTAL Tire México S.A. de C.V. y sus filiales y
									subsidiarias (en adelante "CONTINENTAL"), le dan la más
									cordial bienvenida. Agradecemos su preferencia y nos
									permitimos informarle sobre algunos puntos importantes a
									considerar durante su visita a www.contishop.com.mx.
								</p>
								<p>
									Con el objetivo de ofrecerle un ambiente seguro y agradable
									para nuestros clientes y visitantes, hemos establecido reglas
									y términos bajo los cuales se delimitan las áreas de
									responsabilidad y derechos, tanto de CONTINENTAL como de
									nuestros visitantes.
								</p>
								<p>
									AL INGRESAR Y UTILIZAR ESTE SITIO DE INTERNET, CUYO NOMBRE DE
									DOMINIOES: www.contishop.com.mx, PROPIEDAD DE CONTINENTAL TIRE
									DE MÉXICO, S.A. DE C.V. y sus filiales, EN LO SUCESIVO
									“CONTINENTAL” USTED (EL USUARIO) ESTÁ ACEPTANDO LOS TÉRMINOS Y
									LAS CONDICIONES CONTENIDOS EN ESTE CONVENIO Y DECLARA
									EXPRESAMENTE SU ACEPTACIÓN, MANIFESTANDO SU VOLUNTAD EN
									TÉRMINOS DE LO ESTABLECIDO POR LOS ARTÍCULO 1803 Y 1834 BISDEL
									CÓDIGO CIVIL FEDERAL, 80, 81, 89 Y DEMÁS RELATIVOS Y
									APLICABLES DEL CÓDIGO DE COMERCIO Y DEMÁS RELATIVOS Y
									APLICABLES DE LA LEGISLACIÓN APLICABLE.
								</p>
								<p>
									SI EL USUARIO NO ACEPTA EN FORMA ABSOLUTA LOS TÉRMINOS Y
									CONDICIONES DE ESTE CONVENIO, DEBERÁ ABSTENERSE DE USAR Y VER
									www.contishop.com.mx PARA EL CASO QUE EL USUARIO CONTINÚE EN
									EL USO DE www.contishop.com.mx, DICHA ACCIÓN SE CONSIDERARÁ
									COMO SU ABSOLUTA ACEPTACIÓN A LOS TÉRMINOS Y CONDICIONES AQUÍ
									ESTABLECIDOS. LA SOLA UTILIZACIÓN DEL SITIO LE OTORGA AL
									PÚBLICO EN GENERAL O A QUIEN LO USE, LA CONDICIÓN DE USUARIO
									(EN ADELANTE REFERIDO COMO EL"USUARIO" O LOS "USUARIOS") E
									IMPLICA LA ACEPTACIÓN, PLENA E INCONDICIONAL, DE TODAS Y CADA
									UNA DE LAS CONDICIONES GENERALES Y PARTICULARES INCLUIDAS EN
									ESTOS TÉRMINOS DE USO EN LA VERSIÓN PUBLICADA POR CONTINENTAL
									EN EL MOMENTO MISMO EN QUE EL USUARIO ACCEDA AL SITIO.
									CUALQUIER MODIFICACIÓN A LOS PRESENTES TÉRMINOS DE USO SERÁ
									REALIZADA CUANDO CONTINENTAL LO CONSIDERE APROPIADO, SIENDO
									EXCLUSIVA RESPONSABILIDAD DEL USUARIO ASEGURARSE DE TOMAR
									CONOCIMIENTO DE TALES MODIFICACIONES.
								</p>
								<p>
									CONTRATO DE ADHESIÓN PARA USO DE SITIO DE INTERNET, QUE
									CELEBRAN POR UNA PARTE CONTINENTAL TIRE DE MÉXICO S.A. DE C.V.
									Y/O SUS FILIALES (EN LOSUCESIVO CONTINENTAL) Y, POR LA OTRA,
									EL USUARIO, SUJETÁNDOSE, AMBAS PARTES, A LO ESTABLECIDO EN EL
									TEXTO DEL PRESENTE CONTRATO.
								</p>
							</div>
						</div>
						<div>
							<div className="full">
								<div className="switch">
									<input
										type="checkbox"
										name="subscribe"
										id="subscribe"
										checked={subscribe}
										onChange={handleSubscriptionChange}
										tabIndex={10}
									/>
									<label htmlFor="subscribe" tabIndex={10}>
										<span className="icon-switch"></span>
										<div>
											Deseo recibir noticias y promociones de Continental Tire
											de México.
										</div>
									</label>
								</div>
							</div>
							<div {...getFieldProps("terms")}>
								<div className="switch">
									<input
										type="checkbox"
										name="accept"
										id="accept"
										checked={accept}
										onChange={handleTermsChange}
										tabIndex={11}
									/>
									<label htmlFor="accept" tabIndex={11}>
										<span className="icon-switch"></span>
										<div>Acepto términos y condiciones de uso del sitio.</div>
									</label>
								</div>
							</div>
							<div className="full">{renderErrorList()}</div>
							<div className="full center">
								<button type="submit" tabIndex={12} className="button main">
									Registrarme
								</button>
							</div>
						</div>
					</div>
				</form>
			);
		}
		return null;
	}
	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal"
				overlayClassName="modal-overlay"
				onRequestClose={handleOverlayCloseClick}
				contentLabel="Registro"
			>
				<div className="modal-inner" id="login-inner">
					<div className="modal-close" onClick={handleCloseClick}></div>
					<h2>Registro</h2>
					{renderLoader()}
					{renderForm()}
				</div>
			</Modal>
		</div>
	);
}
