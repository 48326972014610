import React from "react";
import VehicleFinder from "./VehicleFinder";
import SizeFinder from "./SizeFinder";

export default function TireFinder() {
	return (
		<div id="tire-finder">
			<VehicleFinder />
			<SizeFinder />
		</div>
	);
}
