import React from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { format, parseISO } from "date-fns";
import es from "date-fns/locale/es";
import { GENDER_TYPES } from "features/constants";
import { toggleShowAccountData } from "features/account/accountSlice";
import { toggleShowAccountEdit } from "../../features/account/accountEditSlice";
import Loader from "components/Loader";

export default function AccountDetailsModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isLoading, isOpen } = useSelector((state) => state.account);
	const { user } = useSelector((state) => state.session);
	// Handlers
	function handleCloseClick() {
		dispatch(toggleShowAccountData({ isOpen: true }));
	}
	function handleChangeClick() {
		dispatch(toggleShowAccountEdit({ isOpen: false }));
	}
	// Render Functions
	function renderLoader() {
		if (isLoading) {
			return <Loader msg="Cargando..." />;
		}
		return null;
	}
	function renderDetails() {
		if (!isLoading && user.user != null) {
			return (
				<div className="history-data">
					<div>
						<div>Nombre</div>
						<div>
							{user.user.name +
								" " +
								user.user.lastname +
								" " +
								user.user.mothername}
						</div>
					</div>
					<div>
						<div>Correo</div>
						<div>{user.user.email}</div>
					</div>
					<div>
						<div>Género</div>
						<div>{GENDER_TYPES.properties[user.user.gender].name}</div>
					</div>
					<div>
						<div>Fecha de Nacimiento</div>
						<div>
						{format(parseISO(user.user.birthdate), "dd/MMM/yyyy", {locale: es})}
						</div>
					</div>
					<div>
						<div>Teléfono</div>
						<div>{user.user.phone}</div>
					</div>
					<div>
						<div>Deseo recibir correos con información y promociones</div>
						<div>{user.user.subscribe ? "Sí" : "No"}</div>
					</div>
				</div>
			);
		}
		return null;
	}
	function renderControls() {
		if (!isLoading && user != null) {
			return (
				<div className="order-controls">
					<div>
						<div onClick={handleChangeClick} className="modal-button">
							Modificar
						</div>
						<div onClick={handleCloseClick} className="modal-button alternate">
							Cerrar
						</div>
					</div>
				</div>
			);
		}
		return (
			<div className="order-controls">
				<div>
					<div onClick={handleCloseClick} className="modal-button alternate">
						Cerrar
					</div>
				</div>
			</div>
		);
	}
	return (
		<div>
			<Modal
				isOpen={isOpen}
				className="modal"
				overlayClassName="modal-overlay"
				onRequestClose={handleCloseClick}
				contentLabel="MisDatos"
			>
				<div className="modal-inner" id="history-inner">
					<div className="modal-close" onClick={handleCloseClick}></div>
					<h2>Mis Datos</h2>
					<div className="history-details">
						{renderDetails()}
						{renderLoader()}
						{renderControls()}
					</div>
				</div>
			</Modal>
		</div>
	);
}
