import React from "react";
import { useDispatch } from "react-redux";
import { setCartInvoiceAddress } from "features/cart/cartSlice";

export default function CartInvoiceAddressButton(props) {
	const dispatch = useDispatch();
	// Handlers
	function handleSelectAddress() {
		const { index, item } = props;
		dispatch(setCartInvoiceAddress({ invoiceAddress: index, invoiceAddressItem: item }));
	}
	return (
		<div
			className={props.className}
			id={props.index}
			onClick={handleSelectAddress}
			key={props.index}
		>
			<div>
				<span className="icon-location"></span>
			</div>
			<div>{props.item.Alias}</div>
		</div>
	);
}
