import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SHIP_TYPES as s } from "features/constants";
import { currencyFormat, getModalButtonClass } from "features/functions";
import {
	setCartActiveTab,
	evaluateCartNextStep,
} from "features/cart/cartSlice";
import { fetchPaymentCardListIfNeeded } from "features/cart/paymentCardSlice";
import CartInvoiceAddress from "./CartInvoiceAddress";
import { validateInvoiceAddress } from "features/functions";

export default function CartShip() {
	const dispatch = useDispatch();
	// Selectors
	const { cartObject, shipType, showTab, invoiceAddressItem } = useSelector(
		(state) => state.cart
	);
	const regimeList = useSelector((state) => state.cfdiRegime.list);
	const usoList = useSelector((state) => state.cfdiUso.list);
	const isValid = validateInvoiceAddress(invoiceAddressItem, regimeList, usoList);
	// Handlers
	function handlePrevClick() {
		dispatch(setCartActiveTab({ showTab: showTab - 1 }));
	}
	function handleNextClick() {
		if (isValid) {
			dispatch(evaluateCartNextStep(showTab + 1));
			dispatch(fetchPaymentCardListIfNeeded());
		}
	}
	// Render
	const totalAmount =
		shipType === s.DHL_EMPLEADOS
			? cartObject.Total * 1.16 + cartObject.Costo_Envio
			: cartObject.Total * 1.16;
	return (
		<div id="cart-content-2">
			<CartInvoiceAddress />
			<div id="cart-summary-2">
				<div id="cart-totals">
					<div>
						<div>Subtotal</div>
						<div>{currencyFormat(cartObject.Total * 1.16)}</div>
					</div>
					<div>
						<div>Envío</div>
						<div>
							{currencyFormat(
								shipType === s.DHL_EMPLEADOS ? cartObject.Costo_Envio : 0
							)}
						</div>
					</div>
					<div>
						<div className="totals">Total</div>
						<div className="totals">{currencyFormat(totalAmount)}</div>
					</div>
					<div>
						<div>Desglose IVA</div>
						<div>{currencyFormat(cartObject.Total * 0.16)} </div>
					</div>
					<div>
						<div>
							<div className="modal-button" onClick={handlePrevClick}>
								Anterior
							</div>
						</div>
						<div>
							<div
								className={getModalButtonClass(!isValid)}
								onClick={handleNextClick}
							>
								Continuar
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
