import React from "react";
import { useSelector } from "react-redux";
import { SHIP_TYPES } from "features/constants";
import CartShipToButton from "./CartShipToButton";

const getShipToButtonClass = (selected) => {
	if (selected) {
		return "shipto-item selected";
	}
	return "shipto-item";
};

export default function CartShipTo() {
	// Selectors
	const { shipType } = useSelector((state) => state.cart);
	// Render functions
	function renderDealer() {
		const type = SHIP_TYPES.DEALER;
		return (
			<div className={getShipToButtonClass(shipType === SHIP_TYPES.DEALER)}>
				<div>
					<span className="icon-check"></span>
					{SHIP_TYPES.properties[type].fullname}
				</div>
				<CartShipToButton amount={0} shipType={type} />
			</div>
		);
	}
	// Render
	if (shipType !== 0) {
		return null;
	}
	return <div id="cart-content-shipto">{renderDealer()}</div>;
}
