import { createSlice } from "@reduxjs/toolkit";
import { sessionService } from "redux-react-session";
import axios from "axios";
import { BASE_API_URL, ORDER_STATUS_TYPES } from "../constants";
import { runUnauthorizedActions } from "features/account/loginSlice";

const initialState = {
	isLoading: false,
	isOpen: false,
	orderId: null,
	order: null,
	hasQueryString: false,
};
const orderConfirmation = createSlice({
	name: "orderConfirmation",
	initialState,
	reducers: {
		requestOrderConfirmation(state) {
			state.isLoading = true;
		},
		receiveOrderConfirmation(state, action) {
			state.isLoading = false;
			state.order = action.payload.order;
		},
		setOrderConfirmationId(state, action) {
			state.orderId = action.payload.orderId;
		},
		toggleShowOrderConfirmation(state, action) {
			state.isOpen = !action.payload.isOpen;
		},
		setOrderConfirmationQueryString(state, action) {
			state.hasQueryString = action.payload.hasQueryString;
		},
		clearOrderConfirmationData: () => initialState,
	},
});

export const {
	requestOrderConfirmation,
	receiveOrderConfirmation,
	setOrderConfirmationId,
	toggleShowOrderConfirmation,
	setOrderConfirmationQueryString,
	clearOrderConfirmationData,
} = orderConfirmation.actions;

export const evaluateShowOrderConfirmation =
	() => async (dispatch, getState) => {
		const theState = getState();
		const order = theState.orderConfirmation.order;
		if (
			order.Estatus === ORDER_STATUS_TYPES.INCOMPLETE ||
			order.Estatus === ORDER_STATUS_TYPES.RESERVE ||
			order.Estatus === ORDER_STATUS_TYPES.VALIDATION ||
			order.Estatus === ORDER_STATUS_TYPES.NOT_AUTHORIZED
		) {
			dispatch(toggleShowOrderConfirmation({ isOpen: false }));
		} else {
			dispatch(receiveOrderConfirmation({ order: null }));
			dispatch(setOrderConfirmationId({ orderId: null }));
		}
	};

export const fetchOrderConfirmation = () => async (dispatch, getState) => {
	sessionService
		.loadSession()
		.then((session) => {
			const headers = {
				Authorization: "bearer " + session.loginData.access_token,
				"Content-Type": "application/json",
			};
			const options = {
				headers: headers,
			};
			const theState = getState();
			dispatch(requestOrderConfirmation());
			const url =
				BASE_API_URL +
				"/api/Pedidos/Confirmacion/" +
				theState.orderConfirmation.orderId;
			return axios
				.get(url, options)
				.then((response) => {
					dispatch(receiveOrderConfirmation({ order: response.data }));
					dispatch(evaluateShowOrderConfirmation());
				})
				.catch((error) => {
					// 401 Unauthorized login failed
					if (error.response.status === 401) {
						dispatch(runUnauthorizedActions());
					}
				});
		})
		.catch((err) => console.log("Error", err));
};

export default orderConfirmation.reducer;
