import React from "react";
import Helmet from "react-helmet";
import {
	SITE_TITLE,
	SITE_DESCRIPTION,
	SITE_HOST,
} from "features/constants";
import MainMenu from "./MainMenu";
import HomeContent from "./HomeContent";
import CartModal from "../cart/CartModal";
import OrderConfirmationModal from "../order/OrderConfirmationModal";
import LoginModal from "../account/LoginModal";
import RegisterModal from "../account/RegisterModal";
import PasswordModal from "components/account/ResetPasswordModal";
import ConfirmationModal from "../account/ConfirmationModal";
import ShipAddressModal from "../account/ShipAddressModal";
import ShipAddressAddModal from "components/account/ShipAddressAddModal";
import InvoiceAddressModal from "components/account/InvoiceAddressModal";
import InvoiceAddressAddModal from "components/account/InvoiceAddressAddModal";
import InvoiceAddressEditModal from "components/account/InvoiceAddressEditModal";
import AccountOrders from "../account/AccountOrders";
import OrderModal from "../account/OrderModal";
import AccountDetailsModal from "../account/AccountDetailsModal";
import AccountEditModal from "../account/AccountEditModal";
import ActivationModal from "components/account/ActivationModal";
import AlertModal from "components/alert/AlertModal";
import ShipAddressEditModal from "components/account/ShipAddressEditModal";
import OpenPayPointsModal from "components/cart/OpenPayPointsModal";
import AccountCards from "components/account/AccountCards";
import AccountCardEdit from "components/account/AccountCardEdit";
import AccountCardDelete from "components/account/AccountCardDelete";

export default function Home() {
	return (
		<div>
			<Helmet>
				<title>{SITE_TITLE}</title>
				<meta name="description" content={SITE_DESCRIPTION} />
				<meta property="og:title" content={SITE_TITLE} />
				<meta property="og:descritpion" content={SITE_DESCRIPTION} />
				<meta property="og:type" content="website" />
				<meta property="og:url" content={SITE_HOST} />
				<meta property="og:image" content={SITE_HOST + "/images/contishop.jpg"} />
				<meta property="og:image:type" content="image/jpeg" />
				<meta property="og:image:width" content="1102" />
				<meta property="og:image:height" content="665" />
			</Helmet>
			<div id="logo-conti-top">
				<span></span>
			</div>
			<MainMenu />
			<HomeContent />
			<div id="outer-frame">
				<div id="main-container"></div>
			</div>
			<CartModal />
			<OpenPayPointsModal />
			<OrderConfirmationModal />
			<LoginModal />
			<RegisterModal />
			<PasswordModal />
			<ConfirmationModal />
			<ShipAddressModal />
			<ShipAddressAddModal />
			<ShipAddressEditModal />
			<InvoiceAddressModal />
			<InvoiceAddressAddModal />
			<InvoiceAddressEditModal />
			<AccountOrders />
			<AccountCards />
			<AccountCardEdit />
			<AccountCardDelete />
			<OrderModal />
			<AccountDetailsModal />
			<AccountEditModal />
			<ActivationModal />
			<AlertModal />
		</div>
	);
}
