import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

export default function PaymentPayPalForm() {
	// Selectors
	const { payPalResponse } = useSelector((state) => state.cart);
	// Ref
	const formRef = useRef();
	// Effect
	useEffect(() => {
		formRef.current.submit();
	}, []);
	// Render functions
	function renderFormInputs() {
		return Object.keys(payPalResponse).map((name, index) => {
			if (name !== "Url") {
				return (
					<input key={index} name={name} type="hidden" value={payPalResponse[name]} />
				);
			} else {
				return null;
			}
		});
	}
	const { Url } = payPalResponse;
	// Render
	return (
		<form ref={formRef} action={Url} method="post">
			{renderFormInputs()}
		</form>
	);
}
