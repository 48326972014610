import React from "react";
import { useHistory, useLocation } from "react-router";
import TermsDisclaimer from "./TermsDisclaimer";

export default function Terms() {
	// History
	const history = useHistory();
	const location = useLocation();
	// Handlers
	function handleClick() {
		if (location != "/"){
			history.push("/");
		}
	}
	// Render
	return (
		<div className="login">
			<div>
				<div id="logo-conti-top">
					<span></span>
				</div>
			</div>
			<div id="main-menu" className="home">
				<div id="logo-conti-top-mobile">
					<span></span>
				</div>
			</div>
			<div id="login-content">
				<article className="terms">
					<div className="right">
						<div onClick={handleClick} className="button main">
						  {"<"}	Regresar
						</div>
					</div>
					<TermsDisclaimer />
					<div className="center">
						<div onClick={handleClick} className="button main">
						{"<"}	Regresar
						</div>
					</div>
				</article>
			</div>
			<div id="outer-frame">
				<div id="main-container"></div>
			</div>
		</div>
	);
}
