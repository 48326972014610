import React from "react";
import history from "features/history";
import ContactForm from "../contact/ContactForm";

export default function HomeFooter() {
	// Handlers
	function handleTermsClick() {
		history.push("/terminos");
	}
	// function handleVisionZeroClick() {
	// 	window.open(
	// 		"https://www.continentaltire.mx/car/continental/vision-zero",
	// 		"_blank"
	// 	);
	// }

	// Render
	return (
		<div id="footer-view">
			<ContactForm />
			<div id="footer-data">
				<h3>Sitio</h3>
				<aside>
					<ul>
						<li>
							<div className="footer-link" onClick={handleTermsClick}>
								Términos y Condiciones
							</div>
						</li>
						<li>
							<div className="footer-link" onClick={handleTermsClick}>
								Gastos de envío
							</div>
						</li>
						<li>
							<div className="footer-link" onClick={handleTermsClick}>
								Información legal
							</div>
						</li>
						<li>
							<div className="footer-link" onClick={handleTermsClick}>
								Formas de Pago
							</div>
						</li>
						<li>
							<div className="footer-link" onClick={handleTermsClick}>
								Políticas de Privacidad
							</div>
						</li>
					</ul>
				</aside>
				<article>
					<div className="sponsor">
						<p>&nbsp;</p>
						<p>
							<img
								src="/images/footer/logo-tecnologia-alemana-inverted.png"
								alt="Tecnología Alemana que conecta tu vida"
								style={{ width: "150px" }}
							/>
						</p>
					</div>
					<div className="sponsor">
						<p>
							<img
								src="/images/footer/esr-logo.png"
								alt="Empresa Socialmente Responsable"
								style={{ width: "124px" }}
							/>
						</p>
					</div>
				</article>
			</div>
		</div>
	);
}
