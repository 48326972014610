import { createSlice } from "@reduxjs/toolkit";
import { BASE_API_URL } from "../constants";
import { getMatchingItemsNeighborhoods } from "../functions";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	list: null,
	zipCode: null,
};

const neighborhoods = createSlice({
	name: "neighborhoods",
	initialState,
	reducers: {
		requestNeighborhoodsList(state) {
			state.isLoading = true;
		},
		receiveNeighborhoodsList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
		},
		loadNeighborhoodsSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateNeighborhoodsSuggestions(state, action) {
			state.isLoading = false;
			if (state.value === action.payload.value) {
				state.suggestions = action.payload.suggestions;
			}
		},
		setNeighborhoodsZipCode(state, action) {
			state.zipCode = action.payload.zipCode;
		},
		updateNeighborhoodValue(state, action) {
			state.value = action.payload.value;
		},
		updateNeighborhoodItem(state, action) {
			state.selectedItem = action.payload.selectedItem;
		},
		clearNeighborhoodsSuggestions(state) {
			state.suggestions = [];
		},
		clearNeighborhoodValue(state) {
			state.value = "";
			state.selectedItem = {};
		},
	},
});

export const {
	requestNeighborhoodsList,
	receiveNeighborhoodsList,
	loadNeighborhoodsSuggestionsBegin,
	maybeUpdateNeighborhoodsSuggestions,
	setNeighborhoodsZipCode,
	updateNeighborhoodValue,
	updateNeighborhoodItem,
	clearNeighborhoodsSuggestions,
	clearNeighborhoodValue
} = neighborhoods.actions;

const shouldFetchNeighborhoods = (state, zipCode) => {
	if (state.neighborhoods.list == null) {
		return true;
	}
	if (state.neighborhoods.zipCode !== zipCode) {
		return true;
	}
	return false;
};

export const fetchNeighborhoodsIfNeeded =
	(zipCode) => async (dispatch, getState) => {
		if (shouldFetchNeighborhoods(getState(), zipCode)) {
			return dispatch(fetchNeighborhoods(zipCode));
		}
	};

export const loadNeighborhoodsSuggestions =
	(value) => async (dispatch, getState) => {
		const theState = getState();
		dispatch(loadNeighborhoodsSuggestionsBegin());
		dispatch(fetchNeighborhoodsIfNeeded(theState.neighborhoods.zipCode));
		setTimeout(() => {
			dispatch(
				maybeUpdateNeighborhoodsSuggestions({
					suggestions: getMatchingItemsNeighborhoods(
						theState.neighborhoods,
						value
					),
					value,
				})
			);
		});
	};

export const fetchNeighborhoods = (zipCode) => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	const options = {
		method: "GET",
		headers: headers,
	};
	dispatch(requestNeighborhoodsList());
	return fetch(BASE_API_URL + "/api/Cuenta/Colonias/" + zipCode, options)
		.then((response) => {
			if (response.ok) {
				return response.json();
			} else {
				return response.text().then((error) => Promise.reject(error));
			}
		})
		.then((json) => {
			dispatch(receiveNeighborhoodsList({ list: json }));
		})
		.catch(() => {
			// If 401 Unauthorized login failed
		});
};

export default neighborhoods.reducer;
