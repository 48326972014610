import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import { BASE_API_URL } from "../constants";
import { getMatchingItemsBlank } from "features/functions";

const initialState = {
	isLoading: false,
	value: "",
	selectedItem: {},
	suggestions: [],
	renderInput: false,
	list: null,
};

const vehicleVersions = createSlice({
	name: "vehicleVersions",
	initialState,
	reducers: {
		requestVehicleVersionsList(state) {
			state.isLoading = true;
		},
		receiveVehicleVersionsList(state, action) {
			state.isLoading = false;
			state.list = action.payload.list;
			state.renderInput = action.payload.renderInput;
		},
		loadVehicleVersionsSuggestionsBegin(state) {
			state.isLoading = true;
		},
		maybeUpdateVehicleVersionsSuggestions(state, action) {
			state.isLoading = false;
			if (state.value !== action.payload.value) {
			} else {
				state.suggestions = action.payload.suggestions;
			}
		},
		updateVehicleVersionValue(state, action) {
			state.value = action.payload.value;
		},
		updateVehicleVersionItem(state, action) {
			if (
				action.payload.selectedItem != null &&
				action.payload.selectedItem.Name != null
			) {
				ReactGA.event({
					category: "Vehiculo",
					action: "Seleccionar Versión",
					label: action.payload.selectedItem.Name,
					value: action.payload.selectedItem.ID,
				});
			}
			state.selectedItem = action.payload.selectedItem;
		},
		clearVehicleVersionsSuggestions(state) {
			state.suggestions = [];
		},
		clearVehicleVersionValue(state, action) {
			state.value = "";
			state.selectedItem = {};
			state.renderInput = action.payload.renderInput;
		},
		clearVehicleVersionData: () => initialState,
	},
});

export const {
	requestVehicleVersionsList,
	receiveVehicleVersionsList,
	loadVehicleVersionsSuggestionsBegin,
	maybeUpdateVehicleVersionsSuggestions,
	updateVehicleVersionValue,
	updateVehicleVersionItem,
	clearVehicleVersionsSuggestions,
	clearVehicleVersionValue,
	clearVehicleVersionData,
} = vehicleVersions.actions;

const shouldFetchVehicleVersions = (state, year) => {
	const yearItem = state.vehicleYears.selectedItem;
	if (state.vehicleVersions.list == null) {
		if (yearItem != null) {
			return true;
		}
		return false;
	}
	if (yearItem?.anio !== year) {
		return true;
	}
	return false;
};
export const fetchVehicleVersionsIfNeeded =
	(year) => async (dispatch, getState) => {
		const theState = getState();
		if (shouldFetchVehicleVersions(theState, year)) {
			return dispatch(
				fetchVehicleVersions(
					theState.vehicleBrands.selectedItem.ID,
					theState.vehicleModels.selectedItem.ID,
					year
				)
			);
		}
	};
export const loadVehicleVersionsSuggestions =
	(value) => async (dispatch, getState) => {
		dispatch(loadVehicleVersionsSuggestionsBegin());
		const theState = getState();
		dispatch(
			fetchVehicleVersionsIfNeeded(theState.vehicleYears.selectedItem.anio + "")
		);
		setTimeout(() => {
			dispatch(
				maybeUpdateVehicleVersionsSuggestions({
					suggestions: getMatchingItemsBlank(theState.vehicleVersions, value),
					value,
				})
			);
		});
	};
export const fetchVehicleVersions =
	(brandId, modelId, year) => async (dispatch) => {
		const headers = {
			"Content-Type": "application/json",
		};
		const options = {
			method: "GET",
			headers: headers,
		};
		dispatch(requestVehicleVersionsList());
		const url =
			BASE_API_URL +
			"/api/Vehiculos/Buscar/Versiones?marca=" +
			brandId +
			"&modelo=" +
			modelId +
			"&anio=" +
			year;
		return fetch(url, options)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					return response.text().then((error) => Promise.reject(error));
				}
			})
			.then((json) => {
				dispatch(receiveVehicleVersionsList({ list: json, renderInput: true }));
			})
			.catch(() => {
				// TODO: fetch error actions
			});
	};

export default vehicleVersions.reducer;
