import React from "react";

export default function ProductDetailsQty(props) {
	// Handlers
	function handleQtyChange (e) {
		const qty = e.target.value.replace(/[^0-9]/g, "");
		props.onChange(qty);
	};
	function handleQtyFocus (e) {
		e.target.select();
	};
	function handleQtyBlur (e) {
		const qty = Number(e.target.value.replace(/[^0-9]/g, ""));
		props.onBlur(qty);
	};
	function handleQtyKeyPress (e) {
		const qty = Number(e.target.value.replace(/[^0-9]/g, ""));
		if (e.key === "Enter") {
			props.onBlur(qty);
		}
	};
	function handleQtyKeyDown (e) {
		const qty = Number(e.target.value.replace(/[^0-9]/g, ""));
		if (e.key === "Tab") {
			props.onBlur(qty);
		}
		if (e.key === "Escape") {
			props.onBlur(0);
		}
	};
	// Render
	return (
		<div>
			<input
				type="text"
				id={props.Kartis + "-qty"}
				value={props.value}
				onChange={handleQtyChange}
				onBlur={handleQtyBlur}
				onFocus={handleQtyFocus}
				onKeyPress={handleQtyKeyPress}
				onKeyDown={handleQtyKeyDown}
			/>
		</div>
	);
}
