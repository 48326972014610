import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import ReactDropdown from "react-dropdown";
import { validateZip, validateRFC } from "features/functions";
import ZipCodeAutoSuggest from "components/autosuggest/ZipCodeAutosuggest";
import NeighborhoodAutosuggest from "components/autosuggest/NeighborhoodAutosuggest";
import CityAutosuggest from "components/autosuggest/CityAutosuggest";
import CountyAutosuggest from "components/autosuggest/CountyAutosuggest";
import {
	setInvoiceAddressErrorMessage,
	clearInvoiceAddressAddData,
	toggleShowInvoiceAddressAdd,
	fetchAddInvoiceAddress,
} from "features/account/invoiceAddressSlice";
import {
	fetchZipCodesIfNeeded,
	setZipCodesStateId,
	clearZipCodeValue,
} from "features/account/zipCodesSlice";
import { clearNeighborhoodValue } from "features/account/neighborhoodsSlice";
import {
	fetchCitiesIfNeeded,
	clearCityValue,
} from "features/account/citiesSlice";
import {
	fetchCountiesIfNeeded,
	clearCountyValue,
} from "features/account/countiesSlice";
import Loader from "components/Loader";
import { CFDI_TYPE } from "features/constants";
import { validateRazonSocial } from "features/functions";
import { getCfdiType } from "features/functions";
import { mapCfdiOptions } from "features/functions";

const clearCityCountyError = (errorMsg) => async (dispatch) => {
	if (errorMsg != null && errorMsg.city != null) {
		dispatch(
			setInvoiceAddressErrorMessage({
				errorMsg: { ...errorMsg, city: null, county: null },
			})
		);
	}
};

export default function InvoiceAddressAddModal() {
	const dispatch = useDispatch();
	// Selectors
	const { isOpenAdd, isLoadingAdd, errorMsg } = useSelector(
		(state) => state.invoiceAddress
	);
	const { options } = useSelector((state) => state.states);
	const zipValue = useSelector((state) => state.zipCodes.value);
	const cityValue = useSelector((state) => state.cities.value);
	const countyValue = useSelector((state) => state.counties.value);
	const regimeList = useSelector((state) => state.cfdiRegime.list);
	const usoList = useSelector((state) => state.cfdiUso.list);
	// State
	const [alias, setAlias] = useState("");
	const [rfc, setRfc] = useState("");
	const [cfdiType, setCfdiType] = useState(null);
	const [name, setName] = useState("");
	const [street, setStreet] = useState("");
	const [ext, setExt] = useState("");
	const [int, setInt] = useState("");
	const [selectedState, setSelectedState] = useState(null);
	const [zip, setZip] = useState("");
	const [neighborhood, setNeighborhood] = useState("");
	const [city, setCity] = useState("");
	const [county, setCounty] = useState("");
	const [regime, setRegime] = useState(null);
	const [uso, setUso] = useState(null);
	// Effect
	useEffect(() => {
		let prevZipValue;
		let prevCityValue;
		let prevCountyValue;
		if (
			prevZipValue !== zipValue &&
			prevCityValue !== cityValue &&
			cityValue !== "" &&
			prevCountyValue !== countyValue &&
			countyValue !== ""
		) {
			setCity(cityValue);
			setCounty(countyValue);
			dispatch(clearCityCountyError(errorMsg));
		}
		return () => {
			prevZipValue = zipValue;
			prevCityValue = cityValue;
			prevCountyValue = countyValue;
		};
	}, [dispatch, errorMsg, zipValue, cityValue, countyValue]);
	// Handlers
	function handleAliasChange(e) {
		if (errorMsg != null && errorMsg.alias != null) {
			dispatch(
				setInvoiceAddressErrorMessage({
					errorMsg: { ...errorMsg, alias: null, error: null },
				})
			);
		}
		setAlias(e.target.value);
	}
	function handleRfcChange(e) {
		if (errorMsg != null && errorMsg.rfc != null) {
			dispatch(
				setInvoiceAddressErrorMessage({
					errorMsg: { ...errorMsg, rfc: null, error: null },
				})
			);
		}
		const fixed = e.target.value.replace(/[^a-zA-Z0-9&]/g, "");
		setRfc(fixed.toUpperCase());
		setRegime(null);
		setUso(null);
		e.target.value = fixed;
		if (validateRFC(fixed)) {
			setCfdiType(getCfdiType(fixed));
		} else {
			setCfdiType(null);
		}
	}
	function handleNameChange(e) {
		if (errorMsg != null && errorMsg.name != null) {
			dispatch(
				setInvoiceAddressErrorMessage({
					errorMsg: { ...errorMsg, name: null, error: null },
				})
			);
		}
		setName(e.target.value.toUpperCase());
	}
	function handleStreetChange(e) {
		if (errorMsg != null && errorMsg.street != null) {
			dispatch(
				setInvoiceAddressErrorMessage({
					errorMsg: { ...errorMsg, street: null, error: null },
				})
			);
		}
		setStreet(e.target.value);
	}
	function handleExtChange(e) {
		if (errorMsg != null && errorMsg.ext != null) {
			dispatch(
				setInvoiceAddressErrorMessage({
					errorMsg: { ...errorMsg, ext: null, error: null },
				})
			);
		}
		setExt(e.target.value);
	}
	function handleIntChange(e) {
		if (errorMsg != null && errorMsg.int != null) {
			dispatch(
				setInvoiceAddressErrorMessage({
					errorMsg: { ...errorMsg, int: null, error: null },
				})
			);
		}
		setInt(e.target.value);
	}
	function handleSelectState(option) {
		if (errorMsg != null && errorMsg.selectedState != null) {
			dispatch(
				setInvoiceAddressErrorMessage({
					errorMsg: { ...errorMsg, selectedState: null, error: null },
				})
			);
		}
		const stateId = option.value + "";
		setSelectedState(option);
		setZip("");
		setNeighborhood("");
		setCity("");
		setCounty("");
		dispatch(clearNeighborhoodValue());
		dispatch(clearZipCodeValue());
		dispatch(clearCityValue());
		dispatch(clearCountyValue());
		dispatch(fetchZipCodesIfNeeded(stateId));
		dispatch(fetchCitiesIfNeeded(stateId));
		dispatch(fetchCountiesIfNeeded(stateId));
		dispatch(setZipCodesStateId({ stateId }));
	}
	function handleZipChange(e) {
		if (errorMsg != null && errorMsg.zip != null) {
			dispatch(
				setInvoiceAddressErrorMessage({
					errorMsg: { ...errorMsg, zip: null, error: null },
				})
			);
		}
		setZip(e.target.value);
		setNeighborhood("");
		setCity("");
		setCounty("");
		dispatch(clearNeighborhoodValue());
		dispatch(clearCityValue());
		dispatch(clearCountyValue());
	}
	function handleNeighborhoodChange(e) {
		if (errorMsg != null && errorMsg.neighborhood != null) {
			dispatch(
				setInvoiceAddressErrorMessage({
					errorMsg: { ...errorMsg, neighborhood: null, error: null },
				})
			);
		}
		setNeighborhood(e.target.value);
	}
	function handleCityChange(e) {
		if (errorMsg != null && errorMsg.city != null) {
			dispatch(
				setInvoiceAddressErrorMessage({
					errorMsg: { ...errorMsg, city: null, error: null },
				})
			);
		}
		setCity(e.target.value);
	}
	function handleCountyChange(e) {
		if (errorMsg != null && errorMsg.county != null) {
			dispatch(
				setInvoiceAddressErrorMessage({
					errorMsg: { ...errorMsg, county: null, error: null },
				})
			);
		}
		setCounty(e.target.value);
	}
	function handleRegimeSelect(option) {
		if (errorMsg != null && errorMsg.regime != null) {
			dispatch(
				setInvoiceAddressErrorMessage({
					errorMsg: { ...errorMsg, regime: null, error: null },
				})
			);
		}
		setRegime(option);
	}
	function handleUsoSelect(option) {
		if (errorMsg != null && errorMsg.uso != null) {
			dispatch(
				setInvoiceAddressErrorMessage({
					errorMsg: { ...errorMsg, uso: null, error: null },
				})
			);
		}
		setUso(option);
	}

	function handleReset() {
		setAlias("");
		setName("");
		setRfc("");
		setStreet("");
		setExt("");
		setInt("");
		setSelectedState(null);
		setZip("");
		setNeighborhood("");
		setCity("");
		setCounty("");
		setRegime(null);
		setUso(null);
		setCfdiType(null);
		dispatch(clearZipCodeValue());
		dispatch(clearInvoiceAddressAddData());
		dispatch(clearNeighborhoodValue());
		dispatch(clearCityValue());
		dispatch(clearCountyValue());
	}
	function handleCloseClick() {
		handleReset();
		dispatch(toggleShowInvoiceAddressAdd({ isOpenAdd: true }));
	}
	function handleOverlayCloseClick() {
		dispatch(toggleShowInvoiceAddressAdd({ isOpenAdd: true }));
	}
	function handleSubmit(e) {
		e.preventDefault();
		let valid = true;
		let errorMessages = { ...errorMsg };
		if (alias.trim() === "") {
			errorMessages = {
				...errorMessages,
				alias: "Debes escribir un alias único para esta dirección",
			};
			valid = false;
		}
		if (rfc.trim() === "") {
			errorMessages = {
				...errorMessages,
				rfc: "Debes escribir el RFC",
			};
			valid = false;
		} else {
			if (!validateRFC(rfc)) {
				errorMessages = {
					...errorMessages,
					rfc: "Debes escribir un RFC válido",
				};
				valid = false;
			}
		}
		if (name.trim() === "") {
			errorMessages = {
				...errorMessages,
				name: "Debes escribir el nombre o razón social",
			};
			valid = false;
		}
		const rfcType = getCfdiType(rfc);
		if (rfcType === CFDI_TYPE.MORAL && !validateRazonSocial(name)) {
			errorMessages = {
				...errorMessages,
				name: "La razón social no es válida para CFDI 4.0 debes escribirla como la CSF del SAT",
			};
			valid = false;
		}
		if (street.trim() === "") {
			errorMessages = {
				...errorMessages,
				street: "Debes escribir la calle",
			};
			valid = false;
		}
		if (ext.trim() === "") {
			errorMessages = {
				...errorMessages,
				ext: "Debes escribir el número exterior",
			};
			valid = false;
		}
		if (selectedState == null) {
			errorMessages = {
				...errorMessages,
				selectedState: "Debes seleccionar un estado",
			};
			valid = false;
		}
		const zipString = zip + "";
		if (zipString.trim() === "") {
			errorMessages = {
				...errorMessages,
				zip: "Debes escribir el código postal",
			};
			valid = false;
		} else {
			if (!validateZip(zip)) {
				errorMessages = {
					...errorMessages,
					zip: "El código postal no es válido",
				};
				valid = false;
			}
		}
		if (neighborhood.trim() === "") {
			errorMessages = {
				...errorMessages,
				neighborhood: "Debes escribir la colonia",
			};
			valid = false;
		}
		if (city.trim() === "") {
			errorMessages = {
				...errorMessages,
				city: "Debes escribir la ciudad",
			};
			valid = false;
		}
		if (county.trim() === "") {
			errorMessages = {
				...errorMessages,
				county: "Debes escribir el municipio",
			};
			valid = false;
		}
		if (regime == null) {
			errorMessages = {
				...errorMessages,
				regime: "Debes seleccionar el Régimen Fiscal",
			};
			valid = false;
		} else {
			const regimeFiltered = regimeList.filter(
				(el) => el.Id === regime.value
			)[0];
			if (
				(rfcType === CFDI_TYPE.FISICA && !regimeFiltered.Fisica) ||
				(rfcType === CFDI_TYPE.MORAL && !regimeFiltered.Moral)
			) {
				errorMessages = {
					...errorMessages,
					regime: "El Régimen Fiscal no es válido",
				};
				valid = false;
			}
		}
		if (uso == null) {
			errorMessages = {
				...errorMessages,
				uso: "Debes seleccionar el Uso del CFDI",
			};
			valid = false;
		} else {
			const usoFiltered = usoList.filter((el) => el.Id === uso.value)[0];
			if (
				(rfcType === CFDI_TYPE.FISICA && !usoFiltered.Fisica) ||
				(rfcType === CFDI_TYPE.MORAL && !usoFiltered.Moral)
			) {
				errorMessages = {
					...errorMessages,
					regime: "El Uso del CFDI no es válido",
				};
				valid = false;
			}
		}
		if (valid) {
			dispatch(
				fetchAddInvoiceAddress({
					alias,
					rfc,
					name,
					street,
					ext,
					int,
					zip,
					neighborhood,
					city,
					county,
					stateId: selectedState.value,
					regime: regime.value,
					uso: uso.value,
				})
			);
			dispatch(setInvoiceAddressErrorMessage({ errorMsg: null }));
		} else {
			dispatch(setInvoiceAddressErrorMessage({ errorMsg: errorMessages }));
		}
	}
	// Render Functions
	function renderError(error) {
		if (error != null) {
			return <li>{error}</li>;
		}
		return null;
	}
	function renderErrorList() {
		if (errorMsg != null) {
			return (
				<div className="error-list">
					<ul>
						{renderError(errorMsg.alias)}
						{renderError(errorMsg.rfc)}
						{renderError(errorMsg.name)}
						{renderError(errorMsg.street)}
						{renderError(errorMsg.ext)}
						{renderError(errorMsg.int)}
						{renderError(errorMsg.selectedState)}
						{renderError(errorMsg.zip)}
						{renderError(errorMsg.neighborhood)}
						{renderError(errorMsg.city)}
						{renderError(errorMsg.county)}
						{renderError(errorMsg.regime)}
						{renderError(errorMsg.uso)}
						{renderError(errorMsg.error)}
					</ul>
				</div>
			);
		}
		return null;
	}
	function getFieldProps(p) {
		if (
			errorMsg == null ||
			!(errorMsg != null && p in errorMsg) ||
			(errorMsg != null && p in errorMsg && errorMsg[p] == null)
		) {
			if (p === "street" || p === "name") {
				return { className: "full" };
			}
			if (p === "ext" || p === "int") {
				return { className: "half" };
			}
		}
		if (errorMsg != null && p in errorMsg && errorMsg[p] != null) {
			if (p === "street" || p === "name") {
				return { className: "full error" };
			}
			if (p === "ext" || p === "int") {
				return { className: "half error" };
			}
			return { className: "error" };
		}
		return {};
	}
	function renderLoader() {
		if (isLoadingAdd) {
			return <Loader msg="Por favor espere..." />;
		}
		return null;
	}
	const defaultOption = selectedState == null ? "Estado" : selectedState;
	const regimeOptions = mapCfdiOptions(regimeList, cfdiType);
	const defaultRegimeOption = regime == null ? "Régimen Fiscal" : regime;
	const usoOptions = mapCfdiOptions(usoList, cfdiType);
	const defaultUsoOption = uso == null ? "Uso de CFDI" : uso;
	function renderForm() {
		if (!isLoadingAdd) {
			return (
				<form onSubmit={handleSubmit}>
					<div className="address-form">
						<div>
							<div {...getFieldProps("alias")}>
								<label>Alias</label>
								<input
									type="text"
									className="modal-field"
									placeholder="Alias"
									onChange={handleAliasChange}
									value={alias}
									autoComplete="custom-addr-alias"
									tabIndex={1}
								/>
							</div>
							<div {...getFieldProps("rfc")}>
								<label>RFC</label>
								<input
									type="text"
									className="modal-field"
									placeholder="RFC"
									onChange={handleRfcChange}
									value={rfc}
									autoComplete="erp-taxid-from-database"
									tabIndex={2}
								/>
							</div>
						</div>
						<div>
							<div {...getFieldProps("name")}>
								<label>Nombre o Razón Social</label>
								<input
									type="text"
									className="modal-field"
									placeholder="Nombre o Razón Social"
									onChange={handleNameChange}
									value={name}
									autoComplete="organization"
									tabIndex={3}
								/>
							</div>
						</div>
						<div>
							<div {...getFieldProps("regime")}>
								<label>Régimen Fiscal</label>
								<ReactDropdown
									options={regimeOptions}
									onChange={handleRegimeSelect}
									value={defaultRegimeOption}
									placeholder="Régimen Fiscal"
								/>
							</div>
							<div {...getFieldProps("uso")}>
								<label>Uso de CFDI</label>
								<ReactDropdown
									options={usoOptions}
									onChange={handleUsoSelect}
									value={defaultUsoOption}
									placeholder="Uso de CFDI"
								/>
							</div>
						</div>
						<div>
							<div {...getFieldProps("street")}>
								<label>Calle</label>
								<input
									type="text"
									className="modal-field"
									placeholder="Calle"
									onChange={handleStreetChange}
									value={street}
									autoComplete="address-line1"
									tabIndex={4}
								/>
							</div>
						</div>
						<div>
							<div {...getFieldProps("ext")}>
								<label>Num Ext</label>
								<input
									type="text"
									className="modal-field"
									placeholder="Num Ext"
									onChange={handleExtChange}
									value={ext}
									tabIndex={5}
								/>
							</div>
							<div {...getFieldProps("int")}>
								<label>Num Int</label>
								<input
									type="text"
									className="modal-field"
									placeholder="Num Int"
									onChange={handleIntChange}
									value={int}
									tabIndex={6}
								/>
							</div>
							<div {...getFieldProps("selectedState")}>
								<label>Estado</label>
								<ReactDropdown
									options={options}
									onChange={handleSelectState}
									value={defaultOption}
									placeholder="Estado"
								/>
							</div>
						</div>
						<div>
							<div {...getFieldProps("zip")}>
								<label>Código Postal</label>
								<ZipCodeAutoSuggest onSelect={handleZipChange} />
							</div>
							<div {...getFieldProps("neighborhood")}>
								<label>Colonia</label>
								<NeighborhoodAutosuggest onSelect={handleNeighborhoodChange} />
							</div>
						</div>
						<div>
							<div {...getFieldProps("city")}>
								<label>Ciudad</label>
								<CityAutosuggest onSelect={handleCityChange} />
							</div>
							<div {...getFieldProps("county")}>
								<label>Delegación o Municipio</label>
								<CountyAutosuggest onSelect={handleCountyChange} />
							</div>
						</div>
						<div>
							<div className="full">{renderErrorList()}</div>
						</div>
						<div>
							<div className="full center">
								<div className="right">
									<div onClick={handleCloseClick} className="modal-button ">
										Cancelar
									</div>
									<button type="submit" tabIndex={12} className="button main">
										Guardar
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			);
		}
		return null;
	}
	return (
		<div>
			<Modal
				isOpen={isOpenAdd}
				className="modal"
				overlayClassName="modal-overlay"
				onRequestClose={handleOverlayCloseClick}
				contentLabel="Nueva Dirección de Envío"
			>
				<div className="modal-inner" id="register-inner">
					<div className="modal-close" onClick={handleCloseClick}></div>
					<h2>Nueva Dirección de Facturación</h2>
					{renderLoader()}
					{renderForm()}
				</div>
			</Modal>
		</div>
	);
}
