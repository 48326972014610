import { createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";
import history from "../history";
import { BASE_API_URL } from "../constants";
import { toggleShowAlert, setAlert } from "features/alerts/alertsSlice";

const initialState = {
	isLoading: false,
  isOpen: false,
	errorMsg: null,
};

const sendConfirmation = createSlice({
	name: "sendConfirmation",
	initialState,
	reducers: {
		requestConfirmation(state) {
			state.isLoading = true;
		},
		completeConfirmation(state) {
			state.isLoading = false;
		},
		toggleShowConfirmation(state, action) {
			if (!action.payload.isOpen) {
				ReactGA.event({
					category: "Cuenta",
					action: "Mostrar Enviar Confirmación"});
			}
			state.isOpen = !action.payload.isOpen;
		},
		setConfirmationErrorMsg(state, action) {
			state.errorMsg = action.payload.errorMsg;
		},
		clearConfirmationData: () => initialState,
	},
});

export const {
	requestConfirmation,
	completeConfirmation,
	toggleShowConfirmation,
	setConfirmationErrorMsg,
	clearConfirmationData,
} = sendConfirmation.actions;

export const fetchSendConfirmation = (email) => async (dispatch) => {
	const headers = {
		"Content-Type": "application/json",
	};
	// const theState = getState();
	const options = {
		method: "POST",
		headers: headers,
		body: JSON.stringify({
			email: email,
		}),
	};
	dispatch(requestConfirmation());
	const url = BASE_API_URL + "/api/Cuenta/Confirmacion/";
	let responseStatus = 0;
	return fetch(url, options)
		.then((response) => {
			responseStatus = response.status;
			if (response.ok) {
				return response.text();
			} else {
				return response.text().then((text) => Promise.reject(text));
			}
		})
		.then(() => {
			ReactGA.event({
				category: "Cuenta",
				action: "Enviar Confirmación",
			});
			dispatch(completeConfirmation());
			dispatch(toggleShowAlert({ isOpen: false }));
			dispatch(clearConfirmationData());
			dispatch(
				setAlert({
					alert: {
						title: "¡Excelente!",
						description:
							"Se envió el correo de confirmación. No olvides también verificar que el correo no se encuentre en el filtro de spam.",
						details: null,
					},
				})
			);
			history.push("/login")
		})
		.catch((error) => {
			// TODO: fetch error actions
			dispatch(completeConfirmation());
			// Not Found (404)
			if (responseStatus === 404) {
				dispatch(
					setConfirmationErrorMsg({
						errorMsg: {
							error: "Ocurrió un error, verifica que el correo sea correcto.",
						},
					})
				);
				ReactGA.event({
					category: "Cuenta",
					action: "Error al enviar Confirmación",
					label: "Ocurrió un error, verifica que el correo sea correcto.",
				});
			}
			// Bad Request (400)
			if (responseStatus === 400) {
				if (error.includes("Message")) {
					const json = JSON.parse(error);
					dispatch(
						setConfirmationErrorMsg({ errorMsg: { error: json.Message } })
					);
					ReactGA.event({
						category: "Cuenta",
						action: "Error al enviar Confirmación",
						label: json.Message,
					});
				} else {
					dispatch(
						setConfirmationErrorMsg({
							errorMsg: {
								error:
									"Ocurrió un error, por favor notifícalo al empleado que te invitó.",
							},
						})
					);
					ReactGA.event({
						category: "Cuenta",
						action: "Error al enviar Confirmación",
						label:
							"Ocurrió un error, por favor notifícalo al empleado que te invitó.",
					});
				}
			}
		});
};

export default sendConfirmation.reducer;
